import {Fragment, useState} from 'react'
import navigation from '@src/navigation/verticalRd'
import classnames from 'classnames'
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'
import {PropTypes} from "prop-types"

const Sidebar = props => {
    const {menuCollapsed, routerProps, currentActiveItem} = props

    const [groupOpen, setGroupOpen] = useState([])
    const [groupActive, setGroupActive] = useState([])
    const [activeItem, setActiveItem] = useState(null)

    const [menuHover, setMenuHover] = useState(false)

    const onMouseEnter = () => {
        if (menuCollapsed) {
            setMenuHover(true)
        }
    }

    return (
        <Fragment>
            <div className={classnames('main-menu menu-fixed menu-accordion menu-shadow menu-light', {expanded: menuHover || menuCollapsed === false})}>
                <button className='btn nav-button'
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={() => setMenuHover(false)}
                >
                    <Fragment>
                        <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
                        <ul className='navigation navigation-main'>
                            <VerticalNavMenuItems
                                items={navigation}
                                groupActive={groupActive}
                                setGroupActive={setGroupActive}
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                                groupOpen={groupOpen}
                                setGroupOpen={setGroupOpen}
                                routerProps={routerProps}
                                menuCollapsed={menuCollapsed}
                                menuHover={menuHover}
                                currentActiveItem={currentActiveItem}
                            />
                        </ul>
                    </Fragment>
                </button>
            </div>
        </Fragment>
    )
}

Sidebar.propTypes = {
    menuCollapsed: PropTypes.bool.isRequired,
    routerProps: PropTypes.object.isRequired,
    currentActiveItem: PropTypes.string
}

export default Sidebar
