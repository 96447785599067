import {Fragment, useState} from "react"
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"
import "@styles/base/pages/app-ecommerce.scss"
import UpcommingPartnerships from './upcommingPartnerships'
import PastPartnerships from './pastPartnetships'
import Invoices from './invoices'

const ManagePartnerships = () => {
    const [active, setActive] = useState("1")

    return (
        <Fragment>
            <Nav tabs className="my-2">
                <NavItem>
                    <NavLink active={active === "1"} onClick={() => setActive("1")}>Invoices</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={active === "2"} onClick={() => setActive("2")}>Upcoming Partnerships</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={active === "3"} onClick={() => setActive("3")}>Past Partnerships</NavLink>
                </NavItem>
            </Nav>
            <TabContent className="py-50" activeTab={active}>
                <TabPane tabId="1">
                    <Invoices/>
                </TabPane>
                <TabPane tabId="2">
                    <UpcommingPartnerships/>
                </TabPane>
                <TabPane tabId="3">
                    <PastPartnerships/>
                </TabPane>
            </TabContent>
        </Fragment>
    )
}
export default ManagePartnerships
