import {useState, Fragment, useEffect, useRef} from 'react'
import Flatpickr from 'react-flatpickr'
import DataTable from 'react-data-table-component'
import {
    ChevronDown,
    Search,
    RefreshCcw
} from 'react-feather'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    Spinner
} from 'reactstrap'

import {useSelector, useDispatch} from 'react-redux'
import {
    getCommissionReportingData,
    getSearchCommissionReports
} from '@src/views/tables/data-tables-admin-comission-reports/store/actions'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/libs/flatpickr/flatpickr.scss'

const CommissionReport = () => {
    const [Picker, setPicker] = useState('')
    const [searchRdName, setSearchRdName] = useState('')
    const [searchEventTitle, setSearchEventTitle] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [filteredData, setFilteredData] = useState([])
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)
    const refComp = useRef(null)

    const commissionReportsSelector = useSelector(store => store.adminCommissionReport)
    const dispatch = useDispatch()

    useEffect(() => {
        const commissionReports = commissionReportsSelector?.commissionReports ? commissionReportsSelector?.commissionReports : null
        if (commissionReports) {
            const {links, meta, pages} = commissionReports
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }
    }, [commissionReportsSelector])

    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getCommissionReportingData())
    }, [])

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'RD Name',
            selector: 'rd_name',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Event Title',
            selector: 'event_title',
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Event Date',
            selector: 'start_date',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Sponsorship Fee',
            selector: 'sponsorship_fee',
            sortable: true,
            minWidth: '210px',
            cell: row => <span>${row.sponsorship_fee || 0}</span>
        },
        {
            name: 'Sponsoring Brands',
            selector: 'sponsoring_brands',
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Commission',
            selector: 'commission',
            sortable: true,
            minWidth: '120px',
            cell: row => <span>${row.commission || 0}</span>
        }
    ])

    // ** Function to handle Pagination
    const handlePagination = page => setCurrentPage(page.selected)

    // ** Table data to render
    const dataToRender = () => {
        if (commissionReportsSelector?.commissionReports) {
            return commissionReportsSelector?.commissionReports?.data
        }
    }

    const makeDate = (range) => {
        const arr = []
        range.map(i => {
            const date = new Date(i)
            const year = date.getFullYear()
            let month = (1 + date.getMonth()).toString()
            month = month.length > 1 ? month : `${month}`
            let day = date.getDate().toString()
            day = day.length > 1 ? day : `${day}`
            arr.push(`${month}-${day}-${year}`)
            return true
        })
        setPicker(arr)
    }

    const searchCommissionReports = () => {
        setIsTableLoading(true)
        dispatch(getSearchCommissionReports(false, {
            rd_name: searchRdName,
            event_name: searchEventTitle,
            from_date: Picker ? Picker[0] : '',
            to_date: Picker ? Picker[1] : ''
        }))
    }

    const refreshSearchForm = () => {
        refComp.current.flatpickr.clear()
        setSearchRdName('')
        setSearchEventTitle('')
        setPicker('')
        setIsTableLoading(true)
        dispatch(getCommissionReportingData())
    }

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        dispatch(getSearchCommissionReports(true, {
            rd_name: searchRdName,
            event_name: searchEventTitle,
            from_date: Picker ? Picker[0] : '',
            to_date: Picker ? Picker[1] : ''
        }, pageUrl))
    }

    return (
        <Fragment>
            <Card>
                <CardHeader className='border-bottom'>
                    <CardTitle tag='h4'>Commission Report</CardTitle>
                    <div className='d-flex mt-md-0 mt-1'>
                        <h4 className="my-0">Earned Commission:<b className="ml-1 text-primary">${commissionReportsSelector?.commissionReports?.total_earn || 0}</b></h4>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>RD Name</Label>
                                <Input id='name' placeholder='RD Name' value={searchRdName}
                                       onChange={(e) => setSearchRdName(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='email'>Event Title</Label>
                                <Input
                                    type='text'
                                    id='email'
                                    placeholder='Event Title'
                                    value={searchEventTitle}
                                    onChange={(e) => setSearchEventTitle(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Event Start & End Date:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    placeholder='Date'
                                    // value={Picker}
                                    options={{mode: 'range', dateFormat: 'M d, Y'}}
                                    onChange={date => makeDate(date)}
                                    ref={refComp}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <div className="admin-filter-actions inline-direction">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchCommissionReports}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchForm}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={currentPage + 1}
                            data={dataToRender()}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
            </Card>
        </Fragment>
    )
}

export default CommissionReport
