import {Link, useLocation} from 'react-router-dom'
import classnames from 'classnames'
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap'
import {isNavGroupActive} from '@layouts/utils'
import HorizontalNavMenuItems from './HorizontalNavMenuItems'
import {PropTypes} from "prop-types"

const HorizontalNavMenuGroup = props => {
    const {
        item,
        submenu,
        groupActive,
        onMouseEnter,
        onMouseLeave,
        openDropdown,
        setGroupActive,
        activeItem,
        setActiveItem,
        routerProps,
        setOpenDropdown,
        currentActiveItem
    } = props

    const currentURL = useLocation().pathname

    const menuModifiers = {
        setMaxHeight: {
            enabled: true,
            fn: data => {
                const pageHeight = window.innerHeight,
                    ddTop = data.instance.reference.getBoundingClientRect().top,
                    ddHeight = data.popper.height
                let maxHeight, stylesObj

                if (pageHeight - ddTop - ddHeight - 28 < 1) {
                    maxHeight = pageHeight - ddTop - 25
                    stylesObj = {
                        maxHeight,
                        overflowY: 'auto'
                    }
                }

                const ddRef = data.instance.popper.getBoundingClientRect()

                if (ddRef.left + ddRef.width - (window.innerWidth - 16) >= 0) {
                    data.instance.popper.closest('.dropdown').classList.add('openLeft')
                }

                return {
                    ...data,
                    styles: {
                        ...stylesObj
                    }
                }
            }
        }
    }
    return (
        <Dropdown
            tag='li'
            className={classnames({
                'nav-item': submenu === false,
                'dropdown-submenu': submenu === true,
                'sidebar-group-active active':
                    isNavGroupActive(item.children, currentURL, routerProps) || groupActive.includes(item.id)
            })}
            isOpen={openDropdown.includes(item.id)}
            toggle={() => onMouseEnter(item.id)}
            onMouseEnter={() => onMouseEnter(item.id)}
            onMouseLeave={() => onMouseLeave(item.id)}
        >
            <DropdownToggle
                to='/'
                tag={Link}
                className={classnames('dropdown-toggle d-flex align-items-center', {
                    'dropdown-item': submenu === true,
                    'nav-link': submenu === false
                })}
                onClick={e => e.preventDefault()}
            >
                {item.icon}
                <span>
          {item.title}
        </span>
            </DropdownToggle>
            <DropdownMenu tag='ul' modifiers={menuModifiers}>
                <HorizontalNavMenuItems
                    submenu={true}
                    parentItem={item}
                    items={item.children}
                    activeItem={activeItem}
                    groupActive={groupActive}
                    routerProps={routerProps}
                    openDropdown={openDropdown}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    setActiveItem={setActiveItem}
                    setGroupActive={setGroupActive}
                    isChild={true}
                    setOpenDropdown={setOpenDropdown}
                    currentActiveItem={currentActiveItem}
                />
            </DropdownMenu>
        </Dropdown>
    )
}

HorizontalNavMenuGroup.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        icon: PropTypes.node,
        title: PropTypes.string.isRequired,
        children: PropTypes.array
    }).isRequired,
    submenu: PropTypes.bool,
    groupActive: PropTypes.arrayOf(PropTypes.string).isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    openDropdown: PropTypes.arrayOf(PropTypes.string).isRequired,
    setGroupActive: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    setActiveItem: PropTypes.func.isRequired,
    routerProps: PropTypes.object.isRequired,
    setOpenDropdown: PropTypes.func.isRequired,
    currentActiveItem: PropTypes.string
}

HorizontalNavMenuGroup.defaultProps = {
    submenu: false,
    activeItem: '',
    currentActiveItem: null
}

export default HorizontalNavMenuGroup
