import axios from 'axios'
import {
    RESOURCES_CONTENT,
    RESOURCES_CONTENT_DELETE,
    RESOURCES_CONTENT_STATUS,
    UPLOAD_DOCS, UPLOAD_LINK, UPLOAD_MEDIA
} from "../actionsTypes"

const API_URL = process.env.REACT_APP_BASEURL

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const index = () => async (dispatch) => {

}

export const uploadLinkResourceAction = (dataObj) => async (dispatch) => {
    dispatch({type: UPLOAD_LINK.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/upload-resource-link`, dataObj, {headers: headersObj})
        dispatch({type: UPLOAD_LINK.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: UPLOAD_LINK.ERROR, payload: error.message})
    }
}

export const uploadLinkResourceResetAction = () => async (dispatch) => {
    dispatch({type: UPLOAD_LINK.RESET})
}

export const resourcesContentAction = (dataObj) => async (dispatch) => {
    dispatch({type: RESOURCES_CONTENT.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/resources-content`, dataObj, {headers: headersObj})
        dispatch({type: RESOURCES_CONTENT.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: RESOURCES_CONTENT.ERROR, payload: error.message})
    }
}

export const resourcesContentStatusAction = (dataObj) => async (dispatch) => {
    dispatch({type: RESOURCES_CONTENT_STATUS.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/resources-content-status`, dataObj, {headers: headersObj})
        dispatch({type: RESOURCES_CONTENT_STATUS.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: RESOURCES_CONTENT_STATUS.ERROR, payload: error.message})
    }
}

export const resourcesContentStatusResetAction = () => async (dispatch) => {
    dispatch({type: RESOURCES_CONTENT_STATUS.RESET})
}

export const resourcesContentDeleteAction = (dataObj) => async (dispatch) => {
    dispatch({type: RESOURCES_CONTENT_DELETE.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/resources-content-delete`, dataObj, {headers: headersObj})
        dispatch({type: RESOURCES_CONTENT_DELETE.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: RESOURCES_CONTENT_DELETE.ERROR, payload: error.message})
    }
}

export const resourcesContentDeleteResetAction = () => async (dispatch) => {
    dispatch({type: RESOURCES_CONTENT_DELETE.RESET})
}

export const uploadDocsAction = (dataObj) => async (dispatch) => {
    dispatch({type: UPLOAD_DOCS.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/upload-resource-docs`, dataObj, {headers: headersObj})
        dispatch({type: UPLOAD_DOCS.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: UPLOAD_DOCS.ERROR, payload: error.message})
    }
}

export const uploadDocsResetAction = () => async (dispatch) => {
    dispatch({type: UPLOAD_DOCS.RESET})
}

export const uploadMediaAction = (dataObj) => async (dispatch) => {
    dispatch({type: UPLOAD_MEDIA.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/upload-resource-media`, dataObj, {headers: headersObj})
        dispatch({type: UPLOAD_MEDIA.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: UPLOAD_MEDIA.ERROR, payload: error.message})
    }
}

export const uploadMediaResetAction = () => async (dispatch) => {
    dispatch({type: UPLOAD_MEDIA.RESET})
}