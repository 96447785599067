import {useState, Fragment, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {
    getParticipationRequestsData,
    searchParticipationRequestsData,
    nullSearchData
} from '@src/views/tables/data-tables-admin-brands/store/actions'
import Flatpickr from 'react-flatpickr'
import {
    ChevronDown,
    Search,
    RefreshCcw
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardBody,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    Spinner,
    Badge
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const RequestsForParticipation = () => {
    const participationRequests = useSelector(store => store.dataTablesAdminBrands)
    const dispatch = useDispatch()
    const {id} = useParams()
    const [searchDietitionName, setSearchDietitionName] = useState('')
    const [searchEventName, setSearchEventName] = useState('')
    const [searchEventFrom, setSearchEventFrom] = useState('')
    const [searchEventTo, setSearchEventTo] = useState('')
    const [searchRequestFrom, setSearchRequestFrom] = useState('')
    const [searchRequestTo, setSearchRequestTo] = useState('')
    const [searchMembershipAmount, setSearchMembershipAmount] = useState('')
    const [isTableLoading, setIsTableLoading] = useState(true)
    const [listingData, setListingData] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [paginationInfo, setPaginationInfo] = useState(null)

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Dietition Name',
            selector: 'dietitian_name',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Event Name',
            selector: 'event_name',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Event Date',
            selector: 'event_date',
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Request Date',
            selector: 'request_date',
            sortable: true,
            minWidth: '50px'
        },
        {
            name: 'Sponsorship Fee',
            selector: 'sponsorship_fee',
            sortable: true,
            minWidth: '70px',
            cell: row => <span>${row.sponsorship_fee || '0'}</span>
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '70px',
            cell: row => <span>
        {row.status === 'paid' && <Badge color={'success'}>Paid</Badge>}
                {row.status === 'accepted' && <Badge color={'primary'}>Accepted</Badge>}
                {row.status === 'rejected' && <Badge color={'danger'}>Rejected</Badge>}
                {row.status === 'requested' && <Badge color={'warning'}>Requested</Badge>}
                {row.status === 'invoice_sent' && <Badge color={'secondary'}>Invoice Sent</Badge>}
                {row.status === 'failed' && <Badge color={'danger'}>Failed</Badge>}
      </span>
        }
    ])

    useEffect(() => {
        if (!!participationRequests?.participationRequest) {
            setListingData(participationRequests.participationRequest)
            const {links, meta, pages} = participationRequests.participationRequest
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }
    }, [participationRequests])

    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getParticipationRequestsData(id))
    }, [])


    const searchParticipationRequest = () => {
        setIsTableLoading(true)
        // Event From date
        const eventFrom = new Date(searchEventFrom)
        const finalEventFrom = `${eventFrom.getMonth() + 1}-${eventFrom.getDate()}-${eventFrom.getFullYear()}`
        // Event To date
        const eventTo = new Date(searchEventTo)
        const finalEventTo = `${eventTo.getMonth() + 1}-${eventTo.getDate()}-${eventTo.getFullYear()}`
        // Request From date
        const requestFrom = new Date(searchRequestFrom)
        const finalRequestFrom = `${requestFrom.getMonth() + 1}-${requestFrom.getDate()}-${requestFrom.getFullYear()}`
        // Request To date
        const requestTo = new Date(searchRequestTo)
        const finalRequestTo = `${requestTo.getMonth() + 1}-${requestTo.getDate()}-${requestTo.getFullYear()}`

        const params = {
            event_name: searchDietitionName,
            rd_name: searchEventName,
            from_date: finalEventFrom === 'NaN-NaN-NaN' ? "" : finalEventFrom,
            to_date: finalEventTo === 'NaN-NaN-NaN' ? "" : finalEventTo,
            request_from: finalRequestFrom === 'NaN-NaN-NaN' ? "" : finalRequestFrom,
            request_to: finalRequestTo === 'NaN-NaN-NaN' ? "" : finalRequestTo,
            amount: searchMembershipAmount
        }

        dispatch(searchParticipationRequestsData(id, params, false))
    }

    const refreshParticipationRequest = () => {
        setSearchDietitionName('')
        setSearchEventName('')
        setSearchEventFrom('')
        setSearchEventTo('')
        setSearchRequestFrom('')
        setSearchRequestTo('')
        setSearchMembershipAmount('')
        dispatch(nullSearchData('searchParticipationRequest'))
        setIsTableLoading(true)
    }

    const dataToRender = () => {
        return participationRequests?.participationRequest?.data
    }

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        // Event From date
        const eventFrom = new Date(searchEventFrom)
        const finalEventFrom = `${eventFrom.getMonth() + 1}-${eventFrom.getDate()}-${eventFrom.getFullYear()}`
        // Event To date
        const eventTo = new Date(searchEventTo)
        const finalEventTo = `${eventTo.getMonth() + 1}-${eventTo.getDate()}-${eventTo.getFullYear()}`
        // Request From date
        const requestFrom = new Date(searchRequestFrom)
        const finalRequestFrom = `${requestFrom.getMonth() + 1}-${requestFrom.getDate()}-${requestFrom.getFullYear()}`
        // Request To date
        const requestTo = new Date(searchRequestTo)
        const finalRequestTo = `${requestTo.getMonth() + 1}-${requestTo.getDate()}-${requestTo.getFullYear()}`

        const params = {
            event_name: searchDietitionName,
            rd_name: searchEventName,
            from_date: finalEventFrom === 'NaN-NaN-NaN' ? "" : finalEventFrom,
            to_date: finalEventTo === 'NaN-NaN-NaN' ? "" : finalEventTo,
            request_from: finalRequestFrom === 'NaN-NaN-NaN' ? "" : finalRequestFrom,
            request_to: finalRequestTo === 'NaN-NaN-NaN' ? "" : finalRequestTo,
            amount: searchMembershipAmount
        }
        dispatch(searchParticipationRequestsData(id, params, true, pageUrl))
    }

    return (
        <Fragment>
            <Card className="mb-0">
                <CardBody className="">
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Dietitian Name:</Label>
                                <Input id='name' placeholder='Dietitian Name'
                                       value={searchDietitionName}
                                       onChange={(e) => setSearchDietitionName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Event Name:</Label>
                                <Input id='name' placeholder='Event Name'
                                       value={searchEventName}
                                       onChange={(e) => setSearchEventName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Event Date From:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    value={searchEventFrom}
                                    onChange={date => setSearchEventFrom(date)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Event Date To:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    value={searchEventTo}
                                    onChange={date => setSearchEventTo(date)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Request From:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    value={searchRequestFrom}
                                    onChange={date => setSearchRequestFrom(date)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Request To:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    value={searchRequestTo}
                                    onChange={date => setSearchRequestTo(date)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Membership Amount:</Label>
                                <Input id='name' placeholder='Membership Amount'
                                       type="number"
                                       value={searchMembershipAmount}
                                       onChange={(e) => setSearchMembershipAmount(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="admin-filter-actions ml-auto text-right">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchParticipationRequest}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshParticipationRequest}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <Fragment>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={currentPage + 1}
                            data={dataToRender()}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </Fragment>
                )}
            </Card>
        </Fragment>
    )
}

export default RequestsForParticipation
