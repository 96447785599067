import {Link, useLocation} from 'react-router-dom'
import classnames from 'classnames'
import {Collapse, Badge} from 'reactstrap'
import VerticalNavMenuItems from './VerticalNavMenuItems'
import {isNavGroupActive, getAllParents} from '@layouts/utils'
import {PropTypes} from "prop-types"

const VerticalNavMenuGroup = ({
                                  item,
                                  groupActive,
                                  setGroupActive,
                                  activeItem,
                                  setActiveItem,
                                  groupOpen,
                                  setGroupOpen,
                                  parentItem,
                                  menuCollapsed,
                                  menuHover,
                                  routerProps,
                                  currentActiveItem
                              }) => {
    const currentURL = useLocation().pathname

    const toggleOpenGroup = (item, parentItem) => {
        let openArr = groupOpen
        let allParents

        if (parentItem) {
            allParents = getAllParents(parentItem, 'id')
            allParents.pop()
        }

        if (groupOpen && allParents && groupOpen[0] === allParents[0]) {
            groupOpen.includes(item) ? openArr.splice(openArr.indexOf(item), 1) : openArr.push(item)
        } else {
            openArr = []
            if (!groupOpen.includes(item)) {
                openArr.push(item)
            }
        }
        setGroupOpen([...openArr])
    }

    const toggleActiveGroup = (item, parentItem) => {
        let activeArr = groupActive
        let allParents

        if (parentItem) {
            allParents = getAllParents(parentItem, 'id')
            activeArr = allParents
        } else {
            activeArr.includes(item) ? activeArr.splice(activeArr.indexOf(item), 1) : activeArr.push(item)
        }
        const openArr = groupOpen.filter(val => !activeArr.includes(val))
        setGroupOpen([...openArr])
        setGroupActive([...activeArr])
    }

    const onCollapseClick = (e, item) => {
        if ((groupActive && groupActive.includes(item.id)) || isNavGroupActive(item.children, currentURL, routerProps)) {
            toggleActiveGroup(item.id)
        } else {
            toggleOpenGroup(item.id, parentItem)
        }
        e.preventDefault()
    }

    const openClassCondition = id => {
        if ((menuCollapsed && menuHover) || menuCollapsed === false) {
            if (groupActive.includes(id) || groupOpen.includes(item.id)) {
                return true
            }
        } else if (groupActive.includes(id) && menuCollapsed && menuHover === false) {
            return false
        } else {
            return null
        }
    }

    return (
        <li
            className={classnames('nav-item has-sub', {
                open: openClassCondition(item.id),
                'menu-collapsed-open': groupActive.includes(item.id),
                'sidebar-group-active': groupActive.includes(item.id) || groupOpen.includes(item.id)
            })}
        >
            <Link className={`d-flex align-items-center ${item.tourClass}`} to='/' onClick={e => onCollapseClick(e, item)}>
                {item.icon}
                <span className='menu-title text-truncate'>
                    {item.title}
                </span>

                {item.badge && item.badgeText ? (
                    <Badge className='ml-auto mr-2' color={item.badge} pill>
                        {item.badgeText}
                    </Badge>
                ) : null}
            </Link>

            <ul className='menu-content'>
                <Collapse isOpen={(groupActive && groupActive.includes(item.id)) || (groupOpen && groupOpen.includes(item.id))}>
                    <VerticalNavMenuItems
                        items={item.children}
                        groupActive={groupActive}
                        setGroupActive={setGroupActive}
                        groupOpen={groupOpen}
                        setGroupOpen={setGroupOpen}
                        toggleActiveGroup={toggleActiveGroup}
                        parentItem={item}
                        menuCollapsed={menuCollapsed}
                        menuHover={menuHover}
                        routerProps={routerProps}
                        currentActiveItem={currentActiveItem}
                        activeItem={activeItem}
                        setActiveItem={setActiveItem}
                    />
                </Collapse>
            </ul>
        </li>
    )
}

VerticalNavMenuGroup.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        icon: PropTypes.node,
        title: PropTypes.string.isRequired,
        badge: PropTypes.string,
        badgeText: PropTypes.string,
        children: PropTypes.array,
        tourClass: PropTypes.string
    }).isRequired,
    groupActive: PropTypes.array.isRequired,
    setGroupActive: PropTypes.func.isRequired,
    activeItem: PropTypes.any,
    setActiveItem: PropTypes.func.isRequired,
    groupOpen: PropTypes.array.isRequired,
    setGroupOpen: PropTypes.func.isRequired,
    parentItem: PropTypes.object,
    menuCollapsed: PropTypes.bool.isRequired,
    menuHover: PropTypes.bool.isRequired,
    routerProps: PropTypes.object.isRequired,
    currentActiveItem: PropTypes.any
}

export default VerticalNavMenuGroup
