import axios from 'axios'
import { RD, NULL } from '../actionTypes'
import { getRdUnreadMessages } from '../../../../../redux/actions/rdDashboard'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

// ** Get User Profile
export const getUserProfile = () => {
  return dispatch => {
    return axios.get('/apps/chatRd/users/profile-user').then(res => {
      return dispatch({
        type: 'GET_USER_PROFILE',
        userProfile: res.data
      })
    })
  }
}
export const getMessageTemplete = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}rd/load-msg-template`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${getToken()}`
        }
      }).then(res => {
        // console.log(res.data)
        dispatch({
          type: RD.CHAT_GET_MESSAGE_TEMPLETES,
          messageTemplete: res.data
        })
      })
  }
}

export const changeReduxState = (name, value) => {
  return dispatch => {
    switch (name) {
      case NULL.LOAD_SELECTED_RD_USER_CHAT:
        dispatch({
          type: RD.LOAD_SELECTED_USER_CHAT,
          selectedUserRDChatLoading: value
        })
        break
    }
  }
}

export const selectChatFromServer = id => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}rd/load-chat-window?user_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${getToken()}`
          }
        }).then(res => {
      dispatch({type: 'SELECT_CHAT_FROM_SERVER', selectedUserChat: res.data})
      dispatch(changeReduxState(NULL.LOAD_SELECTED_RD_USER_CHAT, false))
      dispatch(getMessageTemplete())
      dispatch(getRdUnreadMessages())

    }).catch(e => {
      dispatch({type: 'RD_CHAT_LOAD_ERROR', payload: e})
    })
  }
}

export const saveTemplete = obj => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`
  }
  const data = {
    title: obj.title,
    template: obj.message
  }
  return dispatch => {
    axios.post(`${process.env.REACT_APP_BASEURL}rd/save-msg-template`, data, {
      headers
    })
      .then((response) => {
        dispatch(getMessageTemplete())
        // console.log('in action response for templete save rd', response)
      })
      .catch((error) => {
        // console.log('error')
      })

  }
}

export const recieveMsgRD = obj => {

  // console.log('receive messeg rd', obj)
  return dispatch => {
    dispatch({ type: RD.NEW_MESSAGE, message: obj })
    // dispatch(selectChatFromServer(obj.to_user_id))

  }
}
// ** Get Chats & Contacts
export const getChatContacts = () => {
  return dispatch => {
    dispatch({
      type: 'GET_CHAT_CONTACTS',
      data: []
    })
    // axios.get('/apps/chatRd/chats-and-contacts').then(res => {
    //   dispatch({
    //     type: 'GET_CHAT_CONTACTS',
    //     data: res.data
    //   })
    // })
  }
}
export const addRdContactsInRedux = obj => {

  return dispatch => {
    // console.log('console add rdcontacts', obj)
    dispatch({ type: RD.CHAT_ADD_CONTACT, rdContact: obj })

  }
}
export const getUserProfileFromServer = () => {
  const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
  // console.log('user obj', user)
  return dispatch => {
    dispatch({ type: RD.CHAT_GET_USER_PROFILE_FROM_SERVER, rdUserProfile: user })
  }

}
// ** Get Chats & Contacts // get user chats list
export const getChatContactsFromServer = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}rd/load-chat-users`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${getToken()}`
          }
        }).then(res => {
      dispatch({type: RD.CHAT_CONTACTS, chatRdContacts: res.data})
    }).catch(e => {
      dispatch({type: "RD_CONTACT_LOAD_ERROR", payload: e})
    })
  }
}

// ** Select Chat
export const selectChat = id => {
  return dispatch => {
    axios.get('/apps/chatRd/get-chat', { id }).then(res => {
      dispatch({ type: 'SELECT_CHAT', data: res.data })
      dispatch(getChatContacts())
    })
  }
}
export const sendMsgRD = obj => {
  const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
  const chatID = user.chat_id
  return dispatch => {
    axios.post(`${process.env.REACT_APP_BASEURL}rd/save-chat-message`, {
      message: obj.message,
      to_user_id: obj.to_user_id,
      from_user_id: chatID,
      timezone: obj.timezone
    }, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      dispatch(getChatContactsFromServer())
      dispatch(selectChatFromServer(obj.to_user_id))
      dispatch({type: "SEND_MSG_SUCCESS_RESPONSE_RD", payload: res.data})
    })
  }
}
// ** Send Msg
export const sendMsg = obj => {
  return dispatch => {
    axios.post('/apps/chatRd/send-msg', { obj }).then(res => {
      dispatch({ type: 'SEND_MSG', data: res.data })
      dispatch(selectChat(172))
    })
  }
}
export const storeMessagesInRedux = obj => {
  return dispatch => {
    // console.log('console store message in redux', obj)
    dispatch({ type: 'CHAT_RD_WITH_BRANDS', rdChatWithBrands: obj })

  }
}
export const selectedContactDetail = selectedContact => {
  return dispatch => {
    dispatch({ type: RD.CHAT_SELECTED_CONTACT, selectedRdContactDetail: selectedContact })
  }
}
export const uploadMediaToserver = obj => {
  return dispatch => {
    const formData = new FormData()
    formData.append("file", obj.file)
    formData.append("user_to_id", parseInt(obj.to_user_id))
    formData.append("timezone", obj.timezone)
    axios.post(`${process.env.REACT_APP_BASEURL}rd/upload-chat-file`, formData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      const media = {
        to_user_id: obj.to_user_id,
        media: res.data.media,
        file_name:res.data.file_name,
        message: "",
        from_user_id:obj.from_user_id
      }
      obj.socket.emit("new-media", media)
      dispatch(getChatContactsFromServer())
      dispatch(selectChatFromServer(obj.to_user_id))
      dispatch({type: "SEND_MSG_SUCCESS_RESPONSE_RD", payload: res.data})
    })
  }
}
