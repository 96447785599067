import {useState, Fragment, useEffect, useRef} from 'react'
import Flatpickr from 'react-flatpickr'
import {
    ChevronDown,
    Search,
    RefreshCcw
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner
} from 'reactstrap'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {useDispatch, useSelector} from 'react-redux'
import {
    getCancellSubscriptionReportingData,
    getPlansData,
    getSearchCancellSubscriptionReports
} from '@src/views/tables/data-tables-admin-cancelled-subscription-reports/store/actions'
import {Link} from "react-router-dom"

const CancelledSubscription = () => {
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)
    const cancelledSubscriptionReports = useSelector(store => store.adminCancellSubscriptionsReport)
    const dispatch = useDispatch()
    const refComp = useRef(null)

    useEffect(() => {
        const canclledSubscriptions = cancelledSubscriptionReports?.canclledSubscriptions ? cancelledSubscriptionReports?.canclledSubscriptions : null
        if (canclledSubscriptions) {
            const {links, meta, pages} = canclledSubscriptions
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }
    }, [cancelledSubscriptionReports])

    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getCancellSubscriptionReportingData())
        dispatch(getPlansData())
    }, [])

    const [basicModal, setBasicModal] = useState(false)
    const [reasonValue, setReasonValue] = useState([])
    // ** States
    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'company',
            selector: 'company',
            cell: row => <Link to={`/admin/manage-brands/${row.user_id}`} className='company-link'>{row.company}</Link>
        },
        {
            name: 'contact name',
            selector: 'contact_name'
        },
        {
            name: 'purchased date',
            selector: 'purchased_date'
        },
        {
            name: 'subscription plan',
            selector: 'subscription_plan'
        },
        {
            name: 'subscription type',
            selector: 'subscription_type'
        },
        {
            name: 'Total paid',
            selector: 'total_paid',
            cell: row => <span>${row.total_paid}</span>
        },
        {
            name: 'Reason',
            selector: 'reason',
            cell: row => {
                return (
                    <span className="text-primary cursor-pointer"
                          onClick={() => {
                              setReasonValue(row?.reason)
                              setBasicModal(true)
                          }}
                          role="button"
                          tabIndex="0"
                          aria-label="View reason"
                          onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                  e.preventDefault()
                                  setReasonValue(row?.reason)
                                  setBasicModal(true)
                              }
                          }}
                    >
                        View Reason
                    </span>
                )
            }
        }
    ])
    const [Picker, setPicker] = useState('')
    const [searchCompanyName, setSearchCompanyName] = useState('')
    const [searchContactName, setSearchContactName] = useState('')
    const [searchPlanId, setSearchPlanId] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [filteredData, setFilteredData] = useState([])

    // ** Function to handle Pagination
    const handlePagination = page => setCurrentPage(page.selected)

    // ** Table data to render
    const dataToRender = () => {
        if (cancelledSubscriptionReports?.canclledSubscriptions) {
            return cancelledSubscriptionReports?.canclledSubscriptions?.data
        }
    }

    const makeDate = (range) => {
        const arr = []
        range.map(i => {
            const date = new Date(i)

            const year = date.getFullYear()
            let month = (1 + date.getMonth()).toString()
            month = month.length > 1 ? month : `${month}`
            let day = date.getDate().toString()
            day = day.length > 1 ? day : `${day}`
            arr.push(`${month}-${day}-${year}`)
            return true
        })
        setPicker(arr)
    }

    const searchCancelledReports = () => {
        setIsTableLoading(true)
        dispatch(getSearchCancellSubscriptionReports(false, {
            brand_name: searchCompanyName,
            owner_name: searchContactName,
            plan_id: searchPlanId,
            purchase_from: Picker ? Picker[0] : '',
            purchase_to: Picker ? Picker[1] : ''
        }))
    }

    const refreshSearchForm = () => {
        refComp.current.flatpickr.clear()
        setSearchCompanyName('')
        setSearchContactName('')
        setSearchPlanId('')
        setPicker('')
        setIsTableLoading(true)
        dispatch(getCancellSubscriptionReportingData())
    }

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        dispatch(getSearchCancellSubscriptionReports(true, {
            brand_name: searchCompanyName,
            owner_name: searchContactName,
            plan_id: searchPlanId,
            purchase_from: Picker ? Picker[0] : '',
            purchase_to: Picker ? Picker[1] : ''
        }, pageUrl))
    }

    return (
        <Fragment>
            <Card className="brands-my-campaign-table-parent">
                <CardHeader className='border-bottom'>
                    <CardTitle tag='h4'>Cancelled Subscription Report</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='3'>
                            <FormGroup>
                                <Label for='name'>Company</Label>
                                <Input id='name' placeholder='Company' value={searchCompanyName} onChange={(e) => setSearchCompanyName(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='3'>
                            <FormGroup>
                                <Label for='email'>Contact Name</Label>
                                <Input
                                    type='email'
                                    id='email'
                                    placeholder='Contact Name'
                                    value={searchContactName}
                                    onChange={(e) => setSearchContactName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='3'>
                            <FormGroup>
                                <Label for='state'>Subscription Plan</Label>
                                <Input type="select" id='state' placeholder='Subscription Plan' value={searchPlanId} onChange={(e) => setSearchPlanId(e.target.value)}>
                                    <option value=''>Subscription Plan</option>
                                    {cancelledSubscriptionReports.plansListings && cancelledSubscriptionReports.plansListings.map(plans => (
                                        <option value={plans.id}>{plans.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='2' md='2'>
                            <FormGroup>
                                <Label for='date'>Date</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    placeholder='Date'
                                    // value={Picker}
                                    options={{mode: 'range', dateFormat: 'm/d/Y'}}
                                    onChange={date => makeDate(date)}
                                    ref={refComp}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='1' md='1'>
                            <FormGroup>
                                <Label>Go</Label>
                                <div>
                                    <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchCancelledReports}>
                                        <Search size={16}/>
                                    </Button.Ripple>
                                    <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchForm}>
                                        <RefreshCcw size={16}/>
                                    </Button.Ripple>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={currentPage + 1}
                            data={dataToRender()}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
            </Card>
            <Modal isOpen={basicModal} className='modal-dialog-centered' size="lg">
                <ModalHeader toggle={() => {
                    setBasicModal(false)
                    setReasonValue([])
                }}>Reason</ModalHeader>
                <ModalBody>
                    {reasonValue?.length > 0 && reasonValue?.map((item, index) => (
                        <div key={index}>
                            <p className="bg-light p-1">{item.que}</p>
                            <p className="pl-1">{item.ans}</p>
                        </div>
                    ))}
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default CancelledSubscription

