import Avatar from '@components/avatar'
import classnames from 'classnames'
import Select from 'react-select'
import {selectThemeColors} from '@utils'
import {
    Box,
    DollarSign,
    TrendingUp,
    User
} from 'react-feather'
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Spinner,
    Col,
    Media,
    Row,
    UncontrolledTooltip
} from 'reactstrap'
import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getDashboardStatsValues} from '@store/actions/adminDashboard'
import {PropTypes} from "prop-types"

const FilterByOptions = [
    {value: 'all', label: 'All'},
    {value: 'quarter', label: 'Quarter'},
    {value: 'month', label: 'Month'},
    {value: 'year', label: 'Year'}
]

const StatsCard = ({cols}) => {
    const [isStatsLoading, setIsStatsLoading] = useState(false)
    const [isDispatched, setIsDispatched] = useState(true)
    const [statsData, setStatsData] = useState(null)

    const dispatch = useDispatch()
    const adminDashboardStoreSlice = useSelector(state => state.adminDashboardSlice)

    useEffect(() => {
        if (!!adminDashboardStoreSlice?.stats) {
            setStatsData(adminDashboardStoreSlice?.stats?.data)
            setIsStatsLoading(false)
        }
    }, [adminDashboardStoreSlice])

    useEffect(() => {
        if (isDispatched) {
            setIsStatsLoading(true)
            dispatch(getDashboardStatsValues('all'))
            setIsDispatched(false)
        }
    }, [])

    const statsList = [
        {
            title: statsData?.all_brands,
            id: 970,
            subtitle: 'All Brands',
            subtitleHover: 'paid vs. free',
            color: 'light-primary',
            icon: <TrendingUp size={21}/>
        },
        {
            title: statsData?.all_rds,
            id: 971,
            subtitle: "All RDs",
            color: 'light-info',
            icon: <User size={21}/>
        },
        {
            title: statsData?.open_brand_campaigns,
            id: 972,
            subtitle: "Open Brand Campaigns",
            color: 'light-info',
            icon: <User size={21}/>
        },
        {
            title: statsData?.available_rd_opportunities,
            id: 973,
            subtitle: "Available RD Opportunities",
            color: 'light-info',
            icon: <User size={21}/>
        },
        {
            title: statsData?.partnerships_secured,
            id: 974,
            subtitle: 'Partnerships Secured',
            subtitleHover: 'total vs monthly vs quarterly',
            color: 'light-primary',
            icon: <TrendingUp size={21}/>
        },
        {
            title: statsData?.unique_monthly_logins,
            id: 975,
            subtitle: 'Unique Monthly Logins',
            subtitleHover: 'Brands / RDs',
            color: 'light-primary',
            icon: <TrendingUp size={21}/>
        },
        {
            title: statsData?.total_revenue,
            id: 976,
            subtitle: 'Total Revenue',
            subtitleHover: `Subscription (${statsData?.subscription}) - Commission (${statsData?.commission})`,
            color: 'light-success',
            icon: <DollarSign size={21}/>
        },
        {
            title: statsData?.churn,
            id: 977,
            subtitle: 'Brands Churned',
            color: 'light-danger',
            icon: <Box size={21}/>
        }
    ]

    const renderData = () => {
        return statsList.map((item, index) => {
            const margin = Object.keys(cols)
            return (
                <Col
                    key={index}
                    {...cols}
                    className={classnames({
                        [`mb-2`]: index !== statsList.length - 1
                    })}
                >
                    <Media>
                        <Avatar id={`av-tooltip-${item.id}`} color={item.color} icon={item.icon} className='mr-1'/>
                        {item.subtitleHover && <UncontrolledTooltip placement='top' target={`av-tooltip-${item.id}`}>{item.subtitleHover}</UncontrolledTooltip>}
                        <Media className='media-avatar m-auto' body>
                            <h4 className='font-weight-bolder mb-0'>{item.title}</h4>
                            <h6 className='total-partnership'>{item.subtitle}</h6>
                        </Media>
                    </Media>
                </Col>
            )
        })
    }

    const filterStats = (filterValue) => {
        setIsStatsLoading(true)
        dispatch(getDashboardStatsValues(filterValue))
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Statistics</CardTitle>
                <Select
                    theme={selectThemeColors}
                    className='react-select w-250px'
                    classNamePrefix='select'
                    defaultValue={FilterByOptions[0]}
                    options={FilterByOptions}
                    isClearable={false}
                    isSearchable={false}
                    onChange={(e) => filterStats(e.value)}
                />
            </CardHeader>
            <CardBody>
                {isStatsLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isStatsLoading && (<Row>{renderData()}</Row>)}
            </CardBody>
        </Card>
    )
}


StatsCard.propTypes = {
    cols: PropTypes.string
}

export default StatsCard
