import {useContext} from 'react'
import {AbilityContext} from '@src/utility/context/Can'

export const resolveVerticalNavMenuItemComponent = item => {
    if (item.header) return 'VerticalNavMenuSectionHeader'
    if (item.children) return 'VerticalNavMenuGroup'
    return 'VerticalNavMenuLink'
}

export const resolveHorizontalNavMenuItemComponent = item => {
    if (item.children) return 'HorizontalNavMenuGroup'
    return 'HorizontalNavMenuLink'
}

export const isNavLinkActive = (link, currentURL, routerProps) => {
    return (
        currentURL === link ||
        (routerProps && routerProps.meta && routerProps.meta.navLink && routerProps.meta.navLink === link)
    )
}

export const isNavGroupActive = (children, currentURL, routerProps) => {
    return children.some(child => {
        if (child.children) {
            return isNavGroupActive(child.children, currentURL, routerProps)
        }
        return isNavLinkActive(child.navLink, currentURL, routerProps)
    })
}

export const search = (navigation, currentURL, routerProps) => {
    let result
    navigation.some(child => {
        let children
        if (child.children && (children = search(child.children, currentURL, routerProps))) {
            return (result = {
                id: child.id,
                children
            })
        }
        if (isNavLinkActive(child.navLink, currentURL, routerProps)) {
            return (result = {
                id: child.id
            })
        }
    })
    return result
}

export const getAllParents = (obj, match) => {
    const res = []
    const recurse = (obj, current) => {
        for (const key in obj) {
            const value = obj[key]
            if (value !== undefined) {
                if (value && typeof value === 'object') {
                    recurse(value, key)
                } else {
                    if (key === match) {
                        res.push(value)
                    }
                }
            }
        }
    }
    recurse(obj)
    return res
}

export const canViewMenuGroup = item => {
    const ability = useContext(AbilityContext)
    const hasAnyVisibleChild = item.children && item.children.some(i => ability.can(i.action, i.resource))
    if (!(item.action && item.resource)) {
        return hasAnyVisibleChild
    }
    return ability.can(item.action, item.resource) && hasAnyVisibleChild
}

export const canViewMenuItem = item => {
    const ability = useContext(AbilityContext)
    return ability.can(item.action, item.resource)
}
