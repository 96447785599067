import {useEffect, useState, Fragment} from 'react'
import {useHistory} from 'react-router-dom'
import {
    Col,
    Row,
    Form,
    Card,
    Label,
    Input,
    Button,
    CardBody,
    FormGroup,
    CardHeader,
    Alert,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    Table,
    DropdownToggle,
    Spinner,
    CustomInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap'
import {MoreVertical, Trash, Check, X} from 'react-feather'
import {useSelector, useDispatch} from 'react-redux'
import {createNewBrandUser, getChildUsers, nullManageUsers, deleteChildUser, updateChildUserStatus} from '@store/actions/brandManageUsers/index'
import {BRAND, NULL} from '@store/actionTypes'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const ManageUsers = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [nameTwo, setNameTwo] = useState('')
    const [emailTwo, serEmailTwo] = useState('')
    const [toastHeaderContent, setToastHeaderContent] = useState('')
    const [toastMessageContent, setToastMessageContent] = useState('')
    const [toastClass, setToastClass] = useState('')
    const [showModal, setShowModal] = useState(false)

    const [error, setError] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [visible, setVisible] = useState(false)
    const [isLoadingOne, setIsLoadingOne] = useState(false)
    const [isLoadingTwo, setIsLoadingTwo] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [childUsers, setChildUsers] = useState([])
    const [deleteChildUserRes, setDeleteChildUserRes] = useState(null)
    const [updateStatusChildUserRes, setUpdateStatusChildUserRes] = useState(null)
    const [showMsgWhenDelete, setShowMsgWhenDelete] = useState(false)
    const [showMsgWhenUpdateStatus, setShowMsgWhenUpdateStatus] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [activeUserId, setActiveUserId] = useState(null)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const EmailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
    const brandManageUserSlice = useSelector(store => store.brandManageUser)
    const dispatch = useDispatch()
    const history = useHistory()
    const sweetAlert = withReactContent(Swal)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')
        if (user.parent && !user?.subscriptionexp) {
            history.push('/dashboard/brands')
        }

        dispatch(getChildUsers())
        setIsListLoading(true)
    }, [])

    useEffect(() => {
        const childUsersOfBrand = brandManageUserSlice?.childUsersOfBrand ? brandManageUserSlice?.childUsersOfBrand : null
        if (childUsersOfBrand) {
            setChildUsers(childUsersOfBrand?.data)
            setIsListLoading(false)
        }

        const deleteChildUserOfBrand = brandManageUserSlice?.deleteChildUserOfBrand ? brandManageUserSlice?.deleteChildUserOfBrand : null
        if (deleteChildUserOfBrand) {
            setDeleteChildUserRes(deleteChildUserOfBrand)
            setShowMsgWhenDelete(true)
            const {status} = deleteChildUserOfBrand
            if (status) {
                dispatch(getChildUsers())
            }
            const timer = setTimeout(() => {
                setShowMsgWhenDelete(false)
                clearTimeout(timer)
            }, 5000)
            dispatch(nullManageUsers(NULL.DELETE_CHILD_USER))
        }

        const updateStatusOfChildUserOfBrand = brandManageUserSlice?.updateStatusOfChildUserOfBrand ? brandManageUserSlice?.updateStatusOfChildUserOfBrand : null
        if (updateStatusOfChildUserOfBrand) {
            setUpdateStatusChildUserRes(updateStatusOfChildUserOfBrand)
            setShowMsgWhenUpdateStatus(true)
            const {status} = updateStatusOfChildUserOfBrand
            if (status) {
                dispatch(getChildUsers())
            }
            const timer = setTimeout(() => {
                setShowMsgWhenUpdateStatus(false)
                clearTimeout(timer)
            }, 5000)
            dispatch(nullManageUsers(NULL.UPDATE_CHILD_USER_STATUS))
        }

        const brandCreateUser = brandManageUserSlice?.brandCreateUser ? brandManageUserSlice?.brandCreateUser : null
        if (brandCreateUser) {
            setIsLoadingTwo(false)
            setIsLoadingOne(false)
            const {msg, success} = brandCreateUser
            setVisible(true)
            setCaptchaError(false)
            setCaptchaValue(null)
            if (success) {
                setSuccessMsg(msg)
                dispatch(getChildUsers())
                setIsListLoading(true)
                setName('')
                setEmail('')
            }
            if (!success) setError(msg)
            const timer = setTimeout(() => {
                setVisible(false)
                setSuccessMsg("")
                setError("")
                clearTimeout(timer)
            }, 5000)
            const classValue = brandCreateUser?.success === true ? 'bg-success' : 'bg-danger'
            const toastHeader = brandCreateUser?.success === true ? 'Success' : 'Error'
            setToastClass(classValue)
            setToastHeaderContent(toastHeader)
            setToastMessageContent(brandCreateUser?.msg)
            setShowModal(true)
            const timer2 = setTimeout(() => {
                setShowModal(false)
                clearTimeout(timer2)
            }, 3000)
            dispatch({type: BRAND.BRAND_CREATE_USER, brandCreateUser: null})
        }
    }, [brandManageUserSlice])

    const createUser = async (form) => {
        const token = await executeRecaptcha('brand_manage_users')
        if (token) {
            const params = {
                username: name,
                useremail: email,
                re_captcha_token: token
            }
            !!name ? setNameError(false) : setNameError(true)
            !!email && EmailPattern.test(email) ? setEmailError(false) : setEmailError(true)
            if (!!name && !!email && EmailPattern.test(email)) {
                if (form === 'FormTwo') setIsLoadingTwo(true)
                if (form === 'FormOne') setIsLoadingOne(true)
                dispatch(createNewBrandUser(params))
            }
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    const SwitchLabel = () => (
        <Fragment>
      <span className='switch-icon-left'>
        <Check size={14}/>
      </span>
            <span className='switch-icon-right'>
        <X size={14}/>
      </span>
        </Fragment>
    )

    const handleChildUserStatus = (status, id) => {
        setIsListLoading(true)
        dispatch(updateChildUserStatus({id, status}))
    }

    const handleDeleteChildUser = () => {
        setDeleteModal(false)
        setIsListLoading(true)
        dispatch(deleteChildUser(activeUserId))
    }

    const onCaptchaChange = (value) => {
        setCaptchaError(false)
        setCaptchaValue(value)
    }

    return (
        <Fragment>
            <Card>
                <CardHeader className="text-center">
                    <h2 className="text-primary">Manage Users</h2>
                    <small className="text-dark"><strong>Max 3 users are allowed.</strong></small>
                </CardHeader>
                <CardBody>
                    <Alert color={!!error ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                        <div className='alert-body'>
                            {error || successMsg}
                        </div>
                    </Alert>
                    <Form className='form' onSubmit={e => e.preventDefault()}>
                        <Row>
                            <Col sm='12' className='blockquote pl-1 border-right border-right-1'>
                                <FormGroup className='mb-2'>
                                    <Label for='name'>Name</Label>
                                    <Input
                                        id='name'
                                        className='form-control'
                                        placeholder='name'
                                        onChange={(e) => {
                                            setName(e.target.value)
                                            setNameError(false)
                                        }} value={name}
                                    />
                                    {nameError && <div className="validation-err">This field is required</div>}
                                </FormGroup>
                                <FormGroup className='mb-2'>
                                    <Label for='email'>Email</Label>
                                    <Input
                                        id='email'
                                        placeholder='email'
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            setEmailError(false)
                                        }}
                                        value={email}/>
                                    {emailError && <div className="validation-err">Required and must be valid email address</div>}
                                </FormGroup>
                                <Button type='button' className='primary float-right' onClick={() => createUser('FormOne')}
                                        disabled={isLoadingOne}
                                >{isLoadingOne ? 'Creating...' : 'Create'}</Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
            <Card>
                <CardHeader className="text-center">
                    <h2 className="text-primary">Users</h2>
                </CardHeader>
                <div className="px-2">
                    <Alert color={!deleteChildUserRes?.status ? 'danger' : 'success'} isOpen={showMsgWhenDelete} toggle={() => setVisible(false)}>
                        <div className='alert-body'>
                            {deleteChildUserRes?.msg}
                        </div>
                    </Alert>
                    <Alert color={!updateStatusChildUserRes?.status ? 'danger' : 'success'} isOpen={showMsgWhenUpdateStatus} toggle={() => setVisible(false)}>
                        <div className='alert-body'>
                            {updateStatusChildUserRes?.msg}
                        </div>
                    </Alert>
                </div>
                {isListLoading && <div className="d-flex justify-content-center my-3"><Spinner color="primary"/></div>}
                {!isListLoading && childUsers.length === 0 && <div className="d-flex justify-content-center my-3">User doesn't exist</div>}
                {!isListLoading && childUsers.length !== 0 && <Table responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {childUsers?.map((user, i) => (
                        <tr key={i}>
                            <td>
                                <span className='align-middle font-weight-bold'>{++i}</span>
                            </td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                                <CustomInput
                                    type='switch'
                                    label={<SwitchLabel/>}
                                    className='custom-control-success'
                                    id={`${user.id}icon-success`}
                                    name={`${user.id}icon-success`}
                                    inline
                                    checked={user.status === 'enabled'}
                                    onChange={(e) => handleChildUserStatus(user.status, user.id)}
                                />
                            </td>
                            <td>
                                <UncontrolledDropdown>
                                    <DropdownToggle className='icon-btn hide-arrow' color='transparent' size='sm' caret>
                                        <MoreVertical size={15}/>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={() => {
                                            setDeleteModal(true)
                                            setActiveUserId(user.id)
                                        }}>
                                            <Trash className='mr-50' size={15}/> <span className='align-middle'>Delete</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>}
                <Modal
                    isOpen={deleteModal}
                    className='modal-dialog-centered'
                    modalClassName='modal-danger'
                >
                    <ModalHeader><span className="text-body">Delete Secondary User</span></ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete secondary user?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={() => setDeleteModal(false)}>
                            No
                        </Button>
                        <Button color='danger' onClick={() => handleDeleteChildUser()}>
                            Yes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Card>
        </Fragment>
    )
}
export default ManageUsers
