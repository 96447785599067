import axios from 'axios'
import { ADMIN_DASHBOARD, API_URL, RD, BRAND, NULL_PARTNERSHIP, UPLOAD_AGREEMENT, SEND_AGREEMENT} from '../actionType'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const deleteInvoice = id => {
  return (dispatch, getStore) => {
    axios
      .delete('/apps/invoice/delete', { id })
      .then(response => {
        dispatch({
          type: 'DELETE_INVOICE'
        })
      })
      .then(() => dispatch(getData(getStore().invoice.params)))
  }
}


// ** Dashboard - Manage RD
export const getManageRdsGeneralInfo = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${process.env.REACT_APP_BASEURL}admin/dashboard-manage-rds`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: ADMIN_DASHBOARD.MANAGE_RD_LISTING,
        manageRdsGeneralInfo: response.data
      })
    }).catch((error) => {
      dispatch({
        type: ADMIN_DASHBOARD.ERROR,
        error
      })
    })
  }
}

// ** Dashboard - Manage Brands
export const getManageBrandsGeneralInfo = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${process.env.REACT_APP_BASEURL}admin/admin-manage-brands`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: ADMIN_DASHBOARD.MANAGE_BRANDS_LISTING,
        manageBrandsGeneralInfo: response.data
      })
    }).catch((error) => {
      dispatch({
        type: ADMIN_DASHBOARD.ERROR,
        error
      })
    })
  }
}

// ** Dashboard - Manage RD
export const getRdPartnershipPayments = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}rd/load-partnership-payment`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: RD.PARTNERSHIP_PAYMENT_LISTING,
        rdPartnershipPayments: response.data
      })
    }).catch((error) => {
      dispatch({
        type: RD.ERROR,
        error
      })
    })
  }
}

export const getPaymentHistory = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}brand/load-payment-history`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.PAYMENT_HISTORY,
        paymentHistoryData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: RD.ERROR,
        error
      })
    })
  }
}

export const getRDPaymentHistory = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}brand/load-partnership-payment-history`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.RD_PAYMENT_HISTORY,
        rdPaymentHistoryData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: RD.ERROR,
        error
      })
    })
  }
}

export const getRdInvoiceDetail = (id) => {
  return dispatch => {
    axios.get(`${API_URL}brand/load-partnership-payment-history?id=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.RD_INVOICE_DETAIL,
        rdInvoiceDetailData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: RD.ERROR,
        error
      })
    })
  }
}

export const getInvoiceDetail = (id) => {
  return dispatch => {
    axios.get(`${API_URL}brand/view-subscription-invoice?id=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.INVOICE_DETAIL,
        invoiceDetailData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: RD.ERROR,
        error
      })
    })
  }
}

export const uploadRdAgrement = (dataObj) => async (dispatch) => {
  dispatch({type: UPLOAD_AGREEMENT.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}rd/add-agreement`, dataObj, {headers: header})
    dispatch({type: UPLOAD_AGREEMENT.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: UPLOAD_AGREEMENT.ERROR, payload: error})
  }
}

export const uploadRdAgrementReset = () => async (dispatch) => {
  dispatch({type: UPLOAD_AGREEMENT.RESET})
}

export const sendRdAgrement = (dataObj) => async (dispatch) => {
  dispatch({type: SEND_AGREEMENT.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}rd/send-agreement-to-brand`, dataObj, {headers: header})
    dispatch({type: SEND_AGREEMENT.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: SEND_AGREEMENT.ERROR, payload: error})
  }
}

export const sendRdAgrementReset = () => async (dispatch) => {
  dispatch({type: SEND_AGREEMENT.RESET})
}

export const nullResponsePartnership = (name) => {
  return dispatch => {
    switch (name) {
      case NULL_PARTNERSHIP.SEND_AGREEMENT:
        dispatch({
          type: RD.SEND_AGREEMENT,
          sendRdAgreementResponse: null
        })
        break
      case NULL_PARTNERSHIP.UPLOAD_AGREEMENT:
        dispatch({
          type: RD.UPLOAD_AGREEMENT,
          uploadRdAgreementResponse: null
        })
        break
    }
  }
}