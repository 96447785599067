import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import {
    CardTitle,
    CardBody,
    Card,
    CardHeader,
    Nav,
    NavItem,
    NavLink
} from "reactstrap"
import BrandConversationWithRD from '@src/views/admin/viewConversation/brandConversationWithRD'
import RDConversationWithBrands from '@src/views/admin/viewConversation/rdConversationWithBrands'
import {useSelector, useDispatch} from "react-redux"
import {getChatUsersProfile, getSingleUserChats} from '@src/views/admin/viewConversation/chatAdmin/store/actions'

const ViewConversation = () => {
    const chatSelector = useSelector(store => store.chatAdmin)
    const dispatch = useDispatch()

    const [mainUserId, setMainUserId] = useState(null)

    const rdsData = chatSelector?.chatUserProfile?.rds
    const optionsForRD = rdsData?.map(data => (
        {value: data.id, label: data.name, chatUser: data.chatUser}
    ))

    const brandsData = chatSelector?.chatUserProfile?.brands
    const optionsForBrand = brandsData?.map(data => (
        {value: data.id, label: data.name, chatUser: data.chatUser}
    ))

    const [active, setActive] = useState("1")
    const [selectedOptionForRD, setSelectedOptionForRD] = useState(null)
    const [selectedOptionForBrand, setSelectedOptionForBrand] = useState(null)
    const [showBrandChat, setShowBrandChat] = useState(false)
    const [showRDChat, setRDChat] = useState(false)

    useEffect(() => {
    }, [chatSelector])

    const toggle = (tab) => {
        if (active !== tab) {
            setActive(tab)
            dispatch({type: 'GET_SINGLE_USER_CHATS_DETAIL', singleUserChatDetails: {}})
        }
    }

    useEffect(() => {
        dispatch(getChatUsersProfile())

        const selectedUser = JSON.parse(localStorage.getItem('selectedChatUserForAdmin'))
        if (!selectedUser) return
        if (selectedUser?.user_role === 'rd') {
            toggle('1')
            setRDChat(true)
            setMainUserId(selectedUser.user_id)
            setSelectedOptionForRD({
                label: selectedUser.name,
                value: selectedUser.user_id
            })
            dispatch(getSingleUserChats(selectedUser.user_id))
            localStorage.setItem(`${process.env.REACT_APP_SELECTED_RD_USER_FOR_ADMIN_CHAT}`, JSON.stringify({
                label: selectedUser.name,
                value: selectedUser.user_id
            }))
        }

        if (selectedUser?.user_role === 'brand') {
            toggle('2')
            setShowBrandChat(true)
            setMainUserId(selectedUser.user_id)
            setSelectedOptionForBrand({
                label: selectedUser.brand_name,
                value: selectedUser.user_id
            })
            dispatch(getSingleUserChats(selectedUser.user_id))
            localStorage.setItem(`${process.env.REACT_APP_SELECTED_BRAND_USER_FOR_ADMIN_CHAT}`, JSON.stringify({
                label: selectedUser.brand_name,
                value: selectedUser.user_id
            }))
        }

    }, [])

    useEffect(() => {

    }, [])

    const handleRDChange = selectedOption => {
        localStorage.setItem(`${process.env.REACT_APP_SELECTED_RD_USER_FOR_ADMIN_CHAT}`, JSON.stringify(selectedOption))
        setRDChat(true)
        dispatch({type: 'CLEAR_ADMIN_CHAT_FIRST'})
        dispatch({type: 'CLEAR_ADMIN_CHAT_DETAILS_FIRST'})
        dispatch(getSingleUserChats(selectedOption.value))
        setMainUserId(selectedOption.value)
        setSelectedOptionForRD(selectedOption)
    }

    const handleBrandChange = selectedOption => {
        localStorage.setItem(`${process.env.REACT_APP_SELECTED_BRAND_USER_FOR_ADMIN_CHAT}`, JSON.stringify(selectedOption))
        setShowBrandChat(true)
        dispatch({type: 'CLEAR_ADMIN_CHAT_FIRST'})
        dispatch({type: 'CLEAR_ADMIN_CHAT_DETAILS_FIRST'})
        dispatch(getSingleUserChats(selectedOption.value))
        setMainUserId(selectedOption.value)
        setSelectedOptionForBrand(selectedOption)
    }

    const styles = {
        option: (provided, state) => {
            return {
                ...provided,
                backgroundColor: '#fff',
                color: '#6e6b7b',
                ':hover': {
                    backgroundColor: '#57B6FF',
                    color: 'white'
                },
                ':active': {
                    backgroundColor: '#57B6FF',
                    color: 'white'
                },
                ':focus': {
                    backgroundColor: '#57B6FF',
                    color: 'white'
                }
            }
        },
        singleValue: (provided, state) => ({
            ...provided,
            color: state.data.color,
            fontSize: state.selectProps.myFontSize
        })
    }

    return (
        <div className="d-block w-100 admin-view-conversation">
            <Card className="mb-0">
                <CardHeader className="border-bottom">
                    <CardTitle tag="h4">View Conversation</CardTitle>
                </CardHeader>
                <Nav className="mt-1" tabs>
                    <NavItem>
                        <NavLink active={active === '1'} onClick={() => {
                            toggle('1')
                            setMainUserId(null)
                            setSelectedOptionForRD(null)
                            setRDChat(false)
                            setShowBrandChat(false)
                        }}>
                            RD conversation with Brands
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink active={active === '2'} onClick={() => {
                            toggle('2')
                            setMainUserId(null)
                            setSelectedOptionForRD(null)
                            setRDChat(false)
                            setShowBrandChat(false)
                        }}>
                            Brand conversation with RD's
                        </NavLink>
                    </NavItem>
                </Nav>
                <CardBody>
                    {active === '1' && <div>
                        <Select
                            className="mx-300 custom-chat-select"
                            value={selectedOptionForRD}
                            onChange={handleRDChange}
                            options={optionsForRD}
                            styles={styles}
                        />
                    </div>}
                    {active === '2' && <div>
                        <Select
                            className="mx-300 custom-chat-select"
                            value={selectedOptionForBrand}
                            onChange={handleBrandChange}
                            options={optionsForBrand}
                            styles={styles}
                        />
                    </div>}
                </CardBody>
            </Card>
            <div className="content-area-wrapper animate__animated animate__fadeIn">
                {active === '1' && showRDChat && <RDConversationWithBrands mainUser={mainUserId}/>}
                {active === '2' && showBrandChat && <BrandConversationWithRD mainUser={mainUserId}/>}
            </div>
        </div>
    )
}

export default ViewConversation
