import axios from 'axios'
import { API_URL, BRAND, NULL } from '../../actionTypes'

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const createNewBrandUser = (params) => {
    const formData = new FormData()
    formData.append("name", params.username)
    formData.append("email", params.useremail)
    formData.append("re_captcha_token", params.re_captcha_token)
    return dispatch => {
        axios.post(`${API_URL}brand/add-new-user`, formData, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: BRAND.BRAND_CREATE_USER, brandCreateUser: res.data })
        }).catch(error => {
            dispatch({ type: BRAND.BRAND_DASHBOARD_ERROR, error })
        })
    }
}

export const getChildUsers = () => {
    return dispatch => {
        axios.get(`${API_URL}brand/child-users-list`, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: BRAND.CHILD_USERS_LIST, childUsersOfBrand: res.data })
        }).catch(error => {
            dispatch({ type: BRAND.BRAND_DASHBOARD_ERROR, error })
        })
    }
}

export const deleteChildUser = (id) => {
    return dispatch => {
        axios.post(`${API_URL}brand/delete-child-user`, {
            id
        }, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: BRAND.DELETE_CHILD_USER, deleteChildUserOfBrand: res.data })
        }).catch(error => {
            dispatch({ type: BRAND.BRAND_DASHBOARD_ERROR, error })
        })
    }
}

export const updateChildUserStatus = ({ id, status }) => {
    return dispatch => {
        axios.post(`${API_URL}brand/child-user-status`, {
            id,
            status
        }, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: BRAND.UPDATE_CHILD_USER_STATUS, updateStatusOfChildUserOfBrand: res.data })
        }).catch(error => {
            dispatch({ type: BRAND.BRAND_DASHBOARD_ERROR, error })
        })
    }
}

export const nullManageUsers = (name) => {
    return dispatch => {
        switch (name) {
            case NULL.CHILD_USERS_LIST:
                dispatch({ type: BRAND.CHILD_USERS_LIST, childUsersOfBrand: null })
                break
            case NULL.DELETE_CHILD_USER:
                dispatch({ type: BRAND.DELETE_CHILD_USER, deleteChildUserOfBrand: null })
                break
            case NULL.UPDATE_CHILD_USER_STATUS:
                dispatch({ type: BRAND.UPDATE_CHILD_USER_STATUS, updateStatusOfChildUserOfBrand: null })
                break
        }
    }
}