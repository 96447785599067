import Layout from '@layouts/HorizontalLayout'
import {PropTypes} from "prop-types"

const HorizontalLayout = props => <Layout {...props}>{props.children}</Layout>

HorizontalLayout.propTypes = {
    children: PropTypes.node.isRequired
}

export default HorizontalLayout
