import React, {useState, useEffect, Fragment} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner,
    Row,
    Col,
    Card,
    CardText, CardBody
} from 'reactstrap'
import Avatar from '@components/avatar'
import EventList from '@src/views/tables/data-tables-admin/view/event-list'
import BrandsListWithMembership from '@src/views/tables/data-tables-admin/view/brands-list-with-membership'
import BrandsRequestForParticipation from '@src/views/tables/data-tables-admin/view/brands-request-for-participation'
import {getRDProfileInfo} from '@src/views/tables/data-tables-admin/store/actions'

const ViewRdProfile = () => {
    const [activeTab, setActiveTab] = useState('1')
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [profileInfo, setProfileInfo] = useState(null)
    const {id} = useParams()
    const dispatch = useDispatch()
    const manageRdsStoreSlice = useSelector(store => store.dataTablesAdmin)

    useEffect(() => {
        if (!!manageRdsStoreSlice?.profileInfo) {
            setProfileInfo(manageRdsStoreSlice.profileInfo)
            setIsPageLoading(false)
        }

    }, [manageRdsStoreSlice])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getRDProfileInfo(id))
    }, [])

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (
        <div className="view-page">
            <Link to="/admin/manage-rd" className='btn btn-primary mb-1'>Back</Link>
            {isPageLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
            {!isPageLoading && (
                <Fragment>
                    <Row className="match-height">
                        <Col lg="4">
                            <Card className='ecommerce-card view-card'>
                                <div className='item-img text-center mx-auto mt-3'>
                                    <Link to="#">
                                        <Avatar img={`${profileInfo?.profile_img}`} imgHeight='120' imgWidth='120'/>
                                    </Link>
                                </div>
                                <CardBody className="text-center">
                                    <h4 className='item-name font-weight-bolder'>{profileInfo?.name}</h4>
                                    <h5 className='item-name font-weight-bolder'>{profileInfo?.email}</h5>
                                    <div className="healthcare blue-bg-500">
                                        <p className="general-wellness font-weight-bolder blue-700">
                                            {profileInfo?.attributes?.industry ? profileInfo?.attributes?.industry[0]?.value : 'N/A'}
                                            {" "}|{" "}
                                            {profileInfo?.attributes?.speciality ? profileInfo?.attributes?.speciality[0]?.value : 'N/A'}
                                        </p>
                                    </div>
                                    <div className="text-left location-info mb-1">
                                        <CardText className='item-description d-flex font-weight-bolder'>
                                            <div className="w-65">City</div>
                                            <span className="ml-2 text-primary">{profileInfo?.location?.city[0]?.value || 'N/A'}</span></CardText>
                                        <CardText className='item-description d-flex font-weight-bolder'>
                                            <div className="w-65">State</div>
                                            <span className="ml-2 text-primary">{profileInfo?.location?.state[0]?.value || 'N/A'}</span></CardText>
                                        <CardText className='item-description d-flex font-weight-bolder'>
                                            <div className="w-65">Website</div>
                                            <span className="ml-2 text-truncate">
                                            {profileInfo?.website ? (
                                                <a className="text-primary" href={profileInfo?.website} target="_blank">{profileInfo?.website}</a>) : ("N/A")}
                                            </span>
                                        </CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <Card className='ecommerce-card'>
                                <CardBody>
                                    <h2 className='font-weight-bolder mb-2'>About</h2>
                                    <div>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>Industry</h5>
                                                <h6 className=''>
                                                    {profileInfo?.attributes?.industry?.length === 0 ? 'N/A' : (
                                                        <Fragment>
                                                            {profileInfo?.attributes?.industry && profileInfo?.attributes?.industry.map(name => (
                                                                <span key={name?.id}>{name?.value}, </span>))}
                                                        </Fragment>
                                                    )}
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>Speciality</h5>
                                                <h6 className=''>
                                                    {profileInfo?.attributes?.speciality?.length === 0 ? 'N/A' : (
                                                        <Fragment>
                                                            {profileInfo?.attributes?.speciality && profileInfo?.attributes?.speciality.map(name => (
                                                                <span key={name?.id}>{name?.value}, </span>))}
                                                        </Fragment>
                                                    )}
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>Are you a RD2Be or Dietitian?</h5>
                                                <h6 className=''>
                                                    <span>
                                                        {profileInfo?.is_rd === 1 && ("RD")}
                                                        {profileInfo?.is_dietetic_intern === 1 && ("RD2Be")}
                                                        {profileInfo?.other_rd ?? ""}
                                                    </span>
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>How do you primarily want to connect with brands?</h5>
                                                <h6 className=''>
                                                    <span>{profileInfo?.primarily_brands?.value || 'N/A'}</span>
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText className='oppo-detail'>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>How did you here about LINK?</h5>
                                                <h6 className=''>
                                                    <span>{profileInfo?.hear_about || 'N/A'}</span>
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText className='oppo-detail'>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>CDR</h5>
                                                <h6 className=''>
                                                    <span>{profileInfo?.cdr_number || 'N/A'}</span>
                                                </h6>
                                            </div>
                                        </CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody className="p-0">
                            <Nav tabs className="mb-0">
                                <NavItem>
                                    <NavLink
                                        active={activeTab === '1'}
                                        onClick={() => {
                                            toggle('1')
                                        }}
                                    >
                                        Event List
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        active={activeTab === '2'}
                                        onClick={() => {
                                            toggle('2')
                                        }}
                                    >
                                        Brands Who Purchased Membership
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        active={activeTab === '3'}
                                        onClick={() => {
                                            toggle('3')
                                        }}
                                    >
                                        Brands Requests for Participation
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                    <Card>
                        <TabContent className='py-0' activeTab={activeTab}>
                            <TabPane tabId='1'>
                                <EventList/>
                            </TabPane>
                            <TabPane tabId='2'>
                                <BrandsListWithMembership/>
                            </TabPane>
                            <TabPane tabId='3'>
                                <BrandsRequestForParticipation/>
                            </TabPane>
                        </TabContent>
                    </Card>
                </Fragment>
            )}
        </div>
    )
}

export default ViewRdProfile