import {useEffect, useState, Fragment} from "react"
import {Link} from "react-router-dom"
import PDF from "@src/assets/images/icons/pdf1.png"
import Avatar from "@components/avatar"
import Tiktok from "@src/assets/images/icons/tik-tok.svg"
import Pinterest from "@src/assets/images/icons/pinterest.svg"
import {useHistory} from 'react-router'
import {
    Card,
    CardBody,
    CardText,
    Row,
    Col,
    Spinner, Button
} from "reactstrap"
import {
    Facebook,
    Twitter,
    Linkedin,
    Instagram,
    Edit,
    Video,
    Eye
} from 'react-feather'
import {useSelector, useDispatch} from 'react-redux'
import {getBrandProfile} from '../../../redux/actions/brandProfile/index'
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import BlankUser from "@src/assets/images/avatars/avatar-blank.png"
import ImageViewer from "@src/views/components/ImageViewer"
import PdfViewer from "@src/views/components/PdfViewer"
import VideoPlayer from "@src/views/components/VideoPlayer"

const BrandProfile = () => {
    const [isTableLoading, setisTableLoading] = useState(false)
    const [brandProfile, setBrandProfile] = useState(null)
    const [imageViewer, setImageViewer] = useState({show: false, src: ''})
    const [pdfViewer, setPdfViewer] = useState({show: false, url: '', filename: ''})
    const [videoPlayer, setVideoPlayer] = useState({show: false, url: '', filename: ''})

    const brandProfileSlice = useSelector(store => store.brandDashboard)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!brandProfileSlice?.brandProfile) {
            setisTableLoading(false)
            setBrandProfile(brandProfileSlice?.brandProfile.data)
        }
    }, [brandProfileSlice])

    useEffect(() => {
        setisTableLoading(true)
        dispatch(getBrandProfile())

        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')
        if (user.parent && !user?.subscriptionexp) {
            history.push('/dashboard/brands')
        }
    }, [])

    return (
        <Fragment>
            {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
            {!isTableLoading && !!brandProfile && (
                <Fragment>
                    <Row className="match-height mb-0">
                        <Col md="12" lg="12" sm="12">
                            <h2 className="mb-2">Profile</h2>
                        </Col>
                        <Col md="5" lg="3" sm="12">
                            <Card className="ecommerce-card view-card text-center">
                                <div className="item-img text-center mx-auto mt-3">
                                    {brandProfile.company_logo ? (
                                        <Avatar className="avatar-img-fit-contain" img={brandProfile.company_logo}
                                                imgHeight="110" imgWidth="110"/>) : (
                                        <Avatar img={BlankLogo}
                                                imgHeight="110" imgWidth="110"/>)}
                                </div>
                                <CardBody className="text-center">
                                    <h5 className="item-name font-weight-bolder">{brandProfile.brand_name}</h5>
                                    {brandProfile?.website && (
                                        <CardText className="item-description d-flex justify-content-center font-weight-bolder">
                    <span className="text-truncate">
                      <a
                          href={brandProfile.website || '#'}
                          className=""
                          target="_blank"
                      >
                        View Website
                      </a>
                    </span>
                                        </CardText>
                                    )}
                                    {brandProfile?.attributes?.special_diet.length !== 0 &&
                                        brandProfile?.attributes?.values.length !== 0 &&
                                        brandProfile?.attributes?.category.length !== 0 &&
                                        <div className="healthcare blue-bg-500">
                                            <p className="general-wellness font-weight-bolder blue-700">
                                                {brandProfile.attributes?.values[0]?.value || ''}
                                                {" "}|{" "}
                                                {brandProfile.attributes?.special_diet[0]?.value || ''}
                                                {" "}|{" "}
                                                {brandProfile.attributes?.category[0]?.value || ''}
                                            </p>
                                        </div>}
                                    <div className="d-flex justify-content-center flex-wrap">
                                        {!!brandProfile.instagram_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandProfile.instagram_url || '#'}
                                            target="_blank"
                                        >
                                            <Instagram/>{" "}
                                        </a>}
                                        {!!brandProfile.facebook_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandProfile.facebook_url || '#'}
                                            target="_blank"
                                        >
                                            <Facebook/>{" "}
                                        </a>}
                                        {!!brandProfile.twitter_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandProfile.twitter_url || '#'}
                                            target="_blank"
                                        >
                                            <Twitter/>{" "}
                                        </a>}
                                        {!!brandProfile.linkedin_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandProfile.linkedin_url || '#'}
                                            target="_blank"
                                        >
                                            <Linkedin/>{" "}
                                        </a>}
                                        {!!brandProfile.tiktok_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandProfile.tiktok_url || '#'}
                                            target="_blank"
                                        >
                                            <img src={Tiktok} alt={Tiktok} width="24" height="26"/>{" "}
                                        </a>}
                                        {!!brandProfile.pinterest_url && <a
                                            className="mx-1 text-truncate mb-1"
                                            href={brandProfile.pinterest_url || '#'}
                                            target="_blank"
                                        >
                                            <img src={Pinterest} alt={Pinterest} width="24" height="24"/>{" "}
                                        </a>}
                                    </div>

                                </CardBody>
                            </Card>
                            <Card
                                className="ecommerce-card view-card">
                                <CardBody className="">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="avatar mr-1">
                                            <Avatar img={brandProfile.profile_image || BlankUser} imgHeight="60" imgWidth="60"/>
                                        </div>
                                        <div className="profile-user-info item-name">
                                            <h5 className="font-weight-bolder mb-0">{brandProfile.name}</h5>
                                            {brandProfile.person_title ? (
                                                <p className="font-weight-normal mb-0">{brandProfile.person_title}</p>) : (
                                                <p className="mb-0 font-small-1">N/A</p>)}
                                        </div>
                                    </div>
                                    <hr/>
                                    {brandProfile?.child?.length > 0 && brandProfile?.child.map((val) => (
                                        <div className="d-flex justify-content-start align-items-center mt-2">
                                            <div className="avatar mr-1">
                                                <Avatar img={BlankUser} imgHeight="60" imgWidth="60"/>
                                            </div>
                                            <div className="profile-user-info item-name">
                                                <h5 className="font-weight-bolder mb-0">{val.name}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="7" lg="9" sm="12">
                            <Card className="ecommerce-card mb-0">
                                <CardBody>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="font-weight-bolder">About</h4>
                                        <Button.Ripple tag={Link} to="/dashboard/brands/edit-profile">
                                            <Edit size={16}/> Edit
                                        </Button.Ripple>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: brandProfile.about_company || 'N/A'}}/>
                                    {/* <CardText className="">
                    <h6 className="text-primary font-weight-bolder">Location</h6>
                    <span className="">{brandProfile.location.region.value}, {brandProfile.location.country.value}</span>
                  </CardText> */}
                                    {/* <CardText>
                    <h6 className="text-primary font-weight-bolder">Contact</h6>
                    <span className="">{brandProfile.phone_number}</span>
                  </CardText>
                  <CardText>
                    <h6 className="text-primary font-weight-bolder">Email</h6>
                    <span className="">{brandProfile.email}</span>
                  </CardText> */}

                                    {brandProfile.attributes.category.length > 0 && (
                                        <CardText>
                                            <h6 className="text-primary font-weight-bolder">Food Category</h6>
                                            {brandProfile.attributes.category.map((val, i) => (
                                                <span>
                              {val.value}
                                                    {brandProfile.attributes.category.length - 1 !== i && ", "}
                            </span>
                                            ))}
                                        </CardText>
                                    )}

                                    {brandProfile.attributes.special_diet.length > 0 && (
                                        <CardText>
                                            <h6 className="text-primary font-weight-bolder">Special Diet</h6>
                                            {brandProfile.attributes.special_diet.map((val, i) => (
                                                <span>
                              {val.value}
                                                    {brandProfile.attributes.special_diet.length - 1 !== i && ", "}
                            </span>
                                            ))}
                                        </CardText>
                                    )}

                                    {brandProfile.attributes.values.length > 0 && (
                                        <CardText>
                                            <h6 className="text-primary font-weight-bolder">Company Values</h6>
                                            {brandProfile.attributes.values.map((val, i) => (
                                                <span>
                              {val.value}
                                                    {brandProfile.attributes.values.length - 1 !== i && ", "}
                            </span>
                                            ))}
                                        </CardText>
                                    )}

                                    {/* <CardText>
                    <h6 className="text-primary font-weight-bolder">Personal Attributes</h6>
                    {brandProfile.interested_brands.personal_attributes.length === 0 && <span>N/A</span>}
                    {brandProfile.interested_brands.personal_attributes.map((val, i) => (<span>
                      {val.value}
                      {brandProfile.interested_brands.personal_attributes.length - 1 !== i && ", "}
                    </span>))}
                  </CardText>
                  <CardText>
                    <h6 className="text-primary font-weight-bolder">Speciality</h6>
                    {brandProfile.interested_brands.speciality.length === 0 && <span>N/A</span>}
                    {brandProfile.interested_brands.speciality.map((val, i) => (<span>
                      {val.value}
                      {brandProfile.interested_brands.speciality.length - 1 !== i && ", "}
                    </span>))}
                  </CardText>
                  <CardText>
                    <h6 className="text-primary font-weight-bolder">Industry</h6>
                    {brandProfile.interested_brands.industry.length === 0 && <span>N/A</span>}
                    {brandProfile.interested_brands.industry.map((val, i) => (<span>
                      {val.value}
                      {brandProfile.interested_brands.industry.length - 1 !== i && ", "}
                    </span>))}
                  </CardText> */}
                                </CardBody>
                            </Card>
                            {/*{brandProfile.media.length === 0 && (*/}
                            {/*  <Card className="mt-2"><CardBody className="text-center">Media doesn't exist</CardBody></Card>*/}
                            {/*)}*/}
                            <div className="d-flex flex-wrap -mi-10px mt-2">
                                {brandProfile?.media?.map((media, i) => {
                                    const type = media.image_type.toLowerCase()
                                    return (
                                        <Fragment key={i}>
                                            {(type === 'png' || type === 'jpg' || type === 'jpeg') && (
                                                <div className='media-img media-file'>
                                                    <div className="download-icon cursor-pointer">
                                                        <a href='javascript:;'
                                                           onClick={() => setImageViewer({show: true, src: media.image_url})}>
                                                            <Eye size={16}/>
                                                        </a>
                                                    </div>
                                                    <div className="name-wrap">
                                                        <img className='rounded img-fit-contain'
                                                             src={media?.image_url} alt='Card image'
                                                             style={{height: '100px', width: '100px'}}/>
                                                        <p className="text-truncate mt-1">
                                                            <strong>{media.product_image}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {(type === 'pdf') && (
                                                <div className='media-img media-file'>
                                                    <div className="download-icon cursor-pointer">
                                                        <a href='javascript:;'
                                                           onClick={() => setPdfViewer({show: true, url: media.image_url, filename: media.product_image})}>
                                                            <Eye size={16}/>
                                                        </a>
                                                    </div>
                                                    <div className="name-wrap">
                                                        <img className='rounded img-fit-contain png-icon-img' src={PDF} alt='Card image'/>
                                                        <p className="text-truncate mt-1">
                                                            <strong>{media.product_image}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {(type === 'mp4') && (
                                                <div className='media-img media-file'>
                                                    <div className="download-icon cursor-pointer">
                                                        <a href='javascript:;'
                                                           onClick={() => setVideoPlayer({show: true, url: media.image_url, filename: media.product_image})}>
                                                            <Eye size={16}/>
                                                        </a>
                                                    </div>
                                                    <div className="name-wrap">
                                                        <Video className="text-primary text-center" size={90}/>
                                                        <p className="text-truncate mt-1">
                                                            <strong>{media.product_image}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </Fragment>)
                                })}
                            </div>
                        </Col>
                    </Row>
                    <ImageViewer
                        show={imageViewer.show}
                        hide={() => setImageViewer({show: false, src: ''})}
                        images={[{src: imageViewer.src}]}
                    />
                    <PdfViewer show={pdfViewer.show}
                               url={pdfViewer.url}
                               filename={pdfViewer.filename}
                               hide={() => setPdfViewer({show: false, url: '', filename: ''})}
                    />
                    <VideoPlayer show={videoPlayer.show}
                                 url={videoPlayer.url}
                                 filename={videoPlayer.filename}
                                 hide={() => setVideoPlayer({show: false, url: '', filename: ''})}
                    />
                </Fragment>
            )}
        </Fragment>
    )
}
export default BrandProfile
