import Timeline from '@components/timeline'
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'

const SubscriptionHistory = (props) => {
  return (
    <Card className='card-user-timeline'>
      <CardHeader>
          <CardTitle tag='h4'>Subscription History</CardTitle>
      </CardHeader>
      <CardBody>
        <Timeline className='ml-50 mb-0' data={props?.subscriptionHistory} />
      </CardBody>
    </Card>
  )
}

export default SubscriptionHistory
