import {Fragment, useEffect} from "react"
import {useParams, useHistory} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import {
    onAccountVerificationSuccess,
    onAccountVerificationSuccessReset
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import {Card, CardBody, Spinner} from "reactstrap"

const VerificationSuccess = () => {
    const {token} = useParams()
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        rdAccountSuccessLoading,
        rdAccountSuccessFullFill,
        rdAccountSuccessError
    } = useSelector((store) => store.brandsOppoprunities)

    useEffect(() => {
        const data = {
            token
        }
        dispatch(onAccountVerificationSuccess(data))
    }, [])

    useEffect(() => {
        if (rdAccountSuccessFullFill?.status === true) {
            dispatch(onAccountVerificationSuccessReset())
            const data = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
            data.stripe_account_status = true
            localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(data))
            history.push('/dashboard/rd/payment-settings?type=success')
        }
    }, [rdAccountSuccessFullFill])

    return (
        <Fragment>
            <Card>
                <CardBody>
                    {rdAccountSuccessError?.response?.data && (
                        <p className="bg-danger p-1 text-white">{rdAccountSuccessError?.response?.data?.msg}</p>
                    )}
                    {rdAccountSuccessLoading && (
                        <div className="mt-5 mb-5">
                            <p className="text-center">
                                <Spinner color="primary"/>
                            </p>
                            <p className="text-center font-medium-3 font-weight-bold">
                                Please wait! We are updating your bank information. You will be redirected soon.
                            </p>
                        </div>
                    )}
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default VerificationSuccess
