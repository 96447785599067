
import { useState, Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getManageEventsData,
  getSearchBrandPurchasedMembershiplData,
  getBrandPurchasedMembershiplData
} from '@src/views/tables/data-tables-admin-manage-events/store/actions/index'
import {
  getBrandsWhoPurchasedMembership,
  searchBrandsWhoPurchasedMembership
} from '@src/views/tables/data-tables-admin/store/actions/index'
import Flatpickr from 'react-flatpickr'
import {
  ChevronDown,
  Search,
  RefreshCcw
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
  Card,
  CardBody,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Button,
  Spinner
} from 'reactstrap'

import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const DataTableAdvSearch = () => {
  const [searchCompanyName, setSearchCompanyName] = useState('')
  const [searchBrandOwnerName, setSearchBrandOwnerName] = useState('')
  const [searchPurchaseDateFrom, setSearchPurchaseDateFrom] = useState('')
  const [searchPurchaseDateTo, setSearchPurchaseDteTo] = useState('')

  const [isTableLoading, setIsTableLoading] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState(null)

  const [advSearchColumns, setAdvSearchColumns] = useState([
    {
      name: 'Company Name',
      selector: 'company_name',
      sortable: true,
      minWidth: '90px'
    },
    {
      name: 'Brand Owner Name',
      selector: 'brand_owner_name',
      sortable: true,
      minWidth: '200px'
    },
    {
      name: 'Purchased Date',
      selector: 'purchased_date',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
      minWidth: '50px',
      cell: row => {
        return (
          <span>{`$ ${row?.amount}`}</span>
        )
      }
    },
    {
      name: 'Commission',
      selector: 'commission',
      sortable: true,
      minWidth: '70px',
      cell: row => {
        return (
          <span>{`$ ${row?.commission}`}</span>
        )
      }
    }
  ])

  const participationRequests = useSelector(store => store.dataTablesAdminBrands)
  const membershipsStoreSlice = useSelector(store => store.datatablesAdminManageEvents)
  const manageRdsStoreSlice = useSelector(store => store.dataTablesAdmin)

  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (!!manageRdsStoreSlice?.brandsWhoPurchasedMembership) {
      const { links, meta, pages } = manageRdsStoreSlice?.brandsWhoPurchasedMembership
      setPaginationInfo({ ...links, ...meta, pages })
      setIsTableLoading(false)
    }
  }, [membershipsStoreSlice, manageRdsStoreSlice])

  useEffect(() => {
    setIsTableLoading(true)
    dispatch(getBrandsWhoPurchasedMembership())
  }, [])

  const searchParticipationRequest = () => {
    setIsTableLoading(true)
    // Event From date
    const eventFrom = new Date(searchPurchaseDateFrom)
    const finalEventFrom = `${eventFrom.getMonth() + 1}-${eventFrom.getDate()}-${eventFrom.getFullYear()}`
    // Event To date
    const eventTo = new Date(searchPurchaseDateTo)
    const finalEventTo = `${eventTo.getMonth() + 1}-${eventTo.getDate()}-${eventTo.getFullYear()}`

    const params = {
      brand_name: searchCompanyName,
      owner_name: searchBrandOwnerName,
      from_date: finalEventFrom === 'NaN-NaN-NaN' ? "" : finalEventFrom,
      to_date: finalEventTo === 'NaN-NaN-NaN' ? "" : finalEventTo
    }
    dispatch(searchBrandsWhoPurchasedMembership(id, params, false))
  }

  const refreshParticipationRequest = () => {
    setSearchCompanyName('')
    setSearchBrandOwnerName('')
    setSearchPurchaseDateFrom('')
    setSearchPurchaseDteTo('')
    setIsTableLoading(true)
    dispatch(getBrandsWhoPurchasedMembership())
  }

  const dataToRender = () => {
    return manageRdsStoreSlice?.brandsWhoPurchasedMembership?.data
  }

  const handleCustomPagination = pageUrl => {
    setIsTableLoading(true)
    // Event From date
    const eventFrom = new Date(searchPurchaseDateFrom)
    const finalEventFrom = `${eventFrom.getMonth() + 1}-${eventFrom.getDate()}-${eventFrom.getFullYear()}`
    // Event To date
    const eventTo = new Date(searchPurchaseDateTo)
    const finalEventTo = `${eventTo.getMonth() + 1}-${eventTo.getDate()}-${eventTo.getFullYear()}`

    const params = {
      brand_name: searchCompanyName,
      owner_name: searchBrandOwnerName,
      from_date: finalEventFrom === 'NaN-NaN-NaN' ? "" : finalEventFrom,
      to_date: finalEventTo === 'NaN-NaN-NaN' ? "" : finalEventTo
    }
    dispatch(searchBrandsWhoPurchasedMembership(id, params, true, pageUrl))
  }

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="">
          <Row form className='mt-1 mb-50'>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='name'>Comapny Name</Label>
                <Input id='name' placeholder=''
                  value={searchCompanyName}
                  onChange={(e) => setSearchCompanyName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='name'>Brand Owner Name</Label>
                <Input id='name' placeholder=''
                  value={searchBrandOwnerName}
                  onChange={(e) => setSearchBrandOwnerName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='date'>Purchased Date From:</Label>
                <Flatpickr
                  className='form-control'
                  id='date'
                  value={searchPurchaseDateFrom}
                  onChange={date => setSearchPurchaseDateFrom(date)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='date'>Purchased Date To:</Label>
                <Flatpickr
                  className='form-control'
                  id='date'
                  value={searchPurchaseDateTo}
                  onChange={date => setSearchPurchaseDteTo(date)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="admin-filter-actions ml-auto text-right">
                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchParticipationRequest}>
                  <Search size={16} />
                </Button.Ripple>
                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshParticipationRequest}>
                  <RefreshCcw size={16} />
                </Button.Ripple>
              </div>
            </Col>
          </Row>
        </CardBody>
        {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary' /></div>)}
        {!isTableLoading && (
          <DataTable
            noHeader
            paginationServer
            columns={advSearchColumns}
            paginationPerPage={7}
            className='react-dataTable'
            sortIcon={<ChevronDown size={10} />}
            data={dataToRender()}
          />
        )}
        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination} />
      </Card>
    </Fragment>
  )
}

export default DataTableAdvSearch
