import axios from 'axios'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getChatUsersProfile = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}admin/conversations`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      dispatch({
        type: 'GET_CHAT_USER_PROFILE',
        chatUserProfiles: res.data
      })
    })
  }
}

export const getSingleUserChats = (user_id) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("user_id", user_id)
    axios.post(`${process.env.REACT_APP_BASEURL}admin/load-chat-users`, formData, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      dispatch({
        type: 'GET_SINGLE_USER_CHATS',
        singleUserChats: res.data.data
      })
    })
  }
}

export const getuserDetailChat = (params) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("to_user_id", params.to_user_id)
    formData.append("from_user_id", params.from_user_id)
    axios.post(`${process.env.REACT_APP_BASEURL}admin/load-chat-window`, formData, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      dispatch({
        type: 'GET_SINGLE_USER_CHATS_DETAIL',
        singleUserChatDetails: res.data
      })
    })
  }
}

// ** Get User Profile
export const getUserProfile = () => {
  return dispatch => {
    axios.get('/apps/chatAdmin/users/profile-user').then(res => {
      dispatch({
        type: 'GET_USER_PROFILE',
        userProfile: res.data
      })
    })
  }
}

// ** Get Chats & Contacts
export const getChatContacts = () => {
  return dispatch => {
    dispatch({
      type: 'GET_CHAT_CONTACTS',
      data: []
    })
    // axios.get('/apps/chatAdmin/chats-and-contacts').then(res => {
    //   dispatch({
    //     type: 'GET_CHAT_CONTACTS',
    //     data: res.data
    //   })
    // })
  }
}

// ** Select Chat
export const selectChat = id => {
  return dispatch => {
    axios.get('/apps/chatAdmin/get-chat', { id }).then(res => {
      dispatch({ type: 'SELECT_CHAT', data: res.data })
      dispatch(getChatContacts())
    })
  }
}

// ** Send Msg
export const sendMsg = obj => {
  return dispatch => {
    axios.post('/apps/chatAdmin/send-msg', { obj }).then(res => {
      dispatch({ type: 'SEND_MSG', data: res.data })
      dispatch(selectChat(obj.contact.id))
    })
  }
}
