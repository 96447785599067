import {useState, useEffect, Fragment} from 'react'
import {Link} from 'react-router-dom'
import DataTable from 'react-data-table-component'
import {
    ChevronDown,
    Eye,
    Download
} from 'react-feather'
import {useDispatch, useSelector} from 'react-redux'
import '@styles/react/apps/app-invoice.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import {getPaymentHistory, getRDPaymentHistory} from '@src/views/apps/invoice/store/actions'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import styled from "styled-components"
import {
    Spinner,
    Card,
    CardBody,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

const PaymentHistory = () => {
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [isTableLoadingTwo, setIsTableLoadingTwo] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [paginationInfoTwo, setPaginationInfoTwo] = useState(null)

    const paymentHistorySlice = useSelector(store => store.invoice)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!paymentHistorySlice?.paymentHistoryData) {
            const {links, meta, pages} = paymentHistorySlice?.paymentHistoryData
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }

        if (!!paymentHistorySlice?.rdPaymentHistoryData) {
            const {links, meta, pages} = paymentHistorySlice?.rdPaymentHistoryData
            setPaginationInfoTwo({...links, ...meta, pages})
            setIsTableLoadingTwo(false)
        }
    }, [paymentHistorySlice])
    useEffect(() => {
        dispatch(getRDPaymentHistory())
        dispatch(getPaymentHistory())
    }, [])

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(" ")
    }

    const [showWishlist, setShowWishlist] = useState(true)

    const handleShowWishlist = () => {
        setShowWishlist((prevCheck) => !prevCheck)
    }
    const [active, setActive] = useState("1")

    const toggle = (tab) => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const UpcomingOppoWrapper = styled.div`
        ul {
            list-style: none
            padding: 0
        }

        li {
            font-weight: 600 span {
            display: inline-block
            margin-right: 25px
            font-weight: 400
            width: 6rem
            white-space: nowrap
        }
        }`

    const [columns, setColumns] = useState([
        {
            name: 'Purchased Date',
            minWidth: '200px',
            selector: 'purchased_date',
            sortable: true
        },
        {
            name: 'Subscription Plan',
            selector: 'subscription_plan',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Subscription Type',
            selector: 'subscription_type',
            sortable: true,
            minWidth: '200px',
            className: 'text'
        },
        {
            name: 'Expiry Date',
            minWidth: '200px',
            selector: 'expiry_date',
            sortable: true
        },
        {
            name: 'Amount',
            selector: 'paid_amount',
            sortable: true,
            minWidth: '200px',
            cell: row => (
                <span>${row?.paid_amount}</span>
            )
        },
        {
            name: 'Action',
            selector: 'monthlyLogins',
            sortable: true,
            minWidth: '200px',
            cell: row => (
                // tag={Link}
                <div className='column-action d-flex align-items-center'>
                    <Link to={`/dashboard/brands/invoice/view/${row.id}`}><Download className="mx-25" size={17}/></Link>
                    <Link to={`/dashboard/brands/invoice/view/${row.id}`}>
                        <Eye className="mx-25" size={17}/>
                    </Link>
                </div>
            )
        }
    ])


    const [columnsTwo, setColumnsTwo] = useState([
        {
            name: 'Payment Date',
            minWidth: '200px',
            selector: 'payment_date',
            sortable: true
        },
        {
            name: 'Event Title',
            selector: 'event',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Transaction ID',
            selector: 'transaction_id',
            sortable: true,
            minWidth: '200px',
            className: 'text'
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
            minWidth: '200px',
            cell: row => (
                <span>{`${row?.amount} $`}</span>
            )
        },
        {
            name: 'Payment Status',
            minWidth: '200px',
            selector: 'payment_status',
            sortable: true
        },
        {
            name: 'Action',
            selector: 'monthlyLogins',
            sortable: true,
            minWidth: '200px',
            cell: row => (
                // tag={Link}
                <div className='column-action d-flex align-items-center'>
                    <Link to={`/dashboard/brands/view/rd-invoice/${row.id}`}><Download className="mx-25" size={17}/></Link>
                    <Link to={`/dashboard/brands/view/rd-invoice/${row.id}`}>
                        <Eye className="mx-25" size={17}/>
                    </Link>
                </div>
            )
        }
    ])

    const dataToRender = () => {
        if (paymentHistorySlice?.paymentHistoryData?.data?.length) {
            return paymentHistorySlice?.paymentHistoryData?.data
        }
    }

    const dataTwoRender = () => {
        if (paymentHistorySlice?.rdPaymentHistoryData?.data?.length) {
            return paymentHistorySlice?.rdPaymentHistoryData?.data
        }
    }

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        dispatch(getPaymentHistory(true, pageUrl))
    }

    const handleCustomPaginationTwo = pageUrl => {
        setIsTableLoadingTwo(true)
        dispatch(getRDPaymentHistory(true, pageUrl))
    }

    return (

        <Fragment>
            <Nav tabs className="my-2">
                <NavItem>
                    <NavLink
                        active={active === "1"}
                        onClick={() => {
                            toggle("1")
                        }}
                    >
                        Subscription Payments
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === "2"}
                        onClick={() => {
                            toggle("2")
                        }}
                    >
                        RD Payments
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className="py-50" activeTab={active}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <Card>
                                <CardBody>
                                    <UpcomingOppoWrapper>
                                        <Card>
                                            <div className='invoice-list-dataTable'>
                                                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                                                {!isTableLoading && (
                                                    <DataTable
                                                        noHeader
                                                        paginationServer
                                                        subHeader={true}
                                                        columns={columns}
                                                        responsive={true}
                                                        sortIcon={<ChevronDown/>}
                                                        className='react-dataTable'
                                                        defaultSortField='invoiceId'
                                                        data={dataToRender()}
                                                    />
                                                )}
                                                <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                                            </div>
                                        </Card>
                                    </UpcomingOppoWrapper>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <Card>
                                <CardBody>
                                    <UpcomingOppoWrapper>
                                        <Card>
                                            <div className='invoice-list-dataTable'>
                                                {isTableLoadingTwo && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                                                {!isTableLoadingTwo && (
                                                    <DataTable
                                                        noHeader
                                                        paginationServer
                                                        subHeader={true}
                                                        columns={columnsTwo}
                                                        responsive={true}
                                                        sortIcon={<ChevronDown/>}
                                                        className='react-dataTable'
                                                        defaultSortField='invoiceId'
                                                        data={dataTwoRender()}
                                                    />
                                                )}
                                                <CustomPagination pageLinks={paginationInfoTwo?.pages} onPaginationClick={handleCustomPaginationTwo}/>
                                            </div>
                                        </Card>
                                    </UpcomingOppoWrapper>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </Fragment>
    )
}

export default PaymentHistory
