import {Fragment, useEffect, useState} from "react"
import {useSelector, useDispatch} from "react-redux"
import {
    Spinner,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CustomInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input
} from "reactstrap"
import {
    Folder,
    Filter,
    X
} from 'react-feather'
import classnames from "classnames"
import Cards from "./Cards"
import Search from "./Search"
import {
    getAllBrandsAtRD, searchBrandsAtRD,
    getBrandProductAttributes,
    getFavoriteFolders,
    createFavoriteFolder,
    addToFavorite, nullResponse
} from '@store/actions/rdDashboard'
import {NULL} from '@src/redux/actionTypes'
import CustomPagination from "@src/views/components/pagination/CustomPagination"
import queryString from "query-string"
import {useHistory} from "react-router-dom"
import '@styles/base/pages/app-ecommerce.scss'

const Brands = () => {
    const history = useHistory()
    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)
    const [brandProductAttributes, setBrandProductAttributes] = useState(null)
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [showFilters, setShowFilters] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [specialDietValues, setSpecialDietValues] = useState([])
    const [categoryValues, setCategoryValues] = useState([])
    const [filterValues, setFilterValues] = useState([])
    const [activeView, setActiveView] = useState('grid')
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const rdDashboardStoreSlice = useSelector(store => store.rd)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!rdDashboardStoreSlice?.addToFavroiteRes) {
            setIsListLoading(true)
            dispatch(nullResponse(NULL.RD_ALL_BRANDS))
            dispatch(nullResponse(NULL.RD_ADD_TO_FAVORITE))
            dispatch(getAllBrandsAtRD())
            setIsAddingToFavorite(false)
            setIsSelectFoldersModalOpen(false)
        }

        if (!!rdDashboardStoreSlice?.favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }

        if (!!rdDashboardStoreSlice?.createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.RD_GET_FOLDERS))
            dispatch(nullResponse(NULL.RD_CREATE_FOLDER))
            dispatch(getFavoriteFolders(false))
            setIsFoldersLoading(true)
        }

        if (!!rdDashboardStoreSlice?.brandProductAttributesRes) {
            setBrandProductAttributes(rdDashboardStoreSlice.brandProductAttributesRes)
        }

        if (!!rdDashboardStoreSlice?.allbrandsAtRd) {
            const {links, meta, pages} = rdDashboardStoreSlice?.allbrandsAtRd
            setPaginationInfo({...links, ...meta, pages})
            setIsListLoading(false)
        }
    }, [rdDashboardStoreSlice])

    useEffect(() => {
        const parsed = queryString.parseUrl(window.location.href)
        const pageNo = parsed?.query?.page
        const API_URL = process.env.REACT_APP_BASEURL
        const path = `${API_URL}rd/rd-brand-list`
        if (pageNo) {
            dispatch(searchBrandsAtRD(true, {
                value: filterValues,
                category: categoryValues,
                special_diet: specialDietValues,
                name: searchValue
            }, `${path}?page=${pageNo}`))
        } else {
            dispatch(getAllBrandsAtRD())
        }
        setIsListLoading(true)
        dispatch(getBrandProductAttributes())
        dispatch(getFavoriteFolders(false))
    }, [])

    const handleCustomPagination = (pageUrl) => {
        setIsListLoading(true)
        dispatch(searchBrandsAtRD(true, {
            value: filterValues,
            category: categoryValues,
            special_diet: specialDietValues,
            name: searchValue
        }, pageUrl))

        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        history.push({
            pathname: window.location.pathname,
            search: `?page=${pageNo}`
        })
    }

    const handleChange = (filterName, value, name) => {
        switch (filterName) {
            case 'ValueChange':
                let values1 = [...filterValues]
                if (value) values1.push(parseInt(name))
                if (!value) values1 = values1.filter(x => x !== parseInt(name))
                setFilterValues(values1)
                break
            case 'CategoryChange':
                let values2 = [...categoryValues]
                if (value) values2.push(parseInt(name))
                if (!value) values2 = values2.filter(x => x !== parseInt(name))
                setCategoryValues(values2)
                break
            case 'SpecialDietChange':
                let values3 = [...specialDietValues]
                if (value) values3.push(parseInt(name))
                if (!value) values3 = values3.filter(x => x !== parseInt(name))
                setSpecialDietValues(values3)
                break
        }
    }

    const handleFiltersSearch = () => {
        setIsListLoading(true)
        dispatch(searchBrandsAtRD(false, {
            value: filterValues,
            category: categoryValues,
            special_diet: specialDietValues,
            name: searchValue
        }))
    }

    const handleSearchByName = (searchValue) => {
        setIsListLoading(true)
        setSearchValue(searchValue)
        dispatch(searchBrandsAtRD(false, {
            value: [],
            category: [],
            special_diet: [],
            name: searchValue
        }))
    }

    const handleResetSearch = () => {
        setIsListLoading(true)
        setSearchValue('')
        setSearchValue([])
        setSpecialDietValues([])
        setCategoryValues([])
        setFilterValues([])
        document.querySelectorAll("input[type=checkbox]").forEach(checkbox => {
            checkbox.checked = false
        })
        dispatch(getAllBrandsAtRD())
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({folderName}))
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const getAddToFavoriteProfile = (profile) => {
        setSelectedProfile(profile)
        if (!profile.is_favorite) setIsSelectFoldersModalOpen(true)
        if (profile.is_favorite) {
            setIsListLoading(true)
            dispatch(addToFavorite({id: profile.user_id, folder_id: profile.folder_id, type: 'profile'}))
        }
    }

    const handleAddToFavorite = () => {
        setIsAddingToFavorite(true)
        dispatch(addToFavorite({id: selectedProfile.user_id, folder_id: selectedFolder.id, type: 'profile'}))
    }

    return (
        <Fragment>
            <div className="content-body">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className='filter-heading'>All Brands</h3>
                    <Button.Ripple className='btn-icon' outline color='primary' onClick={() => setShowFilters(!showFilters)}>
                        <Filter size={16}/>
                    </Button.Ripple>
                </div>

                {showFilters && <Card className='my-0 filters-right-sidebar'>
                    <CardHeader className="card-header-c">
                        <h3 className='filter-heading my-0'>Filters</h3>
                        <Button.Ripple className='btn-icon' color='danger' onClick={() => setShowFilters(false)}>
                            <X size={16}/>
                        </Button.Ripple>
                    </CardHeader>
                    <CardBody className="pt-2 overflow-y-auto">
                        <h5 className='filter-title mt-0 mb-2'>Values</h5>
                        <div className='d-flex flex-wrap' onChange={e => handleChange('ValueChange', e.target.checked, e.target.name)}>
                            {brandProductAttributes?.values?.map(val => (
                                <CustomInput
                                    key={val.label}
                                    type='checkbox'
                                    className="mx-2 mb-1"
                                    name={val.id}
                                    id={val.id}
                                    label={val.label}
                                />
                            ))}
                        </div>
                        <h5 className='filter-title mt-2 mb-2'>Categories</h5>
                        <div className='d-flex flex-wrap' onChange={(e) => handleChange('CategoryChange', e.target.checked, e.target.name)}>
                            {brandProductAttributes?.category?.map(cate => (
                                <CustomInput
                                    key={cate.label}
                                    type='checkbox'
                                    className="mx-2 mb-1"
                                    id={cate.label}
                                    name={cate.id}
                                    label={cate.label}
                                />
                            ))}
                        </div>
                        <h5 className='filter-title mt-2 mb-2'>Special Diets</h5>
                        <div className='d-flex flex-wrap' onChange={(e) => handleChange('SpecialDietChange', e.target.checked, e.target.name)}>
                            {brandProductAttributes?.special_diet?.map(sp_diet => (
                                <CustomInput
                                    key={sp_diet.title}
                                    type='checkbox'
                                    className="mx-2 mb-1"
                                    id={sp_diet.label}
                                    name={sp_diet.id}
                                    label={sp_diet.label}
                                />
                            ))}
                        </div>

                    </CardBody>
                    <CardFooter>
                        <Button.Ripple color='primary' className="mb-1" onClick={handleFiltersSearch} block>
                            Apply Filters
                        </Button.Ripple>
                        <Button.Ripple color='danger' outline onClick={handleResetSearch} block>
                            Clear Filters
                        </Button.Ripple>
                    </CardFooter>
                </Card>}

                <Search handleSearch={handleSearchByName} handleReset={handleResetSearch} searchValue={searchValue}/>
                <div
                    className={classnames("body-content-overlay", {
                        show: sidebarOpen
                    })}
                    onClick={() => setSidebarOpen(false)}
                ></div>
                {isListLoading && (
                    <div className="w-100 my-4 text-center">
                        <Spinner color="primary"/>
                    </div>
                )}
                {!isListLoading && rdDashboardStoreSlice?.allbrandsAtRd?.data?.length !== 0 && (
                    <Fragment>
                        <Cards
                            allBrands={rdDashboardStoreSlice?.allbrandsAtRd?.data}
                            activeView={activeView}
                            handleAddToFavorite={getAddToFavoriteProfile}
                        />
                        <CustomPagination
                            pageLinks={paginationInfo?.pages}
                            onPaginationClick={handleCustomPagination}
                        />
                    </Fragment>)}

                {!isListLoading && rdDashboardStoreSlice?.allbrandsAtRd?.data?.length === 0 && (
                    <div className='d-flex justify-content-center mt-2'>
                        <p>Record doesn't exists</p>
                    </div>)}
            </div>

            <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered'>
                <ModalHeader>Select Folder</ModalHeader>
                <ModalBody className="py-2" style={{maxHeight: '50vh', overflowY: 'auto'}}>
                    {isFoldersLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                    {!isFoldersLoading && (
                        <Fragment>
                            {rdDashboardStoreSlice?.favoriteFoldersRes?.data.map(item => (
                                <Card className={classnames({
                                    'ecommerce-card': true,
                                    'mb-1': true,
                                    'border-1px-white': selectedFolder?.id !== item.id,
                                    'cursor-pointer': true,
                                    'border-1px-purple': selectedFolder?.id === item.id
                                })} key={item.folder_name} onClick={() => handleSelectFolder(item)}>
                                    <CardBody className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Folder
                                                className='mr-1 text-secondary'
                                                size={24}
                                            />
                                            <h5 className='mb-0'>{item.folder_name}</h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))}
                        </Fragment>
                    )}

                    {rdDashboardStoreSlice?.favoriteFoldersRes?.data?.length > 0 && (
                        <p className="text-center">or</p>
                    )}

                    <div className='form-group'>
                        <Label className={classnames({
                            'form-label': true
                        })} htmlFor={`folderName`}>
                            Folder Name<span className="is-invalid">*</span>
                        </Label>
                        <Input
                            type='text'
                            onChange={(e) => {
                                setFolderName(e.target.value)
                                setFolderNameError(false)
                            }}
                            value={folderName}
                            name={`folderName`}
                            id={`folderName`}
                            className={classnames({'is-invalid': folderNameError})}
                        />
                        {folderNameError && <div className="validation-err">This field is required</div>}
                    </div>
                    <div className="text-center">
                        <Button color='primary' outline disabled={isFolderCreating} onClick={handleCreateFolder}>
                            {isFolderCreating ? 'Creating...' : 'Create Folder'}
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' hidden={isAddingToFavorite} onClick={() => setIsSelectFoldersModalOpen(!isSelectFoldersModalOpen)}>
                        Cancel
                    </Button>
                    <Button color='primary' disabled={isAddingToFavorite || !selectedFolder?.id} onClick={() => handleAddToFavorite()}>
                        {isAddingToFavorite ? 'Adding...' : 'Add to Favorite'}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Brands
