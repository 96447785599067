import {Fragment, useState} from 'react'
import navigation from '@src/navigation/verticalBrands'
import classnames from 'classnames'
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'
import {PropTypes} from "prop-types"

const Sidebar = props => {
    const {menuCollapsed, routerProps, menu, currentActiveItem, skin} = props
    const [groupOpen, setGroupOpen] = useState([])
    const [groupActive, setGroupActive] = useState([])
    const [activeItem, setActiveItem] = useState(null)
    const [menuHover, setMenuHover] = useState(false)

    const onMouseEnter = () => {
        if (menuCollapsed) {
            setMenuHover(true)
        }
    }

    return (
        <Fragment>
            <div className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
                expanded: menuHover || menuCollapsed === false,
                'menu-light': skin !== 'semi-dark' && skin !== 'dark',
                'menu-dark': skin === 'semi-dark' || skin === 'dark'
            })}
                 onMouseEnter={onMouseEnter}
                 onMouseLeave={() => setMenuHover(false)}
                 role="menu"
                 tabIndex="0"
                 aria-label="Main menu"
                 onKeyDown={(e) => {
                     if (e.key === 'Enter' || e.key === ' ') {
                         e.preventDefault()
                         setMenuHover(true)
                     }
                 }}
            >
                {menu ? (
                    menu
                ) : (
                    <Fragment>
                        <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
                        <ul className='navigation navigation-main'>
                            <VerticalNavMenuItems
                                items={navigation}
                                groupActive={groupActive}
                                setGroupActive={setGroupActive}
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                                groupOpen={groupOpen}
                                setGroupOpen={setGroupOpen}
                                routerProps={routerProps}
                                menuCollapsed={menuCollapsed}
                                menuHover={menuHover}
                                currentActiveItem={currentActiveItem}
                            />
                        </ul>

                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

Sidebar.propTypes = {
    menuCollapsed: PropTypes.bool.isRequired,
    routerProps: PropTypes.object.isRequired,
    menu: PropTypes.node,
    currentActiveItem: PropTypes.string,
    skin: PropTypes.oneOf(['light', 'semi-dark', 'dark']).isRequired
}

export default Sidebar
