import axios from 'axios'
import {API_URL, BRAND, NULL, REC_OPPORTUNITIES, BRANDS} from '../../actionTypes'

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getBrandProfile = () => {
    return dispatch => {
        axios.get(`${API_URL}brand/view-profile`, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: BRAND.BRAND_PROFILE, brandProfile: res.data })
        }).catch(error => {
            dispatch({ type: BRAND.BRAND_DASHBOARD_ERROR, error })
        })
    }
}

export const getBrandsSubscriptionPlans = () => {
    return dispatch => {
        axios.get(`${API_URL}brand/current-plan`, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: BRAND.CURRENT_PLAN, currentPlanData: res.data })
        }).catch(error => {
            dispatch({ type: BRAND.BRAND_DASHBOARD_ERROR, error })
        })
    }
}

export const getSubscriptionPlans = () => {
    return dispatch => {
        axios.get(`${API_URL}plan-list/monthly`, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then((res) => {
            dispatch({ type: BRAND.SUBSCRIPTION_PLAN, subscriptionPlansData: res.data })
        }).catch(error => {
            dispatch({ type: BRAND.BRAND_DASHBOARD_ERROR, error })
        })
    }
}

export const cancelPlan = (dataObj) => async (dispatch) => {
    dispatch({type: BRANDS.CANCEL_PLAN_REQUEST})
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}brand/cancel-plan`, dataObj, {headers})
        dispatch({type: BRANDS.CANCEL_PLAN_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: BRANDS.CANCEL_PLAN_ERROR, payload: error})
    }
}

export const cancelPlanReset = () => async (dispatch) => {
    dispatch({type: BRANDS.CANCEL_PLAN_RESET})
}

export const nullResponse = (property) => {
    return dispatch => {
        switch (property) {
            case NULL.CANCEL_PLAN:
                dispatch({ type: BRAND.CANCEL_PLAN, cancelPlansData: null })
                break
            case NULL.SUBSCRIPTION_PLAN:
                dispatch({ type: BRAND.SUBSCRIPTION_PLAN, subscriptionPlansData: null })
                break
            case NULL.CURRENT_PLAN:
                dispatch({ type: BRAND.CURRENT_PLAN, currentPlanData: null })
                break
            
        }
    }
  }

