import {useState, Fragment, useEffect, useRef} from 'react'
import {useParams} from "react-router-dom"
import {useSelector, useDispatch} from 'react-redux'
import ImgsViewer from "react-images-viewer"
import {
    ChevronDown,
    Search,
    RefreshCcw,
    MoreHorizontal,
    Trash,
    Folder,
    Video,
    Cloud,
    Link2,
    Image,
    Edit
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    CustomInput,
    DropdownItem,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner, Alert, Form
} from 'reactstrap'
import queryString from "query-string"
import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import * as yup from "yup"
import {Formik} from "formik"
import {
    resourcesContentAction,
    resourcesContentDeleteAction,
    resourcesContentDeleteResetAction,
    resourcesContentStatusAction,
    resourcesContentStatusResetAction,
    uploadDocsAction,
    uploadDocsResetAction,
    uploadLinkResourceAction,
    uploadLinkResourceResetAction,
    uploadMediaAction,
    uploadMediaResetAction
} from "@src/views/tables/data-tables-admin-resources-content/store/actions"
import Avatar from "@components/avatar"

const ResourcesContent = () => {
    const formikRef = useRef()
    const {id} = useParams()

    const [idValue, setIdValue] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)
    const [isAlert, setIsAlert] = useState(false)
    const [resStatus, setResStatus] = useState('')
    const [resMsg, setResMsg] = useState('')
    const [search, setSearch] = useState('')
    const [videoNotifications, setVideoNotifications] = useState('enabled')
    const [linkNotifications, setLinkNotifications] = useState('enabled')
    const [pdfNotifications, setPdfNotifications] = useState('enabled')

    const regexURL = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
    const RESOURCE_DOC_MAX_SIZE = 5 * 1024 * 1024
    const RESOURCE_DOC_FORMATS = ["application/pdf"]
    const RESOURCE_MEDIA_MAX_SIZE = 1024 * 1024 * 1024
    const RESOURCE_MEDIA_FORMATS = ["video/mp4"]
    const RESOURCE_COVER_MAX_SIZE = 1 * 1024 * 1024
    const RESOURCE_COVER_FORMATS = ["image/png", "image/jpeg", "image/jpg"]
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [openImageModal, setOpenImageModal] = useState(false)
    const [openDocsModal, setOpenDocsModal] = useState(false)
    const [openImageViewer, setImageViewer] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [rowData, setRowData] = useState({})
    const [countChar, setCountChar] = useState(0)

    const dispatch = useDispatch()
    const {uploadLinkResourceLoading, uploadLinkResourceData, uploadLinkResourceError} = useSelector(store => store.uploadLinkResourceReducer)
    const {resourcesContentLoading, resourcesContentData, resourcesContentError} = useSelector(store => store.resourcesContentReducer)
    const {resourceContentStatusLoading, resourceContentStatusData, resourceContentStatusError} = useSelector(store => store.resourceContentStatusReducer)
    const {resourceContentDeleteLoading, resourceContentDeleteData, resourceContentDeleteError} = useSelector(store => store.resourceContentDeleteReducer)
    const {uploadDocsLoading, uploadDocsData, uploadDocsError} = useSelector(store => store.uploadDocsReducer)
    const {uploadMediaLoading, uploadMediaData, uploadMediaError} = useSelector(store => store.uploadMediaReducer)

    useEffect(() => {
        if (uploadLinkResourceData?.status === true || uploadLinkResourceData?.status === false) {
            setIsAlert(true)
            setResStatus(uploadLinkResourceData?.status)
            setResMsg(uploadLinkResourceData?.msg)
            setOpenImageModal(false)
            setIdValue('')
            setRowData({})
            dispatch(uploadLinkResourceResetAction())
            dispatch(resourcesContentAction({
                folder_id: id
            }))
            setTimeout(() => setIsAlert(false), 3000)
        }
    }, [uploadLinkResourceData])

    useEffect(() => {
        if (resourceContentStatusData?.status === true || resourceContentStatusData?.status === false) {
            setIsAlert(true)
            setResStatus(resourceContentStatusData?.status)
            setResMsg(resourceContentStatusData?.msg)
            dispatch(resourcesContentStatusResetAction())
            setTimeout(() => setIsAlert(false), 3000)
        }
    }, [resourceContentStatusData])

    useEffect(() => {
        if (resourceContentDeleteData?.status === true || resourceContentDeleteData?.status === false) {
            setIsAlert(true)
            setResStatus(resourceContentDeleteData?.status)
            setResMsg(resourceContentDeleteData?.msg)
            setDeleteModal(false)
            setIdValue("")
            dispatch(resourcesContentDeleteResetAction())
            dispatch(resourcesContentAction({
                folder_id: id
            }))
            setTimeout(() => {
                setIsAlert(false)
            }, 3000)
        }
    }, [resourceContentDeleteData])

    useEffect(() => {
        if (uploadDocsData?.status === true || uploadDocsData?.status === false) {
            setIsAlert(true)
            setResStatus(uploadDocsData?.status)
            setResMsg(uploadDocsData?.msg)
            setOpenDocsModal(false)
            setIdValue('')
            setRowData({})
            dispatch(uploadDocsResetAction())
            dispatch(resourcesContentAction({
                folder_id: id
            }))
            setTimeout(() => setIsAlert(false), 3000)
        }
    }, [uploadDocsData])

    useEffect(() => {
        if (uploadMediaData?.status === true || uploadMediaData?.status === false) {
            setIsAlert(true)
            setResStatus(uploadMediaData?.status)
            setResMsg(uploadMediaData?.msg)
            setOpenVideoModal(false)
            setIdValue('')
            setRowData({})
            dispatch(uploadMediaResetAction())
            dispatch(resourcesContentAction({
                folder_id: id
            }))
            setTimeout(() => setIsAlert(false), 3000)
        }
    }, [uploadMediaData])

    useEffect(() => {
        dispatch(resourcesContentAction({
            folder_id: id
        }))
    }, [])

    const handleDelete = (id) => {
        setIdValue(id)
        setDeleteModal(true)
    }

    const deleteResourceContent = () => {
        dispatch(resourcesContentDeleteAction({
            id: idValue
        }))
    }

    const changeStatus = (id, status) => {
        dispatch(resourcesContentStatusAction({id}))
    }

    const contentViewer = (URL) => {
        if (URL) {
            window.open(URL, '_blank')
        }
    }

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(resourcesContentAction({
            folder_id: id,
            title: search,
            page: pageNo
        }))
    }

    const searchWithTitle = () => {
        dispatch(resourcesContentAction({
            folder_id: id,
            title: search
        }))
    }

    const clearSearch = () => {
        setSearch('')
        dispatch(resourcesContentAction({
            folder_id: id
        }))
    }

    const uploadVideoModal = () => {
        setOpenVideoModal(true)
    }

    const uploadImageModal = () => {
        setOpenImageModal(true)
    }

    const uploadDocsModal = () => {
        setOpenDocsModal(true)
    }

    const uploadResourceLink = (values) => {
        const formData = new FormData()
        if (idValue) {
            formData.append('id', idValue)
        }
        formData.append('folder_id', id)
        formData.append('title', values.title)
        formData.append('description', values.description)
        formData.append('link', values.link)
        if (values.cover_img) {
            formData.append('cover_img', values.cover_img)
        }
        formData.append('notifications', linkNotifications)
        dispatch(uploadLinkResourceAction(formData))
        setCountChar(0)
    }

    const onClose = () => {
        setImageViewer(false)
        setImageURL('')
    }

    const uploadResourceDoc = (values) => {
        const formData = new FormData()
        if (idValue) {
            formData.append('id', idValue)
        }
        formData.append('folder_id', id)
        formData.append('title', values.title)
        formData.append('description', values.description)
        if (values.resource_doc) {
            formData.append('doc', values.resource_doc)
        }
        if (values.cover_img) {
            formData.append('cover_img', values.cover_img)
        }
        formData.append('notifications', pdfNotifications)
        dispatch(uploadDocsAction(formData))
        setCountChar(0)
    }

    const uploadResourceMedia = (values) => {
        const formData = new FormData()
        if (idValue) {
            formData.append('id', idValue)
        }
        formData.append('folder_id', id)
        formData.append('title', values.title)
        formData.append('description', values.description)
        if (values.resource_media) {
            formData.append('media', values.resource_media)
        }
        if (values.cover_img) {
            formData.append('cover_img', values.cover_img)
        }
        formData.append('notifications', videoNotifications)
        dispatch(uploadMediaAction(formData))
        setCountChar(0)
    }

    const handleEdit = (row) => {
        setIdValue(row.id)
        setRowData(row)
        setCountChar(row?.char_counter)
        if (row.type === 'doc') {
            setOpenDocsModal(true)
        }
        if (row.type === 'link') {
            setOpenImageModal(true)
        }
        if (row.type === 'media') {
            setOpenVideoModal(true)
        }
    }

    const handleResourceContentModals = () => {
        setIdValue('')
        setRowData('')
        setCountChar(0)
        setOpenImageModal(false)
        setOpenDocsModal(false)
        setOpenVideoModal(false)
    }

    const countCharacter = (e) => {
        setCountChar(e.target.value.length)
    }

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Cover',
            selector: 'cover',
            width: '100px',
            wrap: true,
            cell: row => {
                return (
                    row.cover ? <Avatar img={row.cover} imgHeight="50" imgWidth="50" className="avatar-img-fit-contain"/> : <Image size="50" style={{color: '#56B6FF'}}/>
                )
            }
        },
        {
            name: 'Title',
            selector: 'title',
            wrap: true,
            cell: row => {
                return (
                    <span className="text-primary cursor-pointer"
                          onClick={() => contentViewer(row.content_path)}
                          role="button"
                          tabIndex="0"
                          aria-label={`View content: ${row.title}`}
                          onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                  e.preventDefault()
                                  contentViewer(row.content_path)
                              }
                          }}
                    >
                        {row.title}
                    </span>
                )
            }
        },
        {
            name: 'Description',
            selector: 'short_desc',
            width: '400px',
            wrap: true,
            cell: row => {
                return (
                    <span title={row.description}>{row.short_desc}</span>
                )
            }
        },
        {
            name: 'Type',
            selector: 'full_type',
            wrap: true
        },
        {
            name: 'Status',
            selector: 'status',
            cell: row => {
                return (
                    <CustomInput
                        className='custom-control-success'
                        type='switch'
                        id={`status-${row.id}`}
                        name={`status`}
                        inline
                        defaultChecked={row.status === 'enabled'}
                        onChange={() => changeStatus(row.id, row.status)}
                        disabled={resourceContentStatusLoading}
                    />
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => contentViewer(row.content_path)}>
                                    <Folder size={15}/>
                                    <span className='align-middle ml-50'>Open</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleEdit(row)}>
                                    <Edit size={15}/>
                                    <span className='align-middle ml-50'>Edit</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDelete(row.id)}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    return (
        <Fragment>
            {openImageViewer && (<style>{`.fade:not(.show){opacity: 1;}`}</style>)}
            <ImgsViewer
                imgs={[{src: imageURL}]}
                isOpen={openImageViewer}
                onClose={onClose}
                showImgCount={false}
            />
            <Card className="brands-my-campaign-table-parent">
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <Col lg="5" md="5">
                        <CardTitle className="my-1 text-break" tag='h4'>
                            {resourcesContentData?.folder?.title || "..."}
                        </CardTitle>
                    </Col>
                    <Col lg="7" md="7">
                        <Button.Ripple className="float-right ml-1" color='primary' onClick={uploadVideoModal}><Video size={16}/> Upload Video</Button.Ripple>
                        <Button.Ripple className="float-right ml-1" color='primary' onClick={uploadImageModal}><Link2 size={16}/> Upload Links</Button.Ripple>
                        <Button.Ripple className="float-right ml-1" color='primary' onClick={uploadDocsModal}><Cloud size={16}/> Upload PDF</Button.Ripple>
                    </Col>
                    <Modal isOpen={openVideoModal} className='modal-dialog-centered'>
                        <ModalHeader toggle={handleResourceContentModals}>
                            {idValue ? "Edit Video" : "Upload Video"}
                        </ModalHeader>
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                                title: rowData?.title ?? "",
                                description: rowData?.description ?? "",
                                resource_media: ""
                            }}
                            validationSchema={yup.object().shape({
                                title: yup.string().required("This field is required.").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
                                description: yup.string(),
                                resource_media: yup.mixed()
                                    .test(
                                        "fileFormat",
                                        "File is empty or invalid.",
                                        value => (idValue) || (value && RESOURCE_MEDIA_FORMATS.includes(value.type))
                                    )
                                    .test(
                                        "fileSize",
                                        "File too large",
                                        value => (idValue) || (value && value.size <= RESOURCE_MEDIA_MAX_SIZE)
                                    ),
                                cover_img: yup.mixed()
                                    .test(
                                        "fileFormat",
                                        "Unsupported Format",
                                        value => !value || (value && RESOURCE_COVER_FORMATS.includes(value.type))
                                    )
                                    .test(
                                        "fileSize",
                                        "File too large",
                                        value => !value || (value && value.size <= RESOURCE_COVER_MAX_SIZE)
                                    )
                            })}
                            onSubmit={(values) => {
                                uploadResourceMedia(values)
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  touched,
                                  values,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <ModalBody>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="title">Title<span className="text-danger">*</span></Label>
                                            <Input
                                                type='text'
                                                name="title"
                                                id="title"
                                                placeholder='Title'
                                                value={values.title}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={uploadMediaLoading}
                                            />
                                            <div className="validation-err">{touched.title && errors.title}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="description" className="d-flex justify-content-between">
                                                <span>Description <span className="text-danger">(Max 100 characters will display)</span></span>
                                                <span className="text-danger font-weight-bold">{countChar}</span>
                                            </Label>
                                            <Input
                                                type='textarea'
                                                name='description'
                                                id='description'
                                                rows='3'
                                                placeholder='Description'
                                                value={values.description}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    countCharacter(e)
                                                }}
                                                disabled={uploadMediaLoading}
                                            />
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="cover-img" className="d-flex justify-content-between">
                                                <span>Cover Image</span>
                                                <span className="text-danger font-weight-bold">Maximum 1MB</span>
                                            </Label>
                                            <CustomInput accept=".png, .jpg, .jpeg"
                                                         type="file"
                                                         className="form-control-file mb-1"
                                                         name="cover_img"
                                                         id="cover-img"
                                                         onBlur={handleBlur}
                                                         onChange={(e) => setFieldValue('cover_img', e.target.files[0])}
                                                         disabled={uploadMediaLoading}
                                            />
                                            <div className="validation-err">{touched.cover_img && errors.cover_img}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="resource-media" className="d-flex justify-content-between">
                                                <span>Video</span>
                                                <span className="font-weight-bold text-danger">Upto 1GB (MP4)</span>
                                            </Label>
                                            <CustomInput accept=".mp4"
                                                         type="file"
                                                         className="form-control-file mb-1"
                                                         name="resource_media"
                                                         id="resource-media"
                                                         onBlur={handleBlur}
                                                         onChange={(e) => setFieldValue('resource_media', e.target.files[0])}
                                                         disabled={uploadMediaLoading}
                                            />
                                            <div className="validation-err">{touched.resource_media && errors.resource_media}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="resource-media" className="d-flex justify-content-between">
                            <span>
                              RD Notifications - {videoNotifications === 'enabled' ? (<span className="text-success">ON</span>) : (<span className="text-danger">OFF</span>)}
                            </span>
                                            </Label>
                                            <CustomInput
                                                className='custom-control-success'
                                                type='switch'
                                                id="video-notify"
                                                defaultChecked={true}
                                                value={videoNotifications}
                                                onChange={(e) => {
                                                    videoNotifications === 'enabled' ? setVideoNotifications('disabled') : setVideoNotifications('enabled')
                                                }}
                                            />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button.Ripple type="submit" color="primary" disabled={uploadMediaLoading}>
                                            {uploadMediaLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : "Submit"}
                                        </Button.Ripple>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                    <Modal isOpen={openImageModal} className='modal-dialog-centered'>
                        <ModalHeader toggle={handleResourceContentModals}>
                            {idValue ? "Edit External Link" : "Add External Link"}
                        </ModalHeader>
                        <Formik
                            initialValues={{
                                title: rowData?.title ?? "",
                                description: rowData?.description ?? "",
                                link: rowData?.content_path ?? ""
                            }}
                            validationSchema={yup.object().shape({
                                title: yup.string().required("This field is required.").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
                                description: yup.string(),
                                link: yup.string().required("This field is required.").matches(regexURL, 'Invalid Link.'),
                                cover_img: yup.mixed()
                                    .test(
                                        "fileFormat",
                                        "Unsupported Format",
                                        value => !value || (value && RESOURCE_COVER_FORMATS.includes(value.type))
                                    )
                                    .test(
                                        "fileSize",
                                        "File too large",
                                        value => !value || (value && value.size <= RESOURCE_COVER_MAX_SIZE)
                                    )
                            })}
                            onSubmit={(values) => {
                                uploadResourceLink(values)
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  touched,
                                  values,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit}>

                                    <ModalBody>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="title">Title<span className="text-danger">*</span></Label>
                                            <Input
                                                type='text'
                                                name="title"
                                                id="title"
                                                placeholder='Title'
                                                value={values.title}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={uploadLinkResourceLoading}
                                            />
                                            <div className="validation-err">{touched.title && errors.title}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="description" className="d-flex justify-content-between">
                                                <span>Description <span className="text-danger">(Max 100 characters will display)</span></span>
                                                <span className="text-danger font-weight-bold">{countChar}</span>
                                            </Label>
                                            <Input
                                                type='textarea'
                                                name='description'
                                                id='description'
                                                rows='3'
                                                placeholder='Description'
                                                value={values.description}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    countCharacter(e)
                                                }}
                                                disabled={uploadLinkResourceLoading}
                                            />
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="cover-img" className="d-flex justify-content-between">
                                                <span>Cover Image</span>
                                                <span className="text-danger font-weight-bold">Maximum 1MB</span>
                                            </Label>
                                            <CustomInput accept=".png, .jpg, .jpeg"
                                                         type="file"
                                                         className="form-control-file mb-1"
                                                         name="cover_img"
                                                         id="cover-img"
                                                         onBlur={handleBlur}
                                                         onChange={(e) => setFieldValue('cover_img', e.target.files[0])}
                                                         disabled={uploadLinkResourceLoading}
                                            />
                                            <div className="validation-err">{touched.cover_img && errors.cover_img}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="link" className="d-flex justify-content-between">
                                                <span>External Link</span>
                                                <span className="text-danger font-weight-bold">URL should contain http:// or https://</span>
                                            </Label>
                                            <Input
                                                type='text'
                                                name="link"
                                                id="link"
                                                placeholder='Link'
                                                value={values.link}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={uploadLinkResourceLoading}
                                            />
                                            <div className="validation-err">{touched.link && errors.link}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="resource-media" className="d-flex justify-content-between">
                            <span>
                              RD Notifications - {linkNotifications === 'enabled' ? (<span className="text-success">ON</span>) : (<span className="text-danger">OFF</span>)}
                            </span>
                                            </Label>
                                            <CustomInput
                                                className='custom-control-success'
                                                type='switch'
                                                id="video-notify"
                                                defaultChecked={true}
                                                value={linkNotifications}
                                                onChange={(e) => {
                                                    linkNotifications === 'enabled' ? setLinkNotifications('disabled') : setLinkNotifications('enabled')
                                                }}
                                            />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button.Ripple type="submit" color="primary" disabled={uploadLinkResourceLoading}>
                                            {uploadLinkResourceLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : "Submit"}
                                        </Button.Ripple>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                    <Modal isOpen={openDocsModal} className='modal-dialog-centered'>
                        <ModalHeader toggle={handleResourceContentModals}>
                            {idValue ? "Edit PDF" : "Upload PDF"}
                        </ModalHeader>
                        <Formik
                            initialValues={{
                                title: rowData?.title ?? "",
                                description: rowData?.description ?? "",
                                resource_doc: ""
                            }}
                            validationSchema={yup.object().shape({
                                title: yup.string().required("This field is required.").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
                                description: yup.string(),
                                resource_doc: yup.mixed()
                                    .test(
                                        "fileFormat",
                                        "Document is empty or invalid.",
                                        value => (idValue) || (value && RESOURCE_DOC_FORMATS.includes(value.type))
                                    )
                                    .test(
                                        "fileSize",
                                        "File too large",
                                        value => (idValue) || (value && value.size <= RESOURCE_DOC_MAX_SIZE)
                                    ),
                                cover_img: yup.mixed()
                                    .test(
                                        "fileFormat",
                                        "Unsupported Format",
                                        value => !value || (value && RESOURCE_COVER_FORMATS.includes(value.type))
                                    )
                                    .test(
                                        "fileSize",
                                        "File too large",
                                        value => !value || (value && value.size <= RESOURCE_COVER_MAX_SIZE)
                                    )
                            })}
                            onSubmit={(values) => {
                                uploadResourceDoc(values)
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  touched,
                                  values,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <ModalBody>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="title">Title<span className="text-danger">*</span></Label>
                                            <Input
                                                type='text'
                                                name="title"
                                                id="title"
                                                placeholder='Title'
                                                value={values.title}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={uploadDocsLoading}
                                            />
                                            <div className="validation-err">{touched.title && errors.title}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="description" className="d-flex justify-content-between">
                                                <span>Description <span className="text-danger">(Max 100 characters will display)</span></span>
                                                <span className="text-danger font-weight-bold">{countChar}</span>
                                            </Label>
                                            <Input
                                                type='textarea'
                                                name='description'
                                                id='description'
                                                rows='3'
                                                placeholder='Description'
                                                value={values.description}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    countCharacter(e)
                                                }}
                                                disabled={uploadDocsLoading}
                                            />
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="cover-img" className="d-flex justify-content-between">
                                                <span>Cover Image</span>
                                                <span className="text-danger font-weight-bold">Maximum 1MB</span>
                                            </Label>
                                            <CustomInput accept=".png, .jpg, .jpeg"
                                                         type="file"
                                                         className="form-control-file mb-1"
                                                         name="cover_img"
                                                         id="cover-img"
                                                         onBlur={handleBlur}
                                                         onChange={(e) => setFieldValue('cover_img', e.target.files[0])}
                                                         disabled={uploadDocsLoading}
                                            />
                                            <div className="validation-err">{touched.cover_img && errors.cover_img}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="resource-doc" className="d-flex justify-content-between">
                                                <span>Docs</span>
                                                <span className="text-danger font-weight-bold">Maximum 5MB</span>
                                            </Label>
                                            <CustomInput accept=".pdf"
                                                         type="file"
                                                         className="form-control-file mb-1"
                                                         name="resource_doc"
                                                         id="resource-doc"
                                                         onBlur={handleBlur}
                                                         onChange={(e) => setFieldValue('resource_doc', e.target.files[0])}
                                                         disabled={uploadDocsLoading}
                                            />
                                            <div className="validation-err">{touched.resource_doc && errors.resource_doc}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="resource-media" className="d-flex justify-content-between">
                            <span>
                              RD Notifications - {pdfNotifications === 'enabled' ? (<span className="text-success">ON</span>) : (<span className="text-danger">OFF</span>)}
                            </span>
                                            </Label>
                                            <CustomInput
                                                className='custom-control-success'
                                                type='switch'
                                                id="video-notify"
                                                defaultChecked={true}
                                                value={pdfNotifications}
                                                onChange={(e) => {
                                                    pdfNotifications === 'enabled' ? setPdfNotifications('disabled') : setPdfNotifications('enabled')
                                                }}
                                            />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button.Ripple type="submit" color="primary" disabled={uploadDocsLoading}>
                                            {uploadDocsLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : "Submit"}
                                        </Button.Ripple>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='title-search'>Title</Label>
                                <Input id='title-search'
                                       placeholder='Title'
                                       value={search}
                                       onChange={(e) => setSearch(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <div className="admin-filter-actions inline-direction">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchWithTitle}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={clearSearch}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                    <Alert color={resStatus ? "success" : "danger"} isOpen={isAlert} className="mt-2" toggle={() => setIsAlert(!isAlert)}>
                        <div className='alert-body'>{resMsg}</div>
                    </Alert>

                </CardBody>
                {resourcesContentLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!resourcesContentLoading && (
                    <>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={1}
                            data={resourcesContentData?.data}
                        />
                        <CustomPagination pageLinks={resourcesContentData?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
            </Card>
            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>Delete Content</ModalHeader>
                <ModalBody>
                    Are you sure want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' outline onClick={() => setDeleteModal(!deleteModal)} disabled={resourceContentDeleteLoading}>
                        No
                    </Button>
                    <Button color='danger' onClick={deleteResourceContent} disabled={resourceContentDeleteLoading}>
                        {resourceContentDeleteLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : "Yes"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default ResourcesContent

