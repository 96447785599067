import {Fragment, useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import Chat from './Chat'
import Sidebar from './SidebarLeft'
import UserProfileSidebar from './UserProfileSidebar'
import classnames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {getChatContactsFromServer, getUserProfileFromServer} from './store/actions'
import '@styles/base/pages/app-chat.scss'
import '@styles/base/pages/app-chat-list.scss'

const AppChat = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const store = useSelector(state => state.chatRd)
    const chatRdStoreSlice = useSelector(state => state.chatRd)

    const [user, setUser] = useState({})
    const [sidebar, setSidebar] = useState(false)
    const [userSidebarRight, setUserSidebarRight] = useState(false)
    const [userSidebarLeft, setUserSidebarLeft] = useState(false)
    const [currentUser, setCurrentUser] = useState({})
    const [chatRdContacts, setchatRdContacts] = useState()
    const [selectedUserRdChat, setselectedUserRdChat] = useState()
    const [selectedRdContactDetail, setSelectedRdContactDetail] = useState()

    const handleSidebar = () => setSidebar(!sidebar)

    const handleUserSidebarLeft = () => setUserSidebarLeft(!userSidebarLeft)

    const handleUserSidebarRight = () => setUserSidebarRight(!userSidebarRight)

    const handleOverlayClick = () => {
        setSidebar(false)
        setUserSidebarRight(false)
        setUserSidebarLeft(false)
    }

    const handleUser = obj => setUser(obj)

    useEffect(() => {
        dispatch(getUserProfileFromServer())
        dispatch(getChatContactsFromServer())
    }, [dispatch])

    useEffect(() => {
        setchatRdContacts(chatRdStoreSlice?.chatRdContacts)
        setselectedUserRdChat(chatRdStoreSlice?.selectedUserRdChat)
        setSelectedRdContactDetail(chatRdStoreSlice?.selectedRdContactDetail)
        setCurrentUser(chatRdStoreSlice?.rdUserProfile)
    }, [chatRdStoreSlice])


    return (
        <Fragment>
            <Sidebar
                store={store}
                sidebar={sidebar}
                handleSidebar={handleSidebar}
                userSidebarLeft={userSidebarLeft}
                handleUserSidebarLeft={handleUserSidebarLeft}
                chatRdContacts={chatRdContacts}
                selectedUserID={id}
            />
            <div className='content-right'>
                <div className='content-wrapper'>
                    <div className='content-body'>
                        <div
                            className={classnames('body-content-overlay', {
                                show: userSidebarRight === true || sidebar === true || userSidebarLeft === true
                            })}
                            onClick={handleOverlayClick}
                        ></div>
                        <Chat
                            store={store}
                            handleUser={handleUser}
                            handleSidebar={handleSidebar}
                            userSidebarLeft={userSidebarLeft}
                            handleUserSidebarRight={handleUserSidebarRight}
                            selectedUserID={id}
                            selectedUserRdChat={selectedUserRdChat}
                            selectedRdContactDetail={selectedRdContactDetail}
                            currentUser={currentUser}
                        />
                        <UserProfileSidebar
                            user={user}
                            userSidebarRight={userSidebarRight}
                            handleUserSidebarRight={handleUserSidebarRight}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AppChat