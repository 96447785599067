import PurchasePackages from '../../views/outerPages/PurchasePackages'
import SubscriptionPlans from '../../views/outerPages/SubscriptionPlans'
import SubscriptionPlanSuccess from '../../views/outerPages/SubscriptionPlanSuccess'
import BrandDashboard from "@src/views/brands/dashboard"
import Messages from "@src/views/brands/messages"
import NutritionExperts from "@src/views/brands/nutritionExperts"
import MyRDs from "@src/views/brands/nutritionExperts/myRDs"
import Favourites from "@src/views/brands/nutritionExperts/favourites"
import FavouriteDetails from "@src/views/brands/nutritionExperts/favourites/details"
import MyCampaigns from "@src/views/brands/myCampaigns"
import CampaignDetails from "@src/views/brands/myCampaigns/CampaignDetails"
import RdOpportunities from "@src/views/brands/rdOpportunities"
import ManagePartnerships from "@src/views/brands/managePartnerships"
import BrandProfile from "@src/views/brands/profile"
import EditBrandProfile from "@src/views/brands/profile/EditProfile"
import SubscriptionPlan from "@src/views/brands/subscriptionPlan"
import ManageACH from "@src/views/brands/manageACH"
import ManageUsers from "@src/views/brands/manageUsers"
import ManageCards from "@src/views/brands/manageCards"
import PaymentHistory from "@src/views/brands/paymentHistory"
import ViewPaymentInvoice from "@src/views/brands/paymentHistory/ViewPaymentInvoice"
import ViewRdPaymentInvoice from "@src/views/brands/paymentHistory/ViewRdPaymentInvoice"
import NotificationSetting from "@src/views/brands/notificationSetting"
import BrandChangePasswordForm from "@src/views/brands/others/ChangePassword"
import Notifications from "@src/views/brands/others/Notifications"
import OpportunityDetails from "@src/views/brands/rdOpportunities/opportunityDetails"
import ViewProfile from "@src/views/brands/nutritionExperts/ViewProfile"

const BrandRoutes = [
    {
        path: '/dashboard/brands',
        component: BrandDashboard,
        exact: true,
        layout: 'VerticalLayoutBrands',
        meta: {
            layout: 'full',
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/brands/apps/chat/chatBrands',
        layout: 'VerticalLayoutBrands',
        appLayout: true,
        exact: true,
        className: 'chat-application',
        component: Messages,
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'brand'
        }
    },
    {
        path: '/brands/apps/chat/chatBrands/:id',
        layout: 'VerticalLayoutBrands',
        appLayout: true,
        className: 'chat-application',
        component: Messages,
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/nutritions',
        className: 'ecommerce-application',
        component: NutritionExperts,
        exact: true,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/my-rds',
        className: 'ecommerce-application',
        component: MyRDs,
        exact: true,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/favorite-folders',
        component: Favourites,
        layout: 'VerticalLayoutBrands',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/favorite-folders/:id',
        component: FavouriteDetails,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/my-campaigns',
        className: 'ecommerce-application',
        component: MyCampaigns,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/campaign-detail/:id',
        className: 'ecommerce-application',
        component: CampaignDetails,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/opportunities',
        className: 'ecommerce-application',
        component: RdOpportunities,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/manage-partnerships',
        className: 'ecommerce-application',
        component: ManagePartnerships,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/profile',
        component: BrandProfile,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/edit-profile',
        component: EditBrandProfile,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/subscription-plan',
        component: SubscriptionPlan,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/manage-ach',
        component: ManageACH,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/manage-users',
        component: ManageUsers,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/manage-card',
        component: ManageCards,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/payment-history',
        component: PaymentHistory,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/invoice/view/:id',
        component: ViewPaymentInvoice,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/view/rd-invoice/:id',
        component: ViewRdPaymentInvoice,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/notification-setting',
        component: NotificationSetting,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/change-password',
        component: BrandChangePasswordForm,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/notifications',
        component: Notifications,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/subscription-plans',
        component: SubscriptionPlans,
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/subscription-plan-success',
        component: SubscriptionPlanSuccess,
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/purchase-plan',
        component: PurchasePackages,
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/nutrition-detail/:id',
        component: OpportunityDetails,
        layout: 'VerticalLayoutBrands',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'brand'
        }
    },
    {
        path: '/dashboard/brands/nutritions/:id',
        className: 'ecommerce-application',
        component: ViewProfile,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    },
    {
        path: '/dashboard/brands/my-rds/:id',
        className: 'ecommerce-application',
        component: ViewProfile,
        layout: 'VerticalLayoutBrands',
        meta: {
            action: "manage",
            resource: "brand",
            isSubscriptionActive: true
        }
    }
]

export default BrandRoutes