import axios from 'axios'
import { API_URL, BRANDS, NULL } from '../actionType'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getCardsListing = () => {
  return dispatch => {
    axios.get(`${API_URL}brand/credit-cards`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRANDS.CARDS_LISTING,
        cardsListingData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: BRANDS.ERROR,
        error
      })
    })
  }
}

export const createNewCard = (params) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("cvc", params.cvc)
    formData.append("owner_name", params.owner_name)
    formData.append("card_number", params.card_number)
    formData.append("expiry_year", params.expiry_year)
    formData.append("expiry_month", params.expiry_month)
    formData.append("re_captcha_token", params.re_captcha_token)
    axios.post(`${API_URL}brand/save-card`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRANDS.CREATE_NEW_CARD,
        createBankCard: response.data
      })
    }).catch((error) => {
      dispatch({
        type: BRANDS.ERROR,
        error
      })
    })
  }
}

export const deleteCard = (id) => {
  return dispatch => {
    axios.post(`${API_URL}brand/delete-card`, {
      card_id: id
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({ type: BRANDS.DELETE_CARD, deleteCardRes: response.data })
    }).catch((error) => {
      dispatch({
        type: BRANDS.ERROR,
        error
      })
    })
  }
}

export const setCardAsDefault = (id) => {
  return dispatch => {
    axios.post(`${API_URL}brand/set-default-card`, {
      card_id: id
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({ type: BRANDS.SET_CARD_AS_DEFAULT, setCardAsDefaultRes: response.data })
    }).catch((error) => {
      dispatch({
        type: BRANDS.ERROR,
        error
      })
    })
  }
}

export const nullResponse = (property) => {
  return dispatch => {
    switch (property) {
      case NULL.CREATE_NEW_CARD:
        dispatch({ type: BRANDS.CREATE_NEW_CARD, createBankCard: null })
        break
      case NULL.SET_CARD_AS_DEFAULT:
        dispatch({ type: BRANDS.SET_CARD_AS_DEFAULT, setCardAsDefaultRes: null })
        break
      case NULL.DELETE_CARD:
        dispatch({ type: BRANDS.DELETE_CARD, deleteCardRes: null })
        break
    }
  }
}
