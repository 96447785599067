import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import BrandsPurchasedMembership from '@src/views/admin/manageEvents/brandsWhoPurchasedMembership'
import RequestsForParticipation from '@src/views/admin/manageEvents/brandsRequestedForParticipation'
import {
    Calendar,
    MapPin
} from 'react-feather'
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardText,
    CardBody,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner
} from 'reactstrap'
import {
    getEventDetailData
} from '@src/views/tables/data-tables-admin-manage-events/store/actions'

const ViewEvent = () => {
    const {id} = useParams()
    const [activeTab, setActiveTab] = useState('1')
    const [approveModal, setApproveModal] = useState(false)
    const [isPageLoader, setIsPageLoader] = useState(true)
    const [eventDetailData, setEventDetailData] = useState({})

    const brandDetailSelector = useSelector(store => store.datatablesAdminManageEvents)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!!brandDetailSelector?.eventDetail) {
            setEventDetailData(brandDetailSelector.eventDetail)
            setIsPageLoader(false)
        }
    }, [brandDetailSelector])
    useEffect(() => {
        setIsPageLoader(true)
        dispatch(getEventDetailData(id))
    }, [])

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (
        <div className="view-page">
            {isPageLoader && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
            {!isPageLoader && (
                <>
                    <Row>
                        <Col lg="12">
                            <Card className='ecommerce-card'>
                                <CardHeader>
                                    <h2 className='font-weight-bolder'>{eventDetailData?.title}</h2>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <CardText>
                                            <div className="p-25">
                                                <h6>
                                                    <Calendar/> {" "}{" "}
                                                    <span>{eventDetailData?.start_datetime} - {eventDetailData?.end_datetime} </span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MapPin/> {" "}{" "}
                                                    <span>{eventDetailData?.venue} </span>
                                                </h6> <br/>
                                                <h5>
                                                    <span className='font-weight-bolder'>Description: </span><span> {eventDetailData?.description} </span>
                                                </h5>
                                            </div>
                                        </CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Card className='ecommerce-card'>
                                <CardBody>
                                    <Row>
                                        <Col lg="3">
                                            <div>
                                                <CardText>
                                                    <div className="p-25">
                                                        <h5 className='font-weight-bolder'>Number of attendees</h5>
                                                        <h6>{eventDetailData?.expected_attendees}</h6>
                                                    </div>
                                                </CardText>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div>
                                                <CardText>
                                                    <div className="p-25">
                                                        <h5 className='font-weight-bolder'>Type of event</h5>
                                                        <h6>{eventDetailData?.event_type}</h6>
                                                    </div>
                                                </CardText>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div>
                                                <CardText>
                                                    <div className="p-25">
                                                        <h5 className='font-weight-bolder'>Type of audience</h5>
                                                        <h6>{eventDetailData?.audience_type}</h6>
                                                    </div>
                                                </CardText>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div>
                                                <CardText>
                                                    <div className="p-25">
                                                        <h5 className='font-weight-bolder'>Expected reach</h5>
                                                        <h6>{eventDetailData?.expected_reach}</h6>
                                                    </div>
                                                </CardText>
                                            </div>
                                        </Col>
                                    </Row> <br/>
                                    <Row>
                                        <Col lg="3">
                                            <div>
                                                <CardText>
                                                    <div className="p-25">
                                                        <h5 className='font-weight-bolder'>Cost of partnerships</h5>
                                                        <h6>{eventDetailData?.partnership ?? "Free"} ($ {eventDetailData?.cost_of_partnership ?? "0"})</h6>
                                                    </div>
                                                </CardText>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div>
                                                <CardText>
                                                    <div className="p-25">
                                                        <h5 className='font-weight-bolder'>Dietition name who post event</h5>
                                                        <h6>{eventDetailData?.rd_name}</h6>
                                                    </div>
                                                </CardText>
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div>
                                                <CardText>
                                                    <div className="p-25">
                                                        <h5 className='font-weight-bolder'>Followers</h5>
                                                        <h6>{eventDetailData?.no_of_follower}</h6>
                                                    </div>
                                                </CardText>
                                            </div>
                                        </Col>
                                    </Row><br/>
                                    <Row>
                                        <Col lg="12">
                                            <div>
                                                <CardText>
                                                    <div className="p-25">
                                                        <h5 className='font-weight-bolder'>Dietition description</h5>
                                                        <h6>
                                                            <p>{eventDetailData?.rd_description}</p>
                                                        </h6>
                                                    </div>
                                                </CardText>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody className="p-0">
                            <Nav tabs className="mb-0">
                                <NavItem>
                                    <NavLink
                                        active={activeTab === '1'}
                                        onClick={() => {
                                            toggle('1')
                                        }}
                                    >
                                        Brands Who Purchased Membership
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        active={activeTab === '2'}
                                        onClick={() => {
                                            toggle('2')
                                        }}
                                    >
                                        Brands Requests For Participation
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                    <Card>
                        <TabContent className='py-0' activeTab={activeTab}>
                            <TabPane tabId='1'>
                                <BrandsPurchasedMembership/>
                            </TabPane>
                            <TabPane tabId='2'>
                                <RequestsForParticipation/>
                            </TabPane>
                        </TabContent>
                    </Card>
                    <Modal isOpen={approveModal} toggle={() => setApproveModal(!approveModal)} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setApproveModal(!approveModal)}>Approve Profile</ModalHeader>
                        <ModalBody>
                            Are you sure want to approve this profile?
                        </ModalBody>
                        <ModalFooter>
                            <Button color='danger' outline onClick={() => setApproveModal(!approveModal)}>
                                No
                            </Button>
                            <Button color='primary' onClick={() => setApproveModal(!approveModal)}>
                                Yes
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
            )}
        </div>
    )
}

export default ViewEvent