import {useEffect, useState} from 'react'
import {Link, NavLink, useHistory} from 'react-router-dom'
import Avatar from '@components/avatar'
import logo from '@src/assets/images/logo.png'
import {isUserLoggedIn} from '@utils'
import {useDispatch} from 'react-redux'
import {handleLogout} from '@store/actions/auth'
import {
    User,
    Menu,
    X,
    LogOut
} from 'react-feather'
import defaultAvatar from "@src/assets/images/avatars/avatar-blank.png"
import {PropTypes} from "prop-types"

const VerticalMenuHeader = props => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        if (isUserLoggedIn() !== null) {
            setUserData(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`)))
        }
    }, [])

    const userAvatar = (userData && userData.profile_image) || defaultAvatar
    const {menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover} = props

    useEffect(() => {
        if (!menuHover && menuCollapsed) setGroupOpen([])
    }, [menuHover, menuCollapsed])

    const Toggler = () => {
        if (!menuCollapsed) {
            return (
                <X
                    size={20}
                    data-tour='toggle-icon'
                    className='text-black toggle-icon d-none d-xl-block'
                    onClick={() => setMenuCollapsed(true)}
                />
            )
        } else {
            return (
                <Menu
                    size={20}
                    data-tour='toggle-icon'
                    className='text-black toggle-icon d-none d-xl-block'
                    onClick={() => setMenuCollapsed(false)}
                />
            )
        }
    }

    const logoutUser = () => {
        dispatch(handleLogout())
        history.push('/login')
    }
    return (
        <>
            <div className='navbar-header'>
                <ul className='nav navbar-nav flex-row'>
                    <li className='nav-item mr-auto'>
                        <NavLink to='/' className='navbar-brand'>
                            <span className='brand-logo'>
                                <img src={logo} alt='logo'/>
                            </span>
                        </NavLink>
                    </li>
                    <li className='nav-item nav-toggle'>
                        <div className='nav-link modern-nav-toggle cursor-pointer'>
                            <Toggler/>
                            <X onClick={() => setMenuVisibility(false)} className='toggle-icon icon-x d-block d-xl-none' size={20}/>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="userDropDown">
                <ul>
                    <div className='user-nav d-sm-flex d-none flex-row align-items-center'>
                        <div className="col-auto">
                            <Avatar className="avatar-img-fit-contain" img={userAvatar} imgHeight='91' imgWidth='91' status='online'/>
                        </div>
                        <div className="col">
                            <Link to="/loginrd" className='user-name font-weight-medium text-white'>{userData?.name}</Link>
                            <ul>
                                <li className="d-inline-block mr-1" style={{marginTop: '3px'}}>
                                    <NavLink to="/dashboard/admin/edit-profile" className="text-white"><User size={18}/></NavLink>
                                </li>
                                <li className="d-inline-block mr-1">
                                    <LogOut className="text-white" size={18} onClick={logoutUser}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ul>
            </div>
        </>
    )
}

VerticalMenuHeader.propTypes = {
    menuCollapsed: PropTypes.bool.isRequired,
    setMenuCollapsed: PropTypes.func.isRequired,
    setMenuVisibility: PropTypes.func.isRequired,
    setGroupOpen: PropTypes.func.isRequired,
    menuHover: PropTypes.bool.isRequired
}

export default VerticalMenuHeader
