import axios from 'axios'

// ** Get table Data
const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

// ** Get table Data
export const getCmsListingData = () => {
  return async dispatch => {
    await axios.get(`${process.env.REACT_APP_BASEURL}admin/cms`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'MANAGE_CMS_DATA',
        allData: response.data
      })
    })
  }
}

export const updateCmsData = (params) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("id", params.id)
    formData.append("title", params.title)
    formData.append("content", params.content)
    formData.append("slug", params.slug)
    axios.post(`${process.env.REACT_APP_BASEURL}admin/update-cms`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'UPDATE_CMS_DATA',
        allData: response.data
      })
    })
  }
}

