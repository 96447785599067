import Layout from '@layouts/VerticalLayout'
import {PropTypes} from "prop-types"

const VerticalLayout = props => <Layout {...props}>{props.children}</Layout>

VerticalLayout.propTypes = {
    children: PropTypes.node.isRequired
}

export default VerticalLayout
