import {useState, Fragment, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
    RefreshCcw,
    Search,
    MoreHorizontal,
    Eye,
    Trash,
    Edit3
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    CustomInput,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner,
    Form
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {
    adminAddUserAction,
    adminAddUserActionReset,
    adminUsersListAction,
    adminUserStatusAction,
    adminUserStatusResetAction,
    adminUserDeleteAction,
    adminUserDeleteResetAction,
    adminUserUpdateAction,
    adminUserUpdateResetAction
} from "@src/redux/actions/AdminActions"
import {useFormik} from "formik"
import * as Yup from "yup"
import * as Icon from 'react-feather'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Permissions = () => {
    const sweetAlert = withReactContent(Swal)

    const [openAddUserModal, setOpenAddUserModal] = useState(false)
    const [openViewUserModal, setOpenViewUserModal] = useState(false)
    const [openEditUserModal, setOpenEditUserModal] = useState(false)
    const [userInfo, setUserInfo] = useState(null)

    const {
        adminAddUserLoading,
        adminAddUserData,
        adminAddUserError,

        adminUsersListLoading,
        adminUsersListData,
        adminUsersListError,

        adminUserStatusLoading,
        adminUserStatusData,
        adminUserStatusError,

        adminUserDeleteLoading,
        adminUserDeleteData,
        adminUserDeleteError,

        adminUserUpdateLoading,
        adminUserUpdateData,
        adminUserUpdateError
    } = useSelector(store => store.adminReducer)

    const dispatch = useDispatch()

    const addForm = useFormik({
        initialValues: {
            name: '',
            email: '',
            permissions: []
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required('This field is required.'),
            email: Yup.string().email('Invalid email.').required('This field is required.'),
            permissions: Yup.array().min(1, 'A minimum of one permission is needed.').required('A minimum of one permission is needed.')
        }),
        onSubmit: (values) => {
            dispatch(adminAddUserAction({
                name: values.name,
                email: values.email,
                permissions: values.permissions
            }))
        }
    })

    const editForm = useFormik({
        initialValues: {
            id: '',
            name: '',
            email: '',
            permissions: []
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required('This field is required.'),
            email: Yup.string().email('Invalid email.').required('This field is required.'),
            permissions: Yup.array().min(1, 'A minimum of one permission is needed.').required('A minimum of one permission is needed.')
        }),
        onSubmit: (values) => {
            dispatch(adminUserUpdateAction({
                id: values.id,
                name: values.name,
                email: values.email,
                permissions: values.permissions
            }))
        }
    })

    const searchForm = useFormik({
        initialValues: {
            search: ''
        },
        onSubmit: (values) => {
            dispatch(adminUsersListAction(`?query=${values.search}`))
        }
    })

    useEffect(() => {
        if (adminAddUserData?.status) {
            addForm.resetForm()
            setOpenAddUserModal(false)
            sweetAlert.fire('Congratulations!', adminAddUserData?.msg, 'success')
            dispatch(adminAddUserActionReset())
            dispatch(adminUsersListAction(``))
        }

        if (adminUserStatusData?.status) {
            addForm.resetForm()
            setOpenAddUserModal(false)
            sweetAlert.fire('Congratulations!', adminUserStatusData?.msg, 'success')
            dispatch(adminUserStatusResetAction())
        }

        if (adminUserDeleteData?.status) {
            addForm.resetForm()
            setOpenAddUserModal(false)
            sweetAlert.fire('Congratulations!', adminUserDeleteData?.msg, 'success')
            dispatch(adminUserDeleteResetAction())
            dispatch(adminUsersListAction(``))
        }

        if (adminUserUpdateData?.status) {
            editForm.resetForm()
            setOpenEditUserModal(false)
            sweetAlert.fire('Congratulations!', adminUserUpdateData?.msg, 'success')
            dispatch(adminUserUpdateResetAction())
            dispatch(adminUsersListAction(``))
        }

        if (adminAddUserError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (adminAddUserError?.response?.data?.name) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminAddUserError?.response?.data?.name[0]}</li>`)
            }
            if (adminAddUserError?.response?.data?.email) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminAddUserError?.response?.data?.email[0]}</li>`)
            }
            if (adminAddUserError?.response?.data?.permissions) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminAddUserError?.response?.data?.permissions[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(adminAddUserActionReset())
        }

        if (adminUserUpdateError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (adminAddUserError?.response?.data?.name) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminUserUpdateError?.response?.data?.name[0]}</li>`)
            }
            if (adminAddUserError?.response?.data?.email) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminUserUpdateError?.response?.data?.email[0]}</li>`)
            }
            if (adminAddUserError?.response?.data?.permissions) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminUserUpdateError?.response?.data?.permissions[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(adminUserUpdateResetAction())
        }
    }, [
        adminAddUserData,
        adminUserStatusData,
        adminUserDeleteData,
        adminUserUpdateData,
        adminAddUserError,
        adminUserUpdateError
    ])

    useEffect(() => {
        dispatch(adminUsersListAction(``))
    }, [])

    const deleteUser = (id) => {
        sweetAlert.fire({
            title: 'Confirm!',
            icon: 'warning',
            html: '<p>Are you sure you want to delete this user?</p>',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminUserDeleteAction({
                    id
                }))
            }
        })
    }

    const editUser = (data) => {
        editForm.setFieldValue('id', data.id)
        editForm.setFieldValue('name', data.name)
        editForm.setFieldValue('email', data.email)
        editForm.setFieldValue('permissions', data.permissions)
        setOpenEditUserModal(true)
    }

    const [advSearchColumns] = useState([
        {
            name: 'Name',
            cell: (row) => <span>{row.name}</span>
        },
        {
            name: 'Email',
            cell: (row) => <span>{row.email}</span>
        },
        {
            name: 'Status',
            cell: row => {
                return (
                    <CustomInput className='custom-control-success'
                                 type='switch'
                                 id={`status-${row.id}`}
                                 defaultChecked={row.status === 'enabled'}
                                 onClick={() => dispatch(adminUserStatusAction({
                                     id: row.id
                                 }))}
                    />
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2 cursor-pointer'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => {
                                    setUserInfo(row)
                                    setOpenViewUserModal(true)
                                }}>
                                    <span className='align-middle ml-50'>
                                        <Eye size={14}/> View
                                    </span>
                                </DropdownItem>
                                <DropdownItem onClick={() => editUser(row)}>
                                    <span className='align-middle ml-50'>
                                        <Edit3 size={14}/> Edit
                                    </span>
                                </DropdownItem>
                                <DropdownItem onClick={() => deleteUser(row?.id)}>
                                    <span className='align-middle ml-50 text-danger'>
                                        <Trash size={14}/> Delete
                                    </span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    const menus = [
        {
            label: 'Dashboard',
            value: 'admin-dashboard'
        },
        {
            label: 'Manage RDs',
            value: 'admin-manage-rd'
        },
        {
            label: 'Pending RDs',
            value: 'admin-pending-rd'
        },
        {
            label: 'Manage Brands',
            value: 'admin-manage-brands'
        },
        {
            label: 'Manage Events',
            value: 'admin-manage-events'
        },
        {
            label: 'Subscribed Brands',
            value: 'admin-subscribed-brands'
        },
        {
            label: 'Manage Coupons',
            value: 'admin-manage-coupons'
        },
        {
            label: 'Manage Reports',
            value: 'admin-manage-reports'
        },
        {
            label: 'View Conversations',
            value: 'admin-view-conversations'
        },
        {
            label: 'Account Settings',
            value: 'admin-Account-Settings'
        },
        {
            label: 'Manage CMS',
            value: 'admin-manage-csm'
        },
        {
            label: 'Manage Blog',
            value: 'admin-manage-blog'
        },
        {
            label: 'FAQs',
            value: 'admin-faqs'
        },
        {
            label: 'Resources',
            value: 'admin-resources'
        }
    ]

    const handlePermissions = (e, form) => {
        const {permissions} = form.values
        if (e.target.checked) {
            form.setFieldValue('permissions', [...permissions, e.target.value])
        } else {
            const updatedPermissions = permissions.filter(item => item !== e.target.value)
            form.setFieldValue('permissions', updatedPermissions)
        }
    }

    const permissionsTitle = (item) => {
        return menus.find((x) => x.value === item)?.label
    }

    return (
        <Fragment>
            <Card className="brands-my-campaign-table-parent">
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom'>
                    <CardTitle tag='h4'>Users & Permissions</CardTitle>
                    <div>
                        <Button.Ripple color='primary' size='sm' onClick={() => setOpenAddUserModal(true)}>
                            <Icon.UserPlus size={14}/> Add New User
                        </Button.Ripple>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={searchForm.handleSubmit}>
                        <Row className='mt-1'>
                            <Col lg='3' md='4'>
                                <FormGroup>
                                    <Input id='search'
                                           placeholder='Search...'
                                           name="search"
                                           value={searchForm.values.search}
                                           onBlur={searchForm.handleBlur}
                                           onChange={searchForm.handleChange}
                                           disabled={adminUsersListLoading}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button.Ripple className='btn-icon mr-10px' color='success' disabled={adminUsersListLoading} type='submit'>
                                    <Search size={16}/> Search
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' color='primary' disabled={adminUsersListLoading} onClick={() => {
                                    dispatch(adminUsersListAction(``))
                                    searchForm.resetForm()
                                }}>
                                    <RefreshCcw size={16}/> Reset
                                </Button.Ripple>
                            </Col>
                        </Row>
                    </Form>

                    {(adminUserStatusLoading || adminUserDeleteLoading) && (
                        <div className='p-1 bg-light text-center mb-1'>
                            <span>
                                <Spinner color='primary' style={{height: '16px', width: '16px'}}/> <strong>Please wait...</strong>
                            </span>
                        </div>
                    )}

                    {adminUsersListLoading ? (
                        <div className="w-100 my-4 text-center">
                            <Spinner color='primary'/>
                        </div>
                    ) : (
                        <DataTable noHeader columns={advSearchColumns} className='react-dataTable' data={adminUsersListData?.data} style={{minHeight: '130px'}}/>
                    )}
                </CardBody>
            </Card>

            {/* --- Add User Modal --- */}
            <Modal isOpen={openAddUserModal} className='modal-dialog-centered' size="lg">
                <ModalHeader toggle={() => {
                    setOpenAddUserModal(false)
                    addForm.resetForm()
                }}>
                    Add New User
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={addForm.handleSubmit}>
                        <Row>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor="name">Name<span className="text-danger">*</span></Label>
                                <Input
                                    type='text'
                                    name="name"
                                    id="name"
                                    placeholder='Name'
                                    value={addForm.values.name}
                                    onBlur={addForm.handleBlur}
                                    onChange={addForm.handleChange}
                                    disabled={adminAddUserLoading}
                                />
                                <div className="validation-err">{addForm.touched.name && addForm.errors.name}</div>
                            </FormGroup>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor="email">Email<span className="text-danger">*</span></Label>
                                <Input
                                    type='text'
                                    name="email"
                                    id="email"
                                    placeholder='Email'
                                    value={addForm.values.email}
                                    onBlur={addForm.handleBlur}
                                    onChange={addForm.handleChange}
                                    disabled={adminAddUserLoading}
                                />
                                <div className="validation-err">{addForm.touched.email && addForm.errors.email}</div>
                            </FormGroup>
                            <FormGroup tag={Col} md='12'>
                                <Label className='font-medium-1'>
                                    <Icon.Key size='16'/> <strong>Set Permissions</strong>
                                </Label>
                                <div className="validation-err">{addForm.touched.permissions && addForm.errors.permissions}</div>
                            </FormGroup>
                            {menus.map((item, index) => (
                                <FormGroup tag={Col} md='4'>
                                    <CustomInput type='checkbox'
                                                 label={item.label}
                                                 id={item.value}
                                                 value={item.value}
                                                 onChange={(e) => handlePermissions(e, addForm)}
                                                 disabled={adminAddUserLoading}
                                    />
                                </FormGroup>
                            ))}
                            <FormGroup tag={Col} md='12' className='text-right border-top'>
                                <Button.Ripple type="submit" color="primary" disabled={adminAddUserLoading} className='mt-1'>
                                    {adminAddUserLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : "Create"}
                                </Button.Ripple>
                            </FormGroup>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            {/* --- View User Modal --- */}
            <Modal isOpen={openViewUserModal} className='modal-dialog-centered' size="lg">
                <ModalHeader toggle={() => {
                    setOpenViewUserModal(false)
                    setUserInfo(null)
                }}>
                    View User
                </ModalHeader>
                <ModalBody className='pl-2 pr-2'>
                    <h4 className='text-primary bg-light p-1 mb-1'>User Info</h4>
                    <table className='table table-bordered'>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                        </tr>
                        <tr>
                            <td>{userInfo?.name}</td>
                            <td>{userInfo?.email}</td>
                        </tr>
                    </table>
                    <h4 className='text-primary bg-light p-1 mb-1'>Granted Permissions</h4>
                    <Row className='p-1'>
                        {userInfo?.permissions.map((item, index) => (
                            <FormGroup tag={Col} md='4' key={index}>
                                <CustomInput type='checkbox' label={permissionsTitle(item)} checked={true}/>
                            </FormGroup>
                        ))}
                    </Row>
                </ModalBody>
            </Modal>

            {/* --- Edit User Modal --- */}
            <Modal isOpen={openEditUserModal} className='modal-dialog-centered' size="lg">
                <ModalHeader toggle={() => {
                    setOpenEditUserModal(false)
                    editForm.resetForm()
                }}>
                    Edit User
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={editForm.handleSubmit}>
                        <Row>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor="name">Name<span className="text-danger">*</span></Label>
                                <Input
                                    type='text'
                                    name="name"
                                    id="name"
                                    placeholder='Name'
                                    value={editForm.values.name}
                                    onBlur={editForm.handleBlur}
                                    onChange={editForm.handleChange}
                                    disabled={adminUserUpdateLoading}
                                />
                                <div className="validation-err">{editForm.touched.name && editForm.errors.name}</div>
                            </FormGroup>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor="email">Email<span className="text-danger">*</span></Label>
                                <Input
                                    type='text'
                                    name="email"
                                    id="email"
                                    placeholder='Email'
                                    value={editForm.values.email}
                                    onBlur={editForm.handleBlur}
                                    onChange={editForm.handleChange}
                                    disabled={adminUserUpdateLoading}
                                />
                                <div className="validation-err">{editForm.touched.email && editForm.errors.email}</div>
                            </FormGroup>
                            <FormGroup tag={Col} md='12'>
                                <Label className='font-medium-1'>
                                    <Icon.Key size='16'/> <strong>Set Permissions</strong>
                                </Label>
                                <div className="validation-err">{editForm.touched.permissions && editForm.errors.permissions}</div>
                            </FormGroup>
                            {menus.map((item, index) => (
                                <FormGroup tag={Col} md='4' key={index}>
                                    <CustomInput type='checkbox'
                                                 label={item.label}
                                                 id={item.value}
                                                 value={item.value}
                                                 onChange={(e) => handlePermissions(e, editForm)}
                                                 disabled={adminUserUpdateLoading}
                                                 checked={editForm.values.permissions.includes(item.value)}
                                    />
                                </FormGroup>
                            ))}
                            <FormGroup tag={Col} md='12' className='text-right border-top'>
                                <Button.Ripple type="submit" color="primary" disabled={adminUserUpdateLoading} className='mt-1'>
                                    {adminUserUpdateLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : "Update"}
                                </Button.Ripple>
                            </FormGroup>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default Permissions
