import axios from 'axios'
import {API_URL, COMMISSION_REPORT} from '../actionTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getCommissionReportingData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-commission-report`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: COMMISSION_REPORT.LISTING,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: COMMISSION_REPORT.ERROR,
        error
      })
    })
  }
}

export const getSearchCommissionReports = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-commission-report`
  return async dispatch => {
    await axios.post(endpoint, {
      rd_name: body.rd_name,
      event_name: body.event_name,
      from_date: body.from_date,
      to_date: body.to_date
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: COMMISSION_REPORT.LISTING,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: COMMISSION_REPORT.ERROR,
        error
      })
    })
  }
}

export const nullSearchData = (listName) => {
  return async dispatch => {
    switch (listName) {
      case 'searchComissionReport':
        dispatch({
          type: COMMISSION_REPORT.SEARCH,
          searchCommissionReports: null
        })
        break
      default:
        break
    }
  }
}
