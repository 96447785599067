import { useState } from 'react'
import classnames from 'classnames'
import { Menu, Grid, List } from 'react-feather'
import {
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
  ButtonGroup
} from 'reactstrap'

const ProductsHeader = props => {
  const { activeView, setActiveView, dispatch,
    //getProducts,
    store, setSidebarOpen, handleChangeTypeFilter } = props

  const [activeSortFilter, setActiveSortFilter] = useState('Past Partners')

  const handleFilter = (title, value) => {
    handleChangeTypeFilter(value)
    setActiveSortFilter(title)
  }

  return (
    <div className='ecommerce-header'>
      <Row>
        <Col sm='12'>
          <div className='ecommerce-header-items'>
            {/* <div className='result-toggler'>
              <button className='navbar-toggler shop-sidebar-toggler' onClick={() => setSidebarOpen(true)}>
                <span className='navbar-toggler-icon d-block d-lg-none'>
                  <Menu size={14} />
                </span>
              </button>
              <span className='search-results'>{store.totalProducts} Results Found</span>
            </div> */}
            <div className='view-options d-flex ml-auto'>
              <UncontrolledButtonDropdown className='dropdown-sort'>
                <DropdownToggle className='text-capitalize' color='primary' outline caret>
                  {activeSortFilter}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className='w-100'
                    onClick={() => handleFilter('My Favorites', 'favorite')}
                  >
                    My Favorites
                  </DropdownItem>
                  <DropdownItem
                    className='w-100'
                    onClick={() => handleFilter('Past Partners', 'partnerships')}
                  >
                    Past Partners
                  </DropdownItem>
                  {/* <DropdownItem
                    className='w-100'
                    onClick={() => dispatch(getProducts({ ...store.params, sortBy: 'price-desc' }))}
                  >
                    Highest
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              {/* <ButtonGroup className='btn-group-toggle'>
                <Button
                  tag='label'
                  className={classnames('btn-icon view-btn grid-view-btn', {
                    active: activeView === 'grid'
                  })}
                  color='primary'
                  outline
                  onClick={() => setActiveView('grid')}
                >
                  <Grid size={18} />
                </Button>
                <Button
                  tag='label'
                  className={classnames('btn-icon view-btn list-view-btn', {
                    active: activeView === 'list'
                  })}
                  color='primary'
                  outline
                  onClick={() => setActiveView('list')}
                >
                  <List size={18} />
                </Button>
              </ButtonGroup> */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ProductsHeader
