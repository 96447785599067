import Layout from '@layouts/VerticalLayoutRd'
import {PropTypes} from "prop-types"

const VerticalLayoutRd = props => <Layout {...props}>{props.children}</Layout>

VerticalLayoutRd.propTypes = {
    children: PropTypes.node.isRequired
}

export default VerticalLayoutRd
