import {Fragment, useEffect, useState} from "react"
import {useSelector, useDispatch} from "react-redux"
import {useLocation, useHistory, useParams} from "react-router-dom"
import {
    Card,
    CardBody,
    Button,
    Row,
    Col,
    CardText,
    Form,
    Label,
    FormGroup,
    Input,
    CustomInput,
    Alert, Spinner
} from "reactstrap"
import {campaignApplication, getAllRdCampaigns} from "@src/views/rds/brandCampaigns/store/actions"
import Avatar from "@components/avatar"
import {Facebook, Instagram, Linkedin, Twitter} from "react-feather"
import Tiktok from "@src/assets/images/icons/tik-tok.svg"
import Pinterest from "@src/assets/images/icons/pinterest.svg"
import Web from "@src/assets/images/icons/web.svg"

const ApplyCampaign = () => {
    const rdCampaignSelector = useSelector((store) => store.rdCampaigns)
    const {id} = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const [error, setError] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [visible, setVisible] = useState(false)
    const [question, setQuestion] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [inValid, setInValid] = useState(false)
    const [campaignDetails, setCampaignDetails] = useState("")
    const [rdDetail, setRdDetail] = useState("")
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

    useEffect(() => {
        if (!!rdCampaignSelector?.application) {
            const {status, msg} = rdCampaignSelector?.application
            setVisible(true)
            if (!status) {
                setError(msg)
                setTimeout(() => {
                    setVisible(false)
                }, 5000)
                dispatch({type: "CAMPAIGN_MSG_RESET"})
            } else {
                setIsSubmitting(false)
                setDisableSubmitBtn(true)
                window.scroll(0, 0)
                setSuccessMsg(msg)
                setTimeout(() => {
                    history.goBack()
                }, 6000)
                dispatch({type: "CAMPAIGN_MSG_RESET"})
                // setTimeout(() => {
                //   setVisible(false)
                // }, 5000)
            }
        }

        if (rdCampaignSelector?.allCampaignsListing?.data) {
            setIsTableLoading(false)
            setCampaignDetails(rdCampaignSelector?.allCampaignsListing?.data[0] ?? "")
            setRdDetail(rdCampaignSelector?.allCampaignsListing?.rd_details)
        }
    }, [rdCampaignSelector])

    useEffect(() => {
        if (id) {
            setIsTableLoading(true)
            dispatch(getAllRdCampaigns(false, '', id))
        } else {
            history.push('/dashboard/rd/campaigns')
        }
    }, [])

    const onHandleChange = (e) => {
        const {name, value} = e.target
        setQuestion({...question, [name]: {answer: value, question_id: name}})
        setInValid(false)
    }

    const saveAnswers = () => {
        let canSubmit
        const testsQuestions = Object.keys(question).map((key) => {
            return question[key]
        })
        const queLength = testsQuestions.length
        const dataLength = campaignDetails?.form_attributes.length
        queLength === dataLength ? canSubmit = true : canSubmit = false
        testsQuestions.forEach((val, index) => {
            if (!val.answer) {
                canSubmit = false
                return false
            }
        })
        if (canSubmit) {
            setIsSubmitting(true)
            const params = {
                campaign_id: campaignDetails?.id,
                campaign_form_id: campaignDetails?.campaign_form_id,
                form_data: testsQuestions
            }
            dispatch(campaignApplication(params))
        } else {
            setInValid(true)
        }
    }

    const noCampaign = () => {
        history.push('/dashboard/rd/campaigns')
    }

    return (
        <Fragment>
            {isTableLoading && (
                <div className="w-100 my-4 text-center">
                    <Spinner color="primary"/>
                </div>
            )}

            {!isTableLoading && !campaignDetails && (
                <Alert color="danger" isOpen={true} toggle={noCampaign}>
                    <div className='alert-body'>Sorry! Campaign is no longer available.</div>
                </Alert>
            )}

            {!isTableLoading && campaignDetails && campaignDetails?.is_applied && (
                <Alert color="success" isOpen={true} toggle={noCampaign}>
                    <div className='alert-body'>You have already applied on this campaign.</div>
                </Alert>
            )}

            {!isTableLoading && campaignDetails && campaignDetails?.is_applied && (
                <Alert color="success" isOpen={true} toggle={noCampaign}>
                    <div className='alert-body'>You have already applied on this campaign.</div>
                </Alert>
            )}

            {!isTableLoading && campaignDetails && !campaignDetails?.is_applied && !campaignDetails?.can_apply && (
                <Alert color="danger" isOpen={true} toggle={noCampaign}>
                    <div className='alert-body'>The campaign has met its maximum amount of submissions, hence it is not available.</div>
                </Alert>
            )}

            {!isTableLoading && campaignDetails && !campaignDetails?.is_applied && campaignDetails?.can_apply && (
                <>
                    <h4 className="brandSlidesHeading font-weight-bolder mb-2">
                        {campaignDetails?.brand_name}
                    </h4>
                    <div className="brandSlides">
                        <Row>
                            <Col md="12">
                                <Alert color={!!error ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                                    <div className='alert-body'>
                                        {error || successMsg}
                                    </div>
                                </Alert>
                            </Col>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <div className="item-img text-center mx-auto">
                                            <Avatar img={campaignDetails?.brand_img} imgHeight="140" imgWidth="140"
                                                    className="avatar-img-fit-contain"/>
                                        </div>
                                        <CardBody>
                                            <h4 className="item-name text-center">
                                                {campaignDetails?.brand_name}
                                            </h4>
                                        </CardBody>
                                        <CardText className="bg-light p-1 text-break">
                                            <b>{campaignDetails?.campaign_title}</b>
                                        </CardText>
                                        <div className="text-break pl-1 mb-2" style={{whiteSpace: "break-spaces"}}>
                                            <div dangerouslySetInnerHTML={{__html: campaignDetails?.campaign_details}}></div>
                                        </div>
                                        {/*<CardText className="bg-light p-1 text-capitalize">*/}
                                        {/*    <b>{campaignDetails?.opportunity_for}</b>*/}
                                        {/*</CardText>*/}
                                        <hr/>
                                        <CardText className="pl-1 font-weight-bold text-primary">
                                            Complete the form below to apply for this opportunity.
                                        </CardText>
                                        <CardText className="bg-light p-1 text-break">
                                            <b>{campaignDetails?.form_title}</b>
                                        </CardText>

                                        {inValid && (
                                            <Alert color='danger' isOpen={true}>
                                                <div className='alert-body'>Fill out the complete form.</div>
                                            </Alert>)}

                                        <Form className="auth-reset-password-form mt-2">
                                            <Row>
                                                {campaignDetails?.form_attributes?.length && campaignDetails?.form_attributes?.map((dat, index) => (
                                                    <FormGroup tag={Col} md="12" key={index}>
                                                        <Label className="ml-1 font-weight-bold">
                                                            {index + 1}- {dat?.question}
                                                            <span className="is-invalid">*</span>
                                                        </Label>{" "}
                                                        <p></p>
                                                        {dat?.input_type === "input" && (
                                                            <Input
                                                                required
                                                                type="text"
                                                                value={question?.dat?.id}
                                                                name={dat?.id}
                                                                id={`owner_name${dat?.id}`}
                                                                onChange={(e) => onHandleChange(e)}
                                                                className="ml-1 mr-1"
                                                            />
                                                        )}
                                                        {dat?.input_type === "radio" && (
                                                            <Fragment>
                                                                {" "}
                                                                <CustomInput
                                                                    type="radio"
                                                                    id={`exampleCustomRadio_1_${index}`}
                                                                    name={dat?.id}
                                                                    inline
                                                                    label="Yes"
                                                                    value="yes"
                                                                    onChange={(e) => onHandleChange(e)}
                                                                    className="ml-1"
                                                                />
                                                                <CustomInput
                                                                    type="radio"
                                                                    id={`exampleCustomRadio_2_${index}`}
                                                                    name={dat?.id}
                                                                    inline
                                                                    label="No"
                                                                    value="no"
                                                                    onChange={(e) => onHandleChange(e)}
                                                                />
                                                            </Fragment>
                                                        )}
                                                    </FormGroup>
                                                ))}
                                                <FormGroup tag={Col} md="12" className="ml-1 mt-2">
                                                    <Button.Ripple
                                                        type="button"
                                                        color="primary"
                                                        className="d-flex"
                                                        onClick={saveAnswers}
                                                        disabled={isSubmitting || disableSubmitBtn}
                                                    >
                                                        {isSubmitting ? (<Spinner style={{height: '20px', width: '20px'}}/>) : 'Submit For This Campaign'}
                                                    </Button.Ripple>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                        <hr/>
                                        <CardText className="pl-1 font-weight-bold mt-2 text-center">
                                            Your name and social handles will also be shared with brand.
                                        </CardText>

                                        <div className="item-img text-center">
                                            <Avatar img={rdDetail?.profile_pic} imgHeight="90" imgWidth="90"
                                                    className="avatar-img-fit-contain"/>
                                        </div>
                                        <CardBody className="p-0 text-center">
                                            <h6>
                                                {rdDetail?.name}
                                            </h6>
                                        </CardBody>
                                        <div className="d-flex flex-wrap mt-2 align-items-center justify-content-center">
                                            {rdDetail?.website && (
                                                <a className="text-truncate mr-1" href={rdDetail?.website} target="_blank"><img src={Web} alt={Web} width="24" height="24"/></a>)}
                                            {rdDetail?.twitter_url && (<a className="text-truncate mr-1" href={rdDetail?.twitter_url} target="_blank"><Twitter/></a>)}
                                            {rdDetail?.facebook_url && (<a className="text-truncate mr-1" href={rdDetail?.facebook_url} target="_blank"><Facebook/></a>)}
                                            {rdDetail?.pinterest_url && (
                                                <a className="text-truncate mr-1" href={rdDetail?.pinterest_url} target="_blank"><img src={Pinterest} alt={Pinterest} width="24" height="24"/></a>)}
                                            {rdDetail?.linkedin_url && (<a className="text-truncate mr-1" href={rdDetail?.linkedin_url} target="_blank"><Linkedin/></a>)}
                                            {rdDetail?.instagram_url && (<a className="text-truncate mr-1" href={rdDetail?.instagram_url} target="_blank"><Instagram/></a>)}
                                            {rdDetail?.tiktok_url && (
                                                <a className="text-truncate mr-1" href={rdDetail?.tiktok_url} target="_blank"><img src={Tiktok} alt={Tiktok} width="24" height="26"/></a>)}
                                            {rdDetail?.facebook_url_sec && (<a className="text-truncate mr-1" href={rdDetail?.facebook_url_sec} target="_blank"><Facebook/></a>)}
                                            {rdDetail?.instagram_url_sec && (<a className="text-truncate mr-1" href={rdDetail?.instagram_url_sec} target="_blank"><Instagram/></a>)}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </Fragment>
    )
}

export default ApplyCampaign
