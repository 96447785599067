import {useEffect} from "react"
import {Link} from "react-router-dom"
import Avatar from "@components/avatar"
import {
    CheckCircle, List, XCircle
} from "react-feather"
import {
    Button,
    Row,
    Col,
    Spinner, UncontrolledTooltip
} from "reactstrap"
import img4 from "@src/assets/images/banner/banner-44.jpg"
import {getRdCampaigns} from "@src/views/rds/brandCampaigns/store/actions"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import queryString from "query-string"

const RecommendedBrandCampaigns = () => {
    const dispatch = useDispatch()
    const {
        rdRecommendedCampaignsLoading,
        rdRecommendedCampaignsFullFill,
        rdRecommendedCampaignsError
    } = useSelector((store) => store.rdCampaigns)

    useEffect(() => {
        dispatch(getRdCampaigns(""))
    }, [])

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(getRdCampaigns(`?page=${pageNo}`))
    }

    return (
        <>
            {rdRecommendedCampaignsLoading ? (
                <div className="d-flex justify-content-center" style={{height: '200px'}}>
                    <div className="align-self-center">
                        <Spinner color='primary'/>
                    </div>
                </div>
            ) : (
                rdRecommendedCampaignsFullFill?.data?.length > 0 && (
                    <>
                        <h3 className="mb-2">
                            Recommended Brand Campaigns
                        </h3>
                        <div className="brandSlides">
                            <Row className="match-height">
                                {rdRecommendedCampaignsFullFill?.data?.map((rdCamps, index) => (
                                    <Col sm="6" md="4" lg="3" key={index}>
                                        <div className="full-height brandContent">
                                            {/*<div className="row justify-content-between">*/}
                                            {/*    <div className="col-auto ml-auto">*/}
                                            {/*        <small*/}
                                            {/*            className="border border-1 px-75 py-25 border-muted text-muted">*/}
                                            {/*            {rdCamps?.opportunity_for}*/}
                                            {/*        </small>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <Avatar img={rdCamps?.brand_img || img4} imgHeight="80" imgWidth="80"/>
                                            <h4 className="mt-2 mb-2 text-break font-weight-bold" title={rdCamps?.campaign_title || "N/A"} style={{height: "50px"}}>
                                                {rdCamps?.campaign_title.length > 40 ? `${rdCamps?.campaign_title.substring(0, 40)}...` : rdCamps?.campaign_title}
                                            </h4>
                                            {rdCamps?.is_applied === false && rdCamps?.can_apply && (
                                                <Button.Ripple
                                                    tag={Link}
                                                    to={`/dashboard/rd/campaign-application/${rdCamps?.id}`}
                                                    color="secondary"
                                                    className="m-25 healthcare"
                                                >
                                                    <CheckCircle size={14}/> Learn More &amp; Apply
                                                </Button.Ripple>
                                            )}
                                            {rdCamps?.is_applied ? (
                                                <Button.Ripple
                                                    onClick={() => getAppliedCampaignData(rdCamps?.id)}
                                                    color="secondary"
                                                    className="m-25 healthcare"
                                                    id={`UncontrolledTooltip_${rdCamps?.id}`}
                                                >
                                                    <List size={14}/> Applied
                                                    <UncontrolledTooltip placement='top' target={`UncontrolledTooltip_${rdCamps?.id}`}>
                                                        Applied
                                                    </UncontrolledTooltip>
                                                </Button.Ripple>
                                            ) : rdCamps?.can_apply === false ? (
                                                <Button.Ripple color="danger"
                                                               className="m-25 healthcare"
                                                               disabled={true}
                                                >
                                                    <XCircle size={14}/> Limit exceeded.
                                                </Button.Ripple>
                                            ) : null}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="pt-2 justify-content-centered">
                                <Col md="12">
                                    <CustomPagination pageLinks={rdRecommendedCampaignsFullFill?.pages}
                                                      onPaginationClick={handleCustomPagination}/>
                                </Col>
                            </Row>
                        </div>
                    </>
                )
            )}
        </>
    )
}

export default RecommendedBrandCampaigns
