import axios from 'axios'
import { API_URL, COUPON, NULL } from '../actionsTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}
// ** Get table Data
export const getCouponsListing = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-coupons`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
    dispatch({
        type: COUPON.COUPONS_LISTING_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
          type: COUPON.ERROR,
          error
        })
      })
  }
}

export const addNewCoupons = (params) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("coupon_code", params.coupon_code)
    formData.append("coupon_type", params.coupon_type)
    formData.append("value", params.value)
    await axios.post(`${API_URL}admin/coupon-submit`, formData,  {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
    dispatch({
        type: COUPON.NEW_COUPON_ADDED,
        couponCreated: response.data
      })
    }).catch(error => {
      dispatch({
          type: COUPON.ERROR,
          error
        })
      })
  }
}

export const nullResponse = (property) => {
  return dispatch => {
    switch (property) {
      case NULL.COUPONS_LISTING_DATA:
        dispatch({ type: COUPON.COUPONS_LISTING_DATA, allData: null })
        break
      case NULL.NEW_COUPON_ADDED:
        dispatch({ type: COUPON.NEW_COUPON_ADDED, couponCreated: null })
        break
      case NULL.DELETE_COUPON_DATA:
        dispatch({ type: COUPON.DELETE_COUPON_DATA, deleteCoupon: null })
        break
      case NULL.ERROR:
        dispatch({ type: COUPON.ERROR, error: null })
        break
    }
  }
}

export const searchCouponsListing = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-coupons`
  return async dispatch => {
    await axios.post(endpoint,  {
      coupon_code: body.coupon_code
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
    dispatch({
        type: COUPON.COUPONS_LISTING_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
          type: COUPON.ERROR,
          error
        })
      })
  }
}

export const changeCouponStatus = (params) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("id", params.id)
    await axios.post(`${API_URL}admin/update-coupon-status`, formData,  {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
    dispatch({
        type: COUPON.COUPON_STATUS_CHANGE,
        statusData: response.data
      })
    }).catch(error => {
      dispatch({
          type: COUPON.ERROR,
          error
        })
      })
  }
}

export const deleteCouponData = (deleteId) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("id", deleteId)
    await axios.post(`${API_URL}admin/delete-coupon`, formData,  {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
    dispatch({
        type: COUPON.DELETE_COUPON_DATA,
        deleteCoupon: response.data
      })
    }).catch(error => {
      dispatch({
          type: COUPON.ERROR,
          error
        })
      })
  }
}

export const nullSearchData = (listName) => {
  return async dispatch => {
    switch (listName) {
      case 'searchCoupons':
        dispatch({
          type: COUPON.COUPONS_SEARCH_LISTING_DATA,
          allSearchData: null
        })
        break
      default:
        break
    }
  }
}