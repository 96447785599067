import { useState, useEffect } from 'react'
import { getMyBrandPartnerships } from '@store/actions/rdDashboard'
import Avatar from '@components/avatar'
import CustomPagination from "@src/views/components/pagination/CustomPagination"
import {
  partnershipChangeStatus,
  partnershipSendInvoice,
  partnershipChangeStatusReset,
  partnershipSendInvoiceReset
} from "@src/views/rds/brands/store/actions/index"
import {
  ChevronDown,
  MoreVertical,
  Eye,
  Download,
  Upload,
  RefreshCcw,
  Send, CheckCircle, CheckSquare, Delete, GitPullRequest
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  CustomInput,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  FormGroup,
  ModalFooter,
  Spinner, Badge, CardHeader, CardBody, Table, Form
} from 'reactstrap'
import { uploadRdAgrement, sendRdAgrement, uploadRdAgrementReset, sendRdAgrementReset } from '@src/views/apps/invoice/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import '@styles/react/apps/app-invoice.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import {Link} from "react-router-dom"
import queryString from "query-string"
import blankImg from '../../../assets/images/avatars/avatar-blank.png'
import {Formik} from "formik"
import * as yup from "yup"

const MyPartnerships = () => {

  const AGREEMENT_MAX_SIZE = 1 * 1024 * 1024
  const AGREEMENT_FORMATS = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document means .docx\n",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ]

  const [row, setRow] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const [acceptModal, setAcceptModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [successRes, setSuccessRes] = useState(null)
  const [errorRes, setErrorRes] = useState(null)
  const [invoiceModal, setInvoiceModal] = useState(false)
  const [uploadAgreementModal, setUploadAgreementModal] = useState(false)
  const [sendAgreementModal, setSendAgreementModal] = useState(false)


  const dispatch = useDispatch()
  const {
    myPartnershipLoading,
    myPartnershipFullFill,
    myPartnershipError
  } = useSelector(state => state.rd)

  const {
    changePartnershipStatusLoading,
    changePartnershipStatusFullFill,
    changePartnershipStatusError,

    sendInvoiceLoading,
    sendInvoiceFullFill,
    sendInvoiceError
  } = useSelector((store) => store.rdBrands)

  const {
    uploadAgreementLoading,
    uploadAgreementFullFill,
    uploadAgreementError,

    sendAgreementLoading,
    sendAgreementFullFill,
    sendAgreementError
  } = useSelector((store) => store.invoice)

  useEffect(() => {
    if (changePartnershipStatusFullFill?.success) {
      console.log(changePartnershipStatusFullFill)
      setSuccessRes({
        show: true,
        msg: changePartnershipStatusFullFill?.msg
      })
      dispatch(partnershipChangeStatusReset())
      dispatch(getMyBrandPartnerships(""))
      setRow(null)
      setAcceptModal(false)
      setRejectModal(false)
      setTimeout(() => setSuccessRes(null), 5000)
    }

    if (sendInvoiceFullFill?.success) {
      setSuccessRes({
        show: true,
        msg: sendInvoiceFullFill?.msg
      })
      dispatch(partnershipSendInvoiceReset())
      dispatch(getMyBrandPartnerships(""))
      setRow(null)
      setInvoiceModal(false)
      setTimeout(() => setSuccessRes(null), 5000)
    }

    if (uploadAgreementFullFill?.status) {
      setSuccessRes({
        show: true,
        msg: uploadAgreementFullFill?.msg
      })
      dispatch(uploadRdAgrementReset())
      dispatch(getMyBrandPartnerships(""))
      setRow(null)
      setUploadAgreementModal(false)
      setTimeout(() => setSuccessRes(null), 5000)
    }

    if (sendAgreementFullFill?.success) {
      setSuccessRes({
        show: true,
        msg: sendAgreementFullFill?.msg
      })
      dispatch(sendRdAgrementReset())
      dispatch(getMyBrandPartnerships(""))
      setRow(null)
      setSendAgreementModal(false)
      setTimeout(() => setSuccessRes(null), 5000)
    }

    if (sendInvoiceError?.response?.status === 400) {
      setErrorRes({
        show: true,
        msg: sendInvoiceError?.response?.data?.msg,
        key: 'bank'
      })
      dispatch(partnershipSendInvoiceReset())
      setRow(null)
      setInvoiceModal(false)
      setTimeout(() => setErrorRes(null), 20000)
    }

  }, [changePartnershipStatusFullFill, sendInvoiceFullFill, uploadAgreementFullFill, sendAgreementFullFill, sendInvoiceError])

  useEffect(() => {
    dispatch(getMyBrandPartnerships(""))
  }, [])

  const changePartnershipStatus = (status) => {
    const params = {
      request_id: row?.id,
      event_id: row?.event_id,
      status
    }
    dispatch(partnershipChangeStatus(params))
  }

  const sendInvoice = (row) => {
    setRow(row)
    setInvoiceModal(true)
  }

  const sendInvoiceAmount = (values) => {
    dispatch(partnershipSendInvoice({
      request_id: row?.id,
      invoice_Amount: values.invoice_amount
    }))
  }

  const handleSearch = () => {
    dispatch(getMyBrandPartnerships(`?search=${searchValue}`))
  }

  const handleCustomPagination = (pageUrl) => {
    const parsed = queryString.parseUrl(pageUrl)
    const pageNo = parsed?.query?.page
    dispatch(getMyBrandPartnerships(`?page=${pageNo}&search=${searchValue}`))
  }

  const handleSendAgreement = (row) => {
    setRow(row)
    setSendAgreementModal(true)
  }

  const sendAgreementRequest = () => {
    dispatch(sendRdAgrement({
      partnership_id: row?.id,
      brand_user_id: row?.brand_user_id
    }))
  }

  const uploadAgreementData = (values) => {
    const formData = new FormData()
    formData.append("request_id", row?.id)
    formData.append("event_id", row?.event_id)
    formData.append("file", values.agreement)
    dispatch(uploadRdAgrement(formData))
  }

  const onEnterSearch = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleReload = () => {
    setSearchValue("")
    dispatch(getMyBrandPartnerships(""))
  }

  const handleAcceptBtn = (row) => {
    setRow(row)
    setAcceptModal(true)
  }

  const handleRejectBtn = (row) => {
    setRow(row)
    setRejectModal(true)
  }

  const handleAgreementModal = (row) => {
    setRow(row)
    setUploadAgreementModal(true)
  }

  const myPartnershipsCols = [
    {
      name: 'Brand Company',
      cell: row => {
        return (
            <div className='d-flex justify-content-left align-items-center'>
              <Link to={`/dashboard/rd/brands/${row?.brand_id}`}>
                <Avatar className='mr-50 avatar-img-fit-contain' img={row?.brand_logo || blankImg} width='32'
                        height='32'/>
              </Link>
              <div className='d-flex flex-column'>
                <h6 className='user-name white-space-pre-wrap mb-0'>{row?.brand_name}</h6>
              </div>
            </div>
        )
      }
    },
    {
      name: 'Event Title',
      cell: row => (
          <div className="text-truncate">
            {row.event_title}
          </div>
      )
    },
    {
      name: 'Payment',
      cell: row => <span>${row.cost_of_partnership || 0}</span>
    },
    {
      name: 'Agreement',
      cell: row => (
          <div className='column-action d-flex align-items-center'>
            <UncontrolledDropdown>
              <DropdownToggle tag='span'>
                <MoreVertical size={17} className='cursor-pointer'/>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag='a'
                              href={row?.rd_agreement}
                              className='w-100'
                              target="_blank"
                              disabled={!row?.rd_agreement}
                >
                  <Eye size={17} className='mr-50'/>
                  <span className='align-middle'>View</span>
                </DropdownItem>
                <DropdownItem onClick={() => handleAgreementModal(row)} className='w-100'>
                  <Upload size={14} className='mr-50'/>
                  <span className='align-middle'>Upload</span>
                </DropdownItem>
                <DropdownItem className='w-100' onClick={() => handleSendAgreement(row)}>
                  <Send size={14} className='mr-50'/>
                  <span className='align-middle'>Send in Message</span>
                </DropdownItem>
                <DropdownItem tag='a'
                              href={row?.brand_agreement}
                              className='w-100' target="_blank"
                              disabled={!row?.brand_agreement}
                >
                  <Download size={14} className='mr-50'/>
                  <span className='align-middle'>Download Brand Agreement</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
      )
    },
    {
      name: "Partnership Status",
      cell: (row) => (
          <div className="column-action d-flex align-items-center">
            {row?.status === 'Requested' && (
                <Badge color='warning'>
                  <GitPullRequest style={{ stroke: '#FFF' }}/>
                  <span> {row?.status}</span>
                </Badge>
            )}

            {row?.status === 'Accepted' && (
                <Badge color='info'>
                  <CheckSquare style={{ stroke: '#FFF' }}/>
                  <span> {row?.status}</span>
                </Badge>
            )}

            {row?.status === 'Rejected' && (
                <Badge color='danger'>
                  <Delete style={{ stroke: '#FFF' }}/>
                  <span> {row?.status}</span>
                </Badge>
            )}

            {row?.status === 'Paid' && (
                <Badge color='success'>
                  <CheckCircle style={{ stroke: '#FFF' }}/>
                  <span> Invoice Paid</span>
                </Badge>
            )}

            {row?.status === 'Invoice Sent' && (
                <Badge color='primary'>
                  <Send style={{ stroke: '#FFF' }}/>
                  <span> {row?.status}</span>
                </Badge>
            )}
          </div>
      )
    },
    {
      name: "Action",
      justifyContent: "center",
      width: '250px',
      cell: (row) => (
          <div className="column-action d-flex align-items-center">
            {row.is_invoice && (
                <Button className="btn-sm" color="primary" onClick={() => sendInvoice(row)}>
                  <Send style={{ stroke: '#FFF' }} size={12}/> Send Invoice
                </Button>
            )}
            {row?.status === "Requested" && row?.is_invoice === false && (
                <>
                  <Button className="btn-sm mr-1" color="success" onClick={() => handleAcceptBtn(row)}>
                    <CheckSquare style={{ stroke: '#FFF' }} size="12"/> Accept
                  </Button>
                  <Button className="btn-sm" color="danger" onClick={() => handleRejectBtn(row)}>
                    <Delete style={{ stroke: '#FFF' }} size="12" /> Reject
                  </Button>
                </>
            )}
            {(row?.status === "Rejected" || row?.status === "Invoice Sent" || row?.status === "Paid") && row?.is_invoice === false && (
                <span className="cursor-not-allowed">No action needed</span>
            )}
          </div>
      )
    }
  ]

  return (
    <Card>
      <CardHeader className="d-flex">
        <CardTitle>My Partnerships</CardTitle>
        <div style={{ width: '400px'}} className="d-flex">
          <Input
              id='search-invoice'
              className="rounded-0"
              type='text'
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder='Type and press enter to search...'
              onKeyDown={onEnterSearch}
              disabled={myPartnershipLoading}
          />
          <Button color='primary' className="rounded-0" onClick={handleReload}>
            <RefreshCcw size={12} />
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        {successRes?.show && (
            <Alert color='success'>
              <div className='alert-body'>
                <span>{successRes?.msg}</span>
              </div>
            </Alert>
        )}

        {errorRes?.show && (
            <Alert color='danger'>
              <div className='alert-body'>
                <span>{errorRes?.msg}</span>
              </div>
            </Alert>
        )}

        {myPartnershipLoading ? (
            <div className="w-100 my-4 text-center">
              <Spinner color="primary"/>
            </div>
        ) : (
            <>
              <DataTable
                  noHeader
                  paginationServer
                  subHeader={false}
                  columns={myPartnershipsCols}
                  responsive={true}
                  sortIcon={<ChevronDown/>}
                  className='react-dataTable'
                  defaultSortField='invoiceId'
                  data={myPartnershipFullFill?.data}
              />
              <CustomPagination
                  pageLinks={myPartnershipFullFill?.pages}
                  onPaginationClick={handleCustomPagination}
              />
            </>
        )}
      </CardBody>

      <Modal isOpen={uploadAgreementModal} className='modal-dialog-centered'>
        <ModalHeader>Upload Agreement</ModalHeader>
        <Formik
            enableReinitialize={true}
            initialValues={{
              agreement: ""
            }}
            validationSchema={yup.object().shape({
              agreement: yup.mixed()
                  .test(
                      "fileFormat",
                      "File is empty or invalid.",
                      value => (value && AGREEMENT_FORMATS.includes(value.type))
                  )
                  .test(
                      "fileSize",
                      "File too large",
                      value => (value && value.size <= AGREEMENT_MAX_SIZE)
                  )
            })}
            onSubmit={(values) => {
              uploadAgreementData(values)
            }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue
            }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormGroup className="my-1">
                    <Label className="d-flex justify-content-between">
                      <span>Upload Agreement</span>
                      <span className="text-danger font-small-1">
                          <strong>Allowed Only (.pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls)</strong>
                        </span>
                    </Label>
                    <CustomInput
                        accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls"
                        type="file"
                        className="form-control-file"
                        name="agreement"
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue('agreement', e.target.files[0])}
                        disabled={uploadAgreementLoading}
                    />
                    <div className="validation-err">{touched.agreement && errors.agreement}</div>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color='primary' type="submit" disabled={uploadAgreementLoading}>
                    {uploadAgreementLoading ? (<Spinner style={{ width: '12px', height: '12px'}}/>) : 'Upload'}
                  </Button>
                  <Button color='danger' onClick={() => {
                    setRow(null)
                    setUploadAgreementModal(false)
                  }} disabled={uploadAgreementLoading}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Form>
          )}
        </Formik>
      </Modal>

      <Modal isOpen={sendAgreementModal} className='modal-dialog-centered'>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to send this agreement?</p>
        </ModalBody>
        <ModalFooter>
          <Button.Ripple type="button" color="primary" onClick={sendAgreementRequest} disabled={sendAgreementLoading}>
            {sendAgreementLoading ? (<Spinner style={{ width: '12px', height: '12px'}}/>) : 'Yes'}
          </Button.Ripple>
          <Button.Ripple type="button" color="danger" onClick={() => {
            setSendAgreementModal(false)
            setRow(null)
          }} disabled={sendAgreementLoading}>
            No
          </Button.Ripple>
        </ModalFooter>
      </Modal>

      <Modal isOpen={invoiceModal} className="modal-dialog-centered">
        <ModalHeader>
          Send Invoice
        </ModalHeader>
        <Formik
            enableReinitialize={true}
            initialValues={{
              invoice_amount: ""
            }}
            validationSchema={yup.object().shape({
              invoice_amount: yup.number()
                  .required("This field is required.")
                  .min('1', 'Must be greater than or equal to 1')
                  .typeError('Only Numbers.')
                  .max(row?.cost_of_partnership, `Must be less than or equal to ${row?.cost_of_partnership}`)
            })}
            onSubmit={(values) => {
              sendInvoiceAmount(values)
            }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <Table responsive>
                    <thead>
                    <tr>
                      <th width="300">Current Partnership Cost</th>
                      <th>{row?.cost_of_partnership ? `$${row?.cost_of_partnership}` : ""}</th>
                    </tr>
                    </thead>
                  </Table>
                  <p className="p-1 bg-light">
                    <strong>{`Note! You can send any amount between $1 and $${row?.cost_of_partnership}.`}</strong>
                  </p>
                  <FormGroup tag={Col}>
                    <Label htmlFor="invoice-amount">Invoice Amount</Label>
                    <Input
                        id="invoice-amount"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.invoice_amount}
                        name="invoice_amount"
                        placeholder="0"
                        disabled={sendInvoiceLoading}
                    />
                    <div className="validation-err">
                      {touched.invoice_amount && errors.invoice_amount}
                    </div>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button.Ripple type="submit" color="primary" disabled={sendInvoiceLoading}>
                    {sendInvoiceLoading ? (<Spinner style={{ width: '12px', height: '12px'}}/>) : 'Send'}
                  </Button.Ripple>
                  <Button.Ripple type="button" color="danger" onClick={() => {
                    setInvoiceModal(false)
                    setRow(null)
                  }} disabled={sendInvoiceLoading}>
                    Close
                  </Button.Ripple>
                </ModalFooter>
              </Form>
          )}
        </Formik>
      </Modal>

      <Modal size="sm" isOpen={acceptModal} className="modal-dialog-centered">
        <ModalHeader>
          Confirmation
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to accept this partnership?</p>
        </ModalBody>
        <ModalFooter>
          <Button.Ripple type="button" color="primary" onClick={() => changePartnershipStatus('accept')} disabled={changePartnershipStatusLoading}>
            {changePartnershipStatusLoading ? (<Spinner style={{ width: '12px', height: '12px'}}/>) : 'Yes'}
          </Button.Ripple>
          <Button.Ripple type="button" color="danger" onClick={() => {
            setAcceptModal(false)
            setRow(null)
          }} disabled={changePartnershipStatusLoading}>
            No
          </Button.Ripple>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={rejectModal} className="modal-dialog-centered">
        <ModalHeader>
          Confirmation
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to reject this partnership?</p>
        </ModalBody>
        <ModalFooter>
          <Button.Ripple type="button" color="primary" onClick={() => changePartnershipStatus('reject')} disabled={changePartnershipStatusLoading}>
            {changePartnershipStatusLoading ? (<Spinner style={{ width: '12px', height: '12px'}}/>) : 'Yes'}
          </Button.Ripple>
          <Button.Ripple type="button" color="danger" onClick={() => {
            setRejectModal(false)
            setRow(null)
          }} disabled={changePartnershipStatusLoading}>
            No
          </Button.Ripple>
        </ModalFooter>
      </Modal>
    </Card>
  )
}

export default MyPartnerships
