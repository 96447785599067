import {React, Fragment, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"
import Avatar from '@components/avatar'
import {
    Eye,
    MoreVertical,
    Download,
    Upload, GitPullRequest, CheckSquare, Delete, CheckCircle, Send, DollarSign
} from "react-feather"
import {
    Spinner,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge,
    Button,
    Card, CardBody, ModalHeader, Form, ModalBody, FormGroup, Label, CustomInput, ModalFooter, Modal, Alert
} from "reactstrap"
import "@styles/base/pages/app-ecommerce.scss"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {
    brandPastPartnerships,
    uploadAgrementReset,
    uploadAgrement
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import DataTable from 'react-data-table-component'
import {Formik} from "formik"
import * as yup from "yup"
import queryString from "query-string"

const PastPartnerships = () => {

    const AGREEMENT_MAX_SIZE = 1 * 1024 * 1024
    const AGREEMENT_FORMATS = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document means .docx\n",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ]

    const [row, setRow] = useState(null)
    const [successRes, setSuccessRes] = useState(null)
    const [errorRes, setErrorRes] = useState(null)
    const [uploadAgreementModal, setUploadAgreementModal] = useState(false)

    const dispatch = useDispatch()

    const {
        pastPartnershipsLoading,
        pastPartnershipsFullFill,
        pastPartnershipsError,

        uploadBrandAgreementLoading,
        uploadBrandAgreementFullFill,
        uploadBrandAgreementError
    } = useSelector((store) => store.brandsOppoprunities)

    useEffect(() => {
        if (uploadBrandAgreementFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: uploadBrandAgreementFullFill?.msg
            })
            dispatch(uploadAgrementReset())
            dispatch(brandPastPartnerships(""))
            setRow(null)
            setUploadAgreementModal(false)
            setTimeout(() => setSuccessRes(null), 5000)
        }

    }, [uploadBrandAgreementFullFill])

    useEffect(() => {
        dispatch(brandPastPartnerships(""))
    }, [])


    const handleCustomPagination = pageUrl => {
      const parsed = queryString.parseUrl(pageUrl)
      const pageNo = parsed?.query?.page
      dispatch(brandPastPartnerships(`?page=${pageNo}`))
    }


    const handleAgreementModal = (row) => {
        setRow(row)
        setUploadAgreementModal(true)
        setSuccessRes(null)
        setErrorRes(null)
    }

    const advSearchColumns = [
        {
            name: 'Rd name',
            maxWidth: '200px',
            cell: row => {
                return (
                    <div className='d-flex justify-content-left align-items-center'>
                        <Link to={`/dashboard/brands/nutritions/${row.rd_id}`}>
                            <Avatar className='mr-50' img={row.profile_pic} width='32' height='32'/>
                        </Link>
                        <div className='d-flex flex-column'>
                            <h6 className='user-name text-truncate mb-0'>{row.rd_name}</h6>
                        </div>
                    </div>
                )
            }
        },
        {
            name: 'Event Title',
            selector: 'partnership_details'
        },
        {
            name: 'Event Date',
            maxWidth: '220px',
            selector: 'event_date'
        },
        {
            name: 'Reach',
            maxWidth: '100px',
            cell: row => row.reach
        },
        {
            name: 'Investment',
            maxWidth: '150px',
            cell: row => <span>${row.investment || 0}</span>
        },
        {
            name: 'Cost/Impression',
            maxWidth: '150px',
            cell: row => <span>${row.cost_impression || 0}</span>
        },
        {
            name: 'Agreement',
            maxWidth: '130px',
            cell: row => (
                <div className='column-action d-flex align-items-center'>
                    <UncontrolledDropdown>
                        <DropdownToggle tag='span'>
                            <MoreVertical size={17} className='cursor-pointer'/>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem tag='a'
                                          href={row?.brand_agreement}
                                          className='w-100' target="_blank"
                                          disabled={!row?.brand_agreement}
                            >
                                <Eye size={17} className='mr-50'/>
                                <span className='align-middle'>View</span>
                            </DropdownItem>
                            <DropdownItem className='w-100' onClick={() => handleAgreementModal(row)}>
                                <Upload size={17} className='mr-50'/>
                                <span className='align-middle'>Upload</span>
                            </DropdownItem>
                            <DropdownItem tag='a' href={row?.rd_agreement} className='w-100' target="_blank"
                                          disabled={!row?.rd_agreement}>
                                <Download size={14} className='mr-50'/>
                                <span className='align-middle'>Download RD Agreement</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        },
        {
            name: 'Status',
            maxWidth: '150px',
            cell: row => {
                return (
                    <div className="column-action d-flex align-items-center">
                        {row?.status === 'Requested' && (
                            <Badge color='warning'>
                                <GitPullRequest style={{stroke: '#FFF'}}/>
                                <span> {row?.status}</span>
                            </Badge>
                        )}

                        {row?.status === 'Accepted' && (
                            <Badge color='info'>
                                <CheckSquare style={{stroke: '#FFF'}}/>
                                <span> {row?.status}</span>
                            </Badge>
                        )}

                        {row?.status === 'Rejected' && (
                            <Badge color='danger'>
                                <Delete style={{stroke: '#FFF'}}/>
                                <span> {row?.status}</span>
                            </Badge>
                        )}

                        {row?.status === 'Paid' && (
                            <Badge color='success'>
                                <CheckCircle style={{stroke: '#FFF'}}/>
                                <span> Invoice Paid</span>
                            </Badge>
                        )}

                        {row?.status === 'Invoice Sent' && (
                            <Badge color='primary'>
                                <Send style={{stroke: '#FFF'}}/>
                                <span> {row?.status}</span>
                            </Badge>
                        )}
                    </div>
                )
            }
        },
        {
            name: 'Invoice',
            maxWidth: '250px',
            cell: row => (
                <div className='column-action d-flex align-items-center'>
                    {row.can_pay_invoice === true ? (
                        <Button className="btn-sm" color="primary" disabled={true}>
                            <DollarSign style={{stroke: '#FFF'}} size={12}/> Pay Invoice
                        </Button>
                    ) : row?.can_pay_invoice === false && row?.status === 'Paid' ? (
                        <Button className="btn-sm" color="success" href={row.invoice_url} target="_blank">
                            <Eye style={{stroke: '#FFF'}} size={12}/> View Invoice
                        </Button>
                    ) : (
                        <Button className="btn-sm" color="default">
                            No Invoice
                        </Button>
                    )}
                </div>
            )
        }
    ]

    const uploadAgreementData = (values) => {
        const formData = new FormData()
        formData.append("request_id", row?.id)
        formData.append("file", values.agreement)
        dispatch(uploadAgrement(formData))
        setSuccessRes(null)
        setErrorRes(null)
    }

    return (
        <Card>
            <CardBody>

                {successRes?.show && (
                    <Alert color='success'>
                        <div className='alert-body'>
                            <span>{successRes?.msg}</span>
                        </div>
                    </Alert>
                )}

                {errorRes?.show && (
                    <Alert color='danger'>
                        <div className='alert-body'>
                            <span>{errorRes?.msg}</span>
                        </div>
                    </Alert>
                )}

                {pastPartnershipsLoading ? (
                    <div className="w-100 my-4 text-center">
                        <Spinner color='primary'/>
                    </div>
                ) : (
                    <>
                        <DataTable
                            noHeader={true}
                            responsive={true}
                            columns={advSearchColumns}
                            className='react-dataTable'
                            data={pastPartnershipsFullFill?.data}
                        />
                        <CustomPagination pageLinks={pastPartnershipsFullFill?.pages}
                                          onPaginationClick={handleCustomPagination}/>
                    </>
                )}

                <Modal isOpen={uploadAgreementModal} className='modal-dialog-centered'>
                    <ModalHeader>Upload Agreement</ModalHeader>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            agreement: ""
                        }}
                        validationSchema={yup.object().shape({
                            agreement: yup.mixed()
                                .test(
                                    "fileFormat",
                                    "File is empty or invalid.",
                                    value => (value && AGREEMENT_FORMATS.includes(value.type))
                                )
                                .test(
                                    "fileSize",
                                    "File too large",
                                    value => (value && value.size <= AGREEMENT_MAX_SIZE)
                                )
                        })}
                        onSubmit={(values) => {
                            uploadAgreementData(values)
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <ModalBody>
                                    <FormGroup className="my-1">
                                        <Label className="d-flex justify-content-between">
                                            <span>Upload Agreement</span>
                                            <span className="text-danger font-small-1">
                          <strong>Allowed Only (.pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls)</strong>
                        </span>
                                        </Label>
                                        <CustomInput
                                            accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls"
                                            type="file"
                                            className="form-control-file"
                                            name="agreement"
                                            onBlur={handleBlur}
                                            onChange={(e) => setFieldValue('agreement', e.target.files[0])}
                                            disabled={uploadBrandAgreementLoading}
                                        />
                                        <div className="validation-err">{touched.agreement && errors.agreement}</div>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type="submit" disabled={uploadBrandAgreementLoading}>
                                        {uploadBrandAgreementLoading ? (
                                            <Spinner style={{width: '12px', height: '12px'}}/>) : 'Upload'}
                                    </Button>
                                    <Button color='danger' onClick={() => {
                                        setRow(null)
                                        setUploadAgreementModal(false)
                                    }} disabled={uploadBrandAgreementLoading}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>

            </CardBody>
        </Card>
    )
}
export default PastPartnerships
