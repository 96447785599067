import Axios from "axios"
import {EMAIL_VERIFICATION} from '../actionTypes/index'

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const verifyEmailAction = (dataObj) => async (dispatch) => {
    dispatch({type: EMAIL_VERIFICATION.REQUEST})

    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }

    try {
        const {data} = await Axios.post(`${process.env.REACT_APP_BASEURL}email-verification`, dataObj, {headers: header})
        dispatch({type: EMAIL_VERIFICATION.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: EMAIL_VERIFICATION.ERROR, payload: error.message})
    }
}

export const verifyEmailResetAction = () => async (dispatch) => {
    dispatch({type: EMAIL_VERIFICATION.RESET})
}