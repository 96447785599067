import Avatar from '@components/avatar'
import Select from 'react-select'
import {selectThemeColors} from '@utils'
import {getBrandDashboardStatsValues} from '@store/actions/brandsDashboard'
import {Box, DollarSign, TrendingUp, User} from 'react-feather'
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Col,
    Media,
    Row,
    UncontrolledTooltip
} from 'reactstrap'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

const FilterByOptions = [
    {value: 'all', label: 'All'},
    {value: 'month', label: 'Month'},
    {value: 'quarter', label: 'Quarter'},
    {value: 'year', label: 'Year'}
]

const DashboardStats = () => {
    const dispatch = useDispatch()
    const {
        brandStatsLoading,
        brandStatsFullFill,
        brandStatsError
    } = useSelector(state => state.brandsDashboardSlice)


    useEffect(() => {
        dispatch(getBrandDashboardStatsValues('all'))
    }, [])

    const filterStats = (filterValue) => {
        dispatch(getBrandDashboardStatsValues(filterValue))
    }
    return (
        <Card>
            <CardHeader>
                <CardTitle>Statistics</CardTitle>
                <Select
                    theme={selectThemeColors}
                    className='react-select w-250px'
                    classNamePrefix='select'
                    defaultValue={FilterByOptions[0]}
                    options={FilterByOptions}
                    isClearable={false}
                    isSearchable={false}
                    onChange={(e) => filterStats(e.value)}
                />
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="2" sm="2">
                        <Media>
                            <Avatar color="light-primary"
                                    icon={<TrendingUp size={21}/>}
                                    className='mr-1'
                                    id="my-rds"
                            />
                            <UncontrolledTooltip placement='top' target="my-rds">
                                My RDs
                            </UncontrolledTooltip>
                            <Media className='media-avatar m-auto' body>
                                <h4 className='font-weight-bolder mb-0'>
                                    {brandStatsLoading ? ("...") : brandStatsFullFill?.data?.my_rds}
                                </h4>
                                <h6 className='total-partnership'>My RDs</h6>
                            </Media>
                        </Media>
                    </Col>

                    <Col md="2" sm="2">
                        <Media>
                            <Avatar color="light-info"
                                    icon={<User size={21}/>}
                                    className='mr-1'
                                    id="consumer-impact"
                            />
                            <UncontrolledTooltip placement='top' target="consumer-impact">
                                Estimated Consumer Reach
                            </UncontrolledTooltip>
                            <Media className='media-avatar m-auto' body>
                                <h4 className='font-weight-bolder mb-0'>
                                    {brandStatsLoading ? ("...") : brandStatsFullFill?.data?.consumer_impact}
                                </h4>
                                <h6 className='total-partnership'>Consumer Impact</h6>
                            </Media>
                        </Media>
                    </Col>

                    <Col md="2" sm="2">
                        <Media>
                            <Avatar color="light-info"
                                    icon={<User size={21}/>}
                                    className='mr-1'
                                    id="my-partnerships"
                            />
                            <UncontrolledTooltip placement='top' target="my-partnerships">
                                Partnerships
                            </UncontrolledTooltip>
                            <Media className='media-avatar m-auto' body>
                                <h4 className='font-weight-bolder mb-0'>
                                    {brandStatsLoading ? ("...") : brandStatsFullFill?.data?.my_partnerships}
                                </h4>
                                <h6 className='total-partnership'>My Partnerships</h6>
                            </Media>
                        </Media>
                    </Col>

                    <Col md="2" sm="2">
                        <Media>
                            <Avatar color="light-danger"
                                    icon={<Box size={21}/>}
                                    className='mr-1'
                                    id="samples-shared"
                            />
                            <UncontrolledTooltip placement='top' target="samples-shared">
                                Samples Shared
                            </UncontrolledTooltip>
                            <Media className='media-avatar m-auto' body>
                                <h4 className='font-weight-bolder mb-0'>
                                    {brandStatsLoading ? ("...") : brandStatsFullFill?.data?.samples_shared}
                                </h4>
                                <h6 className='total-partnership'>Samples Shared</h6>
                            </Media>
                        </Media>
                    </Col>

                    <Col md="2" sm="2">
                        <Media>
                            <Avatar color="light-danger"
                                    icon={<Box size={21}/>}
                                    className='mr-1'
                                    id="samples-shared"
                            />
                            <UncontrolledTooltip placement='top' target="samples-shared">
                                All RDs
                            </UncontrolledTooltip>
                            <Media className='media-avatar m-auto' body>
                                <h4 className='font-weight-bolder mb-0'>
                                    {brandStatsLoading ? ("...") : brandStatsFullFill?.data?.all_rds}
                                </h4>
                                <h6 className='total-partnership'>All RDs</h6>
                            </Media>
                        </Media>
                    </Col>

                    <Col md="2" sm="2">
                        <Media>
                            <Avatar color="light-success"
                                    icon={<DollarSign size={21}/>}
                                    className='mr-1'
                                    id="total-investment"
                            />
                            <UncontrolledTooltip placement='top' target="total-investment">
                                Total Investment
                            </UncontrolledTooltip>
                            <Media className='media-avatar m-auto' body>
                                <h4 className='font-weight-bolder mb-0'>
                                    {brandStatsLoading ? ("...") : brandStatsFullFill?.data?.total_investment}
                                </h4>
                                <h6 className='total-partnership'>Total Investment</h6>
                            </Media>
                        </Media>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default DashboardStats