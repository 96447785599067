import { useState, Fragment, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Flatpickr from 'react-flatpickr'
import {
  getCountries,
  getStatesById,
  getRDsEventList,
  searchRDsEventList,
  nullSearchData
} from '../../store/actions/index'
import {
  ChevronDown,
  RefreshCcw,
  Search,
  MoreHorizontal,
  Eye
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  CustomInput, Spinner
} from 'reactstrap'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/libs/flatpickr/flatpickr.scss'

const DataTableAdvSearch = () => {
  const [searchRDName, setSearchRDName] = useState('')
  const [searchEventName, setSearchEventName] = useState('')
  const [searchEventType, setSearchEventType] = useState('')
  const [searchStartDatePicker, setSearchStartDatePicker] = useState('')
  const [searchEndDatePicker, setSearchEndDatePicker] = useState('')
  const [searchCity, setSearchCity] = useState('')
  const [searchState, setSearchState] = useState('')
  const [searchCountry, setSearchCountry] = useState('')

  const [isTableLoading, setIsTableLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [paginationInfo, setPaginationInfo] = useState(null)

  const manageRdsStoreSlice = useSelector(store => store.dataTablesAdmin)
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (!!manageRdsStoreSlice?.rdsEventList) {
      const { links, meta, pages } = manageRdsStoreSlice.rdsEventList
      setPaginationInfo({ ...links, ...meta, pages })
      setIsTableLoading(false)
    }
    if (!!manageRdsStoreSlice?.searchedRDsEventList) {
      const { links, meta, pages } = manageRdsStoreSlice.searchedRDsEventList
      setPaginationInfo({ ...links, ...meta, pages })
      setIsTableLoading(false)
    }
  }, [manageRdsStoreSlice])

  useEffect(() => {
    setIsTableLoading(true)
    dispatch(getRDsEventList(id, false))
    dispatch(getCountries())
  }, [])

  const [advSearchColumns, setAdvSearchColumns] = useState([
    {
      name: 'Event Title',
      selector: 'event_title',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Location',
      selector: 'location',
      sortable: true,
      minWidth: '120px'
    },
    {
      name: 'Start Date',
      selector: 'start_date',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'End Date',
      selector: 'end_date',
      sortable: true,
      minWidth: '140px'
    },
    {
      name: 'Event Type',
      selector: 'type_of_event',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Organizer',
      selector: 'organizer',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'City',
      selector: 'city',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'State',
      selector: 'state',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Country',
      selector: 'country',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      minWidth: '40px',
      cell: row => {
        return (
          <CustomInput
            className='custom-control-success'
            type='switch'
            id={`success_${row.id}`}
            name='success'
            inline
            defaultChecked={row.status === 'enabled'}
            onClick={() => updateRdStatus(row.id)} />
        )
      }
    },
    {
      name: 'Actions',
      allowOverflow: true,
      cell: row => {
        return (
          <div className='d-flex pl-2'>
            <UncontrolledDropdown>
              <DropdownToggle className='pr-1' tag='span'>
                <MoreHorizontal size={15} />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={Link} to={`/admin/manage-events/${row.id}`}>
                  <Eye size={15} />
                  <span className='align-middle ml-50'>View</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      }
    }
  ])

  const searchData = () => {
    setIsTableLoading(true)
    const fromDate = new Date(searchStartDatePicker)
    const finalFromDate = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
    const toDate = new Date(searchEndDatePicker)
    const finalToDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`

    const paramsData = {
      rd_name: searchRDName,
      event_name: searchEventName,
      type: searchEventType,
      from_date: finalFromDate === "NaN-NaN-NaN" ? "" : finalFromDate,
      to_date: finalToDate === "NaN-NaN-NaN" ? "" : finalToDate,
      city: searchCity,
      state_id: searchState,
      country_id: searchCountry
    }
    dispatch(searchRDsEventList(id, paramsData, false))
  }

  const handleCustomPagination = pageUrl => {
    setIsTableLoading(true)
    // dispatch(getRDsEventList(id, true, pageUrl))
    const fromDate = new Date(searchStartDatePicker)
    const finalFromDate = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
    const toDate = new Date(searchEndDatePicker)
    const finalToDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`

    const paramsData = {
      rd_name: searchRDName,
      event_name: searchEventName,
      type: searchEventType,
      from_date: finalFromDate === "NaN-NaN-NaN" ? "" : finalFromDate,
      to_date: finalToDate === "NaN-NaN-NaN" ? "" : finalToDate,
      city: searchCity,
      state_id: searchState,
      country_id: searchCountry
    }
    dispatch(searchRDsEventList(id, paramsData, true, pageUrl))
  }

  const getStates = (countryId) => {
    dispatch(getStatesById(countryId))
  }

  const dataToRender = () => {
    return manageRdsStoreSlice?.rdsEventList?.data
  }

  const refreshSearchData = () => {
    setIsTableLoading(true)
    setSearchRDName('')
    setSearchEventName('')
    setSearchEventType('')
    setSearchEndDatePicker('')
    setSearchStartDatePicker('')
    setSearchCity('')
    setSearchState('')
    setSearchCountry('')
    dispatch(nullSearchData('SearchedEventList'))
    dispatch(getRDsEventList(id, false))
  }

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="pt-0">
          <Row form className='mt-1 mb-50'>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='name'>RD Name</Label>
                <Input id='name' placeholder='RD Name' name="rdName" value={searchRDName}
                  onChange={(e) => setSearchRDName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='name'>Event Name</Label>
                <Input id='name' placeholder='Event Name' name="eventName" value={searchEventName}
                  onChange={(e) => setSearchEventName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='name'>Event Type</Label>
                <Input id='name' placeholder='Event Type' name="eventType" value={searchEventType}
                  onChange={(e) => setSearchEventType(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='date'>From</Label>
                <Flatpickr
                  className='form-control'
                  id='from'
                  value={searchStartDatePicker}
                  options={{ dateFormat: 'Y/d/m' }}
                  onChange={date => setSearchStartDatePicker(date)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='date'>To</Label>
                <Flatpickr
                  className='form-control'
                  id='to'
                  value={searchEndDatePicker}
                  options={{ dateFormat: 'Y/d/m' }}
                  onChange={date => setSearchEndDatePicker(date)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='country'>Country</Label>
                <Input id='country' type="select" placeholder='Country' name="country" onChange={(e) => { setSearchCountry(e.target.value); getStates(e.target.value) }}
                  value={searchCountry}
                >
                  <option value=''>Select Country</option>
                  {manageRdsStoreSlice.countries.map(country => (
                    <option value={country.id}>{country.value}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='state'>State</Label>
                <Input type="select" id='state' placeholder='State'
                  name="state" onChange={(e) => setSearchState(e.target.value)}
                  value={searchState}
                >
                  <option value=''>Select State</option>
                  {manageRdsStoreSlice.states && manageRdsStoreSlice.states.map(state => (
                    <option value={state.id}>{state.value}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='city'>City</Label>
                <Input id='city' placeholder='City' name="city" onChange={(e) => setSearchCity(e.target.value)}
                  value={searchCity}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="admin-filter-actions ml-auto text-right">
                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchData}>
                  <Search size={16} />
                </Button.Ripple>
                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchData}>
                  <RefreshCcw size={16} />
                </Button.Ripple>
              </div>
            </Col>
          </Row>
        </CardBody>
        {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary' /></div>)}
        {!isTableLoading && (
          <Fragment>
            <DataTable
              noHeader
              columns={advSearchColumns}
              paginationPerPage={10}
              className='react-dataTable'
              sortIcon={<ChevronDown size={10} />}
              paginationDefaultPage={currentPage + 1}
              paginationServerOptions={{ persistSelectedOnPageChange: true, persistSelectedOnSort: true }}
              data={dataToRender()}
            />
            <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination} />
          </Fragment>
        )}
      </Card>
    </Fragment >
  )
}

export default DataTableAdvSearch
