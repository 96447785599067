import axios from 'axios'
import {
  API_URL,
  RD_CAMPAIGNS,
  RD_DASHBOARD_CAMPAIGNS,
  RD_DASHBOARD_CAMPAIGN_DETAIL
} from '../actionTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getRdCampaigns = (queryString) => async (dispatch) => {
  dispatch({type: RD_DASHBOARD_CAMPAIGNS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}rd/my-campaigns${queryString}`, {headers: header})
    dispatch({type: RD_DASHBOARD_CAMPAIGNS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_DASHBOARD_CAMPAIGNS.ERROR, payload: error})
  }
}

export const getAllRdCampaigns = (isPaginate, pageUrl = '', id = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}rd/all-campaigns?id=${id}`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: RD_CAMPAIGNS.ALL_LISTING_DATA,
        allCampaignsListing: response.data
      })
    }).catch(error => {
      dispatch({
        type: RD_CAMPAIGNS.ERROR,
        error
      })
    })
  }
}

export const getAppliedCampaignDetail = (id) => async (dispatch) => {
  dispatch({type: RD_DASHBOARD_CAMPAIGN_DETAIL.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}rd/campaign-details?campaign_id=${id}`, {headers: header})
    dispatch({type: RD_DASHBOARD_CAMPAIGN_DETAIL.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_DASHBOARD_CAMPAIGN_DETAIL.ERROR, payload: error})
  }
}

export const campaignApplication = (params) => {
  return dispatch => {
     axios.post(`${API_URL}rd/apply-campaign`, params, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: RD_CAMPAIGNS.APPLICATION,
        application: response.data
      })
    }).catch(error => {
      dispatch({
        type: RD_CAMPAIGNS.ERROR,
        error
      })
    })
  }
}