import Layout from '@layouts/VerticalLayoutBrands'
import {PropTypes} from "prop-types"

const VerticalLayoutBrands = props => <Layout {...props}>{props.children}</Layout>

VerticalLayoutBrands.propTypes = {
    children: PropTypes.node.isRequired
}

export default VerticalLayoutBrands
