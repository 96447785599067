import {useContext, useEffect, useState} from "react"
import {ShepherdTour, ShepherdTourContext} from 'react-shepherd'
import 'shepherd.js/dist/css/shepherd.css'
import '@styles/react/libs/shepherd-tour/shepherd-tour.scss'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import {Tv} from "react-feather"
import {setTour} from "@src/redux/actions/auth"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useHistory} from 'react-router'
import {PropTypes} from "prop-types"

let instance = null

const stepsRd = [
    {
        id: 'brands',
        title: 'Brands',
        text: '<strong>#1:</strong> This is where you can view all brands who are a part of The RD Link. These brands are eager to connect with dietitians!',
        attachTo: {element: '.brands-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'msg',
        title: 'Messages',
        text: '<strong>#2:</strong> This is where you can message brands directly. Request samples for patients, share ideas for partnerships, learn more about their products, and build relationships.',
        attachTo: {element: '.msg-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'opportunities',
        title: 'Opportunities',
        text: '<strong>#3:</strong> This is where you can post "Opportunities" you have for brands. This could be samples for an event, an idea for a social post, or an upcoming TV segment. Once you post, each brand will be notified and they can inquire for more information. Here, you can also see and apply to Brand Campaigns.',
        attachTo: {element: '.opportunities-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'my-partnerships',
        title: 'My Partnerships',
        text: '<strong>#4:</strong> Once you connect with a brand on a partnership, these will appear here for easy management. Here you can send invoices and easily accept payment for your partnerships.',
        attachTo: {element: '.my-partnerships-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'resources-rd',
        title: 'Resources',
        text: '<strong>#5:</strong> The Resources Tab is a great place to get started! From recordings of past community calls to our ‘guide on how to work with brands when you are NOT an influencer’ to discounts codes and more! This is your spot to learn more and get more from RD Link!',
        attachTo: {element: '.resources-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'profile',
        title: 'Profile',
        text: '<strong>#6:</strong> Be sure to complete your profile with a photo, description, and your key areas of expertise. Brands are more likely to connect with RDs with complete profiles. Tip: Include how you work with brands in your bio. <strong>Click over to fill this out now!</strong>',
        attachTo: {element: '.profile-tour', on: 'left'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Finish',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.cancel()
            }
        ]
    }
]

const stepsBrand = [
    {
        id: 'nutrition-experts',
        title: 'Nutrition Experts',
        text: '<strong>#1:</strong> This is where you can view all dietitians in the database. Filter through over 1,000 RDs to find the right experts for your brand. Organize them into Favorites Folders for your various campaigns.',
        attachTo: {element: '.nutrition-experts-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'messages-brands',
        title: 'Messages',
        text: '<strong>#2:</strong> This is where you can message any dietitian directly or send mass messages to multiple dietitians at once.',
        attachTo: {element: '.messages-brands-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'opportunities-brands',
        title: 'Opportunities',
        text: '<strong>#3:</strong> This is where you can post how you are looking to work with dietitians. This could be a sample sign up form, ambassador program, influencer partnership, etc. Once you post, all RDs are notified via email and can apply. Here, you will also see Opportunities that RDs have available for you, such as upcoming events, media opportunities, content ideas, and more.',
        attachTo: {element: '.opportunities-brands-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'manage-partnerships',
        title: 'Manage Partnerships',
        text: '<strong>#4:</strong> Once you connect with a dietitian on a partnership, these will appear here for easy management. Here you can easily process payments for partnerships through ACH or credit card.',
        attachTo: {element: '.manage-partnership-tour', on: 'right'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Skip',
                classes: 'btn btn-sm btn-danger',
                action: () => instance.cancel()
            },
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Next',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.next()
            }
        ]
    },
    {
        id: 'profile-brand',
        title: 'Profile',
        text: '<strong>#5:</strong> Be sure to complete your profile with your logo, product images, description, and links to your website and social media. Be sure to set your preferences to get recommendations. Tip: Include how you want to work with RDs in your bio. <br/> <strong>Click over to fill this out now!</strong>',
        attachTo: {element: '.brand-profile-tour', on: 'left'},
        cancelIcon: {
            enabled: true
        },
        buttons: [
            {
                text: 'Back',
                classes: 'btn btn-sm btn-outline-primary',
                action: () => instance.back()
            },
            {
                text: 'Finish',
                classes: 'btn btn-sm btn-primary btn-next',
                action: () => instance.cancel()
            }
        ]
    }
]

const Content = (props) => {
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))

    const sweetAlert = withReactContent(Swal)

    const history = useHistory()

    const tour = useContext(ShepherdTourContext)

    useEffect(() => {
        instance = tour
        instance.on('cancel', () => {
            sweetAlert.fire({
                title: 'Profile Setup!',
                icon: 'info',
                html: '<p>Please complete your profile.</p>',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    if (userData?.role === 'rd') {
                        history.push('/dashboard/rd/edit-profile')
                    } else if (userData?.role === 'brand') {
                        history.push('/dashboard/brands/edit-profile')
                    }
                }
            })
        })
    }, [])

    return (
        <Button color='primary' onClick={() => {
            tour.start()
            props.parentOnStartTour()
        }}> Start Tour </Button>
    )
}

const Footer = () => {
    const sweetAlert = withReactContent(Swal)

    const history = useHistory()

    const [tourModal, setTourModal] = useState(false)
    const [back, setBack] = useState(true)
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))

    useEffect(() => {
        if (userData && userData.is_tour === 0 && userData.role !== 'admin' && !userData?.parent) {
            setTourModal(true)
        }
    }, [])


    const onCloseModal = () => {
        setTourModal(false)
        setTour()
        userData.is_tour = 1
        localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(userData))
        sweetAlert.fire({
            title: 'Profile Setup!',
            icon: 'info',
            html: '<p>Please complete your profile.</p>',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                if (userData?.role === 'rd') {
                    history.push('/dashboard/rd/edit-profile')
                } else if (userData?.role === 'brand') {
                    history.push('/dashboard/brands/edit-profile')
                }
            }
        })
    }

    const onStartTour = () => {
        setTourModal(false)
        setBack(false)
        setTour()
        userData.is_tour = 1
        localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(userData))
    }

    return (
        <div>
            <Modal isOpen={tourModal} className='modal-dialog-centered' backdrop={back}>
                <ModalHeader>Tour</ModalHeader>
                <ModalBody>
                    <div className="text-center mt-2 text-primary">
                        <Tv size={50}/>
                    </div>
                    <div className="text-center mt-2 font-weight-bold font-medium-5">
                        Hi! Welcome to The RD Link
                    </div>
                    <div className="text-center mt-2">
                        Would you like to take a tour?
                    </div>
                </ModalBody>
                <ModalFooter className="mt-2">
                    <ShepherdTour steps={userData?.role === 'rd' ? stepsRd : stepsBrand} tourOptions={{useModalOverlay: true}}>
                        <Content parentOnStartTour={onStartTour}/>
                    </ShepherdTour>
                    <Button color='danger' onClick={onCloseModal}>
                        Skip
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

Content.propTypes = {
    parentOnStartTour: PropTypes.func.isRequired
}

export default Footer
