import { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Card,
  CardHeader,
  Alert,
  Table,
  Spinner,
  CustomInput
} from 'reactstrap'

import {
  getRDNotificationSettingAction,
  rdNotificationSettingUpdateAction,
  rdNotificationSettingUpdateResetAction
} from "./store/actions"

const NotificationsSetting = () => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [successMsgTxt, setSuccessMsgTxt] = useState("")

  const [updateStatusChildUserRes, setUpdateStatusChildUserRes] = useState(null)
  const [showMsgWhenDelete, setShowMsgWhenDelete] = useState(false)
  const [showMsgWhenUpdateStatus, setShowMsgWhenUpdateStatus] = useState(false)

  const {getRDNotificationSettingLoading, getRDNotificationSettingData, getRDNotificationSettingError} = useSelector(store => store.getRDNotificationSettingReducer)
  const {rdNotificationSettingUpdateLoading, rdNotificationSettingUpdateData, rdNotificationSettingUpdateError} = useSelector(store => store.rdNotificationSettingUpdateReducer)

  useEffect(() => {
    if (rdNotificationSettingUpdateData?.status) {
      setSuccessMsg(true)
      setSuccessMsgTxt(rdNotificationSettingUpdateData?.msg)
      dispatch(rdNotificationSettingUpdateResetAction())
      setTimeout(() => { setSuccessMsg(false) }, 2500)
    }
  }, [rdNotificationSettingUpdateData])

  useEffect(() => {
    dispatch(getRDNotificationSettingAction())
  }, [])

  const handleStatus = (settingId) => {
    setSuccessMsg(false)
    dispatch(rdNotificationSettingUpdateAction({
      id: settingId
    }))
  }

  return (
    <Fragment>
      <Card>
        <CardHeader className="text-center">
          <h2 className="text-primary">Email Notification Setting</h2>
        </CardHeader>
        <div className="px-2">
          <Alert color="success" isOpen={successMsg} toggle={() => setSuccessMsg(false)}>
            <div className='alert-body'>
              {successMsgTxt}
            </div>
          </Alert>
          <Alert color={!updateStatusChildUserRes?.status ? 'danger' : 'success'} isOpen={showMsgWhenUpdateStatus} toggle={() => setVisible(false)}>
            <div className='alert-body'>
              {updateStatusChildUserRes?.msg}
            </div>
          </Alert>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>Sr.</th>
              <th>Notification Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {getRDNotificationSettingLoading ? (<tr><td colSpan={3} align="center"><Spinner color="primary" /></td></tr>) : (
              getRDNotificationSettingData?.data?.map((mapData, index) => (
                  <tr key={index}>
                    <td><span className='align-middle font-weight-bold'>{index + 1}</span></td>
                    <td>{mapData.title}</td>
                    <td>
                      <CustomInput
                          className='custom-control-success'
                          type='switch'
                          id={`status-${index}`}
                          name='success'
                          inline
                          defaultChecked={mapData.status === 1}
                          onChange={() => handleStatus(mapData.id)}
                          disabled={rdNotificationSettingUpdateLoading}
                      />
                    </td>
                  </tr>
              ))
          )}
          </tbody>
        </Table>
      </Card>
    </Fragment>
  )
}

export default NotificationsSetting
