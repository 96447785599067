import {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import Avatar from '@components/avatar'
import {useDispatch, useSelector} from 'react-redux'
import {formatDateToMonthShort} from '@utils'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {X, Search, CheckSquare, Bell, User, Trash} from 'react-feather'
import {
    CardText,
    InputGroup,
    InputGroupAddon,
    Input,
    InputGroupText,
    Badge,
    CustomInput,
    Button,
    Spinner
} from 'reactstrap'
import {getuserDetailChat} from '../chatAdmin/store/actions/index'
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import {PropTypes} from "prop-types"

const SidebarLeft = props => {
    const {store, sidebar, handleSidebar, userSidebarLeft, handleUserSidebarLeft} = props
    const {chats, contacts, userProfile} = store

    const chattingSelector = useSelector(store => store)
    const adminChattingSelector = useSelector(store => store.chatAdmin)
    const dispatch = useDispatch()


    const [about, setAbout] = useState('')
    const [query, setQuery] = useState('')
    const [active, setActive] = useState({})
    const [status, setStatus] = useState('online')
    const [filteredChat, setFilteredChat] = useState([])
    const [contactsList, setContactsList] = useState(store?.singleUserChat)
    const [filteredContacts, setFilteredContacts] = useState([])

    useEffect(() => {
        setContactsList(filteredContacts.length > 0 ? filteredContacts : store?.singleUserChat)
    }, [store?.singleUserChat, filteredContacts])

    const handleUserClick = (type, id) => {
        const params = {
            to_user_id: props.generalUserId,
            from_user_id: id
        }
        dispatch({type: 'CLEAR_ADMIN_CHAT_DETAILS_FIRST'})
        dispatch(getuserDetailChat(params))
        setActive({type, id})
        if (sidebar === true) {
            handleSidebar()
        }
    }

    useEffect(() => {
        const selectedUser = JSON.parse(localStorage.getItem('selectedChatUserForAdmin'))
        if (!!selectedUser) {
            handleUserClick('chat', selectedUser.to_user_id)
        }
    }, [])

    const renderChats = () => {
        if (contactsList?.length === 0) return <h6 className='mb-0 mt-3 text-center'>No Contacts Found</h6>

        if (store?.singleUserChat && store?.singleUserChat?.length) {
            if (contactsList?.length > 0) {
                return contactsList?.map(item => {
                    const time = formatDateToMonthShort(item.last_message_time ? item.last_message_time : new Date())
                    return (
                        <li className={classnames({
                            active: active.type === 'chat' && active.id === item.chatUser
                        })}
                            key={item.chatUser}
                            onClick={() => handleUserClick('chat', item.chatUser)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleUserClick('chat', item.chatUser)
                                }
                            }}
                            tabIndex={0}
                        >
                            <Link to={`/admin/manage-brands/${item?.chatUser}`} title="Go to profile"
                                  onClick={(e) => e.stopPropagation()}>
                                <Avatar img={item.brand_logo || BlankLogo} imgHeight='42' imgWidth='42' status={item.status} className="chat-img-animation"/>
                            </Link>
                            <div className='chat-info flex-grow-1'>
                                <h5 className='mb-0'>{item.brand_name}</h5>
                                <CardText className='text-truncate'>{item.last_message}</CardText>
                            </div>
                            <div className='chat-meta text-nowrap'>
                                <small className='float-right mb-25 chat-time ml-25'>{time} </small>
                                {item.last_message >= 1 ? (
                                    <Badge className='float-right' color='danger' pill>
                                        {item.last_message}
                                    </Badge>
                                ) : null}
                            </div>
                        </li>
                    )
                })
            }
        } else {
            return (
                <div className="text-center">
                    <Spinner color="primary" style={{width: '20px', height: '20px'}}/>
                </div>
            )
        }
    }

    const handleFilter = e => {
        setQuery(e.target.value)
        if (!e.target.value) setContactsList(store?.singleUserChat)
        if (!!e.target.value) setFilteredContacts(contactsList.filter(item => item.brand_name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    return store?.singleUserChat ? (
        <div className='sidebar-left'>
            <div className='sidebar'>
                <div
                    className={classnames('chat-profile-sidebar', {
                        show: userSidebarLeft
                    })}
                >
                    <header className='chat-profile-header'>
                        <div className='close-icon'
                             onClick={handleUserSidebarLeft}
                             onKeyDown={(e) => {
                                 if (e.key === 'Enter' || e.key === ' ') {
                                     handleUserSidebarLeft()
                                 }
                             }}
                             tabIndex={0}
                             role="button"
                             aria-label="Close Sidebar"
                        >
                            <X size={14}/>
                        </div>
                        <div className='header-profile-sidebar'>
                            <Avatar className='box-shadow-1 avatar-border' img={userProfile.avatar} status={status} size='xl'/>
                            <h4 className='chat-user-name'>{store?.singleUserChat?.name}</h4>
                            <span className='user-post'>{userProfile.role}</span>
                        </div>
                    </header>
                    <PerfectScrollbar className='profile-sidebar-area' options={{wheelPropagation: false}}>
                        <h6 className='section-label mb-1'>About</h6>
                        <div className='about-user'>
                            <Input
                                rows='5'
                                defaultValue={userProfile.about}
                                type='textarea'
                                onChange={e => setAbout(e.target.value)}
                                className={classnames('char-textarea', {
                                    'text-danger': about && about.length > 120
                                })}
                            />
                            <small className='counter-value float-right'>
                                <span className='char-count'>{userProfile.about ? userProfile.about.length : 0}</span>/ 120
                            </small>
                        </div>
                        <h6 className='section-label mb-1 mt-3'>Status</h6>
                        <ul className='list-unstyled user-status'>
                            <li className='pb-1'>
                                <CustomInput
                                    type='radio'
                                    className='custom-control-primary'
                                    id='online'
                                    label='Online'
                                    onChange={e => setStatus('online')}
                                    checked={status === 'online'}
                                />
                            </li>
                            <li className='pb-1'>
                                <CustomInput
                                    type='radio'
                                    className='custom-control-danger'
                                    id='busy'
                                    label='Do Not Disturb'
                                    onChange={e => setStatus('busy')}
                                    checked={status === 'busy'}
                                />
                            </li>
                            <li className='pb-1'>
                                <CustomInput
                                    type='radio'
                                    className='custom-control-warning'
                                    id='away'
                                    label='Away'
                                    onChange={e => setStatus('away')}
                                    checked={status === 'away'}
                                />
                            </li>
                            <li className='pb-1'>
                                <CustomInput
                                    type='radio'
                                    className='custom-control-secondary'
                                    id='offline'
                                    label='Offline'
                                    onChange={e => setStatus('offline')}
                                    checked={status === 'offline'}
                                />
                            </li>
                        </ul>
                        <h6 className='section-label mb-1 mt-2'>Settings</h6>
                        <ul className='list-unstyled'>
                            <li className='d-flex justify-content-between align-items-center mb-1'>
                                <div className='d-flex align-items-center'>
                                    <CheckSquare className='mr-75' size='18'/>
                                    <span className='align-middle'>Two-step Verification</span>
                                </div>
                                <CustomInput type='switch' id='verification' name='verification' label='' defaultChecked/>
                            </li>
                            <li className='d-flex justify-content-between align-items-center mb-1'>
                                <div className='d-flex align-items-center'>
                                    <Bell className='mr-75' size='18'/>
                                    <span className='align-middle'>Notification</span>
                                </div>
                                <CustomInput type='switch' id='notifications' name='notifications' label=''/>
                            </li>
                            <li className='d-flex align-items-center cursor-pointer mb-1'>
                                <User className='mr-75' size='18'/>
                                <span className='align-middle'>Invite Friends</span>
                            </li>
                            <li className='d-flex align-items-center cursor-pointer'>
                                <Trash className='mr-75' size='18'/>
                                <span className='align-middle'>Delete Account</span>
                            </li>
                        </ul>
                        <div className='mt-3'>
                            <Button color='primary'>Logout</Button>
                        </div>
                    </PerfectScrollbar>
                </div>
                <div
                    className={classnames('sidebar-content', {
                        show: sidebar === true
                    })}
                >
                    <div className='sidebar-close-icon' onClick={handleSidebar}>
                        <X size={14}/>
                    </div>
                    <div className='chat-fixed-search'>
                        <div className='d-flex align-items-center w-100'>
                            <div className='sidebar-profile-toggle'
                                 onClick={handleUserSidebarLeft}
                                 onKeyDown={(e) => {
                                     if (e.key === 'Enter' || e.key === ' ') {
                                         handleUserSidebarLeft()
                                     }
                                 }}
                                 tabIndex={0}
                                 role="button"
                                 aria-label="Toggle Profile Sidebar"
                            >
                            </div>
                            <InputGroup className='input-group-merge ml-1 w-100'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText className='round'>
                                        <Search className='text-muted' size={14}/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    value={query}
                                    className='round'
                                    placeholder='Search or start a new chat'
                                    onChange={handleFilter}
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <PerfectScrollbar className='chat-user-list-wrapper list-group' options={{wheelPropagation: false}}>
                        <h4 className='chat-list-title'>Chats</h4>
                        <ul className='chat-users-list chat-list media-list'>{renderChats()}</ul>
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    ) : null
}

SidebarLeft.propTypes = {
    store: PropTypes.shape({
        chats: PropTypes.array.isRequired,
        contacts: PropTypes.array.isRequired,
        userProfile: PropTypes.object.isRequired,
        singleUserChat: PropTypes.array.isRequired,
        chatAdmin: PropTypes.array.isRequired
    }).isRequired,
    sidebar: PropTypes.bool.isRequired,
    handleSidebar: PropTypes.func.isRequired,
    userSidebarLeft: PropTypes.bool.isRequired,
    handleUserSidebarLeft: PropTypes.func.isRequired,
    generalUserId: PropTypes.number.isRequired
}

export default SidebarLeft
