import AdminDashboard from "@src/views/admin/dashboard"
import ManageRDs from "@src/views/admin/manageRDs"
import PendingRDs from "@src/views/admin/pendingRDs"
import ManageBrands from "@src/views/admin/manageBrands"
import ManageEvents from "@src/views/admin/manageEvents"
import SubscribedBrands from "@src/views/admin/subscribedBrands"
import ManageCoupons from "@src/views/admin/manageCoupons"
import SubscriptionReport from "@src/views/admin/manageReports/SubscriptionReport"
import CommissionReport from "@src/views/admin/manageReports/CommissionReport"
import CancelledSubscription from "@src/views/admin/manageReports/CancelledSubscription"
import ViewConversation from "@src/views/admin/viewConversation"
import AccountSettings from "@src/views/admin/accountSettings"
import ManageCms from "@src/views/admin/manageCms"
import ManageBlogs from "@src/views/admin/manageBlogs"
import Faqs from "@src/views/admin/faqs"
import Resources from "@src/views/admin/resources"
import Permissions from "@src/views/admin/permissions"
import AddUpdateBlog from "@src/views/admin/manageBlogs/addUpdateBlog"
import ChangePassword from "@src/views/admin/others/changePassword"
import EditProfile from "@src/views/admin/others/editProfile"
import ViewRdProfile from "@src/views/admin/manageRDs/viewProfile"
import ViewEvent from "@src/views/admin/manageEvents/viewEvent"
import ViewPendingRdProfile from "@src/views/admin/manageRDs/viewPendingProfile"
import ViewBrandProfile from "@src/views/admin/manageBrands/viewProfile"
import UpdateCms from "@src/views/admin/manageCms/updateCms"
import AdminNotifications from "@src/views/admin/others/notifications"
import ResourcesContent from "@src/views/admin/resources/content.js"

const AdminRoutes = [
    {
        path: '/dashboard/admin',
        exact: true,
        component: AdminDashboard,
        meta: {
            layout: 'full',
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-rd',
        component: ManageRDs,
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-rd-pending',
        component: PendingRDs,
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-brands',
        component: ManageBrands,
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-events',
        component: ManageEvents,
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/subscribed-brands',
        component: SubscribedBrands,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-coupons',
        component: ManageCoupons,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/subscription-reports',
        component: SubscriptionReport,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/comission-reports',
        component: CommissionReport,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/cancelled-subscription-reports',
        component: CancelledSubscription,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/apps/chat/chatAdmin',
        appLayout: true,
        className: 'chat-application',
        component: ViewConversation,
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'admin'
        }
    },
    {
        path: '/admin/account-settings',
        component: AccountSettings,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/cms',
        component: ManageCms,
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/blogs',
        exact: true,
        component: ManageBlogs,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/pages/faqRd',
        component: Faqs,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/resources',
        component: Resources,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/users-permissions',
        component: Permissions,
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/blogs/add',
        exact: true,
        component: AddUpdateBlog,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/blogs/:id',
        component: AddUpdateBlog,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/change-password',
        component: ChangePassword,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/edit-profile',
        component: EditProfile,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-rd/:id',
        component: ViewRdProfile,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-events/:id',
        component: ViewEvent,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-rd-pending/:id',
        component: ViewPendingRdProfile,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/manage-brands/:id',
        component: ViewBrandProfile,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/cms/edit/:id',
        component: UpdateCms,
        layout: 'VerticalLayout',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/dashboard/admin/notifications',
        component: AdminNotifications,
        layout: 'VerticalLayout',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    },
    {
        path: '/admin/resources-content/:id',
        component: ResourcesContent,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'admin'
        }
    }
]

export default AdminRoutes