import {Fragment, useState, useEffect} from 'react'
import Chat from './Chat'
import Sidebar from './SidebarLeft'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
    getChatContactsFromServer,
    getUserProfileFromServer
} from './store/actions'
import '@styles/base/pages/app-chat.scss'
import '@styles/base/pages/app-chat-list.scss'

const AppChat = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const store = useSelector(state => state.chat)
    const chatBrandsStoreSlice = useSelector(state => state.chatBrands)
    const [user, setUser] = useState({})
    const [sidebar, setSidebar] = useState(false)
    const [userSidebarRight, setUserSidebarRight] = useState(false)
    const [userSidebarLeft, setUserSidebarLeft] = useState(false)
    const [currentUser, setCurrentUser] = useState({})
    const [chatContacts, setChatContacts] = useState()
    const [selectedUserBrandChat, setselectedUserBrandChat] = useState()
    const [selectedContactDetail, setSelectedContactDetail] = useState()
    const [brandContacts, setBrandContacts] = useState()
    const handleSidebar = () => setSidebar(!sidebar)
    const handleUserSidebarLeft = () => setUserSidebarLeft(!userSidebarLeft)
    const handleUserSidebarRight = () => setUserSidebarRight(!userSidebarRight)

    const handleUser = obj => setUser(obj)

    useEffect(() => {
        dispatch(getUserProfileFromServer())
    }, [dispatch])

    useEffect(() => {
        setChatContacts(chatBrandsStoreSlice?.chatContacts)
        setselectedUserBrandChat(chatBrandsStoreSlice?.selectedUserBrandChat)
        setSelectedContactDetail(chatBrandsStoreSlice?.selectedContactDetail)
        setCurrentUser(chatBrandsStoreSlice?.brandUserProfile)
        setBrandContacts(chatBrandsStoreSlice?.brandContacts)
    }, [chatBrandsStoreSlice])

    useEffect(() => {
        setChatContacts(null)
        setselectedUserBrandChat(null)
        setSelectedContactDetail(null)
        dispatch({type: 'CLEAR_CHAT_CONTENT_ON_LOAD'})
        dispatch({type: 'CLEAR_CHAT_CONTACT_ON_LOAD'})
        dispatch(getChatContactsFromServer())
    }, [])

    return (
        <Fragment>
            <Sidebar
                store={chatBrandsStoreSlice}
                sidebar={sidebar}
                handleSidebar={handleSidebar}
                userSidebarLeft={userSidebarLeft}
                handleUserSidebarLeft={handleUserSidebarLeft}
                chatContacts={chatContacts}
                brandContacts={brandContacts}
                selectedUserID={id}
            />
            <div className='content-right'>
                <div className='content-wrapper'>
                    <div className='content-body'>
                        <Chat
                            store={store}
                            handleUser={handleUser}
                            handleSidebar={handleSidebar}
                            userSidebarLeft={userSidebarLeft}
                            handleUserSidebarRight={handleUserSidebarRight}
                            selectedUserID={id}
                            currentUser={currentUser}
                            selectedUserBrandChat={selectedUserBrandChat}
                            selectedContactDetail={selectedContactDetail}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AppChat