import Avatar from '@components/avatar'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {X, Mail, PhoneCall, Clock} from 'react-feather'
import {PropTypes} from "prop-types"

const UserProfileSidebar = props => {
    const {user, handleUserSidebarRight, userSidebarRight} = props

    return (
        <div className={classnames('user-profile-sidebar', {show: userSidebarRight === true})}>
            <header className='user-profile-header'>
        <span className='close-icon'
              onClick={handleUserSidebarRight}
              role="button"
              tabIndex="0"
              aria-label="Close user sidebar"
              onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault()
                      handleUserSidebarRight()
                  }
              }}
        >
          <X size={14}/>
        </span>
                <div className='header-profile-sidebar'>
                    <Avatar
                        className='box-shadow-1 avatar-border'
                        size='xl'
                        status={user.status}
                        img={user.avatar}
                        imgHeight='70'
                        imgWidth='70'
                    />
                    <h4 className='chat-user-name'>{user.fullName}</h4>
                    <span className='user-post'>{user.role}</span>
                </div>
            </header>
            <PerfectScrollbar className='user-profile-sidebar-area' options={{wheelPropagation: false}}>
                <h6 className='section-label mb-1'>About</h6>
                <p>{user.about}</p>
                <div className='personal-info'>
                    <h6 className='section-label mb-1 mt-3'>Personal Information</h6>
                    <ul className='list-unstyled'>
                        <li className='mb-1'>
                            <Mail className='mr-50' size={17}/>
                            <span className='align-middle'>lucifer@email.com</span>
                        </li>
                        <li className='mb-1'>
                            <PhoneCall className='mr-50' size={17}/>
                            <span className='align-middle'> +1(123) 456 - 7890</span>
                        </li>
                        <li>
                            <Clock className='mr-50' size={17}/>
                            <span className='align-middle'> Mon - Fri 10AM - 8PM</span>
                        </li>
                    </ul>
                </div>
            </PerfectScrollbar>
        </div>
    )
}

UserProfileSidebar.propTypes = {
    user: PropTypes.shape({
        status: PropTypes.string,
        avatar: PropTypes.string,
        fullName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        about: PropTypes.string
    }).isRequired,
    handleUserSidebarRight: PropTypes.func.isRequired,
    userSidebarRight: PropTypes.bool.isRequired
}

export default UserProfileSidebar
