import {useState, Fragment, useEffect} from 'react'
import {Edit2} from 'react-feather'
import {Table, Button, Spinner, Card, CardHeader, CardTitle} from 'reactstrap'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getCmsListingData} from '@src/views/tables/reactstrap/admin/admin-manage-cms/store/actions'

const ManageCms = () => {
    const [isTableLoading, setIsTableLoading] = useState(false)
    const cmsSelector = useSelector(store => store.adminCmsSettings)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!!cmsSelector?.manageCmsListing) {
            setIsTableLoading(false)
        }
    }, [cmsSelector])
    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getCmsListingData())
    }, [])

    return (
        <Fragment>
            <Card>
                <CardHeader className='border-bottom'>
                    <CardTitle tag='h4'>Manage CMS</CardTitle>
                </CardHeader>
            </Card>
            {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
            {!isTableLoading && (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cmsSelector?.manageCmsListing && cmsSelector?.manageCmsListing.map(x => (
                        <tr key={x.id}>
                            <td>
                                <span className='align-middle font-weight-bold'>{x.title}</span>
                            </td>
                            <td>
                <span className='align-middle font-weight-bold'>
                  <Link to={{pathname: `/admin/cms/edit/${x.title.replace(/\s+/g, '-').toLowerCase()}`, query: {id: x.id, title: x.title, content: x.content, slug: x.slug}}}>
                    <Button.Ripple className='btn-icon' outline color='primary'>
                      <Edit2 size={16}/>
                    </Button.Ripple>
                  </Link>
                </span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
        </Fragment>
    )
}

export default ManageCms