import {useEffect} from 'react'
import {Link} from 'react-router-dom'
import Avatar from '@components/avatar'
import {
    Download,
    Search
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardTitle,
    Spinner,
    CardBody,
    Col,
    FormGroup,
    Row,
    Button,
    Form
} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/apps/app-invoice.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import {
    dashboardManageBrandsAction,
    dashboardManageBrandsCSVAction,
    dashboardManageBrandsCSVResetAction
} from "@store/actions/AdminActions"
import queryString from 'query-string'
import Flatpickr from "react-flatpickr"
import {useFormik} from 'formik'
import moment from "moment"

const ManageBrands = () => {

    const dispatch = useDispatch()

    const filterForm = useFormik({
        initialValues: {
            startDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate()
        },
        onSubmit: values => {
            const startDate = moment(values.startDate).format('YYYY-MM-DD')
            const endDate = moment(values.endDate).format('YYYY-MM-DD')
            dispatch(dashboardManageBrandsAction(`?start_date=${startDate}&end_date=${endDate}`))
        }
    })

    const {
        dashboardManageBrandsLoading,
        dashboardManageBrandsData,
        dashboardManageBrandsError,

        dashboardManageBrandsCSVLoading,
        dashboardManageBrandsCSVData,
        dashboardManageBrandsCSVError
    } = useSelector(state => state.adminReducer)

    useEffect(() => {
        if (dashboardManageBrandsCSVData?.data) {
            const startDate = moment(filterForm.values.startDate).format('DD MMM YYYY')
            const endDate = moment(filterForm.values.endDate).format('DD MMM YYYY')
            let result = "Start Date, End Date, Brand Name, Registration Date, Subscription, Partnerships, Live Campaigns, Mass Messages Sent, Messages Sent, Monthly Logins, Login Time\n"
            dashboardManageBrandsCSVData?.data.forEach((item) => {
                result += `${startDate}, ${endDate}, ${item?.name?.replaceAll(',', '')}, ${item?.reg_date?.replaceAll(',', '')}, ${item?.plan}, ${item?.partnerships}, ${item?.live_campaigns}, ${item?.mass_messages}, ${item?.total_messages}, ${item?.monthly_logins}, ${item?.login_time}\n`
            })
            const csv = `data:text/csv;charset=utf-8,${result}`
            const link = document.createElement('a')
            link.setAttribute('href', encodeURI(csv))
            link.setAttribute('download', 'Dashboard-Brand-Stats')
            link.click()
        }
        dispatch(dashboardManageBrandsCSVResetAction())
    }, [dashboardManageBrandsCSVData])

    useEffect(() => {
        const startDate = moment(filterForm.values.startDate).format('YYYY-MM-DD')
        const endDate = moment(filterForm.values.endDate).format('YYYY-MM-DD')
        dispatch(dashboardManageBrandsAction(`?start_date=${startDate}&end_date=${endDate}`))
    }, [])

    const handleCustomPagination = pageUrl => {
        const startDate = moment(filterForm.values.startDate).format('YYYY-MM-DD')
        const endDate = moment(filterForm.values.endDate).format('YYYY-MM-DD')
        const parsed = queryString.parseUrl(pageUrl)
        dispatch(dashboardManageBrandsAction(`?page=${parsed?.query.page}&start_date=${startDate}&end_date=${endDate}`))
    }

    const downloadCSV = () => {
        const startDate = moment(filterForm.values.startDate).format('YYYY-MM-DD')
        const endDate = moment(filterForm.values.endDate).format('YYYY-MM-DD')
        dispatch(dashboardManageBrandsCSVAction(`?start_date=${startDate}&end_date=${endDate}&export=all`))
    }

    const columns = [
        {
            name: 'Brand Name',
            minWidth: '240px',
            selector: 'name',
            sortable: false,
            cell: row => {
                return (
                    <Link to={`/admin/manage-brands/${row.id}`}>
                        <div className='d-flex justify-content-left align-items-center'>
                            {row?.avatar?.length ? (
                                <Avatar className='mr-50' img={row.avatar} width='32' height='32'/>
                            ) : (
                                <Avatar color='primary' className='mr-50' content={row?.name} initials/>
                            )}
                            <div className='d-flex flex-column'>
                                <h6 className='user-name text-truncate mb-0 text-primary text-capitalize text-break'>
                                    <strong>{row?.name?.length > 15 ? `${row?.name?.substring(0, 15)}...` : row?.name}</strong>
                                </h6>
                            </div>
                        </div>
                    </Link>
                )
            }
        },
        {
            name: 'Registration Date',
            selector: 'reg_date',
            sortable: false,
            minWidth: '180px',
            cell: row => row?.reg_date
        },
        {
            name: 'Subscription',
            selector: 'plan',
            sortable: false,
            minWidth: '150px',
            className: 'text',
            cell: row => <span>{row?.plan}</span>
        },
        {
            name: 'Partnerships',
            selector: 'partnerships',
            sortable: false,
            minWidth: '130px',
            cell: row => <span>{row?.partnerships}</span>
        },
        {
            name: 'Live Campaigns',
            selector: 'partnerships',
            sortable: false,
            minWidth: '130px',
            cell: row => <span>{row?.live_campaigns}</span>
        },
        {
            name: 'Mass Messages Sent',
            selector: 'partnerships',
            sortable: false,
            minWidth: '130px',
            cell: row => <span>{row?.mass_messages}</span>
        },
        {
            name: 'Messages Sent',
            selector: 'partnerships',
            sortable: false,
            minWidth: '130px',
            cell: row => <span>{row?.total_messages}</span>
        },
        {
            name: 'Monthly Logins',
            selector: 'monthly_logins',
            sortable: false,
            minWidth: '162px',
            cell: row => <span>{row?.monthly_logins}</span>
        },
        {
            name: 'Login Time',
            selector: 'login_time',
            sortable: false,
            minWidth: '152px',
            cell: row => <span>{row?.login_time}</span>
        }
    ]

    return (
        <Card className="pt-0">
            <CardHeader>
                <CardTitle className='d-flex justify-content-between w-100'>
                    <span>Manage Brands</span>
                    <Form onSubmit={filterForm.handleSubmit}>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Flatpickr className='form-control'
                                               id='start-date'
                                               placeholder='Start Date'
                                               options={{dateFormat: 'd M, Y'}}
                                               value={filterForm.values.startDate}
                                               onChange={(date) => {
                                                   filterForm.setFieldValue('startDate', date[0])
                                               }}
                                               disabled={dashboardManageBrandsLoading || dashboardManageBrandsCSVLoading}
                                               readOnly={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Flatpickr className='form-control'
                                               id='end-date'
                                               placeholder='End Date'
                                               options={{dateFormat: 'd M, Y'}}
                                               value={filterForm.values.endDate}
                                               onChange={(date) => {
                                                   filterForm.setFieldValue('endDate', date[0])
                                               }}
                                               disabled={dashboardManageBrandsLoading || dashboardManageBrandsCSVLoading}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <Button.Ripple color='primary' className='btn-block' type='submit'
                                               disabled={dashboardManageBrandsLoading || dashboardManageBrandsCSVLoading}>
                                    <Search size={16}/> Find
                                </Button.Ripple>
                            </Col>
                            <Col md={4}>
                                <Button.Ripple color='success' className='btn-block'
                                               disabled={dashboardManageBrandsLoading || dashboardManageBrandsCSVLoading} onClick={downloadCSV}>
                                    {dashboardManageBrandsCSVLoading ? (
                                        <>
                                            <Spinner style={{width: '14px', height: '14px'}}/> Download CSV
                                        </>
                                    ) : (
                                        <>
                                            <Download size={16}/> Download CSV
                                        </>
                                    )}
                                </Button.Ripple>
                            </Col>
                        </Row>
                    </Form>
                </CardTitle>
            </CardHeader>
            <CardBody>
                {dashboardManageBrandsLoading ? (
                    <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
                ) : (
                    <>
                        <DataTable
                            noHeader={true}
                            columns={columns}
                            responsive={true}
                            className='react-dataTable'
                            data={dashboardManageBrandsData?.data}
                        />
                        <CustomPagination pageLinks={dashboardManageBrandsData?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
            </CardBody>
        </Card>
    )
}

export default ManageBrands
