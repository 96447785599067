import {useState, useEffect, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Formik} from "formik"
import * as yup from "yup"
import DataTable from "react-data-table-component"
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Alert,
    Row,
    Col,
    Input,
    Badge,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Table
} from "reactstrap"
import {
    ChevronDown,
    MoreHorizontal
} from "react-feather"
import "@styles/base/pages/page-auth.scss"
import {
    getCountries,
    createAccount,
    getAccountDetails,
    deleteAccount,
    getVerificationLink,
    deleteAccountReset,
    createAccountReset,
    getVerificationLinkReset,
    markDefaultAccount,
    markDefaultAccountReset
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import queryString from "query-string"
import {useHistory} from "react-router-dom"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const AdminChangePasswordForm = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const history = useHistory()
    const formikRef = useRef()
    const sweetAlert = withReactContent(Swal)

    const [deleteBankAccountModal, setDeleteBankAccountModal] = useState(false)
    const [verifyBankAccountModal, setVerifyBankAccountModal] = useState(false)
    const [bankAccountId, setBankAccountId] = useState('')
    const [verificationStatus, setVerificationStatus] = useState(null)

    const dispatch = useDispatch()
    const paymentSettingsSlice = useSelector((store) => store.brandsOppoprunities)
    const {
        rdAccountDeleteLoading,
        rdAccountDeleteFullFill,
        rdAccountDeleteError,

        rdAccountCreateLoading,
        rdAccountCreateFullFill,
        rdAccountCreateError,

        rdAccountDetailsLoading,
        rdAccountDetailsFullFill,
        rdAccountDetailsError,

        rdAccountVerifyLoading,
        rdAccountVerifyFullFill,
        rdAccountVerifyError,

        rdAccountMarkDefLoading,
        rdAccountMarkDefFullFill,
        rdAccountMarkDefError
    } = useSelector((store) => store.brandsOppoprunities)

    useEffect(() => {
        if (rdAccountDeleteFullFill?.status) {
            window.scroll(0, 0)
            setDeleteBankAccountModal(false)
            setBankAccountId(null)
            dispatch(getAccountDetails())
            setTimeout(() => {
                dispatch(deleteAccountReset())
            }, 5000)
        }

        if (rdAccountCreateFullFill?.status) {
            dispatch(getAccountDetails())
            setTimeout(() => {
                dispatch(createAccountReset())
            }, 5000)
            window.scroll(0, 0)
            formikRef.current?.resetForm()
            const data = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
            data.stripe_account = true
            localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(data))
        }

        if (rdAccountCreateFullFill?.status === false || rdAccountCreateFullFill?.success === false) {
            setTimeout(() => {
                dispatch(createAccountReset())
            }, 5000)
            window.scroll(0, 0)
        }

        if (rdAccountVerifyFullFill?.data?.url) {
            dispatch(getVerificationLinkReset())
            window.open(rdAccountVerifyFullFill?.data?.url, "_self")
        }

        if (rdAccountDeleteError?.response?.status) {
            setDeleteBankAccountModal(false)
            setBankAccountId(null)
            window.scroll(0, 0)
            setTimeout(() => {
                dispatch(deleteAccountReset())
            }, 10000)
        }

        if (rdAccountMarkDefFullFill?.status) {
            window.scroll(0, 0)
            setBankAccountId(null)
            dispatch(getAccountDetails())
            setTimeout(() => {
                dispatch(markDefaultAccountReset())
            }, 5000)
        }

    }, [rdAccountDeleteFullFill, rdAccountCreateFullFill, rdAccountVerifyFullFill, rdAccountDeleteError, rdAccountMarkDefFullFill])

    useEffect(() => {
        dispatch(getCountries())
        dispatch(getAccountDetails())
        const parsed = queryString.parseUrl(window.location.href)
        const type = parsed?.query?.type
        if (type) {
            setVerificationStatus(type)
            setTimeout(() => {
                setVerificationStatus(null)
                history.push({
                    pathname: '/dashboard/rd/payment-settings'
                })
            }, 10000)
        }
    }, [])

    const addBankAccount = async (values) => {
        const token = await executeRecaptcha('rd_payment_settings')
        if (token) {
            const params = {
                country_id: values.country_id,
                account_holder_name: values.account_holder_name,
                account_number: values.account_number,
                routing_number: values.routing_number,
                re_captcha_token: token
            }
            dispatch(createAccount(params))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    const deleteBankAccount = () => {
        const dataObj = {
            bank_account_id: bankAccountId
        }
        dispatch(deleteAccount(dataObj))
    }

    const handleVerify = () => {
        dispatch(getVerificationLink())
    }

    const handleMarkDefault = (row) => {
        const dataObj = {
            bank_account_id: row?.id
        }
        dispatch(markDefaultAccount(dataObj))
    }

    const advSearchColumns = [
        {
            name: "Account Title",
            selector: "account_holder_name",
            minWidth: "180px"
        },
        {
            name: "Bank Name",
            selector: "bank_name",
            minWidth: "120px"
        },
        {
            name: "Account Number ",
            selector: "last4",
            minWidth: "120px"
        },
        {
            name: "Routing Number",
            selector: "routing_number",
            minWidth: "70px"
        },
        {
            name: "Status",
            selector: "status",
            minWidth: "50px",
            cell: (row) => {
                return (
                    <>
                        {row.status === "verified" ? <Badge color="success" className="ml-1">Verified</Badge> : <Badge color="warning" className="ml-1">New</Badge>}
                    </>
                )
            }
        },
        {
            name: "Default Account",
            minWidth: "50px",
            cell: (row) => {
                return (
                    <>
                        {row.is_default === true ? (
                            <Badge color="success" className="ml-1">Yes</Badge>
                        ) : (
                            <Badge color="primary" className="ml-1">
                                {rdAccountMarkDefLoading ? <Spinner style={{width: '12px', height: '12px'}}/> : 'No'}
                            </Badge>
                        )}
                    </>
                )
            }
        },
        {
            name: "Actions",
            allowOverflow: true,
            cell: (row) => {
                return (
                    <div className="d-flex pl-2">
                        <UncontrolledDropdown>
                            <DropdownToggle className="pr-1 cursor-pointer" tag="span">
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {row.is_default !== true && (
                                    <DropdownItem onClick={() => {
                                        handleMarkDefault(row)
                                    }}>
                                        <span className='align-middle ml-50'>Set Default</span>
                                    </DropdownItem>
                                )}
                                <DropdownItem onClick={() => {
                                    setDeleteBankAccountModal(true)
                                    setBankAccountId(row.id)
                                }}>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            {verificationStatus && (
                <Alert color={verificationStatus === 'success' ? 'success' : 'danger'} isOpen={true}>
                    <div className='alert-body'>
                        {verificationStatus === 'success' ? 'Your strip account has been verified successfully.' : 'Account verification failed. Please try again.'}
                    </div>
                </Alert>
            )}

            {rdAccountDeleteFullFill?.status && (
                <Alert color="success" isOpen={true}>
                    <div className='alert-body'>
                        {rdAccountDeleteFullFill?.msg}
                    </div>
                </Alert>
            )}

            {rdAccountCreateFullFill?.status && (
                <Alert color="success" isOpen={true}>
                    <div className='alert-body'>
                        {rdAccountCreateFullFill?.msg}
                    </div>
                </Alert>
            )}

            {(rdAccountCreateFullFill?.status === false || rdAccountCreateFullFill?.success === false) && (
                <Alert color="danger" isOpen={true}>
                    <div className='alert-body'>
                        {rdAccountCreateFullFill?.msg}
                    </div>
                </Alert>
            )}

            {rdAccountDeleteError?.response?.status && (
                <Alert color="danger" isOpen={true}>
                    <div className='alert-body'>
                        {rdAccountDeleteError?.response?.data?.msg}
                    </div>
                </Alert>
            )}

            {rdAccountMarkDefFullFill?.status && (
                <Alert color="success" isOpen={true}>
                    <div className='alert-body'>
                        {rdAccountMarkDefFullFill?.msg}
                    </div>
                </Alert>
            )}

            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Add Bank Account</CardTitle>
                </CardHeader>
                <CardBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            country_id: "",
                            account_holder_name: "",
                            account_number: "",
                            routing_number: ""
                        }}
                        validationSchema={yup.object().shape({
                            country_id: yup.string().required("This field is required."),
                            account_holder_name: yup
                                .string()
                                .min(3, 'Must be at least 3 characters')
                                .max(255, 'Must be at most 255 characters')
                                .required("This field is required."),
                            account_number: yup.string().required("This field is required."),
                            routing_number: yup.string().required("This field is required.")
                        })}
                        onSubmit={(values) => {
                            addBankAccount(values)
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="nameMulti">Country</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                type="select"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.country_id}
                                                name={`country_id`}
                                                id="country_id"
                                                disabled={rdAccountCreateLoading}
                                            >
                                                <option value="">Select Country</option>
                                                {paymentSettingsSlice?.countries &&
                                                    paymentSettingsSlice?.countries.map((country) => (
                                                        <option value={country.id}>{country.label}</option>
                                                    ))}
                                            </Input>

                                            <div className="validation-err">
                                                {touched.country_id && errors.country_id}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="lastNameMulti">Account Holder's Name</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.account_holder_name}
                                                name={`account_holder_name`}
                                                type="text"
                                                id="account_holder_name"
                                                placeholder="Account Holder Name"
                                                disabled={rdAccountCreateLoading}
                                            />
                                            <div className="validation-err">
                                                {touched.account_holder_name &&
                                                    errors.account_holder_name}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="cityMulti">Account Number</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.account_number}
                                                name={`account_number`}
                                                type="text"
                                                id="account_number"
                                                placeholder="Account Number"
                                                disabled={rdAccountCreateLoading}
                                            />
                                            <div className="validation-err">
                                                {touched.account_number && errors.account_number}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="CountryMulti">Routing Number</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.routing_number}
                                                name={`routing_number`}
                                                type="text"
                                                id="routing_number"
                                                placeholder="Routing Number"
                                                disabled={rdAccountCreateLoading}
                                            />
                                            <div className="validation-err">
                                                {touched.routing_number && errors.routing_number}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <FormGroup className="text-right mt-3">
                                            <Button.Ripple
                                                type="submit"
                                                color="primary"
                                                disabled={rdAccountCreateLoading}
                                            >
                                                {rdAccountCreateLoading ? <Spinner style={{width: '12px', height: '12px'}}/> : 'Submit'}
                                            </Button.Ripple>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Stripe Account</CardTitle>
                </CardHeader>
                <CardBody>
                    {rdAccountDetailsLoading ? (
                        <div className="w-100 my-4 text-center">
                            <Spinner color="primary"/>
                        </div>
                    ) : (
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Account ID</th>
                                <th>Account Type</th>
                                <th>Account Status</th>
                                <th>card Payments</th>
                                <th>Transfers</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rdAccountDetailsFullFill?.account_info?.account_id ? (
                                <tr>
                                    <td>{rdAccountDetailsFullFill?.account_info?.account_id || 'N/A'}</td>
                                    <td className="text-capitalize">{rdAccountDetailsFullFill?.account_info?.account_type || 'N/A'}</td>
                                    <td>
                                        {rdAccountDetailsFullFill?.account_info?.account_status === 'Verified' ? (
                                            <div>
                                                <Badge color='success'>
                                                    <span className='align-middle'>Verified</span>
                                                </Badge>
                                            </div>
                                        ) : (
                                            <div>
                                                <Badge color='danger'>
                                                    <span className='align-middle'>Pending</span>
                                                </Badge>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {rdAccountDetailsFullFill?.account_info?.card_payments === 'Inactive' ? (
                                            <div>
                                                <Badge color='danger'>
                                                    <span className='align-middle'>
                                                        {rdAccountDetailsFullFill?.account_info?.card_payments}
                                                    </span>
                                                </Badge>
                                            </div>
                                        ) : (
                                            <div>
                                                <Badge color='success'>
                                                    <span className='align-middle'>
                                                        {rdAccountDetailsFullFill?.account_info?.card_payments}
                                                    </span>
                                                </Badge>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {rdAccountDetailsFullFill?.account_info?.transfers === 'Inactive' ? (
                                            <div>
                                                <Badge color='danger'>
                                                    <span className='align-middle'>
                                                        {rdAccountDetailsFullFill?.account_info?.transfers}
                                                    </span>
                                                </Badge>
                                            </div>
                                        ) : (
                                            <div>
                                                <Badge color='success'>
                                                    <span className='align-middle'>
                                                        {rdAccountDetailsFullFill?.account_info?.transfers}
                                                    </span>
                                                </Badge>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {rdAccountDetailsFullFill?.account_info?.is_verified ? (
                                            <div className="d-flex pl-2">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="pr-1 cursor-pointer" tag="span">
                                                        {rdAccountVerifyLoading ? <Spinner color="primary" style={{
                                                            width: '12px',
                                                            height: '12px'
                                                        }}/> : <MoreHorizontal size={15}/>}
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <DropdownItem onClick={handleVerify}>
                                                            <span className='align-middle ml-50'>Update Account</span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        ) : (
                                            <div>
                                                <Button id="account-verify-btn" className="btn btn-danger btn-sm"
                                                        onClick={handleVerify} disabled={rdAccountVerifyLoading}>
                                                    {rdAccountVerifyLoading ? <Spinner color="primary" style={{width: '12px', height: '12px'}}/> : 'Click here to verify the account'}
                                                </Button>
                                            </div>)}
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan="6" align="center">There are no records to display</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    )}
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle tag="h4">
                        <span>Attached Bank Accounts</span>
                        {rdAccountDetailsFullFill?.bank_details?.length && rdAccountDetailsLoading === false && (
                            <span className="ml-1 font-small-3 text-danger">(Payment will be received in the default account.)</span>
                        )}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {rdAccountDetailsLoading ? (
                        <div className="w-100 my-4 text-center">
                            <Spinner color="primary"/>
                        </div>
                    ) : (
                        <Row>
                            <Col md="12" sm="12">
                                <DataTable
                                    noHeader
                                    columns={advSearchColumns}
                                    className="react-dataTable"
                                    sortIcon={<ChevronDown size={10}/>}
                                    data={rdAccountDetailsFullFill?.bank_details}
                                />
                            </Col>
                        </Row>
                    )}
                </CardBody>
            </Card>

            <Modal isOpen={deleteBankAccountModal} className='modal-dialog-centered'>
                <ModalHeader>Delete Bank Account</ModalHeader>
                <ModalBody>
                    Are you sure want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' outline onClick={() => setDeleteBankAccountModal(false)}
                            disabled={rdAccountDeleteLoading}>
                        No
                    </Button>
                    <Button color='danger' onClick={deleteBankAccount} disabled={rdAccountDeleteLoading}>
                        {rdAccountDeleteLoading ? <Spinner color="primary" style={{width: '12px', height: '12px'}}/> : 'Yes'}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AdminChangePasswordForm
