// Main
const API_URL = process.env.REACT_APP_BASEURL

// Brand
const BRANDS = {
    NUTRITION_EXPERTS: '[BRAND] NUTRITION_EXPERTS',
    COUNTRIES: '[BRAND] COUNTRIES',
    STATES_BY_COUNTRY_ID: '[BRAND] STATES_BY_COUNTRY_ID',
    GOALS_ATTRIBUTES: '[BRAND] GOALS_ATTRIBUTES'
}

// NUll
const NULL = {
    NUTRITION_EXPERTS: '[BRAND] [NULL] NUTRITION_EXPERTS',
    COUNTRIES: '[BRAND] [NULL] COUNTRIES',
    STATES_BY_COUNTRY_ID: '[BRAND] [NULL] STATES_BY_COUNTRY_ID',
    GOALS_ATTRIBUTES: '[BRAND] [NULL] GOALS_ATTRIBUTES'
}

// ERROR
const GENERAL = {
    BRANDS_DASHBOARD_ERROR: '[BRANDS] [ERROR] BRANDS_DASHBOARD'
}

export { API_URL, BRANDS, GENERAL, NULL }
