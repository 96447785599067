import {useState, Fragment, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {
    getBrandsParticipationRequestData,
    getSearchBrandsParticipationRequestData
} from '@src/views/tables/data-tables-admin-manage-events/store/actions'
import Flatpickr from 'react-flatpickr'
import {
    ChevronDown,
    Search,
    RefreshCcw
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardBody,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    Spinner
} from 'reactstrap'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/libs/flatpickr/flatpickr.scss'

const BrandsRequestedForParticipation = () => {
    const [searchCompanyName, setSearchCompanyName] = useState('')
    const [searchBrandOwnerName, setSearchBrandOwnerName] = useState('')
    const [searchRequestDateFrom, setSearchRequestDateFrom] = useState('')
    const [searchRequestDateTo, setSearchRequestDateTo] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)

    const participationSlice = useSelector(store => store.dataTablesAdminBrands)
    const participations = useSelector(store => store.datatablesAdminManageEvents)

    const dispatch = useDispatch()
    const {id} = useParams()

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Company Name',
            selector: 'company_name',
            sortable: true,
            minWidth: '90px'
        },
        {
            name: 'Brand Owner Name',
            selector: 'brand_owner_name',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Event Name',
            selector: 'event_name',
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Request Date',
            selector: 'request_date',
            sortable: true,
            minWidth: '50px'
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '70px'
        }
    ])

    useEffect(() => {
        const purchaseMembership = participations?.purchaseMembership ? participations?.purchaseMembership : null
        if (purchaseMembership) {
            const {links, meta, pages} = purchaseMembership
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }
    }, [participationSlice, participations])

    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getBrandsParticipationRequestData())
    }, [])


    const searchParticipationRequest = () => {
        setIsTableLoading(true)
        // Event From date
        const eventFrom = new Date(searchRequestDateFrom)
        const finalEventFrom = `${eventFrom.getMonth() + 1}-${eventFrom.getDate()}-${eventFrom.getFullYear()}`
        // Event To date
        const eventTo = new Date(searchRequestDateTo)
        const finalEventTo = `${eventTo.getMonth() + 1}-${eventTo.getDate()}-${eventTo.getFullYear()}`

        dispatch(getSearchBrandsParticipationRequestData(false, {
            brand_name: searchCompanyName,
            owner_name: searchBrandOwnerName,
            from_date: finalEventFrom === 'NaN-NaN-NaN' ? "" : finalEventFrom,
            to_date: finalEventTo === 'NaN-NaN-NaN' ? "" : finalEventTo
        }))
    }

    const refreshParticipationRequest = () => {
        setSearchCompanyName('')
        setSearchBrandOwnerName('')
        setSearchRequestDateFrom('')
        setSearchRequestDateTo('')
        setIsTableLoading(true)
        dispatch(getBrandsParticipationRequestData())
    }
    // ** Function to handle Pagination
    const handlePagination = page => setCurrentPage(page.selected)

    // ** Table data to render
    const dataToRender = () => {
        if (!!participations?.participationRequest?.data?.length) {
            return participations?.participationRequest?.data
        }
    }

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)

        const eventFrom = new Date(searchRequestDateFrom)
        const finalEventFrom = `${eventFrom.getMonth() + 1}-${eventFrom.getDate()}-${eventFrom.getFullYear()}`
        // Event To date
        const eventTo = new Date(searchRequestDateTo)
        const finalEventTo = `${eventTo.getMonth() + 1}-${eventTo.getDate()}-${eventTo.getFullYear()}`

        dispatch(getBrandsParticipationRequestData(true, {
            brand_name: searchCompanyName,
            owner_name: searchBrandOwnerName,
            from_date: finalEventFrom === 'NaN-NaN-NaN' ? "" : finalEventFrom,
            to_date: finalEventTo === 'NaN-NaN-NaN' ? "" : finalEventTo
        }, pageUrl))
    }

    return (
        <Fragment>
            <Card className="mb-0">
                <CardBody className="">
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Company Name:</Label>
                                <Input id='name' placeholder='Dietitian Name'
                                       value={searchCompanyName}
                                       onChange={(e) => setSearchCompanyName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Brand Owner Name:</Label>
                                <Input id='name' placeholder='Event Name'
                                       value={searchBrandOwnerName}
                                       onChange={(e) => setSearchBrandOwnerName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Request Date From:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    value={searchRequestDateFrom}
                                    onChange={date => setSearchRequestDateFrom(date)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Request Date To:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    value={searchRequestDateTo}
                                    onChange={date => setSearchRequestDateTo(date)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="admin-filter-actions ml-auto text-right">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchParticipationRequest}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshParticipationRequest}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <DataTable
                        noHeader
                        paginationServer
                        columns={advSearchColumns}
                        paginationPerPage={7}
                        className='react-dataTable'
                        sortIcon={<ChevronDown size={10}/>}
                        paginationDefaultPage={currentPage + 1}
                        data={dataToRender()}
                    />
                )}
                <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
            </Card>
        </Fragment>
    )
}

export default BrandsRequestedForParticipation
