import axios from 'axios'
import { API_URL, BRANDS, GENERAL } from '../actionType'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getGoalsAttributes = () => {
  return dispatch => {
    axios.get(`${API_URL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then((res) => {
      dispatch({ type: BRANDS.GOALS_ATTRIBUTES, goalsAttributes: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.BRANDS_DASHBOARD_ERROR, error })
    })
  }
}

export const getCountries = () => {
  return dispatch => {
    axios.get(`${API_URL}get-countries`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({ type: BRANDS.COUNTRIES, countries: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.BRANDS_DASHBOARD_ERROR, error })
    })
  }
}

export const getStates = ({ id }) => {
  return dispatch => {
    axios.post(`${API_URL}get-states`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      country_id: id
    }).then((res) => {
      dispatch({ type: BRANDS.STATES_BY_COUNTRY_ID, states: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.BRANDS_DASHBOARD_ERROR, error })
    })
  }
}

export const getNutritionExperts = (isPagination, pageUrl = '') => {
  let endpoint = ''
  isPagination ? endpoint = pageUrl : endpoint = `${API_URL}brand/nutrition-expert`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then((res) => {
      dispatch({ type: BRANDS.NUTRITION_EXPERTS, nutritionExperts: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.BRANDS_DASHBOARD_ERROR, error })
    })
  }
}

export const searchNutritionExperts = ({ speciality, industry, personal_attr, gender, country, region, state, name }) => {
  return dispatch => {
    axios.post(`${API_URL}brand/nutrition-expert`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`
      },
      speciality,
      industry,
      personal_attr,
      gender,
      country,
      region,
      state,
      name
    }).then((res) => {
      dispatch({ type: BRANDS.NUTRITION_EXPERTS, nutritionExperts: res.data })
    }).catch(error => {
      dispatch({ type: GENERAL.BRANDS_DASHBOARD_ERROR, error })
    })
  }
}


// ** GET Products
// export const getProducts = params => {
//   return dispatch => {
//     return dispatch({ type: 'GET_PRODUCTS', data: [], params: null })
//     // return axios.get('/apps/nutritionExpertsBrands/products', { params }).then(res => {
//     //   dispatch({ type: 'GET_PRODUCTS', data: res.data, params })
//     // })
//   }
// }

// ** Add Item to Cart
// export const addToCart = id => {
//   return (dispatch, getState) => {
//     return axios.post('/apps/nutritionExpertsBrands/cart', { productId: id }).then(res => {
//       dispatch({ type: 'ADD_TO_CART', data: res.data })
//       dispatch(getProducts(getState().ecommerce.params))
//     })
//   }
// }

// ** GET Wishlist Items
// export const getWishlistItems = () => {
//   return dispatch => {
//     return axios.get('/apps/nutritionExpertsBrands/wishlist').then(res => {
//       dispatch({ type: 'GET_WISHLIST', data: res.data })
//     })
//   }
// }

// ** DELETE Wishlist Item
// export const deleteWishlistItem = id => {
//   return dispatch => {
//     return axios.delete(`/apps/nutritionExpertsBrands/wishlist/${id}`).then(res => {
//       dispatch({ type: 'DELETE_WISHLIST_ITEM', data: res.data })
//       dispatch(getWishlistItems())
//     })
//   }
// }

// ** GET Cart Items
// export const getCartItems = () => {
//   return dispatch => {
//     return axios.get('/apps/nutritionExpertsBrands/cart').then(res => {
//       dispatch({ type: 'GET_CART', data: res.data })
//     })
//   }
// }

// ** GET Single Product
// export const getProduct = slug => {
//   return dispatch => {
//     return dispatch({ type: 'GET_PRODUCT', data: [] })
//     // return axios.get(`/apps/nutritionExpertsBrands/products/${slug}`).then(res => {
//     //   dispatch({ type: 'GET_PRODUCT', data: res.data })
//     // })
//   }
// }

// ** Add Item to Wishlist
// export const addToWishlist = id => {
//   return dispatch => {
//     return axios.post('/apps/nutritionExpertsBrands/wishlist', { productId: id }).then(() => {
//       dispatch({ type: 'ADD_TO_WISHLIST' })
//     })
//   }
// }

// ** DELETE Cart Items
// export const deleteCartItem = id => {
//   return dispatch => {
//     return axios.delete(`/apps/nutritionExpertsBrands/cart/${id}`).then(res => {
//       dispatch({ type: 'DELETE_CART_ITEM', data: res.data })
//       dispatch(getCartItems())
//     })
//   }
// }
