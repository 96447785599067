import {useState, Fragment, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
    getFaqData,
    getFaqSearchData,
    createNewFaq,
    updateFaqData,
    deleteFaqData,
    updateFaqStatusData
} from '@src/views/tables/data-tables-admin-faqs/store/actions'

import {
    ChevronDown,
    Search,
    RefreshCcw,
    MoreHorizontal,
    Edit,
    Trash
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    CustomInput,
    DropdownItem,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner, Alert
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const Faqs = () => {
    const [readMoreModal, setReadMoreModal] = useState(false)
    const [qaValue, setQAValue] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [editFAQModal, setEditFAQModal] = useState(false)
    const [questionValue, setQuestionValue] = useState('')
    const [answerValue, setAnswerValue] = useState('')
    const [idValue, setIdValue] = useState('')
    const [deleteFAQModal, setDeleteFAQModal] = useState(false)
    const [show, setShow] = useState(false)
    const [toastHeaderTitle, setToastHeaderTitle] = useState('')
    const [toastMessage, setToastMessage] = useState('')
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)

    const faqSelector = useSelector(store => store.adminFaqsData)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!faqSelector?.faqsData) {
            const {links, meta, pages} = faqSelector.faqsData
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }
    }, [faqSelector])
    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getFaqData())
    }, [])


    const handleReadMoreClick = (e, colName, cellValue) => {
        setQAValue(cellValue)
        setModalTitle(colName)
        setReadMoreModal(true)
    }

    const handleEditFAQ = (question, answer, id) => {
        setIdValue(id)
        setQuestionValue(question)
        setAnswerValue(answer)
        setEditFAQModal(true)
    }

    const handleDeleteFAQ = (id) => {
        setIdValue(id)
        setDeleteFAQModal(true)
    }
    const changeStatus = (id) => {
        const params = {
            id
        }
        dispatch(updateFaqStatusData(params))
        setToastHeaderTitle("Stauts Success")
        setToastMessage("Status changed successfully.")
        setShow(true)
    }
    // ** States
    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Question',
            selector: 'question',
            sortable: true,
            minWidth: '340px',
            maxWidth: '350px',
            wrap: false,
            allowOverflow: false,
            omit: false,
            conditionalCellStyles: [
                {
                    when: row => row.reason !== '',
                    style: {
                        height: 'auto !important'
                    }
                }
            ],
            cell: row => {
                return (
                    <div>
                        <div className="text-truncate" style={{maxWidth: '250px'}}>{row.question}</div>
                        <button onClick={e => handleReadMoreClick(e, 'Question', row.question)} className='btn btn-link p-0'>
                            Read More
                        </button>
                    </div>
                )
            }
        },
        {
            name: 'Answer',
            selector: 'answer',
            sortable: true,
            minWidth: '380px',
            maxWidth: '440px',
            wrap: false,
            allowOverflow: false,
            omit: false,
            conditionalCellStyles: [
                {
                    when: row => row.reason !== '',
                    style: {
                        height: 'auto !important'
                    }
                }
            ],
            cell: row => {
                return (
                    <div>
                        <div className="text-truncate" style={{maxWidth: '320px'}}>{row.answer}</div>
                        <button onClick={e => handleReadMoreClick(e, 'Answer', row.answer)} className='btn btn-link p-0'>
                            Read More
                        </button>
                    </div>
                )
            }
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '50px',
            cell: row => {
                return (
                    <CustomInput
                        className='custom-control-success'
                        type='switch'
                        id={`success_${row.id}`}
                        name='success'
                        inline
                        defaultChecked={row.status === 'enabled'}
                        onChange={() => changeStatus(row.id)}
                    />
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => handleEditFAQ(row.question, row.answer, row.id)}>
                                    <Edit size={15}/>
                                    <span className='align-middle ml-50'>Edit</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDeleteFAQ(row.id)}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])
    const [searchQuestion, setSearchQuestion] = useState('')
    const [searchAnswer, setSearchAnswer] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [addFAQModal, setAddFAQModal] = useState(false)

    // ** Table data to render
    const dataToRender = () => {
        if (
            faqSelector?.searchFaqsData?.length
        ) {
            return faqSelector.searchFaqsData
        } else {
            return faqSelector?.faqsData?.data
        }
    }

    const createFaq = () => {
        const params = {
            question: questionValue,
            answer: answerValue
        }
        dispatch(createNewFaq(params))
        setAddFAQModal(!addFAQModal)
        setToastHeaderTitle("Create Success")
        setToastMessage("Record created successfully.")
        setShow(true)
        dispatch(getFaqData())
        setIsTableLoading(true)
        dispatch(getFaqData())
        setQuestionValue('')
        setAnswerValue('')
        setTimeout(() => {
            setShow(false)
            setToastHeaderTitle("")
            setToastMessage("")
        }, 5000)
    }

    const updateFaq = () => {
        const params = {
            id: idValue,
            question: questionValue,
            answer: answerValue
        }
        dispatch(updateFaqData(params))
        setEditFAQModal(!editFAQModal)
        setToastHeaderTitle("Update Success")
        setToastMessage("Record updated successfully.")
        setShow(true)
        dispatch(getFaqData())
        setIsTableLoading(true)
        dispatch(getFaqData())
        setTimeout(() => {
            setShow(false)
            setToastHeaderTitle("")
            setToastMessage("")
        }, 5000)
        setQuestionValue('')
        setAnswerValue('')
    }

    const deleteFaq = () => {
        const params = {
            id: idValue
        }
        dispatch(deleteFaqData(params))
        setDeleteFAQModal(!deleteFAQModal)
        setToastHeaderTitle("Delete Success")
        setToastMessage("Record deleted successfully.")
        setShow(true)
        setTimeout(() => {
            setShow(false)
            setToastHeaderTitle("")
            setToastMessage("")
        }, 5000)
        dispatch(getFaqData())
        setIsTableLoading(true)
        dispatch(getFaqData())
    }

    const searchFaq = () => {
        setIsTableLoading(true)
        dispatch(getFaqSearchData(false, {
            question: searchQuestion,
            answer: searchAnswer
        }))
    }

    const refreshSearchForm = () => {
        setSearchQuestion('')
        setSearchAnswer('')
        setIsTableLoading(true)
        dispatch(getFaqData())
    }

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        dispatch(getFaqSearchData(true, {
            question: searchQuestion,
            answer: searchAnswer
        }, pageUrl))
    }

    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            searchFaq()
        }
    }

    return (
        <Fragment>
            <Card className="brands-my-campaign-table-parent">
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <CardTitle className="my-1" tag='h4'>FAQ's</CardTitle>
                    <Button.Ripple className="my-1" color='primary' onClick={() => setAddFAQModal(!addFAQModal)}>Add FAQ</Button.Ripple>
                    <Modal isOpen={addFAQModal} toggle={() => setAddFAQModal(!addFAQModal)} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setAddFAQModal(!addFAQModal)}>Add FAQ</ModalHeader>
                        <ModalBody>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor={`question`}>
                                    Question
                                </Label>
                                <Input
                                    type='text'
                                    name={`question`}
                                    id={`question`}
                                    placeholder='Question'
                                    value={questionValue}
                                    onChange={(e) => setQuestionValue(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor={`answer`}>
                                    Answer
                                </Label>
                                <Input type='textarea'
                                       name='answer' id='answer'
                                       rows='3'
                                       placeholder='Answer'
                                       value={answerValue}
                                       onChange={(e) => setAnswerValue(e.target.value)}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' onClick={createFaq}>
                                Submit
                            </Button>
                        </ModalFooter>
                    </Modal>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Question</Label>
                                <Input id='name' placeholder='Question' value={searchQuestion} onChange={(e) => setSearchQuestion(e.target.value)} onKeyDown={onEnterSearch}/>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Answer</Label>
                                <Input id='name' placeholder='Answer' value={searchAnswer} onChange={(e) => setSearchAnswer(e.target.value)} onKeyDown={onEnterSearch}/>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <div className="admin-filter-actions inline-direction">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchFaq}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchForm}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>

                    <Alert color={'success'} isOpen={show} className="mt-2">
                        <div className='alert-body'>
                            {toastMessage}
                        </div>
                    </Alert>

                </CardBody>
                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={currentPage + 1}
                            data={dataToRender()}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
            </Card>
            <Modal isOpen={readMoreModal} toggle={() => setReadMoreModal(!readMoreModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setReadMoreModal(!readMoreModal)}>{modalTitle}</ModalHeader>
                <ModalBody>
                    {qaValue}
                </ModalBody>
            </Modal>

            <Modal isOpen={editFAQModal} toggle={() => setEditFAQModal(!editFAQModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setEditFAQModal(!editFAQModal)}>Edit FAQ</ModalHeader>
                <ModalBody>
                    <FormGroup tag={Col} md='12'>
                        <Label htmlFor={`question`}>
                            Question
                        </Label>
                        <Input
                            type='text'
                            value={questionValue}
                            name={`question`}
                            id={`question`}
                            placeholder='Question'
                            onChange={(e) => setQuestionValue(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='12'>
                        <Label htmlFor={`answer`}>
                            Answer
                        </Label>
                        <Input type='textarea'
                               name='answer' id='answer'
                               value={answerValue}
                               rows='3'
                               placeholder='Answer'
                               onChange={(e) => setAnswerValue(e.target.value)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={updateFaq}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={deleteFAQModal} toggle={() => setDeleteFAQModal(!deleteFAQModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setDeleteFAQModal(!deleteFAQModal)}>Delete FAQ</ModalHeader>
                <ModalBody>
                    Are you sure want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' outline onClick={() => setDeleteFAQModal(!deleteFAQModal)}>
                        No
                    </Button>
                    <Button color='primary' onClick={deleteFaq}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
            {/*<Toast isOpen={show} style={{*/}
            {/*    position: 'absolute',*/}
            {/*    top: 0,*/}
            {/*    right: 0*/}
            {/*  }} className="bg-success">*/}
            {/*  <ToastHeader>{toastHeaderTitle}</ToastHeader>*/}
            {/*  <ToastBody> {toastMessage} </ToastBody>*/}
            {/*</Toast>*/}
        </Fragment>
    )
}

export default Faqs

