import React, {useEffect, useState} from "react"
import {Link, useParams} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardText,
    CardBody,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner
} from "reactstrap"
import img1 from "@src/assets/images/avatars/1.png"
import Avatar from "@components/avatar"
import RequestsForParticipation from "@src/views/admin/manageBrands/requestsForParticipation"
import {getBrandProfileDetail} from "@src/views/tables/data-tables-admin-brands/store/actions"

const ViewBrandProfile = () => {
    const [activeTab, setActiveTab] = useState("1")
    const [approveModal, setApproveModal] = useState(false)
    const [profileInfo, setProfileInfo] = useState(null)
    const [isPageLoading, setIsPageLoading] = useState(true)

    const {id} = useParams()
    const brandProfileSelector = useSelector(
        (store) => store.dataTablesAdminBrands
    )
    const dispatch = useDispatch()
    useEffect(() => {
        if (!!brandProfileSelector?.brandProfile) {
            setProfileInfo(brandProfileSelector?.brandProfile)
            setIsPageLoading(false)
        }
    }, [brandProfileSelector])
    useEffect(() => {
        setIsPageLoading(true)
        dispatch(getBrandProfileDetail(id))
    }, [])

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (
        <div className="view-page">
            <Link to="/admin/manage-brands" className='btn btn-primary mb-1'>Back</Link>
            {isPageLoading && (
                <div className="w-100 my-4 text-center">
                    <Spinner color="primary"/>
                </div>
            )}
            {!isPageLoading && (
                <>
                    <Row className="match-height">
                        <Col lg="4">
                            <Card className="ecommerce-card view-card">
                                <div className="item-img text-center mx-auto mt-3">
                                    <Link to="#">
                                        <Avatar
                                            img={brandProfileSelector?.brandProfile?.profile_image || img1}
                                            imgHeight="120"
                                            imgWidth="120"
                                        />
                                    </Link>
                                </div>
                                <CardBody className="text-center">
                                    <h4 className="item-name font-weight-bolder">
                                        {brandProfileSelector?.brandProfile?.name}
                                    </h4>
                                    <h5 className="item-name font-weight-bolder">
                                        {brandProfileSelector?.brandProfile?.email}
                                    </h5>
                                    <div className="healthcare blue-bg-500">
                                        <p className="general-wellness font-weight-bolder blue-700">
                                            {brandProfileSelector?.brandProfile?.attributes?.category[0]?.value || "N/A"}
                                            {" "}|{" "}
                                            {brandProfileSelector?.brandProfile?.attributes?.values[0]?.value || "N/A"}
                                        </p>
                                    </div>
                                    <div className="text-left location-info mb-1">
                                        <CardText className="item-description d-flex font-weight-bolder">
                                            <div className="w-65">Country</div>
                                            <span className="ml-2 text-primary">
                        {brandProfileSelector?.brandProfile?.location?.country?.value}
                      </span>
                                        </CardText>
                                        <CardText className="item-description d-flex font-weight-bolder">
                                            <div className="w-65">State</div>
                                            <span className="ml-2 text-primary">
                        {brandProfileSelector?.brandProfile?.location?.state && brandProfileSelector?.brandProfile?.location?.state?.map(labe => (
                            <span>{labe.label} &nbsp; </span>
                        ))}
                      </span>
                                        </CardText>
                                        <CardText className="item-description d-flex font-weight-bolder">
                                            <div className="w-65">Website</div>
                                            <span className="ml-2 text-truncate">
                        <a className="text-primary" href={brandProfileSelector?.brandProfile?.website} target="_blank">
                          {brandProfileSelector?.brandProfile?.website}
                        </a>
                      </span>
                                        </CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <Card className="ecommerce-card">
                                <CardHeader>
                                    <h2 className="font-weight-bolder">About</h2>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className="font-weight-bolder">Categories</h5>
                                                <h6 className="">
                                                    {brandProfileSelector?.brandProfile?.attributes?.category && brandProfileSelector?.brandProfile?.attributes?.category?.map((cates) => (
                                                        <span>{cates?.value}, </span>
                                                    ))}
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className="font-weight-bolder">Special Diet</h5>
                                                <h6 className="">
                                                    {brandProfileSelector?.brandProfile?.attributes?.special_diet && brandProfileSelector?.brandProfile?.attributes?.special_diet?.map((cates) => (
                                                        <span>{cates?.value}, </span>
                                                    ))}
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className="font-weight-bolder">Values</h5>
                                                <h6 className="">
                                                    {brandProfileSelector?.brandProfile?.attributes?.values && brandProfileSelector?.brandProfile?.attributes?.values?.map((cates) => (
                                                        <span>{cates?.value}, </span>
                                                    ))}
                                                </h6>
                                            </div>
                                        </CardText>
                                        {/* <CardText className="oppo-detail">
                      <div className="p-25">
                        <h5 className="font-weight-bolder">
                          How did you here about LINK?
                        </h5>
                        <h6 className="">
                          <span>Instagram, </span>
                          <span>Others - unsure</span>
                        </h6>
                      </div>
                    </CardText> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody className="p-0">
                            <Nav tabs className="mb-0">
                                <NavItem>
                                    <NavLink
                                        active={activeTab === "1"}
                                        onClick={() => {
                                            toggle("1")
                                        }}
                                    >
                                        Requests For Participation
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                    <Card>
                        <TabContent className="py-0" activeTab={activeTab}>
                            <TabPane tabId="1">
                                <RequestsForParticipation/>
                            </TabPane>
                        </TabContent>
                    </Card>
                    <Modal
                        isOpen={approveModal}
                        toggle={() => setApproveModal(!approveModal)}
                        className="modal-dialog-centered"
                    >
                        <ModalHeader toggle={() => setApproveModal(!approveModal)}>
                            Approve Profile
                        </ModalHeader>
                        <ModalBody>Are you sure want to approve this profile?</ModalBody>
                        <ModalFooter>
                            <Button
                                color="danger"
                                outline
                                onClick={() => setApproveModal(!approveModal)}
                            >
                                No
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => setApproveModal(!approveModal)}
                            >
                                Yes
                            </Button>
                        </ModalFooter>
                    </Modal>

                </>
            )}
        </div>
    )
}

export default ViewBrandProfile
