import axios from 'axios'
import { API_URL, MANAGE_RD } from '../actionType'

// ** Get Token
const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getManageRDsData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-rd-list`

  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.MANAGE_RD_LISTING,
        manageRDsList: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Search table Data
export const searchManageRDsData = (isPaginate, body, pageUrl = '') => {
    let endpoint = ''
    isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-rd-list`
  return dispatch => {
    axios.post(endpoint, {
      name: body.name,
      email: body.email,
      city: body.city,
      state_id: body.state,
      country_id: body.country,
      region: body.region,
      industry_id: body.industry,
      profile_status: body.status,
      speciality_id: body.speciality_id,
      membership: body.rd_membership
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.MANAGE_RD_LISTING,
        manageRDsList: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Search table Data
export const getCountries = (params) => {
  return dispatch => {
    axios.get(`${API_URL}get-countries`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.COUNTRIES_DATA,
        countries: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Search table Data
export const getStatesById = (country_id) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("country_id", country_id)
    axios.post(`${API_URL}get-states`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.STATES_DATA,
        states: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Search table Data
export const getIndustries = () => {
  return dispatch => {
    axios.get(`${API_URL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.INDUSTRIES_DATA,
        industries: response?.data?.industry
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Delete RD Data
export const deleteManageRDsData = (id) => {
  return async dispatch => {
    await axios.get(`${API_URL}admin/delete-rd?id=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.DELETE_MANAGE_RD_GET_DATA,
        deleteRDsData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

export const getSpecialities = () => {
  return async dispatch => {
    await axios.get(`${API_URL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.SPECIALITIES_DATA,
        specialities: response.data.speciality
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Delete RD Data
export const deleteResponseManageRDsData = () => {
  return async dispatch => {
    dispatch({
      type: MANAGE_RD.DELETE_RD_RESPONSE,
      deleteRDsResponse: null
    })
  }
}

// ** Delete RD Data
export const updateManageRDsStatus = (params) => {
  return async dispatch => {
    await axios.post(`${API_URL}admin/update-rd-status`, params, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.UPDATE_RD_STATUS,
        changeRDsStatus: null
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })

  }
}

// ** Manage RD Profile
export const getRDProfileInfo = (id) => {
  return async dispatch => {
    await axios.get(`${API_URL}admin/view-rd-details/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.PROFILE_INFO,
        profileInfo: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Manage RD Profile - Event List
export const getRDsEventList = (id, isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-event-list?user_id=${id}`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.EVENT_LIST,
        rdsEventList: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Search RDs Event List
export const searchRDsEventList = (userId, params, isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = `${pageUrl}&user_id=${userId}` : endpoint = `${API_URL}admin/load-event-list?user_id=${userId}`
  return dispatch => {
    const formData = new FormData()
    formData.append("rd_name", params.rd_name)
    formData.append("event_name", params.event_name)
    formData.append("from_date", params.from_date)
    formData.append("to_date", params.to_date)
    formData.append("type", params.type)
    formData.append("city", params.city)
    formData.append("state_id", params.state_id)
    formData.append("country_id", params.country_id)
    axios.post(endpoint, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.EVENT_LIST,
        rdsEventList: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Manage RD Profile - Brands Who Purchased Membership
export const getBrandsWhoPurchasedMembership = () => {
  return dispatch => {
    axios.get(`${API_URL}admin/load-partnership-purchased`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.BRANDS_WHO_PURCHASED_MEMBERSHIP,
        brandsWhoPurchasedMembership: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Manage RD Profile - Search Brands Who Purchased Membership
export const searchBrandsWhoPurchasedMembership = (userId, params, isPagination, pageUrl = '') => {
  let endpoint
  isPagination ? endpoint = `${pageUrl}&user_id=${userId}` : endpoint = `${API_URL}admin/load-partnership-purchased?user_id=${userId}`
  return dispatch => {
    const formData = new FormData()
    formData.append("brand_name", params.brand_name)
    formData.append("owner_name", params.owner_name)
    formData.append("from_date", params.from_date)
    formData.append("to_date", params.to_date)
    axios.post(endpoint, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.BRANDS_WHO_PURCHASED_MEMBERSHIP,
        brandsWhoPurchasedMembership: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Manage RD Profile - Brands Who Requested For Participation
export const getBrandsWhoRequestedForParticipation = (id) => {
  return dispatch => {
    axios.get(`${API_URL}admin/load-participate-request`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.BRANDS_WHO_REQUESTED_FOR_PARTICIPATION,
        brandsWhoRequestedParticipation: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

// ** Manage RD Profile - Search Brands Who Requested For Participation
export const searchBrandsWhoRequestedForParticipation = (userId, params, isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = `${pageUrl}&user_id=${userId}` : endpoint = `${API_URL}admin/load-participate-request?user_id=${userId}`
  return dispatch => {
    const formData = new FormData()
    formData.append("brand_name", params.brand_name)
    formData.append("owner_name", params.owner_name)
    formData.append("request_from", params.request_from)
    formData.append("request_to", params.request_to)
    axios.post(endpoint, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.BRANDS_WHO_REQUESTED_FOR_PARTICIPATION,
        brandsWhoRequestedParticipation: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}

export const nullSearchData = (listName) => {
  return async dispatch => {
    switch (listName) {
      case 'SearchedEventList':
        dispatch({
          type: MANAGE_RD.SEARCHED_EVENT_LIST,
          searchedRDsEventList: null
        })
        break
      case 'SearchedBrandsWithMembership':
        dispatch({
          type: MANAGE_RD.SEARCHED_BRANDS_WHO_PURCHASED_MEMBERSHIP,
          searchedBrandsWhoPurchasedMembership: null
        })
        break
      case 'SearchedBrandsWithParticipationRequest':
        dispatch({
          type: MANAGE_RD.SEARCHED_BRANDS_WHO_REQUESTED_FOR_PARTICIPATION,
          searchedBrandsWhoRequestedParticipation: null
        })
        break
      case 'manageRds':
        dispatch({
          type: 'SEARCH_MANAGE_RD_GET_DATA',
          searchedManageRDsListData: null
        })
        break
      default:
        break
    }

  }
}

export const getExportCsvData = () => {
  return async dispatch => {
    await axios.get(`${API_URL}admin/load-rd-list?data-type=export`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_RD.EXPORT_CSV_DATA,
        exportAllData: response?.data?.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_RD.ERROR,
        error
      })
    })
  }
}