import {useEffect, Fragment} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Avatar from '@components/avatar'
import {
    DollarSign,
    TrendingUp,
    User
} from 'react-feather'
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Col,
    Media,
    Row,
    UncontrolledTooltip
} from 'reactstrap'
import Select from 'react-select'
import {selectThemeColors} from '@utils'
import {getRdDashboardStatsValues} from '@store/actions/rdDashboard'

const FilterByOptions = [
    {value: 'all', label: 'All'},
    {value: 'month', label: 'Month'},
    {value: 'quarter', label: 'Quarter'},
    {value: 'year', label: 'Year'}
]

const DashboardStats = () => {

    const dispatch = useDispatch()
    const {
        rdDashboardStatsLoading,
        rdDashboardStatsFullFill,
        rdDashboardStatsError
    } = useSelector(state => state.rd)

    useEffect(() => {
        dispatch(getRdDashboardStatsValues('all'))
    }, [])

    const filterStats = (filterValue) => {
        dispatch(getRdDashboardStatsValues(filterValue))
    }

    return (
        <Fragment>
            <h3 className="mb-2"> Your Partnerships at a Glance</h3>
            <Card>
                <CardHeader>
                    <CardTitle>Metrics</CardTitle>
                    <Select
                        theme={selectThemeColors}
                        className='react-select w-250px'
                        classNamePrefix='select'
                        defaultValue={FilterByOptions[0]}
                        options={FilterByOptions}
                        isClearable={false}
                        isSearchable={false}
                        onChange={(e) => filterStats(e.value)}
                    />
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="3" sm="3">
                            <Media>
                                <Avatar color="light-primary"
                                        icon={<TrendingUp size={21}/>}
                                        className='mr-1'
                                        id="my-partnerships"
                                />
                                <UncontrolledTooltip placement='top' target="my-partnerships">
                                    Partnerships
                                </UncontrolledTooltip>
                                <Media className='media-avatar m-auto' body>
                                    <h4 className='font-weight-bolder mb-0'>
                                        {rdDashboardStatsLoading ? ("...") : rdDashboardStatsFullFill?.data?.my_partnerships}
                                    </h4>
                                    <h6 className='total-partnership'>My Partnerships</h6>
                                </Media>
                            </Media>
                        </Col>

                        <Col md="3" sm="3">
                            <Media>
                                <Avatar color="light-info"
                                        icon={<User size={21}/>}
                                        className='mr-1'
                                        id="my-brand-partners"
                                />
                                <UncontrolledTooltip placement='top' target="my-brand-partners">
                                    Partners
                                </UncontrolledTooltip>
                                <Media className='media-avatar m-auto' body>
                                    <h4 className='font-weight-bolder mb-0'>
                                        {rdDashboardStatsLoading ? ("...") : rdDashboardStatsFullFill?.data?.my_brand_partners}
                                    </h4>
                                    <h6 className='total-partnership'>My Brand Partners</h6>
                                </Media>
                            </Media>
                        </Col>

                        <Col md="3" sm="3">
                            <Media>
                                <Avatar color="light-danger"
                                        icon={<DollarSign size={21}/>}
                                        className='mr-1'
                                        id="total-revenue"
                                />
                                <UncontrolledTooltip placement='top' target="total-revenue">
                                    Total Revenue
                                </UncontrolledTooltip>
                                <Media className='media-avatar m-auto' body>
                                    <h4 className='font-weight-bolder mb-0'>
                                        {rdDashboardStatsLoading ? ("...") : rdDashboardStatsFullFill?.data?.total_revenue}
                                    </h4>
                                    <h6 className='total-partnership'>Total Revenue</h6>
                                </Media>
                            </Media>
                        </Col>

                        <Col md="3" sm="3">
                            <Media>
                                <Avatar color="light-success"
                                        icon={<DollarSign size={21}/>}
                                        className='mr-1'
                                        id="consumer-impact"
                                />
                                <UncontrolledTooltip placement='top' target="consumer-impact">
                                    Estimated Impressions
                                </UncontrolledTooltip>
                                <Media className='media-avatar m-auto' body>
                                    <h4 className='font-weight-bolder mb-0'>
                                        {rdDashboardStatsLoading ? ("...") : rdDashboardStatsFullFill?.data?.consumer_impact}
                                    </h4>
                                    <h6 className='total-partnership'>Consumer Impact</h6>
                                </Media>
                            </Media>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default DashboardStats
