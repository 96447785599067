import axios from 'axios'
import {ADD_RESOURCE, DELETE_RESOURCE, LIST_RESOURCE, STATUS_RESOURCE} from '../actionsTypes/index'

const API_URL = process.env.REACT_APP_BASEURL

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const index = () => async (dispatch) => {

}

export const addResourceAction = (dataObj) => async (dispatch) => {
    dispatch({type: ADD_RESOURCE.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/add-resource`, dataObj, {headers: headersObj})
        dispatch({type: ADD_RESOURCE.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ADD_RESOURCE.ERROR, payload: error.message})
    }
}

export const addResourceResetAction = () => async (dispatch) => {
    dispatch({type: ADD_RESOURCE.RESET})
}

export const listResourceAction = (dataObj) => async (dispatch) => {
    dispatch({type: LIST_RESOURCE.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/resources`, dataObj, {headers: headersObj})
        dispatch({type: LIST_RESOURCE.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: LIST_RESOURCE.ERROR, payload: error.message})
    }
}

export const statusResourceAction = (dataObj) => async (dispatch) => {
    dispatch({type: STATUS_RESOURCE.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/resource-status`, dataObj, {headers: headersObj})
        dispatch({type: STATUS_RESOURCE.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: STATUS_RESOURCE.ERROR, payload: error.message})
    }
}

export const statusResourceResetAction = () => async (dispatch) => {
    dispatch({type: STATUS_RESOURCE.REQUEST})
}

export const deleteResourceAction = (dataObj) => async (dispatch) => {
    dispatch({type: DELETE_RESOURCE.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}admin/delete-resource`, dataObj, {headers: headersObj})
        dispatch({type: DELETE_RESOURCE.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: DELETE_RESOURCE.ERROR, payload: error.message})
    }
}

export const deleteResourceResetAction = () => async (dispatch) => {
    dispatch({type: DELETE_RESOURCE.RESET})
}