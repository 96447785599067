import { useState, useEffect } from "react"
import DataTable from "react-data-table-component"
import { useDispatch, useSelector } from "react-redux"
import { ChevronDown } from "react-feather"
import {
  Row,
  Col,
  Card,
  Spinner
} from "reactstrap"
import { getRdPartnershipPayments } from "@src/views/apps/invoice/store/actions"
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const PartnershipPayments = () => {
  const [paginationInfo, setPaginationInfo] = useState(null)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const partnershipPaymentsSlice = useSelector((store) => store.invoice)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!partnershipPaymentsSlice?.rdPartnershipPayments) {
      const { links, meta, pages } = partnershipPaymentsSlice?.rdPartnershipPayments
      setPaginationInfo({ ...links, ...meta, pages })
      setIsTableLoading(false)
    }
  }, [partnershipPaymentsSlice])

  useEffect(() => {
    setIsTableLoading(true)
    dispatch(getRdPartnershipPayments())
  }, [])

  const dataToRender = () => {
    if (partnershipPaymentsSlice?.rdPartnershipPayments?.data?.length) {
      return partnershipPaymentsSlice?.rdPartnershipPayments?.data
    }
  }

  const [columns, setColumns] = useState([
    {
      name: "Transaction Id",
      minWidth: "230px",
      selector: "transaction_id",
      sortable: true
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      minWidth: "180px"
    },
    {
      name: "Event Title",
      selector: "event_title",
      sortable: true,
      minWidth: "120px",
      className: "text"
    },
    {
      name: "Brand",
      minWidth: "200px",
      selector: "brand",
      sortable: true
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
      minWidth: "90px"
    },
    {
      name: "Transcation Fee",
      selector: "transaction_fee",
      sortable: true,
      minWidth: "162px"
    },
    // {
    //   name: "Admin Fee",
    //   selector: "admin_fee",
    //   sortable: true,
    //   minWidth: "152px"
    // },
    {
      name: "Total Earn",
      selector: "total_earn",
      sortable: true,
      minWidth: "152px"
    }
  ])

  const handleCustomPagination = pageUrl => {
    setIsTableLoading(true)
    dispatch(getRdPartnershipPayments(true, pageUrl))
  }

  return (
    <Row>
      <Col sm="12" md="12" lg="12">
        <h4 className="mb-2">Partnerships Payment</h4>
        {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary' /></div>)}
        {!isTableLoading && (
          <Card>
            <DataTable
              noHeader
              paginationServer
              columns={columns}
              sortIcon={<ChevronDown />}
              className="react-dataTable"
              // paginationDefaultPage={currentPage + 1}
              data={dataToRender()}
            />
            <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
          </Card>
        )}
      </Col>
    </Row>
  )
}

export default PartnershipPayments
