import Dashboard from '@src/views/rds/dashboard'
import Messages from '@src/views/rds/messages'
import Brands from '@src/views/rds/brands'
import MyBrands from '@src/views/rds/brands/myBrands'
import Favourite from '@src/views/rds/brands/favourite'
import FavouriteDetails from '@src/views/rds/brands/favourite/details'
import MyOpportunities from '@src/views/rds/myOpportunities'
import AddOpportunity from '@src/views/rds/myOpportunities/AddOpportunity'
import EditOpportunity from '@src/views/rds/myOpportunities/EditOpportunity'
import BrandCampaigns from '@src/views/rds/brandCampaigns'
import ApplyCampaign from "@src/views/rds/brandCampaigns/ApplyCampaign"
import Invoices from "@src/views/rds/invoices"
import PendingRDAccountMessage from "@src/views/rds/others/PendingRDAccountMessage"
import RDViewProfile from '@src/views/rds/profile'
import EditProfile from '@src/views/rds/profile/EditProfile'
import PaymentSettings from '@src/views/rds/paymentSettings'
import ManageCards from '@src/views/rds/manageCards'
import VerificationSuccess from '@src/views/rds/paymentSettings/VerificationSuccess'
import VerificationError from '@src/views/rds/paymentSettings/VerificationError'
import ChangePassword from '@src/views/rds/others/ChangePassword'
import ManagePartnerships from '@src/views/rds/managePartnerships'
import PartnershipPayments from '@src/views/rds/partnershipPayments'
import PartnershipFeedback from '@src/views/rds/partnershipFeedback'
import ProvideFeedback from '@src/views/rds/partnershipFeedback/ProvideFeedback'
import BrandProfile from '@src/views/rds/brands/Profile'
import RdNotifications from '@src/views/rds/others/notifications'
import Resources from '@src/views/rds/resources'
import ResourcesContent from '@src/views/rds/resources/Content'
import NotificationsSetting from '@src/views/rds/others/NotificationsSetting'

const RDRoutes = [
    {
        path: '/dashboard/rd',
        component: Dashboard,
        exact: true,
        layout: 'VerticalLayoutRd',
        meta: {
            layout: 'full',
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/rd/apps/chat/chatRd',
        layout: 'VerticalLayoutRd',
        exact: true,
        appLayout: true,
        className: 'chat-application',
        component: Messages,
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'rd'
        }
    },
    {
        path: '/rd/apps/chat/chatRd/:id',
        layout: 'VerticalLayoutRd',
        appLayout: true,
        className: 'chat-application',
        component: Messages,
        meta: {
            layout: 'full',
            action: 'read',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/brands',
        className: 'ecommerce-application',
        component: Brands,
        exact: true,
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/my-brands',
        className: 'ecommerce-application',
        component: MyBrands,
        exact: true,
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/favorite-folders',
        component: Favourite,
        layout: 'VerticalLayoutRd',
        exact: true,
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/favorite-folders/:id/list',
        component: FavouriteDetails,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/opportunities',
        component: MyOpportunities,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/opportunities-rdform',
        component: AddOpportunity,
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/edit-opportunities-rdform/:id',
        component: EditOpportunity,
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/campaigns',
        component: BrandCampaigns,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/campaign-application/:id?',
        component: ApplyCampaign,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/rd/invoice',
        component: Invoices,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/pending-account-msg',
        component: PendingRDAccountMessage,
        layout: 'BlankLayout',
        meta: {
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/view-profile',
        component: RDViewProfile,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/edit-profile',
        component: EditProfile,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/payment-settings',
        component: PaymentSettings,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/manage-cards',
        component: ManageCards,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/verification-success/:token',
        component: VerificationSuccess,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/verification-error/:token',
        component: VerificationError,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/change-password',
        component: ChangePassword,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/partnerships/manage',
        component: ManagePartnerships,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/partnerships/payment',
        component: PartnershipPayments,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/partnerships/feedback',
        component: PartnershipFeedback,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/partnerships/:eventId/provide-event-feedback/:brandId',
        component: ProvideFeedback,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/notifications',
        component: RdNotifications,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/brands/:id',
        className: 'ecommerce-application',
        component: BrandProfile,
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/my-brands/:id',
        className: 'ecommerce-application',
        component: BrandProfile,
        layout: 'VerticalLayoutRd',
        meta: {
            action: "manage",
            resource: "rd"
        }
    },
    {
        path: '/dashboard/rd/resources',
        component: Resources,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/resources-content/:id',
        component: ResourcesContent,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    },
    {
        path: '/dashboard/rd/notification-settings',
        component: NotificationsSetting,
        layout: 'VerticalLayoutRd',
        meta: {
            publicRoute: false,
            action: 'manage',
            resource: 'rd'
        }
    }
]

export default RDRoutes