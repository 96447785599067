import {useState, Fragment, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {MoreHorizontal, Edit, Trash, AlertCircle} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card, CardHeader, CardBody, CardTitle, Input, Label, FormGroup, Col, Button, UncontrolledDropdown, DropdownToggle,
    DropdownMenu, DropdownItem, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Alert, Form, Badge
} from 'reactstrap'
import queryString from "query-string"
import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import * as yup from "yup"
import {Formik} from "formik"
import {
    createInvoiceAction, activeBrandsAction, createInvoiceResetAction, allInvoicesAction, deleteInvoiceAction,
    deleteInvoiceResetAction
} from "@src/views/rds/invoices/store/actions"
import {Link} from "react-router-dom"

const DataTableAdvSearch = () => {
    const formikRef = useRef()
    const dispatch = useDispatch()
    const [deleteModal, setDeleteModal] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [editData, setEditData] = useState(null)

    const {
        createInvoiceLoading,
        createInvoiceData,
        activeBrandsLoading,
        activeBrandsData,
        allInvoicesLoading,
        allInvoicesData,
        deleteInvoiceLoading,
        deleteInvoiceData
    } = useSelector(store => store.invoiceReducer)

    useEffect(() => {
        if (createInvoiceData?.status === true) {
            setAddModal(false)
            dispatch(allInvoicesAction())
            dispatch(activeBrandsAction())
            setEditData(null)
            setTimeout(() => dispatch(createInvoiceResetAction()), 5000)
        }
    }, [createInvoiceData])

    useEffect(() => {
        if (deleteInvoiceData?.status === true) {
            setDeleteModal(false)
            dispatch(allInvoicesAction())
            dispatch(activeBrandsAction())
            setEditData(null)
            setTimeout(() => dispatch(deleteInvoiceResetAction()), 5000)
        }
    }, [deleteInvoiceData])

    useEffect(() => {
        dispatch(allInvoicesAction({}))
        dispatch(activeBrandsAction())
    }, [])

    const handleEdit = (row) => {
        setEditData(row)
        setAddModal(true)
    }

    const handleDelete = (row) => {
        setEditData(row)
        setDeleteModal(true)
    }

    const advSearchColumns = [
        {
            name: 'Brand',
            width: '180px',
            cell: row => {
                return (
                    <p className="text-primary">{row.brand_name}</p>
                )
            }
        },
        {
            name: 'Title',
            selector: 'title',
            width: '250px',
            wrap: true
        },
        {
            name: 'Description',
            selector: 'description',
            width: '350px',
            wrap: true,
            cell: row => {
                return (
                    row?.description?.length > 30 ? (
                        <span title={row?.description} className="cursor-pointer">
                            {row?.description.substring(0, 30)}...
                        </span>
                    ) : row?.description
                )
            }
        },
        {
            name: 'Amount',
            cell: row => {
                return (
                    <p>${row.amount}</p>
                )
            }
        },
        {
            name: 'Paid',
            selector: 'is_paid',
            cell: row => {
                return (
                    row.is_paid === 'No' ? (<Badge color="warning">No</Badge>) : (<Badge color="success">Yes</Badge>)
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2 cursor-pointer'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => handleEdit(row)} disabled={row.is_paid === 'Yes'}>
                                    <Edit size={15}/>
                                    <span className='align-middle ml-50'>Edit</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDelete(row)} disabled={row.is_paid === 'Yes'}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ]

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(allInvoicesAction({
            page: pageNo
        }))
    }

    const createInvoice = (values) => {
        if (editData?.id) {
            dispatch(createInvoiceAction({
                id: editData.id,
                title: values.title,
                description: values.description,
                amount: values.amount,
                send_to: values.sendTo
            }))
        } else {
            dispatch(createInvoiceAction({
                title: values.title,
                description: values.description,
                amount: values.amount,
                send_to: values.sendTo
            }))
        }
    }

    const deleteInvoice = () => {
        if (editData?.id) {
            dispatch(deleteInvoiceAction({
                id: editData?.id
            }))
        }
    }

    const onNoHandle = () => {
        setDeleteModal(false)
        setEditData(null)
    }

    const createInvoiceModal = () => {
        setEditData(null)
        setAddModal(true)
    }
    return (
        <Fragment>
            <Card className="brands-my-campaign-table-parent">
                <CardHeader
                    className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <CardTitle className="my-1" tag='h4'>Invoices</CardTitle>
                    <div>
                        <Button.Ripple className="mr-1" color='primary' onClick={createInvoiceModal} disabled={allInvoicesLoading}>
                            Create an Invoice
                        </Button.Ripple>
                    </div>
                    {allInvoicesData?.is_account === false ? (
                        <Modal isOpen={addModal} className='modal-dialog-centered'>
                            <ModalHeader toggle={() => {
                                setAddModal(false)
                                setEditData(null)
                                dispatch(createInvoiceResetAction())
                            }}>
                                Action Required
                            </ModalHeader>
                            <ModalBody>
                                <p className="text-break">Please add your bank account before creating an invoice.</p>
                                <p className="text-break">Click <Link to="/dashboard/rd/payment-settings">here</Link> to add your bank account.</p>
                            </ModalBody>
                        </Modal>
                    ) : allInvoicesData?.is_activated === false ? (
                        <Modal isOpen={addModal} className='modal-dialog-centered'>
                            <ModalHeader toggle={() => {
                                setAddModal(false)
                                setEditData(null)
                                dispatch(createInvoiceResetAction())
                            }}>
                                Action Required
                            </ModalHeader>
                            <ModalBody>
                                <p className="text-break">Your bank account is not activated yet.</p>
                                <p className="text-break">Click <Link to="/dashboard/rd/payment-settings">here</Link> and activate your bank account before creating an invoice.</p>
                            </ModalBody>
                        </Modal>
                    ) : (
                        <Modal isOpen={addModal} className='modal-dialog-centered'>
                            <ModalHeader toggle={() => {
                                setAddModal(false)
                                setEditData(null)
                                dispatch(createInvoiceResetAction())
                            }}>
                                {editData?.id ? "Update Invoice Info" : "Create an Invoice"}
                            </ModalHeader>
                            <Formik
                                innerRef={formikRef}
                                initialValues={{
                                    title: editData?.title ?? '',
                                    description: editData?.description ?? '',
                                    amount: editData?.amount ?? '',
                                    sendTo: editData?.brand_id ?? ''
                                }}
                                validationSchema={yup.object().shape({
                                    title: yup.string().max(30, 'Max 30 character allowed.').required("This field is required.").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
                                    description: yup.string(),
                                    amount: yup.number().required('This field is required.').typeError('Only numbers.'),
                                    sendTo: yup.number().required('This field is required.')
                                })}
                                onSubmit={(values) => {
                                    createInvoice(values)
                                }}
                            >
                                {({
                                      errors,
                                      handleBlur,
                                      handleChange,
                                      handleSubmit,
                                      touched,
                                      values
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <ModalBody>
                                            {createInvoiceData?.status === false && (
                                                <Alert color='danger' className="mt-2">
                                                    <div className='alert-body'>Please click to verify your bank account before creating the invoice. {<a
                                                        href=" /dashboard/rd/payment-settings " target="_blank">{'Click here'}</a>}</div>
                                                </Alert>
                                            )}
                                            <FormGroup tag={Col} md='12'>
                                                <Label htmlFor="title">Title<span className="text-danger">*</span></Label>
                                                <Input
                                                    type='text'
                                                    name="title"
                                                    id="title"
                                                    placeholder='Title'
                                                    value={values.title}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={createInvoiceLoading}
                                                />
                                                <div className="validation-err">{touched.title && errors.title}</div>
                                            </FormGroup>
                                            <FormGroup tag={Col} md='12'>
                                                <Label htmlFor="description">Description</Label>
                                                <Input
                                                    type='textarea'
                                                    name='description'
                                                    id='description'
                                                    rows='3'
                                                    placeholder='Description'
                                                    value={values.description}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={createInvoiceLoading}
                                                />
                                            </FormGroup>
                                            <FormGroup tag={Col} md='12'>
                                                <Label htmlFor="amount">Amount<span className="text-danger">*</span></Label>
                                                <Input
                                                    type='number'
                                                    name="amount"
                                                    id="amount"
                                                    placeholder='0'
                                                    value={values.amount}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={createInvoiceLoading}
                                                />
                                                <div className="validation-err">{touched.amount && errors.amount}</div>
                                            </FormGroup>
                                            <FormGroup tag={Col} md='12'>
                                                <Label htmlFor="send-to">
                                                    <span>Send To Brand</span>
                                                </Label>
                                                <Input type="select"
                                                       name="sendTo"
                                                       id="send-to"
                                                       value={values.sendTo}
                                                       onBlur={handleBlur}
                                                       onChange={handleChange}
                                                       disabled={createInvoiceLoading || activeBrandsLoading}
                                                >
                                                    <option value="">---Select---</option>
                                                    {activeBrandsData?.data?.map((item, index) => (
                                                        <option value={item.id}
                                                                key={index}>{item.brand_profile_name}</option>
                                                    ))}
                                                </Input>
                                                <div className="validation-err">{touched.sendTo && errors.sendTo}</div>
                                            </FormGroup>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button.Ripple type="submit" color="primary" disabled={createInvoiceLoading}>
                                                {createInvoiceLoading ? (<Spinner color='white' size='sm'/>) : "Submit"}
                                            </Button.Ripple>
                                        </ModalFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Modal>
                    )}
                </CardHeader>
                <CardBody>
                    {createInvoiceData?.status === true && (
                        <Alert color='success' className="mt-2">
                            <div className='alert-body'>{createInvoiceData?.msg}</div>
                        </Alert>
                    )}
                    {deleteInvoiceData?.status === true && (
                        <Alert color='success' className="mt-2">
                            <div className='alert-body'>{deleteInvoiceData?.msg}</div>
                        </Alert>
                    )}
                    {allInvoicesData?.is_account === false ? (
                        <Alert color="danger" isOpen={true} className="mt-2">
                            <div className='alert-body'>
                                <AlertCircle size={15}/>
                                <span className='ml-1'>
                                    Notice! Please add your bank account before creating an invoice.
                                    Click <Link to="/dashboard/rd/payment-settings">here</Link> to add your bank account.
                                </span>
                            </div>
                        </Alert>
                    ) : allInvoicesData?.is_activated === false ? (
                        <Alert color="danger" isOpen={true} className="mt-2">
                            <div className='alert-body'>
                                <AlertCircle size={15}/>
                                <span className='ml-1'>
                                    Notice! Your bank account is not activated yet.
                                    Click <Link to="/dashboard/rd/payment-settings">here</Link> and activate your bank account.
                                </span>
                            </div>
                        </Alert>
                    ) : null}
                </CardBody>
                {allInvoicesLoading ? (
                    <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
                ) : (
                    <>
                        <DataTable columns={advSearchColumns} className='react-dataTable' data={allInvoicesData?.data} noHeader/>
                        <CustomPagination pageLinks={allInvoicesData?.pages}
                                          onPaginationClick={handleCustomPagination}/>
                    </>
                )}
            </Card>

            <Modal isOpen={deleteModal} className='modal-dialog-centered'>
                <ModalHeader>Delete Invoice</ModalHeader>
                <ModalBody>
                    Are you sure want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' outline onClick={onNoHandle} disabled={deleteInvoiceLoading}>
                        No
                    </Button>
                    <Button color='danger' onClick={deleteInvoice} disabled={deleteInvoiceLoading}>
                        {deleteInvoiceLoading ? (<Spinner color='white' size='sm'/>) : "Yes"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default DataTableAdvSearch