import {useState, Fragment, useEffect, useRef} from 'react'
import {Link} from "react-router-dom"
import {useSelector, useDispatch} from 'react-redux'
import {
    ChevronDown,
    Search,
    RefreshCcw,
    MoreHorizontal,
    Edit,
    Trash, Folder
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    CustomInput,
    DropdownItem,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner, Alert, Form
} from 'reactstrap'
import queryString from "query-string"
import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import * as yup from "yup"
import {Formik} from "formik"
import {
    addResourceAction, addResourceResetAction, deleteResourceAction, deleteResourceResetAction,
    listResourceAction,
    statusResourceAction,
    statusResourceResetAction
} from "@src/views/tables/data-tables-admin-resources/store/actions"
import Avatar from "@components/avatar"

const Resources = () => {
    const formikRef = useRef()
    const dispatch = useDispatch()

    const RESOURCE_COVER_MAX_SIZE = 1 * 1024 * 1024
    const RESOURCE_COVER_FORMATS = ["image/png", "image/jpeg", "image/jpg"]
    const [idValue, setIdValue] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)
    const [isAlert, setIsAlert] = useState(false)
    const [resStatus, setResStatus] = useState('')
    const [resMsg, setResMsg] = useState('')
    const [search, setSearch] = useState('')
    const [titleVal, setTitleVal] = useState('')
    const [descVal, setDescVal] = useState('')
    const [addModal, setAddModal] = useState(false)

    const {addResourceLoading, addResourceData, addResourceError} = useSelector(store => store.addResourcesReducer)
    const {listResourceLoading, listResourceData, listResourceError} = useSelector(store => store.listResourcesReducer)
    const {statusResourceLoading, statusResourceData, statusResourceError} = useSelector(store => store.statusResourcesReducer)
    const {deleteResourceLoading, deleteResourceData, deleteResourceError} = useSelector(store => store.deleteResourcesReducer)

    useEffect(() => {
        if (addResourceData?.status === true || addResourceData?.status === false) {
            setIsAlert(true)
            setResStatus(addResourceData?.status)
            setResMsg(addResourceData?.msg)
            setIdValue('')
            setAddModal(false)
            dispatch(listResourceAction())
            dispatch(addResourceResetAction())
            setTimeout(() => setIsAlert(false), 3000)
        }
    }, [addResourceData])

    useEffect(() => {
        if (statusResourceData?.status === true || statusResourceData?.status === false) {
            setIsAlert(true)
            setResStatus(statusResourceData?.status)
            setResMsg(statusResourceData?.msg)
            dispatch(statusResourceResetAction())
            setTimeout(() => setIsAlert(false), 3000)
        }
    }, [statusResourceData])

    useEffect(() => {
        if (deleteResourceData?.status === true || deleteResourceData?.status === false) {
            setIsAlert(true)
            setResStatus(deleteResourceData?.status)
            setResMsg(deleteResourceData?.msg)
            setDeleteModal(false)
            setIdValue("")
            dispatch(deleteResourceResetAction())
            dispatch(listResourceAction())
            setTimeout(() => {
                setIsAlert(false)
            }, 3000)
        }
    }, [deleteResourceData])

    useEffect(() => {
        dispatch(listResourceAction())
    }, [])

    const handleEdit = (id, title, description) => {
        setIdValue(id)
        setTitleVal(title)
        setDescVal(description)
        setAddModal(true)
    }

    const handleDelete = (id) => {
        setIdValue(id)
        setDeleteModal(true)
    }

    const changeStatus = (id, status) => {
        setIsAlert(false)
        dispatch(statusResourceAction({id}))
    }

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Cover',
            selector: 'cover',
            width: '100px',
            wrap: true,
            cell: row => {
                return (
                    row.cover ? <Avatar img={row.cover} imgHeight="50" imgWidth="50" className="avatar-img-fit-contain"/> : <Folder size="50" style={{color: '#56B6FF'}}/>
                )
            }
        },
        {
            name: 'Title',
            selector: 'title',
            width: '400px',
            wrap: true,
            cell: row => {
                return (
                    <Link to={`/admin/resources-content/${row.id}`}>{row.title}</Link>
                )
            }
        },
        {
            name: 'Description',
            selector: 'description',
            width: '500px',
            wrap: true
        },
        {
            name: 'Items',
            selector: 'all_items'
        },
        {
            name: 'Status',
            selector: 'status',
            cell: row => {
                return (
                    <CustomInput
                        className='custom-control-success'
                        type='switch'
                        id={`status-${row.id}`}
                        name={`status`}
                        inline
                        defaultChecked={row.status === 'enabled'}
                        onChange={() => changeStatus(row.id, row.status)}
                    />
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={Link} to={`/admin/resources-content/${row.id}`}>
                                    <Folder size={15}/>
                                    <span className='align-middle ml-50'>Open</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleEdit(row.id, row.title, row.description)}>
                                    <Edit size={15}/>
                                    <span className='align-middle ml-50'>Edit</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDelete(row.id)}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(listResourceAction({
            title: search,
            page: pageNo
        }))
    }

    const addResource = (values) => {
        const formData = new FormData()

        if (idValue) {
            formData.append('id', idValue)
        }

        if (values.cover_img) {
            formData.append('cover_img', values.cover_img)
        }

        formData.append('title', values.title)
        formData.append('description', values.description)
        dispatch(addResourceAction(formData))
        setTitleVal('')
        setDescVal('')
    }

    const searchWithTitle = () => {
        dispatch(listResourceAction({
            title: search
        }))
    }

    const clearSearch = () => {
        setSearch('')
        dispatch(listResourceAction())
    }

    const deleteFolder = () => {
        dispatch(deleteResourceAction({
            id: idValue
        }))
    }

    const onNoHandle = () => {
        setDeleteModal(!deleteModal)
        setIdValue('')
        setTitleVal('')
        setDescVal('')
    }

    const addNewFolder = () => {
        setAddModal(true)
        setIdValue('')
        setTitleVal('')
        setDescVal('')
    }

    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            searchWithTitle()
        }
    }

    return (
        <Fragment>
            <Card className="brands-my-campaign-table-parent">
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <CardTitle className="my-1" tag='h4'>Resources</CardTitle>
                    <div>
                        <Button.Ripple className="mr-1" color='primary' onClick={addNewFolder}> Add New Folder</Button.Ripple>
                    </div>
                    <Modal isOpen={addModal} toggle={() => setAddModal(!addModal)} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setAddModal(!addModal)}>
                            {idValue ? "Update Folder Info" : "Add New Folder"}
                        </ModalHeader>
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                                title: titleVal,
                                description: descVal
                            }}
                            validationSchema={yup.object().shape({
                                title: yup.string().required("This field is required.").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
                                description: yup.string(),
                                cover_img: yup.mixed()
                                    .test(
                                        "fileFormat",
                                        "Unsupported Format",
                                        value => !value || (value && RESOURCE_COVER_FORMATS.includes(value.type))
                                    )
                                    .test(
                                        "fileSize",
                                        "File too large",
                                        value => !value || (value && value.size <= RESOURCE_COVER_MAX_SIZE)
                                    )
                            })}
                            onSubmit={(values) => {
                                addResource(values)
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  touched,
                                  values,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit}>

                                    <ModalBody>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="title">Title<span className="text-danger">*</span></Label>
                                            <Input
                                                type='text'
                                                name="title"
                                                id="title"
                                                placeholder='Title'
                                                value={values.title}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={addResourceLoading}
                                            />
                                            <div className="validation-err">{touched.title && errors.title}</div>
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="description">Description</Label>
                                            <Input
                                                type='textarea'
                                                name='description'
                                                id='description'
                                                rows='3'
                                                placeholder='Description'
                                                value={values.description}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={addResourceLoading}
                                            />
                                        </FormGroup>
                                        <FormGroup tag={Col} md='12'>
                                            <Label htmlFor="cover-img" className="d-flex justify-content-between">
                                                <span>Cover Image</span>
                                                <span className="text-danger font-weight-bold">Maximum 1MB</span>
                                            </Label>
                                            <CustomInput accept=".png, .jpg, .jpeg"
                                                         type="file"
                                                         className="form-control-file mb-1"
                                                         name="cover_img"
                                                         id="cover-img"
                                                         onBlur={handleBlur}
                                                         onChange={(e) => setFieldValue('cover_img', e.target.files[0])}
                                                         disabled={addResourceLoading}
                                            />
                                            <div className="validation-err">{touched.cover_img && errors.cover_img}</div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button.Ripple type="submit" color="primary" disabled={addResourceLoading}>
                                            {addResourceLoading ? (<Spinner style={{width: '20px', height: '20px'}}/>) : "Submit"}
                                        </Button.Ripple>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='title-search'>Title</Label>
                                <Input id='title-search'
                                       placeholder='Title'
                                       value={search}
                                       onChange={(e) => setSearch(e.target.value)}
                                       onKeyDown={onEnterSearch}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <div className="admin-filter-actions inline-direction">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchWithTitle}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={clearSearch}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                    <Alert color={resStatus ? "success" : "danger"} isOpen={isAlert} className="mt-2" toggle={() => setIsAlert(!isAlert)}>
                        <div className='alert-body'>{resMsg}</div>
                    </Alert>

                </CardBody>
                {listResourceLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!listResourceLoading && (
                    <>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={1}
                            data={listResourceData?.data}
                        />
                        <CustomPagination pageLinks={listResourceData?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
            </Card>
            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>Delete Folder</ModalHeader>
                <ModalBody>
                    Are you sure want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' outline onClick={onNoHandle} disabled={deleteResourceLoading}>
                        No
                    </Button>
                    <Button color='danger' onClick={deleteFolder} disabled={deleteResourceLoading}>
                        {deleteResourceLoading ? (<Spinner size={12}/>) : "Yes"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Resources

