import {useEffect} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import classnames from 'classnames'
import {search, getAllParents} from '@layouts/utils'
import {PropTypes} from "prop-types"

const HorizontalNavMenuLink = ({
                                   item,
                                   setOpenDropdown,
                                   setGroupActive,
                                   activeItem,
                                   setActiveItem,
                                   routerProps,
                                   currentActiveItem,
                                   isChild
                               }) => {
    const LinkTag = item.externalLink ? 'a' : NavLink
    const location = useLocation()
    const currentURL = location.pathname

    const searchParents = (navigation, currentURL) => {
        const parents = search(navigation, currentURL, routerProps)
        const allParents = getAllParents(parents, 'id')
        allParents.pop()
        return allParents
    }

    const resetOpenDropdowns = () => setOpenDropdown([])

    useEffect(() => {
        if (currentActiveItem !== null) {
            setActiveItem(currentActiveItem)
            const arr = searchParents(navigation, currentURL)
            setGroupActive([...arr])
        }
    }, [location])

    return (
        <li className={classnames('nav-item', {active: item.navLink === activeItem, disabled: item.disabled})}>
            <button onClick={resetOpenDropdowns}>
                <LinkTag className={classnames('d-flex align-items-center', {'dropdown-item': isChild, 'nav-link': !isChild})}
                         tag={LinkTag}
                         target={item.newTab ? '_blank' : undefined}
                    /*eslint-disable */
                         {...(item.externalLink === true
                             ? {
                                 href: item.navLink || '/'
                             }
                             : {
                                 to: item.navLink || '/',
                                 isActive: (match, location) => {
                                     if (!match) {
                                         return false
                                     }

                                     if (match.url && match.url !== '' && match.url === item.navLink) {
                                         currentActiveItem = item.navLink
                                     }
                                 }
                             })}
                    /*eslint-enable */
                >
                    {item.icon}<span>{item.title}</span>
                </LinkTag>
            </button>
        </li>
    )
}

HorizontalNavMenuLink.propTypes = {
    item: PropTypes.shape({
        navLink: PropTypes.string.isRequired,
        externalLink: PropTypes.bool,
        newTab: PropTypes.bool,
        disabled: PropTypes.bool,
        icon: PropTypes.node,
        title: PropTypes.string.isRequired
    }).isRequired,
    setOpenDropdown: PropTypes.func.isRequired,
    setGroupActive: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    setActiveItem: PropTypes.func.isRequired,
    routerProps: PropTypes.object.isRequired,
    currentActiveItem: PropTypes.string,
    isChild: PropTypes.bool
}

export default HorizontalNavMenuLink
