import {useState, Fragment, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import Flatpickr from 'react-flatpickr'
import DataTable from 'react-data-table-component'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {
    getManageEventsData,
    changeEventsStatus,
    getSearchEventsData,
    exportManageBrandsData,
    getEventTypes
} from '@src/views/tables/data-tables-admin-manage-events/store/actions'
import {
    getCountries,
    getRegionsByCountryId,
    getStatesByRegionId,
    getCitiesByStateId
} from '@src/redux/actions/auth/index'
import {
    ChevronDown,
    Search,
    RefreshCcw,
    FileText,
    Eye,
    MoreHorizontal
} from 'react-feather'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    CustomInput,
    Toast,
    ToastHeader,
    ToastBody,
    Spinner
} from 'reactstrap'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const ManageEvents = () => {
    const [isLoadingPage, setIsLoadingPage] = useState(true)
    const [listingData, setListingData] = useState([])
    const [paginationInfo, setPaginationInfo] = useState(null)
    const eventsSelector = useSelector(store => store.datatablesAdminManageEvents)
    const authSelector = useSelector(store => store.auth)
    const dispatch = useDispatch()

    useEffect(() => {
        const manageEventsData = eventsSelector?.manageEventsData ? eventsSelector?.manageEventsData : null
        if (manageEventsData) {
            const {links, meta, pages} = manageEventsData
            const data = manageEventsData?.data ? manageEventsData?.data : []
            setPaginationInfo({...links, ...meta, pages})
            setListingData(data)
            setIsLoadingPage(false)
        }
    }, [eventsSelector, authSelector])

    useEffect(() => {
        setIsLoadingPage(true)
        dispatch(getManageEventsData())
        dispatch(getCountries())
        dispatch(exportManageBrandsData())
        dispatch(getEventTypes())

    }, [])

    const [searchRDsName, setSearchRDsName] = useState('')
    const [searchEventName, setSearchEventName] = useState('')
    const [searchEventType, setSearchEventType] = useState('')
    const [searchCity, setSearchCity] = useState('')
    const [searchStartDate, setSearchStartDate] = useState('')
    const [searchEndDate, setSearchEndDate] = useState('')
    const [searchCountry, setSearchCountry] = useState('')
    const [searchRegion, setSearchRegion] = useState('')
    const [searchState, setSearchState] = useState([])
    const [searchStatus, setSearchStatus] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [searchIndustry, setSearchIndustry] = useState('')
    const [show, setShow] = useState(false)
    const [toastHeader, setToastHeader] = useState("")
    const [toastMessage, setToastMessage] = useState("")

    const changeEventDataStatus = (eventId) => {
        const params = {
            id: eventId,
            type: 'manage'
        }
        dispatch(changeEventsStatus(params))
        setToastHeader("Success")
        setToastMessage("Status updated successfully.")
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Rd Name',
            selector: 'organizer',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Event Title',
            selector: 'event_title',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Type of Event',
            selector: 'type_of_event',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Date',
            selector: 'start_date',
            sortable: true,
            minWidth: '170px'
        },
        {
            name: 'End Date',
            selector: 'end_date',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'State',
            selector: 'state',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '70px',
            cell: row => {
                return (
                    <CustomInput
                        className='custom-control-success'
                        type='switch'
                        id={`success_${row.id}`}
                        name='success'
                        inline
                        defaultChecked={row.status === 'enabled'}
                        onClick={() => changeEventDataStatus(row.id)}
                    />
                )
            }
        },

        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={Link} to={`/admin/manage-events/${row.id}`}>
                                    <Eye size={15}/>
                                    <span className='align-middle ml-50'>View</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    const searchEventData = () => {
        const fromDate = new Date(searchStartDate)
        const finalFromDate = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
        const toDate = new Date(searchEndDate)
        const finalToDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        setIsLoadingPage(true)
        dispatch(getSearchEventsData(false, {
            rd_name: searchRDsName,
            event_name: searchEventName,
            type: searchEventType,
            country_id: searchCountry,
            region_id: searchRegion,
            state_id: searchState,
            city_id: searchCity,
            from_date: finalFromDate === "NaN-NaN-NaN" ? "" : finalFromDate,
            to_date: finalToDate === "NaN-NaN-NaN" ? "" : finalToDate
        }))
    }

    const getRegionWithID = (id) => {
        dispatch(getRegionsByCountryId({id}))
    }

    const getStateWithID = (id) => {
        dispatch(getStatesByRegionId({id}))
    }

    const getCityWithID = (statesIds) => {
        dispatch(getCitiesByStateId({statesIds}))
    }

    const refreshFormData = () => {
        setSearchStartDate('')
        setSearchEndDate('')
        setSearchRDsName('')
        setSearchEventName('')
        setSearchCity('')
        setSearchStatus('')
        setSearchCountry('')
        setSearchState('')
        setCurrentPage('')
        setSearchEventType('')
        setSearchIndustry('')
        setSearchRegion('')
        setIsLoadingPage(true)
        dispatch(getManageEventsData())
    }

    const handlePagination = page => setCurrentPage(page.selected)

    function convertArrayOfObjectsToCSV(array) {
        let result = ''
        result += "Event Title, Location, Start Date, End Date, Type Of Event, Organizer, Profile Img, Country, Region, State, City, Expected Reach, Cost Of Partnership \n"
        array.forEach(item => {
            result += `${item?.event_title?.replaceAll(',', '')},`
            result += `${item?.location?.replaceAll(',', '')},`
            result += `${item?.start_date?.replaceAll(',', '')},`
            result += `${item?.end_date?.replaceAll(',', '')},`
            result += `${item?.type_of_event?.replaceAll(',', '')},`
            result += `${item?.organizer?.replaceAll(',', '')},`
            result += `${item?.profile_img?.replaceAll(',', '')},`
            result += `${item?.country?.replaceAll(',', '')},`
            result += `${item?.region?.replaceAll(',', '')},`
            result += `${item?.state?.replaceAll(',', '')},`
            result += `${item?.city?.replaceAll(',', '')},`
            result += `${item?.exp_reach?.replaceAll(',', '')},`
            result += `${item?.cost_of_partnership?.replaceAll(',', '')}`
            result += "\n"
        })
        return result
    }

    function downloadCSV(array) {
        if (array.length > 0) {
            const link = document.createElement('a')
            let csv = convertArrayOfObjectsToCSV(array)
            if (csv === null) return
            const filename = 'Manage-Events.csv'
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`
            }
            link.setAttribute('href', encodeURI(csv))
            link.setAttribute('download', filename)
            link.click()
        } else {
            alert('Data is being loaded, Please wait...')
        }
    }

    const dataToRender = () => {
        if (eventsSelector.manageEventsData?.data?.length) {
            return eventsSelector.manageEventsData?.data
        }
    }

    const handleCustomPagination = pageUrl => {
        const fromDate = new Date(searchStartDate)
        const finalFromDate = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`
        const toDate = new Date(searchEndDate)
        const finalToDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        setIsLoadingPage(true)
        dispatch(getSearchEventsData(true, {
            rd_name: searchRDsName,
            event_name: searchEventName,
            type: searchEventType,
            country_id: searchCountry,
            region_id: searchRegion,
            state_id: searchState,
            city_id: searchCity,
            from_date: finalFromDate === "NaN-NaN-NaN" ? "" : finalFromDate,
            to_date: finalToDate === "NaN-NaN-NaN" ? "" : finalToDate
        }, pageUrl))
    }
    return (
        <Fragment>
            <Card>
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <CardTitle className="my-1" tag='h4'>Manage Events</CardTitle>
                    <Button.Ripple className="my-1" color='primary' onClick={() => downloadCSV(eventsSelector?.exportManageEventsData)}>
                        <FileText size={15}/> Export (CSV)
                    </Button.Ripple>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>RD Name:</Label>
                                <Input id='name' placeholder='Bruce Wayne' value={searchRDsName} onChange={(e) => setSearchRDsName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='speciality'>Event Name:</Label>
                                <Input id='speciality' placeholder='Weight Management' value={searchEventName} onChange={(e) => setSearchEventName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='industry'>Event Type:</Label>
                                <Input id='industry' type="select" placeholder='Type' value={searchEventType} onChange={(e) => setSearchEventType(e.target.value)}
                                >
                                    <option value=''>Select Event Type</option>
                                    {eventsSelector?.eventTypesData && eventsSelector?.eventTypesData.map(eventType => (
                                        <option value={eventType.id}>{eventType.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='country'>Country:</Label>
                                <Input id='country' type="select" placeholder='Country' value={searchCountry} onChange={(e) => {
                                    setSearchCountry(e.target.value)
                                    getRegionWithID(e.target.value)
                                }}
                                >
                                    <option value=''>Select Country</option>
                                    {authSelector.countries && authSelector.countries.map(country => (
                                        <option value={country.id}>{country.value}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='region'>Region:</Label>
                                <Input id='region' type="select" placeholder='Country' value={searchRegion} onChange={(e) => {
                                    setSearchRegion(e.target.value)
                                    getStateWithID(e.target.value)
                                }}
                                >
                                    <option value=''>Select Country</option>
                                    {authSelector.regionsByCountryID && authSelector.regionsByCountryID.map(region => (
                                        <option value={region.id}>{region.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='state'>State:</Label>
                                <Input type="select" id='state' placeholder='State' value={searchState} onChange={(e) => {
                                    setSearchState(e.target.value)
                                    getCityWithID([e.target.value])
                                }}
                                >
                                    <option value=''>Select State</option>
                                    {authSelector.statesByRegionID && authSelector.statesByRegionID.map(state => (
                                        <option value={state.id}>{state.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='state'>City:</Label>
                                <Input type="select" id='state' placeholder='State' value={searchCity} onChange={(e) => setSearchCity(e.target.value)}
                                >
                                    <option value=''>Select State</option>
                                    {authSelector.citiesByStateID && authSelector.citiesByStateID.map(state => (
                                        <option value={state.id}>{state.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Start Date:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    value={searchStartDate}
                                    options={{dateFormat: 'Y/m/d'}}
                                    onChange={date => setSearchStartDate(date)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>End Date:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    value={searchEndDate}
                                    options={{dateFormat: 'Y/m/d'}}
                                    onChange={date => setSearchEndDate(date)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <div className="admin-filter-actions inline-direction">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchEventData}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshFormData}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                {isLoadingPage && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isLoadingPage && (
                    <>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={currentPage + 1}
                            data={dataToRender()}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
                <Toast isOpen={show} style={{
                    position: 'absolute',
                    top: 0,
                    right: 0
                }} className="bg-success">
                    <ToastHeader>{toastHeader}</ToastHeader>
                    <ToastBody> {toastMessage} </ToastBody>
                </Toast>
            </Card>
        </Fragment>
    )
}

export default ManageEvents
