import axios from 'axios'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

// ** Get table Data
export const getAccountSettingsData = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}admin/load-setting-form`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'ACCOUNT_SETTINGS_DATA',
        accountSettings: response.data
      })
    })
  }
}

export const updateAccountSettingsData = (params) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("settingKey", params.settingKey)
    formData.append("settingValue", params.settingValue)
    axios.post(`${process.env.REACT_APP_BASEURL}admin/update-setting`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      // dispatch({
      //   type: 'ACCOUNT_SETTINGS_DATA',
      //   accountSettings: response.data
      // })
    })
  }
}
