import axios from 'axios'

// ** Get table Data
const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getSubscribedBrandsData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${process.env.REACT_APP_BASEURL}admin/load-subscription-list`
  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'SUBSCRIPTION_LIST_DATA',
        subscriptions: response.data
      })
    })
  }
}

// ** Search pending Rds data
export const getSearchSubscribedBrands = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${process.env.REACT_APP_BASEURL}admin/load-subscription-list`
  return dispatch => {
    axios.post(endpoint, {
      brand_name: body.brand_name,
      owner_name: body.owner_name,
      plan_id: body.plan_id,
      purchase_from: body.purchase_from,
      purchase_to: body.purchase_to
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'SUBSCRIPTION_LIST_DATA',
        subscriptions: response.data
      })
    })
  }
}

export const getPlansData = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}admin/load-plans`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'SUBSCRIPTION_PLANS_DATA',
        subscriptionPlans: response.data
      })
    })
  }
}

export const nullSearchData = (listName) => {
  return dispatch => {
    switch (listName) {
      case 'searchSubscriptionReport':
        dispatch({
          type: 'SUBSCRIBED_BRANDS_SEARCH_DATA',
          searchSubscribedBrands: null
        })
        break
      default:
        break
    }
  }
}