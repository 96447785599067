import {Link} from "react-router-dom"
import {CheckCircle} from "react-feather"
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Button, Alert
} from "reactstrap"
import {Fragment} from "react"
import * as Icon from "react-feather"

const CurrentPlan = (props) => {
    return (<Fragment>
            <Card className="card-user-timeline">
                <CardHeader>
                    <CardTitle tag="h4">Current Plan</CardTitle>
                </CardHeader>
                <Row className="justify-content-end align-items-center bg-light m-0 py-1 px-1">
                    <Col>
                        <div className="mb-1">
                            {props?.planData?.data?.is_free &&
                                <h4 className="font-weight-bolder mr-1">
                                    Free
                                </h4>}
                            {!props?.planData?.data?.is_free && !props?.planData?.data?.is_expire &&
                                <Fragment>
                                    <h4 className="font-weight-bolder mr-1">
                                        {props?.planData?.data?.plan || "N/A"}
                                    </h4>
                                    <div className="d-flex">
                                        <h3 className="text-primary font-weight-bolder mb-0">
                                            {props?.planData?.data?.price || "N/A"}/
                                        </h3>
                                        <span style={{position: 'relative', top: '.3rem'}}>{props?.planData?.data?.plan_short_title || "N/A"}</span>
                                    </div>
                                </Fragment>}
                        </div>
                        <h6 className="m-0">
                            {!props?.planData?.data?.is_expire &&
                                <>
                                    <p>
                                        <span>Auto-renews on </span>
                                        <span className='text-primary'>{props?.planData?.data?.expiry_date || "N/A"}</span>
                                    </p>
                                    {props?.planData?.data?.subscription_type === 'monthly' && props?.planData?.data?.renew_status?.expiry_date && (
                                        <p className="text-danger">
                                            Your plan has cancelled but your subscription will remain active till {props?.planData?.data?.renew_status?.expiry_date}.
                                        </p>
                                    )}
                                    {props?.planData?.data?.subscription_type === 'monthly' && !props?.planData?.data?.renew_status?.expiry_date && (
                                        <p>You can cancel your plan <span className="text-primary cursor-pointer" onClick={props?.openingModal}>here...</span></p>
                                    )}
                                    {props?.planData?.data?.subscription_type === 'yearly' && (
                                        <p>You can cancel your upcoming auto renewal <span className="text-primary cursor-pointer" onClick={props?.openingModal}>here...</span></p>
                                    )}
                                </>
                            }
                        </h6>
                        {props?.planData?.data?.is_expire &&
                            <Fragment>
                                <h5>Sorry! You don't have any plan.</h5>
                            </Fragment>}
                    </Col>
                    <Col sm="auto">
                        {props?.planData?.data?.is_expire && (
                            <Button
                                tag={Link}
                                to="/subscription-plans"
                                className="d-flex ml-auto"
                            >
                                <Icon.ShoppingCart size={14} style={{marginRight: '5px'}}/><span> Purchase Plan</span>
                            </Button>)}
                        {/*{!props?.planData?.data?.is_expire && (*/}
                        {/*    <Button className="d-flex ml-auto btn-danger" onClick={props?.openingModal} disabled={props?.planData?.data?.can_cancel === false}>*/}
                        {/*      Cancel Plan*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                    </Col>
                </Row>
                <CardBody>
                    {/*{!props?.planData?.data?.is_expire && !props?.planData?.data?.plan_features?.length && (<>Plans features isn't exists.</>)}*/}
                    {!props?.planData?.data?.is_expire && props?.planData?.data?.plan_features.map((item) => (
                        <ul className="list-unstyled">
                            <li>
                                <CheckCircle className="mr-1 text-success"/>
                                {item.title}
                            </li>
                        </ul>
                    ))}
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default CurrentPlan
