import {ThemeColors} from '@src/utility/context/ThemeColors'
import StatsCard from '@src/views/admin/dashboard/StatsCard'
import AllRDsChart from '@src/views/admin/dashboard/AllRDsChart'
import RecurringChart from '@src/views/admin/dashboard/RecurringChart'
import ManageRDs from '@src/views/admin/dashboard/ManageRDs'
import ManageBrands from '@src/views/admin/dashboard/ManageBrands'
import '@styles/react/libs/charts/apex-charts.scss'
import '@styles/react/libs/charts/recharts.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {useContext} from 'react'
import {Col, Row} from 'reactstrap'

const AdminDashboard = () => {
    const {colors} = useContext(ThemeColors)

    return (
        <div id='dashboard-analytics'>
            <Row className='match-height'>
                <Col xl='12' md='12' xs='12'>
                    <StatsCard cols={{xl: '3', sm: '4', md: '4'}}/>
                </Col>
            </Row>
            <Row className='match-height allRD'>
                <Col lg='7' sm='12'>
                    <AllRDsChart/>
                </Col>
                <Col lg='5' sm='12'>
                    <RecurringChart primary={colors.primary.main} danger={colors.danger.main}/>
                </Col>
            </Row>
            <Row className='match-height'>
                <Col xs='12'>
                    <ManageRDs/>
                </Col>
            </Row>
            <Row className='match-height'>
                <Col xs='12'>
                    <ManageBrands/>
                </Col>
            </Row>
        </div>
    )
}

export default AdminDashboard
