import {useEffect, useState} from 'react'
import {useSkin} from '@hooks/useSkin'
import {PropTypes} from "prop-types"

const BlankLayout = ({children, ...rest}) => {
    const [skin, setSkin] = useSkin()
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
        return () => setIsMounted(false)
    }, [])

    if (!isMounted) {
        return null
    }

    return (
        <div className='blank-page'>
            <div className='app-content content'>
                <div className='content-wrapper'>
                    <div className='content-body'>{children}</div>
                </div>
            </div>
        </div>
    )
}

BlankLayout.propTypes = {
    children: PropTypes.node
}

export default BlankLayout
