import {Fragment, useEffect, useState} from "react"
import Avatar from "@components/avatar"
import {useParams, useHistory} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import defaultAvatar from "@src/assets/images/avatars/Ellipse.png"
import PDF from "@src/assets/images/icons/pdf1.png"
import TXT from "@src/assets/images/icons/txt1.png"
import CSV from "@src/assets/images/icons/csv.png"
import WORD from "@src/assets/images/icons/word.png"
import XLSX from "@src/assets/images/icons/xlsx.png"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Button,
    Spinner,
    Form,
    FormGroup,
    Label,
    Input, CustomInput
} from "reactstrap"
import {
    Calendar,
    Bookmark,
    Download
} from "react-feather"
import {
    getBrandsOfEventDetail,
    submitFeedBack,
    getViewFeedback,
    nullResponseOppoBrands
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import {NULL} from "@src/views/apps/opportunitiesBrands/store/actionType"
import {allowedFiles, allowedFilesErrorMessages} from '@src/utility/Utils'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const ProvideFeedback = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const {eventId, brandId} = useParams()
    const history = useHistory()
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [description, setDescription] = useState("")
    const [overallResponse, setOverallResponse] = useState("")
    const [helpful, setHelpful] = useState("")
    const [socialMediaUrl, setSocialMediaUrl] = useState("")
    const [noOfAttendees, setNoOfAttendees] = useState("")
    const [impressions, setImpressions] = useState("")
    const [sharedSample, setSharedSample] = useState("")
    const [feedbackMedia, setFeedbackMedia] = useState([])

    const [fileUploadError, setFileUploadError] = useState("")
    const [imageFile, setImageFile] = useState("")
    const [fileSizeError, setFileSizeError] = useState(false)
    const [fileTypeError, setFileTypeError] = useState(false)
    const [isFeedbackSending, setIsFeedbackSending] = useState(false)
    const [feedbackRes, setFeedbackRes] = useState(null)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const sweetAlert = withReactContent(Swal)

    const feedbackSlice = useSelector((store) => store?.brandsOppoprunities)
    const dispatch = useDispatch()

    useEffect(() => {
        setIsTableLoading(false)

        if (feedbackSlice?.providedFeedback) {
            const {success} = feedbackSlice?.providedFeedback
            // setFeedbackRes(feedbackSlice?.providedFeedback)
            setIsFeedbackSending(false)
            dispatch(nullResponseOppoBrands(NULL.BRAND_OPPORTUNITIES_PROVIDE_FEEDBACK))
            if (success) history.goBack()
        }
    }, [feedbackSlice])

    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getBrandsOfEventDetail(eventId, brandId))
        dispatch(getViewFeedback(eventId, brandId))
    }, [])

    const handleFileUpload = (e) => {
        if (!e.target.files[0]) return
        const isPass = [...e.target.files].every(file => allowedFiles().includes(file.name?.split('.').pop().toLowerCase()))
        if (isPass) {
            setFileTypeError(false)
            if ((e.target.files[0].size / 1024 / 1024) > 5.0) {
                setFileSizeError(true)
            } else {
                setFeedbackMedia([...e.target.files])
                setFileSizeError(false)
            }
        } else {
            setFileTypeError(true)
        }
    }

    const saveFeedBack = async () => {
        setIsFeedbackSending(true)
        const token = await executeRecaptcha('provide_feedback')
        if (token) {
            const params = {
                event_id: feedbackSlice?.eventBrandsDetail?.data?.event_id,
                participate_request_id: feedbackSlice?.eventBrandsDetail?.data?.id,
                description,
                overall_response: overallResponse,
                helpful,
                social_media_url: socialMediaUrl,
                no_of_attendees: noOfAttendees,
                impressions,
                shared_sample: sharedSample,
                feedback_media: feedbackMedia,
                re_captcha_token: token
            }
            dispatch(submitFeedBack(params))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    const onCaptchaChange = (value) => {
        setCaptchaError(false)
        setCaptchaValue(value)
    }

    return (
        <Fragment>
            {isTableLoading && (
                <div className="w-100 my-4 text-center">
                    <Spinner color="primary"/>
                </div>
            )}
            {!isTableLoading && (
                <>
                    <Row className="match-height">
                        <Col md="12" lg="12" sm="12">
                            <Card className="p-1 d-flex">
                                <div className="justify-content-sm-center">
                                    <CardBody>
                                        <div>
                                            <CardTitle>
                                                <h2 className="pt-2">
                                                    {feedbackSlice?.eventBrandsDetail?.data
                                                        ?.event_title || "N/A"}
                                                </h2>
                                                <h6>
                                                    {feedbackSlice?.eventBrandsDetail?.data?.city ||
                                                        "N/A"}
                                                    ,{" "}
                                                    {feedbackSlice?.eventBrandsDetail?.data?.state ||
                                                        "N/A"}
                                                    ,{" "}
                                                    {feedbackSlice?.eventBrandsDetail?.data?.country ||
                                                        "N/A"}
                                                </h6>
                                            </CardTitle>
                                            <CardText className="justify-content-sm-center">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="avatar mr-1">
                                                        <Avatar
                                                            img={
                                                                feedbackSlice?.eventBrandsDetail?.data
                                                                    ?.brand_logo || defaultAvatar
                                                            }
                                                            imgHeight="100"
                                                            imgWidth="100"
                                                            className="avatar-img-fit-contain"
                                                        />
                                                    </div>
                                                    <div className="profile-user-info item-name font-weight-bolder">
                                                        <h4 className="mb-0">
                                                            {feedbackSlice?.eventBrandsDetail?.data
                                                                ?.brand_name || "N/A"}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </CardText>
                                        </div>
                                        <Row className="mt-3">
                                            <Col md="4" lg="4" sm="4">
                                                <CardText>
                                                    <div className="p-25">
                                                        <h4 className="mb-1">Date</h4>
                                                        <h6 className="font-weight-bolder">
                                                            {feedbackSlice?.eventBrandsDetail?.data
                                                                ?.start_date ? (
                                                                <Fragment>
                                                                    <Calendar/>{" "}
                                                                    {
                                                                        feedbackSlice?.eventBrandsDetail?.data
                                                                            ?.start_date
                                                                    }
                                                                </Fragment>
                                                            ) : (
                                                                "N/A"
                                                            )}{" "}
                                                            -{" "}
                                                            {feedbackSlice?.eventBrandsDetail?.data
                                                                ?.end_date || "N/A"}
                                                        </h6>
                                                    </div>
                                                </CardText>
                                            </Col>
                                            <Col md="4" lg="4" sm="4">
                                                <CardText>
                                                    <div className="p-25">
                                                        <h4 className="mb-1">Cost of Partnership</h4>
                                                        <h6 className="font-weight-bolder">
                                                            {feedbackSlice?.eventBrandsDetail?.data?.cost ? (
                                                                <Fragment>
                                                                    <Bookmark/> Paid ${" "}
                                                                    {feedbackSlice?.eventBrandsDetail?.data?.cost}
                                                                </Fragment>
                                                            ) : (
                                                                "N/A"
                                                            )}
                                                        </h6>
                                                    </div>
                                                </CardText>
                                            </Col>
                                            <Col md="4" lg="4" sm="4">
                                                <CardText>
                                                    <div className="p-25">
                                                        <h4 className="mb-1">Type of Audience</h4>
                                                        <h6 className="font-weight-bolder">
                                                            {feedbackSlice?.eventBrandsDetail?.data
                                                                ?.audience_type ? (
                                                                <Fragment>
                                                                    <Bookmark/>{" "}
                                                                    {
                                                                        feedbackSlice?.eventBrandsDetail?.data
                                                                            ?.audience_type
                                                                    }
                                                                </Fragment>
                                                            ) : (
                                                                "N/A"
                                                            )}
                                                        </h6>
                                                    </div>
                                                </CardText>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="match-height">
                        <Col md="12" lg="12" sm="12">
                            <Card className="p-1 d-flex">
                                <div className="justify-content-sm-center">
                                    <CardBody>
                                        {feedbackSlice?.eventBrandsDetail?.data?.is_feedback && (
                                            <Fragment>
                                                <CardText className="mb-3">
                                                    <div className="p-25">
                                                        <h5 className="mb-2 font-weight-bolder">
                                                            Detail of product feedback, presentation and
                                                            display
                                                        </h5>
                                                        <h6>
                                                            {feedbackSlice?.feedbackDetail?.data
                                                                ?.description || "N/A"}
                                                        </h6>
                                                    </div>
                                                </CardText>
                                                <CardText className="mb-3">
                                                    <div className="p-25">
                                                        <h5 className="mb-2 font-weight-bolder">
                                                            Detail of overall response and what question or
                                                            comments did attendees have
                                                        </h5>
                                                        <h6>
                                                            {feedbackSlice?.feedbackDetail?.data
                                                                ?.overall_response || "N/A"}
                                                        </h6>
                                                    </div>
                                                </CardText>
                                                <CardText className="mb-3">
                                                    <div className="p-25">
                                                        <h5 className="mb-2 font-weight-bolder">
                                                            Anything else brand could provide that would be
                                                            helpful in the furute
                                                        </h5>
                                                        <h6>
                                                            {feedbackSlice?.feedbackDetail?.data?.helpful ||
                                                                "N/A"}
                                                        </h6>
                                                    </div>
                                                </CardText>


                                                <CardText className="mb-3">
                                                    <div className="p-25">
                                                        <h5 className="font-weight-bolder">Media</h5>
                                                        <FormGroup tag={Col} md="12">
                                                            <Label className="mb-1">
                                                                {feedbackSlice?.feedbackDetail?.data?.media?.length === 0 && (
                                                                    <Card className="mt-2"><CardBody className="text-center">Media doesn't exist</CardBody></Card>
                                                                )}
                                                                <div className="d-flex flex-wrap -mi-10px">
                                                                    {feedbackSlice?.feedbackDetail?.data?.media && feedbackSlice?.feedbackDetail?.data?.media.map((media, i) => {
                                                                        const type = media.media_type.toLowerCase()
                                                                        return (<Fragment>
                                                                            {(type === 'png' || type === 'jpg' || type === 'jpeg') &&
                                                                                (<div className='media-img my-2' key={media.media_name + i}>
                                                                                    <div className="download-icon cursor-pointer">
                                                                                        <a href={media.media_url} target="_blank"><Download size={16}/></a>
                                                                                    </div>
                                                                                    <img className='rounded' src={media.media_url} alt='Card image'/>
                                                                                </div>)}

                                                                            {(type !== 'png' && type !== 'jpg' && type !== 'jpeg') &&
                                                                                (<div className='media-img media-file my-2' key={media.media_name + i}>

                                                                                    <div className="download-icon cursor-pointer">
                                                                                        <a href={media.media_url} target="_blank"><Download size={16}/></a>
                                                                                    </div>
                                                                                    <div className="name-wrap">
                                                                                        {type === 'docx' && (
                                                                                            <img className='rounded png-icon-img' src={WORD} alt='Word Icon'/>)}
                                                                                        {type === 'doc' && (<img className='rounded png-icon-img' src={WORD} alt='Word Icon'/>)}
                                                                                        {type === 'pdf' && (<img className='rounded png-icon-img' src={PDF} alt='PDF Icon'/>)}
                                                                                        {type === 'xlsx' && (
                                                                                            <img className='rounded png-icon-img' src={XLSX} alt='Xlsx Icon'/>)}
                                                                                        {type === 'xls' && (<img className='rounded png-icon-img' src={XLSX} alt='Xlsx Icon'/>)}
                                                                                        {type === 'csv' && (<img className='rounded png-icon-img' src={CSV} alt='Csv Icon'/>)}
                                                                                        {type === 'txt' && (<img className='rounded png-icon-img' src={TXT} alt='Txt Icon'/>)}
                                                                                        <p className="text-truncate">{media.media_name}.....</p>
                                                                                    </div>
                                                                                </div>)}
                                                                        </Fragment>)
                                                                    })}
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </CardText>
                                            </Fragment>
                                        )}
                                        {!feedbackSlice?.eventBrandsDetail?.data?.is_feedback && (
                                            <Fragment>
                                                <Form>
                                                    <CardTitle className="justify-content-sm-center">
                                                        <h3>
                                                            {feedbackSlice?.eventBrandsDetail?.data
                                                                ?.event_title || "N/A"}
                                                        </h3>
                                                    </CardTitle>
                                                    <FormGroup>
                                                        <div className="form-group form-password-toggle">
                                                            <Label for="title">
                                                                <b>
                                                                    <h5>
                                                                        How was the product prepared, presented, or
                                                                        displayed?
                                                                    </h5>
                                                                </b>
                                                            </Label>
                                                            <Input
                                                                name={`title`}
                                                                id={`title`}
                                                                type="textarea"
                                                                rows="3"
                                                                placeholder="Brief Description"
                                                                value={description}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <div className="form-group form-password-toggle">
                                                            <Label for="title">
                                                                <b>
                                                                    <h5>
                                                                        What was your audience's overall response to
                                                                        the product? Did any comments or questions
                                                                        stand out to you?
                                                                    </h5>
                                                                </b>
                                                            </Label>
                                                            <Input
                                                                name={`title`}
                                                                id={`title`}
                                                                type="textarea"
                                                                rows="3"
                                                                placeholder="Brief Description"
                                                                value={overallResponse}
                                                                onChange={(e) => setOverallResponse(e.target.value)}/>
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <div className="form-group form-password-toggle">
                                                            <Label for="title">
                                                                <b>
                                                                    <h5>
                                                                        Is there anything else the brand could
                                                                        provide or do that would be helpful next
                                                                        time?
                                                                    </h5>
                                                                </b>
                                                            </Label>
                                                            <Input
                                                                name={`title`}
                                                                id={`title`}
                                                                type="textarea"
                                                                rows="3"
                                                                placeholder="Brief Description"
                                                                value={helpful}
                                                                onChange={(e) => setHelpful(e.target.value)}
                                                            />
                                                        </div>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div className="form-group form-password-toggle">
                                                            <Label for="title">
                                                                <b>
                                                                    <h5>
                                                                        If you posted to social media please share
                                                                        the links below.
                                                                    </h5>
                                                                </b>
                                                            </Label>
                                                            <Input
                                                                name={`title`}
                                                                id={`title`}
                                                                type="url"
                                                                placeholder="https://www.facebook.com/"
                                                                value={socialMediaUrl}
                                                                onChange={(e) => setSocialMediaUrl(e.target.value)}/>
                                                        </div>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div className="form-group form-password-toggle">
                                                            <Label for="title">
                                                                <b>
                                                                    <h5>
                                                                        Sample Shared ?
                                                                    </h5>
                                                                </b>
                                                            </Label>
                                                            <Input
                                                                name={`title`}
                                                                id={`title`}
                                                                type="number"
                                                                placeholder="Sample Shared"
                                                                value={sharedSample}
                                                                onChange={(e) => setSharedSample(e.target.value)}
                                                            />
                                                        </div>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div className="form-group form-password-toggle">
                                                            <Label for="title">
                                                                <b>
                                                                    <h5>
                                                                        How many impressions did your post(s)
                                                                        receive?
                                                                    </h5>
                                                                </b>
                                                            </Label>
                                                            <Input
                                                                name={`title`}
                                                                id={`title`}
                                                                type="number"
                                                                placeholder="Number of impressions"
                                                                value={impressions}
                                                                onChange={(e) => setImpressions(e.target.value)}
                                                            />
                                                        </div>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div className="form-group form-password-toggle">
                                                            <Label for="title">
                                                                <b>
                                                                    <h5>
                                                                        If you shared the product in person, how
                                                                        many people attended your event or
                                                                        participated in your opportunity?
                                                                    </h5>
                                                                </b>
                                                            </Label>
                                                            <Input
                                                                name={`title`}
                                                                id={`title`}
                                                                type="number"
                                                                placeholder="Number of attendees"
                                                                value={noOfAttendees}
                                                                onChange={(e) => setNoOfAttendees(e.target.value)}/>
                                                        </div>
                                                    </FormGroup>

                                                    <FormGroup className="my-1">
                                                        <Label for="title">
                                                            <b>
                                                                <h5>
                                                                    Please feel free to upload any additional
                                                                    photos, videos, or project results here.
                                                                </h5>
                                                            </b>
                                                        </Label>
                                                        <CustomInput
                                                            multiple
                                                            hidden=""
                                                            accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls"
                                                            type="file"
                                                            className="form-control-file mb-1"
                                                            onChange={handleFileUpload}
                                                        />
                                                        {fileSizeError && <div className="validation-err">Max file size should be upto 5Mb</div>}
                                                        {fileTypeError && <div className="validation-err">{allowedFilesErrorMessages().IMAGE_AND_FILES} </div>}
                                                    </FormGroup>
                                                    <FormGroup className="d-flex justify-content-end">
                                                        <Button.Ripple
                                                            className="mr-1"
                                                            color="primary"
                                                            onClick={saveFeedBack}
                                                            disabled={isFeedbackSending}
                                                        >
                                                            {isFeedbackSending ? 'Submitting...' : 'Submit'}
                                                        </Button.Ripple>
                                                    </FormGroup>
                                                </Form>
                                            </Fragment>
                                        )}
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </Fragment>
    )
}

export default ProvideFeedback
