import axios from 'axios'
import { BRANDS, NULL } from '../actionTypes'
import { getBrandUnreadMessages } from '../../../../../redux/actions/brandsDashboard'

const token = localStorage.getItem(`${process.env.REACT_APP_USERTOKEN}`)

// ** Get Token
const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

// ** Get User Profile
export const getUserProfileFromServer = () => {
  const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
  // console.log('i was called for current user profile', user)
  return dispatch => {
    dispatch({ type: BRANDS.CHAT_GET_USER_PROFILE_FROM_SERVER, brandUserProfile: user })
  }
}

// ** GET User Contacts List
export const getChatContactsFromServer = () => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_BASEURL}brand/load-chat-users`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${getToken()}`
                }
            }).then(res => {
            dispatch({type: BRANDS.CHAT_CONTACTS, chatContacts: res.data})
        }).catch(e => {
            dispatch({type: "BRAND_CONTACT_LOAD_ERROR", payload: e})
        })
    }
}

// ** ADD New Contact to List

export const addBrandContactsInRedux = obj => {
  return dispatch => {
    dispatch({ type: BRANDS.CHAT_ADD_CONTACT, chatContact: obj })

  }
}
// ** Get Message Templetes Of User

export const getMessageTemplete = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}brand/load-msg-template`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${getToken()}`
        }
      }).then(res => {
        dispatch({
          type: BRANDS.CHAT_GET_MESSAGE_TEMPLETES,
          brandMessageTempletes: res.data
        })
      })
  }
}

//** Get Chat of Selected User

export const changeReduxState = (name, value) => {
  return dispatch => {
    switch (name) {
      case NULL.LOAD_SELECTED_USER_CHAT:
        dispatch({
          type: BRANDS.LOAD_SELECTED_USER_CHAT,
          selectedUserBrandChatLoading: value
        })
        break
    }
  }
}

export const selectChatFromServer = id => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_BASEURL}brand/load-chat-window?user_id=${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${getToken()}`
                }
            }).then(res => {
            dispatch({type: BRANDS.SELECTED_USER_CHAT, selectedUserBrandChat: res.data})
            dispatch(changeReduxState(NULL.LOAD_SELECTED_USER_CHAT, false))
            dispatch(getMessageTemplete())
            dispatch(getBrandUnreadMessages())
        }).catch(e => {
            dispatch({type: "BRAND_CHAT_LOAD_ERROR", payload: e})
        })
    }
}

export const recieveMsg = obj => {
  return dispatch => {
    dispatch({ type: BRANDS.NEW_MESSAGE, message: obj })
  }
}

export const sendMsgRD = obj => {
  const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
  const chatID = user.chat_id
  return dispatch => {
    axios.post(`${process.env.REACT_APP_BASEURL}brand/save-chat-message`, {
      message: obj.message,
      to_user_id: obj.to_user_id,
      from_user_id: chatID,
      timezone: obj.timezone
    }, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      dispatch(getChatContactsFromServer())
      dispatch(selectChatFromServer(obj.to_user_id))
      dispatch({type: "SEND_MSG_SUCCESS_RESPONSE", payload: res.data})
    })
  }
}

export const saveTemplete = obj => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`
  }

  const data = {
    title: obj.title,
    template: obj.message
  }
  return dispatch => {
    axios.post(`${process.env.REACT_APP_BASEURL}brand/save-msg-template`, data, {
      headers
    })
      .then((response) => {
        // console.log('in action response for templete save', response)
        // dispatch(getChatContactsFromServer())
        // dispatch(selectChatFromServer(obj.to_user_id))
        dispatch(getMessageTemplete())
      })
      .catch((error) => {
      })

  }
}

export const saveBroadcastMessage = (userList) => {
  return dispatch => {
    axios.post(`${process.env.REACT_APP_BASEURL}brand/save-bulk-msg`, userList, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      dispatch({type: "BROADCAST_SUCCESS_RESPONSE", payload: res.data})
      dispatch(getChatContactsFromServer())
    }).catch((error) => {
      dispatch({type: "BROADCAST_SUCCESS_RESPONSE", payload: error})
    })
  }
}

export const myRdList = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}brand/chat-my-rd-list`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${getToken()}`
        }
      }).then(res => {
        return dispatch({
          type: BRANDS.CHAT_BRAODCAST_CONTACTS,
          braodcastContactList: res.data
        })

      })
      .catch((error) => {
      })
  }
}

export const allRdList = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}brand/all-rds-list`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${getToken()}`
        }
      }).then(res => {
        return dispatch({
          type: BRANDS.CHAT_BRAODCAST_CONTACTS,
          braodcastContactList: res.data
        })
      })
      .catch((error) => {
      })
  }
}

export const sendBroadcastMsg = broadCastMessage => {
  return dispatch => {
    const mdobj = {
      from_user_id: broadCastMessage.from_user_id,
      message: broadCastMessage.message,
      to_users: broadCastMessage.to_users,
      timezone: broadCastMessage.timezone
    }
    broadCastMessage.socket.emit("braodcast-message", mdobj)
    dispatch(saveBroadcastMessage(mdobj))
  }
}
export const uploadMediaToserver = obj => {
  return dispatch => {
    const formData = new FormData()
    formData.append("file", obj.file)
    formData.append("user_to_id", obj.to_user_id)
    axios.post(`${process.env.REACT_APP_BASEURL}brand/upload-chat-file`, formData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
          const media = {
            to_user_id: obj.to_user_id,
            media: res.data.media,
            message: "",
            file_name: res.data.file_name,
            from_user_id: obj.from_user_id

          }
          obj.socket.emit("new-media", media)
          dispatch(getChatContactsFromServer())
          dispatch(selectChatFromServer(obj.to_user_id))
          dispatch({type: "SEND_MSG_SUCCESS_RESPONSE", payload: res.data})
        }).catch((error) => {
      console.log('error')
    })
  }
}

export const selectedContactDetail = selectedContact => {
  return dispatch => {
    dispatch({ type: BRANDS.CHAT_SELECTED_CONTACT, selectedContactDetail: selectedContact })
  }
}

export const getUserProfile = () => {
  return dispatch => {
    return axios.get('/apps/chatBrands/users/profile-user').then(res => {
      return dispatch({
        type: 'GET_USER_PROFILE',
        userProfile: res.data
      })
    })
  }
}

// ** Get Chats & Contacts // get user chats list
export const getChatContacts = () => {
  return dispatch => {
    dispatch({
      type: 'GET_CHAT_CONTACTS',
      data: []
    })
    // axios.get('/apps/chatBrands/chats-and-contacts').then(res => {
    //   dispatch({
    //     type: 'GET_CHAT_CONTACTS',
    //     data: res.data.data
    //   })
    // })
  }
}

// ** Select Chat
export const selectChat = id => {
  return dispatch => {
    axios.get('/apps/chatBrands/get-chat', { id }).then(res => {
      dispatch({ type: 'SELECT_CHAT', data: res.data })
      dispatch(getChatContacts())
    })
  }
}

export const sendMsg = obj => {
  return dispatch => {
    axios.post('/apps/chatRd/send-msg', { obj }).then(res => {
      dispatch({ type: 'SEND_MSG', data: res.data })
      dispatch(selectChat(obj.to_user_id))

    })
  }
}
export const receiveTextMessage = message => {
  return dispatch => {
    dispatch({
      type: RECEIVE_NEW_CHAT_MESSAGE,
      payload: message
    })
  }

}
export const storeMessagesInRedux = obj => {
  return dispatch => {
    dispatch({ type: 'CHAT_BRAND_WITH_RD', brandsChatWithRd: obj })

  }
}
