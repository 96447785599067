import {useEffect} from "react"
import {Link, useHistory} from "react-router-dom"
import Avatar from "@components/avatar"
import {User, MessageSquare} from "react-feather"
import {
    Button,
    Row,
    Col,
    Spinner,
    UncontrolledTooltip
} from "reactstrap"
import img4 from "@src/assets/images/banner/banner-44.jpg"
import {getRecommendedBrandsAtRdDashboard} from "@src/redux/actions/rdDashboard/index"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import queryString from "query-string"

const RecommendedBrands = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const {
        rdRecommendedBrandsLoading,
        rdRecommendedBrandsFullFill,
        rdRecommendedBrandsError
    } = useSelector((store) => store.rd)


    useEffect(() => {
        dispatch(getRecommendedBrandsAtRdDashboard(""))
    }, [])

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(getRecommendedBrandsAtRdDashboard(`?page=${pageNo}`))
    }

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatBrand', JSON.stringify(user))
        history.push(`/rd/apps/chat/chatRd/${user.user_id}`)
    }

    return (
        <>
            {rdRecommendedBrandsLoading ? (
                <div className="d-flex justify-content-center" style={{height: '200px'}}>
                    <div className="align-self-center">
                        <Spinner color='primary'/>
                    </div>
                </div>
            ) : (
                rdRecommendedBrandsFullFill?.data?.length > 0 && (
                    <>
                        <h3 className="mb-2">
                            Recommended Brands
                        </h3>
                        <div className="brandSlides">
                            <Row className="match-height">
                                {rdRecommendedBrandsFullFill?.data?.map((brand, index) => (
                                    <Col sm="6" md="4" lg="3" key={index}>
                                        <div className="full-height brandContent mb-2">
                                            <Link to={`/dashboard/rd/brands/${brand.id}`}>
                                                <Avatar
                                                    img={brand?.brand_logo || img4}
                                                    imgHeight="91"
                                                    imgWidth="91"
                                                />
                                            </Link>
                                            <h3 className="brandSlidesRd mt-2 mb-1">
                                                {brand?.brand_name || "N/A"}
                                            </h3>
                                            <Button.Ripple
                                                tag={Link}
                                                to={`/dashboard/rd/brands/${brand.id}`}
                                                id={`ProfileUncontrolledTooltip_${brand?.id}`}>
                                                <User size={14}/>
                                                <UncontrolledTooltip placement='top'
                                                                     target={`ProfileUncontrolledTooltip_${brand?.id}`}>
                                                    View Profile
                                                </UncontrolledTooltip>
                                            </Button.Ripple>
                                            <Button.Ripple
                                                color="secondary"
                                                className="m-25"
                                                onClick={() => handleRedirectMessage(brand)}
                                                id={`MsgUncontrolledTooltip_${brand?.id}`}>
                                                <MessageSquare size={14}/>
                                                <UncontrolledTooltip placement='top'
                                                                     target={`MsgUncontrolledTooltip_${brand?.id}`}>
                                                    Send Message
                                                </UncontrolledTooltip>
                                            </Button.Ripple>

                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            <Row className="pt-1 justify-content-centered">
                                <Col md="12">
                                    <CustomPagination pageLinks={rdRecommendedBrandsFullFill?.pages}
                                                      onPaginationClick={handleCustomPagination}/>
                                </Col>
                            </Row>
                        </div>
                    </>
                )
            )}
        </>
    )
}

export default RecommendedBrands
