import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
import {useState, useEffect} from 'react'
import {
    Media,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Form,
    Spinner,
    Label,
    Button,
    Input,
    Alert
} from 'reactstrap'
import '@styles/base/pages/page-auth.scss'
import {
    getAdminProfileInfo,
    updateAdminProfile,
    nullResponse
} from '@store/actions/navbar'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const EditProfile = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [error, setError] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [visible, setVisible] = useState(false)
    const [avatar, setAvatar] = useState('')
    const [uploadAvatar, setUploadAvatar] = useState(null)
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [adminName, setAdminName] = useState('')
    const [adminNameError, setAdminNameError] = useState(false)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const sweetAlert = withReactContent(Swal)

    const dispatch = useDispatch()
    const navbarStoreSlice = useSelector(store => store.navbar)

    useEffect(() => {
        const editProfileInfo = navbarStoreSlice?.editProfileInfo ? navbarStoreSlice?.editProfileInfo : null
        if (editProfileInfo) {
            const {data: {img, name}} = editProfileInfo
            setAvatar(img)
            setIsPageLoading(false)
            setAdminName(name)
        }

        const updateProfileRes = navbarStoreSlice?.updateProfileRes ? navbarStoreSlice?.updateProfileRes : null
        if (updateProfileRes) {
            const {success, msg} = updateProfileRes
            dispatch(nullResponse('UpdateProfile'))
            dispatch(nullResponse('EditProfile'))
            setCaptchaError(false)
            setCaptchaValue(null)
            setVisible(true)
            if (success) {
                setSuccessMsg(msg)
                const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
                userData.profile_image = updateProfileRes?.data?.profile_image
                userData.name = updateProfileRes?.data?.name
                localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(userData))
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
            if (!success) {
                setError(msg)
                const timer = setTimeout(() => {
                    setVisible(false)
                    setError("")
                    clearTimeout(timer)
                }, 5000)
            }
            // setIsPageLoading(false)
            dispatch(getAdminProfileInfo())
        }
    }, [navbarStoreSlice])

    useEffect(() => {
        setIsPageLoading(true)
        dispatch(nullResponse('UpdateProfile'))
        dispatch(nullResponse('EditProfile'))
        dispatch(getAdminProfileInfo())
    }, [])

    const onChangeAvatar = e => {
        const reader = new FileReader(),
            files = e.target.files
        reader.onload = function () {
            setAvatar(reader.result)
        }
        reader.readAsDataURL(files[0])
        setUploadAvatar(files[0])
    }

    const handleUpdateProfile = async () => {
        setVisible(false)
        if (!adminName) {
            setAdminNameError(true)
            return
        }
        if (!!adminName) {
            setAdminNameError(false)
            setIsPageLoading(true)
            const token = await executeRecaptcha('admin_edit_profile')
            if (token) {
                dispatch(updateAdminProfile({name: adminName, profile_img: uploadAvatar, re_captcha_token: token}))
            } else {
                sweetAlert.fire({
                    title: 'Captcha Error',
                    icon: 'error',
                    html: '<p>Execute recaptcha not yet available.</p>',
                    confirmButtonText: 'Reload',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            }
        }
    }

    return (
        <div className='auth-wrapper auth-v1 px-2'>
            <div className='auth-inner py-2'>
                <Card className='mb-0'>
                    <CardBody className="p-3">
                        <CardTitle className='mb-75 text-center'>
                            <h3> EDIT ADMIN PROFILE </h3>
                        </CardTitle>
                        <CardText>
                            <Alert color={!!error ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                                <div className='alert-body'>
                                    {error || successMsg}
                                </div>
                            </Alert>
                        </CardText>
                        {isPageLoading && <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>}
                        {!isPageLoading && (
                            <Form className='auth-reset-password-form'>
                                <Media>
                                    <Media className='mr-25' left>
                                        <Media object className='rounded mr-50 img-fit-contain' src={avatar} alt='Admin Profile Image' height='80' width='80'/>
                                    </Media>
                                    <Media className='mt-75 ml-1' body>
                                        <Button.Ripple tag={Label} className='mr-75 mb-1' size='sm' color='primary'>
                                            Upload
                                            <Input type='file' onChange={onChangeAvatar} hidden accept='image/*'/>
                                        </Button.Ripple>
                                        <p>Allowed JPG, JPEG or PNG. Max size should be normal</p>
                                    </Media>
                                </Media>
                                <div className='form-group form-password-toggle'>
                                    <Label className={classnames({
                                        'form-label': true
                                    })} htmlFor={`name`}>
                                        Name<span className="is-invalid">*</span>
                                    </Label>
                                    <Input
                                        type='name'
                                        onChange={(e) => {
                                            setAdminName(e.target.value)
                                            setAdminNameError(false)
                                        }}
                                        value={adminName}
                                        name={`name`}
                                        id={`name`}
                                        className={classnames({'is-invalid': adminNameError})}
                                    />
                                    {adminNameError && <div className="validation-err">This field is required</div>}
                                </div>

                                <div className='form-group form-password-toggle'>
                                    <Label className={classnames({
                                        'is-invalid': false,
                                        'form-label': true
                                    })} htmlFor={`email`}>
                                        Email
                                    </Label>
                                    <Input
                                        type='email'
                                        value={navbarStoreSlice?.editProfileInfo?.data?.email}
                                        name={`email`}
                                        id={`email`}
                                        disabled
                                    />
                                </div>
                                <Button.Ripple
                                    color='primary'
                                    className='mt-2 mx-auto d-flex'
                                    onClick={handleUpdateProfile}>
                                    Update Profile
                                </Button.Ripple>
                            </Form>)}
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default EditProfile