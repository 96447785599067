import React, {useState, useEffect, Fragment} from 'react'
import {Link, useParams, useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {User, X, Filter, Mail} from 'react-feather'
import Avatar from '@components/avatar'
import classnames from 'classnames'
import styled from 'styled-components'
import {
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Card,
    CardTitle,
    CardHeader,
    CardFooter,
    CustomInput,
    FormGroup,
    CardBody,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Spinner
} from 'reactstrap'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {NULL} from '@src/redux/actionTypes'
import {
    getFavoriteRDProfiles, getFavoriteRDOpportunities,
    nullResponse, searchFavoriteRDOpportunities,
    searchFavoriteRDProfiles, getGoalsAttributes,
    getCountries, getRegionsByCountryId, getStatesByRegionId,
    getCitiesByStateId, getEventTypes, getAudienceTypes
} from '@src/redux/actions/brandsDashboard'
import '@styles/react/libs/flatpickr/flatpickr.scss'

const FavouriteDetails = () => {
    const [active, setActive] = useState('1')
    const [isProfilesLoading, setIsProfilesLoading] = useState(false)
    const [isOpportunitiesLoading, setIsOpportunitiesLoading] = useState(false)

    const [showProfileFilters, setShowProfileFilters] = useState(false)
    const [showOpportunitiesFilters, setShowOpportunitiesFilters] = useState(false)
    const [profilesPaginationInfo, setProfilesPaginationInfo] = useState(false)
    const [opportunitiesPaginationInfo, setOpportunitiesPaginationInfo] = useState(false)

    const [industryValues, setIndustryValues] = useState([])
    const [specialityValues, setSpecialityValues] = useState([])
    const [personalAttrValues, setPersonalAttrValues] = useState([])

    const [goalsAttributes, setGoalsAttrbutes] = useState(null)
    const [eventTypesOptions, setEventTypesOptions] = useState(null)
    const [audienceTypesOptions, setAudienceTypesOptions] = useState(null)

    const [countryOptions, setCountryOptions] = useState(null)
    const [regionOptions, setRegionOptions] = useState(null)
    const [stateOptions, setStateOptions] = useState(null)
    const [cityOptions, setCityOptions] = useState(null)
    const [isRegionsLoading, setIsRegionsLoading] = useState(false)
    const [isStatesLoading, setIsStatesLoading] = useState(false)
    const [isCitiesLoading, setIsCitiesLoading] = useState(false)

    const [searchNameValue, setSearchNameValue] = useState('')
    const [selectedProfileCountry, setSelectedProfileCountry] = useState(null)
    const [selectedProfileRegion, setSelectedProfileRegion] = useState(null)
    const [selectedProfileState, setSelectedProfileState] = useState(null)
    const [selectedProfileCity, setSelectedProfileCity] = useState(null)

    const [searchEventNameValue, setSearchEventNameValue] = useState('')
    const [selectedEventType, setSelectedEventType] = useState(null)
    const [selectedAudienceType, setSelectedAudienceType] = useState(null)
    const [dateRangePicker, setDateRangePicker] = useState('')
    const [selectedOpportunityCountry, setSelectedOpportunityCountry] = useState(null)
    const [selectedOpportunityRegion, setSelectedOpportunityRegion] = useState(null)
    const [selectedOpportunityState, setSelectedOpportunityState] = useState(null)
    const [selectedOpportunityCity, setSelectedOpportunityCity] = useState(null)
    const [activeView, setActiveView] = useState('grid')

    const brandsDashboardStoreSlice = useSelector(store => store.brandsDashboardSlice)
    const dispatch = useDispatch()
    const {id} = useParams()
    const history = useHistory()

    useEffect(() => {
        if (!!brandsDashboardStoreSlice?.favoriteRDProfilesRes) {
            const {links, meta, pages} = brandsDashboardStoreSlice?.favoriteRDProfilesRes
            setProfilesPaginationInfo({...links, ...meta, pages})
            setIsProfilesLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.favoriteRDOpportunitiesRes) {
            const {links, meta, pages} = brandsDashboardStoreSlice?.favoriteRDOpportunitiesRes
            setOpportunitiesPaginationInfo({...links, ...meta, pages})
            setIsOpportunitiesLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.countries) {
            setCountryOptions(brandsDashboardStoreSlice.countries)
        }

        if (!!brandsDashboardStoreSlice?.regionsByCountryID) {
            setRegionOptions(brandsDashboardStoreSlice?.regionsByCountryID)
            setIsRegionsLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.statesByRegionID) {
            setStateOptions(brandsDashboardStoreSlice?.statesByRegionID)
            setIsStatesLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.citiesByStateID) {
            setCityOptions(brandsDashboardStoreSlice?.citiesByStateID)
            setIsCitiesLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.goalsAttributes) {
            setGoalsAttrbutes(brandsDashboardStoreSlice.goalsAttributes)
        }

        if (!!brandsDashboardStoreSlice?.audienceTypes) {
            setAudienceTypesOptions(brandsDashboardStoreSlice.audienceTypes)
        }

        if (!!brandsDashboardStoreSlice?.eventTypes) {
            setEventTypesOptions(brandsDashboardStoreSlice.eventTypes)
        }

    }, [brandsDashboardStoreSlice])

    useEffect(() => {

        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')

        setIsProfilesLoading(true)
        setIsOpportunitiesLoading(true)
        dispatch(getAudienceTypes())
        dispatch(getEventTypes())
        dispatch(getGoalsAttributes())
        dispatch(getCountries())
        dispatch(getFavoriteRDProfiles(false, '', id))
        dispatch(getFavoriteRDOpportunities(false, '', id))
    }, [])

    const handleProfileFiltersChange = (filterName, name, value) => {
        switch (filterName) {
            case 'IndustryChange':
                let values0 = [...industryValues]
                if (value) values0.push(parseInt(name))
                if (!value) values0 = values0.filter(x => x !== parseInt(name))
                setIndustryValues(values0)
                break
            case 'PersonalAttrChange':
                let values1 = [...personalAttrValues]
                if (value) values1.push(parseInt(name))
                if (!value) values1 = values1.filter(x => x !== parseInt(name))
                setPersonalAttrValues(values1)
                break
            case 'SpecialityChange':
                let values2 = [...specialityValues]
                if (value) values2.push(parseInt(name))
                if (!value) values2 = values2.filter(x => x !== parseInt(name))
                setSpecialityValues(values2)
                break
        }
    }

    const updateLocation = (location, locationValue) => {
        switch (location) {
            case 'Regions':
                dispatch(getRegionsByCountryId(locationValue))
                break
            case 'States':
                dispatch(getStatesByRegionId(locationValue))
                break
            case 'Cities':
                dispatch(getCitiesByStateId(locationValue))
                break
        }
    }

    const handleProfilesSelect = (select, value) => {
        switch (select) {
            case 'CountrySelect':
                setIsRegionsLoading(true)
                updateLocation('Regions', value)
                setSelectedProfileCountry(value)
                break
            case 'RegionSelect':
                setIsStatesLoading(true)
                updateLocation('States', value)
                setSelectedProfileRegion(value)
                break
            case 'StateSelect':
                setIsCitiesLoading(true)
                updateLocation('Cities', value)
                setSelectedProfileState(value)
                break
            case 'CitySelect':
                setSelectedProfileCity(value)
                break
        }
    }

    const handleProfileFiltersSearch = () => {
        setIsProfilesLoading(true)
        dispatch(searchFavoriteRDProfiles(false, '', id, {
            speciality: specialityValues,
            industry: industryValues,
            personal_attr: personalAttrValues,
            country: selectedProfileCountry?.id || '',
            region: selectedProfileRegion?.id || '',
            state: selectedProfileState?.id || '',
            city: selectedProfileCity?.id || '',
            rd_name: ''
        }))
    }

    const handleSearchByName = () => {
        setIsProfilesLoading(true)
        dispatch(searchFavoriteRDProfiles(false, '', id, {
            speciality: [],
            industry: [],
            personal_attr: [],
            country: '',
            region: '',
            state: '',
            city: '',
            rd_name: searchNameValue
        }))
    }

    const handleOpportunitiesFiltersSearch = () => {
        setIsOpportunitiesLoading(true)
        dispatch(searchFavoriteRDOpportunities(false, '', id, {
            country_id: selectedOpportunityCountry?.id || null,
            region_id: selectedOpportunityRegion?.id || null,
            state_id: selectedOpportunityState?.id || null,
            city: selectedOpportunityCity?.id || null,
            event_type: selectedEventType?.value || null,
            audience_type: selectedAudienceType?.value || null,
            from_date: dateRangePicker[0] || null,
            to_date: dateRangePicker[1] || null,
            txt_search: ''
        }))
    }

    const handleSearchByEventName = () => {
        setIsOpportunitiesLoading(true)
        dispatch(searchFavoriteRDOpportunities(false, '', id, {
            country_id: '',
            state_id: '',
            city: '',
            event_type: '',
            audience_type: '',
            from_date: '',
            to_date: '',
            txt_search: searchEventNameValue
        }))
    }

    const handleResetSearch = (form) => {
        switch (form) {
            case 'ProfileName':
                setIsProfilesLoading(true)
                setSearchNameValue('')
                dispatch(getFavoriteRDProfiles(false, '', id))
                break
            case 'ProfilesClearAll':
                setIsProfilesLoading(true)
                document.querySelectorAll("input[type=checkbox]").forEach(checkbox => {
                    checkbox.checked = false
                })
                setSelectedProfileCountry(null)
                setSelectedProfileRegion(null)
                setSelectedProfileState(null)
                setSelectedProfileCity(null)
                dispatch(getFavoriteRDProfiles(false, '', id))
                break
            case 'OpportunityName':
                setIsOpportunitiesLoading(true)
                setSearchEventNameValue('')
                dispatch(getFavoriteRDOpportunities(false, '', id))
                break
            case 'OpportunitiesClearAll':
                setIsOpportunitiesLoading(true)
                setDateRangePicker('')
                setSelectedEventType(null)
                setSelectedAudienceType(null)
                setSelectedOpportunityCountry(null)
                setSelectedOpportunityRegion(null)
                setSelectedOpportunityState(null)
                setSelectedOpportunityCity(null)
                dispatch(getFavoriteRDOpportunities(false, '', id))
                break
        }

    }

    const handleOpportuntiySelect = (select, value) => {
        switch (select) {
            case "EventType":
                setSelectedEventType(value)
                break
            case "AudienceType":
                setSelectedAudienceType(value)
                break
            case 'CountrySelect':
                setIsRegionsLoading(true)
                updateLocation('Regions', value)
                setSelectedOpportunityCountry(value)
                break
            case 'RegionSelect':
                setIsStatesLoading(true)
                updateLocation('States', value)
                setSelectedOpportunityRegion(value)
                break
            case 'StateSelect':
                setIsCitiesLoading(true)
                updateLocation('Cities', value)
                setSelectedOpportunityState(value)
                break
            case 'CitySelect':
                setSelectedOpportunityCity(value)
                break
        }
    }

    // ---- Pagination -----

    const handleProfilesPagination = (pageUrl) => {
        setIsProfilesLoading(true)
        dispatch(searchFavoriteRDProfiles(true, pageUrl, id, {
            speciality: specialityValues,
            industry: industryValues,
            personal_attr: personalAttrValues,
            country: selectedProfileCountry?.id || '',
            region: selectedProfileRegion?.id || '',
            state: selectedProfileState?.id || '',
            city: selectedProfileCity?.id || '',
            rd_name: searchNameValue
        }))
    }

    const handleOpportunitiesPagination = (pageUrl) => {
        setIsOpportunitiesLoading(true)
        dispatch(searchFavoriteRDOpportunities(true, pageUrl, id, {
            country_id: selectedOpportunityCountry?.id || null,
            state_id: selectedOpportunityState?.id || null,
            city: '',
            event_type: selectedEventType?.value || null,
            audience_type: selectedAudienceType?.value || null,
            from_date: dateRangePicker[0] || null,
            to_date: dateRangePicker[1] || null,
            txt_search: ''
        }))
    }

    const makeDate = (range) => {
        const arr = []
        range.map(i => {
            const date = new Date(i)
            const year = date.getFullYear()
            let month = (1 + date.getMonth()).toString()
            month = month.length > 1 ? month : `0${month}`
            let day = date.getDate().toString()
            day = day.length > 1 ? day : `0${day}`
            arr.push(`${month}-${day}-${year}`)
            return true
        })
        setDateRangePicker(arr)
    }

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
        handleResetSearch('OpportunitiesClearAll')
        handleResetSearch('ProfilesClearAll')
        setRegionOptions(null)
        setStateOptions(null)
        setCityOptions(null)
        dispatch(nullResponse(NULL.BRANDS_REGIONS_BY_COUNTRY_ID))
        dispatch(nullResponse(NULL.BRANDS_STATES_BY_REGIONS_ID))
        dispatch(nullResponse(NULL.BRANDS_CITIES_BY_STATES_ID))
    }

    const UpcomingOppoWrapper = styled.div`
        ul {
            list-style: none;
            padding: 0;
        }

        li {
            font-weight: 600;

            span {
                display: inline-block;
                margin-right: 25px;
                font-weight: 400;
                width: 6rem;
                white-space: nowrap;
            }
        }
    `

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatRd', JSON.stringify(user))
        history.push(`/brands/apps/chat/chatBrands/${user.id}`)
    }

    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearchByEventName()
        }
    }

    const onEnterSearch1 = (e) => {
        if (e.key === 'Enter') {
            handleSearchByName()
        }
    }

    return (
        <React.Fragment>
            <div className="ecommerce-application">
                <h4 className="mb-2">Favorites</h4>
                <Card>
                    <Nav className="mb-0" tabs>
                        <NavItem>
                            <NavLink
                                active={active === '1'}
                                onClick={() => {
                                    toggle('1')
                                }}
                            >
                                Profiles
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={active === '2'}
                                onClick={() => {
                                    toggle('2')
                                }}
                            >
                                Opportunities
                            </NavLink>
                        </NavItem>

                    </Nav>
                </Card>
                <TabContent className='py-50' activeTab={active}>
                    <TabPane tabId='1'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <InputGroup size='lg' className="">
                                            <InputGroupAddon addonType='append'>
                                                <Button tag={Link} to={`/dashboard/brands/nutritions`} color='primary' outline>
                                                    All RDs
                                                </Button>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType='append'>
                                                <Button tag={Link} to={`/dashboard/brands/my-rds`} color='primary' outline>
                                                    My RDs
                                                </Button>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType='append'>
                                                <Button tag={Link} to={`/dashboard/brands/favorite-folders`} color='primary' outline>
                                                    Favorites
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                    <Col md="6">
                                        <InputGroup size='lg' className="">
                                            <Input placeholder='Search By Name' value={searchNameValue} onChange={(e) => setSearchNameValue(e.target.value)}
                                                   onKeyDown={onEnterSearch1}/>
                                            <InputGroupAddon addonType='append'>
                                                <Button color='primary' onClick={handleSearchByName} outline>
                                                    Search
                                                </Button>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType='append'>
                                                <Button color='primary' onClick={() => handleResetSearch('ProfileName')} outline>
                                                    Reset
                                                </Button>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType='append'>
                                                <Button
                                                    className='h-100'
                                                    color='primary'
                                                    onClick={() => setShowProfileFilters(!showProfileFilters)}
                                                >
                                                    <Filter className='' size={18}/>
                                                    <span></span>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {showProfileFilters && <Card className='my-0 filters-right-sidebar'>
                            <CardHeader className="card-header-c">
                                <h3 className='filter-heading my-0'>Profile Filters</h3>
                                <Button
                                    className='h-100'
                                    color='danger'
                                    onClick={() => setShowProfileFilters(false)}
                                >
                                    <X size={18}/>
                                </Button>
                            </CardHeader>
                            <CardBody className="pt-2 overflow-y-auto">
                                <h5 className='filter-title mt-0 mb-2'>Speciality</h5>
                                <div className='d-flex flex-wrap' onChange={e => handleProfileFiltersChange('SpecialityChange', e.target.name, e.target.checked)}>
                                    {goalsAttributes?.speciality?.map((brand, i) => {
                                        return (
                                            <CustomInput
                                                key={brand.label}
                                                type='checkbox'
                                                className="mx-2 mb-1"
                                                name={brand.id}
                                                id={brand.label + i}
                                                label={brand.label + brand.id}
                                            />
                                        )
                                    })}
                                </div>
                                <h5 className='filter-title mt-2 mb-2'>Industry</h5>
                                <div className='d-flex flex-wrap' onChange={(e) => handleProfileFiltersChange('IndustryChange', e.target.name, e.target.checked)}>
                                    {goalsAttributes?.industry?.map(brand => {
                                        return (
                                            <CustomInput
                                                key={brand.label}
                                                type='checkbox'
                                                className="mx-2 mb-1"
                                                id={brand.label}
                                                name={brand.id}
                                                label={brand.label + brand.id}
                                            />
                                        )
                                    })}
                                </div>
                                <h5 className='filter-title mt-2 mb-2'>Personal Characteristics</h5>
                                <div className='d-flex flex-wrap' onChange={e => handleProfileFiltersChange('PersonalAttrChange', e.target.name, e.target.checked)}>
                                    {goalsAttributes?.personal_attributes?.map(brand => {
                                        return (
                                            <CustomInput
                                                key={brand.label}
                                                className="mx-2 mb-1"
                                                type='checkbox'
                                                id={brand.label}
                                                name={brand.id}
                                                label={brand.label + brand.id}
                                            />)
                                    })}
                                </div>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>Country</h6>
                                    <Select
                                        options={countryOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedProfileCountry}
                                        onChange={(value) => handleProfilesSelect("CountrySelect", value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>Region</h6>
                                    <div className="overlay-select-parent">
                                        {isRegionsLoading && <div className="overlay-select"></div>}
                                        <Select
                                            isClearable={false}
                                            options={regionOptions}
                                            value={selectedProfileRegion}
                                            className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Select Region"
                                            onChange={(value) => handleProfilesSelect('RegionSelect', value)}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>State</h6>
                                    <div className="overlay-select-parent">
                                        {isStatesLoading && <div className="overlay-select"></div>}
                                        <Select
                                            options={stateOptions}
                                            value={selectedProfileState}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(value) => handleProfilesSelect("StateSelect", value)}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>City</h6>
                                    <div className="overlay-select-parent">
                                        {isCitiesLoading && <div className="overlay-select"></div>}
                                        <Select
                                            isClearable={false}
                                            options={cityOptions}
                                            value={selectedProfileCity}
                                            className='react-select'
                                            classNamePrefix='select'
                                            placeholder="Select Cities"
                                            onChange={(value) => handleProfilesSelect('CitySelect', value)}
                                        />
                                    </div>
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button.Ripple color='primary' className="mb-1" onClick={handleProfileFiltersSearch} block>
                                    Apply Filters
                                </Button.Ripple>
                                <Button.Ripple onClick={() => handleResetSearch('ProfilesClearAll')} color='danger' outline block>
                                    Clear All Filters
                                </Button.Ripple>
                            </CardFooter>
                        </Card>}
                        {isProfilesLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                        {!isProfilesLoading && (
                            <div className="ecommerce-application">
                                {brandsDashboardStoreSlice?.favoriteRDProfilesRes?.data.length === 0 && (<h5 className="text-center mt-5">Record doesn't exists</h5>)}
                                <div
                                    className={classnames({
                                        'grid-view brands-nutritions': true,
                                        // 'grid-view brands-nutritions': activeView === 'grid',
                                        'list-view': activeView === 'list'
                                    })}
                                >
                                    {brandsDashboardStoreSlice?.favoriteRDProfilesRes?.data.map(item => (
                                        <Card className='ecommerce-card'>
                                            {/* <div className='row align-items-center justify-content-between p-1'>
                        </div> */}
                                            <div className="item-img text-center mx-auto mt-2">
                                                <Avatar img={item.profile_img} imgHeight="120" imgWidth="120"/>
                                            </div>
                                            <CardBody>
                                                <h5 className='item-name text-center'>
                                                    {item.name}
                                                </h5>
                                                {/* <CardText className='item-description text-center'>{item.attributes?.industry[0]?.value || 'N/A'} | {item.attributes?.speciality[0]?.value || 'N/A'}</CardText> */}
                                                {/* <CardText className='item-description text-center'>{item.location?.region?.value || 'N/A'} | {item.location?.country?.value || 'N/A'}</CardText> */}
                                            </CardBody>
                                            <div className='item-options text-center'>
                                                {/* to={`/dashboard/rd/brand-detail/${item.id}`}  */}
                                                <Button tag={Link} to={`/dashboard/brands/nutritions/${item.id}`} color='light' className="btn-wishlist">
                                                    <User className="mr-50" size={14}/>
                                                    <span>Profile</span>
                                                </Button>
                                                <Button onClick={() => handleRedirectMessage(item)} color='primary' className="btn-cart move-cart">
                                                    <Mail className="mr-50" size={14}/>
                                                    <span>Message</span>
                                                </Button>
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                                <CustomPagination pageLinks={profilesPaginationInfo?.pages} onPaginationClick={handleProfilesPagination}/>
                            </div>
                        )}
                    </TabPane>
                    <TabPane tabId='2'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <InputGroup size='lg' className="">
                                            <Input placeholder='Search By Event' value={searchEventNameValue} onChange={(e) => setSearchEventNameValue(e.target.value)}
                                                   onKeyDown={onEnterSearch}/>
                                            <InputGroupAddon addonType='append'>
                                                <Button color='primary' onClick={handleSearchByEventName} outline>
                                                    Search
                                                </Button>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType='append'>
                                                <Button color='primary' onClick={() => handleResetSearch('OpportunityName')} outline>
                                                    Reset
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                    <Col md="6" className="text-md-right text-center mt-md-0 mt-2">
                                        <Button
                                            className='h-100'
                                            color='primary'
                                            onClick={() => setShowOpportunitiesFilters(!showOpportunitiesFilters)}
                                        >
                                            <Filter className='mr-1' size={18}/>
                                            <span>Filters</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {showOpportunitiesFilters && <Card className='my-0 filters-right-sidebar'>
                            <CardHeader className="card-header-c">
                                <h3 className='filter-heading my-0'>Opportunities <br/>Filters</h3>
                                <Button
                                    color='danger'
                                    onClick={() => setShowOpportunitiesFilters(false)}
                                >
                                    <X size={18}/>
                                </Button>
                            </CardHeader>
                            <CardBody className="pt-2 overflow-y-auto">
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>Date Range</h6>
                                    <Flatpickr
                                        className='form-control'
                                        id='date-range'
                                        value={dateRangePicker}
                                        options={{mode: 'range', dateFormat: 'm/d/Y'}}
                                        onChange={date => makeDate(date)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>Event Type</h6>
                                    <Select
                                        options={eventTypesOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedEventType}
                                        onChange={(e) => handleOpportuntiySelect("EventType", e)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>Audience Type</h6>
                                    <Select
                                        options={audienceTypesOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedAudienceType}
                                        onChange={(e) => handleOpportuntiySelect("AudienceType", e)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>Country</h6>
                                    <Select
                                        options={countryOptions}
                                        className='react-select'
                                        classNamePrefix='select'
                                        value={selectedOpportunityCountry}
                                        onChange={(value) => handleOpportuntiySelect("CountrySelect", value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>Region</h6>
                                    <div className="overlay-select-parent">
                                        {isRegionsLoading && <div className="overlay-select"></div>}
                                        <Select
                                            isClearable={false}
                                            options={regionOptions}
                                            value={selectedOpportunityRegion}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(value) => handleOpportuntiySelect('RegionSelect', value)}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>States</h6>
                                    <div className="overlay-select-parent">
                                        {isStatesLoading && <div className="overlay-select"></div>}
                                        <Select
                                            options={stateOptions}
                                            className='react-select'
                                            classNamePrefix='select'
                                            value={selectedOpportunityState}
                                            onChange={(value) => handleOpportuntiySelect("StateSelect", value)}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <h6 className='filter-title mt-1'>City</h6>
                                    <div className="overlay-select-parent">
                                        {isCitiesLoading && <div className="overlay-select"></div>}
                                        <Select
                                            isClearable={false}
                                            options={cityOptions}
                                            value={selectedOpportunityCity}
                                            className='react-select'
                                            classNamePrefix='select'
                                            onChange={(value) => handleOpportuntiySelect('CitySelect', value)}
                                        />
                                    </div>
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button.Ripple color='primary' className="mb-1" onClick={handleOpportunitiesFiltersSearch} block>
                                    Apply Filters
                                </Button.Ripple>
                                <Button.Ripple onClick={() => handleResetSearch('OpportunitiesClearAll')} color='danger' outline block>
                                    Clear All Filters
                                </Button.Ripple>
                            </CardFooter>
                        </Card>}
                        {isOpportunitiesLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                        {!isOpportunitiesLoading && (
                            <Fragment>
                                {brandsDashboardStoreSlice?.favoriteRDOpportunitiesRes?.data.length === 0 && (<h5 className="text-center mt-5">Record doesn't exists</h5>)}
                                <Row>
                                    {brandsDashboardStoreSlice?.favoriteRDOpportunitiesRes?.data.map(item => (
                                        <Col sm="6" md="4">
                                            <Card className='ecommerce-card' key={item.event_title}>
                                                <CardHeader>
                                                    <CardTitle tag={Link} to={`/dashboard/brands/nutrition-detail/${item.id}`}>{item.title}</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <UpcomingOppoWrapper>
                                                        <ul>
                                                            <li><span>Location: </span>{item.country}</li>
                                                            <li><span>Start Date: </span>{item.start_datetime}</li>
                                                            <li><span>End Date: </span>{item.end_datetime}</li>
                                                            <li><span>Type of Event: </span>{item.event_type}</li>
                                                        </ul>
                                                    </UpcomingOppoWrapper>
                                                    <div className='d-flex align-items-center'>
                                                        <Avatar tag={Link} to={`/dashboard/brands/nutrition-detail/${item.id}`} img={item.profile_img} className="mr-1"
                                                                imgHeight='40' imgWidth='40'/>
                                                        <div className='user-nav'>
                                                            <span className='user-name font-weight-bold'>{item.rd_name}</span>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                                <CustomPagination pageLinks={opportunitiesPaginationInfo?.pages} onPaginationClick={handleOpportunitiesPagination}/>
                            </Fragment>
                        )}
                    </TabPane>
                </TabContent>

            </div>
        </React.Fragment>
    )
}

export default FavouriteDetails
