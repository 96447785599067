import {useState, useEffect, useRef} from 'react'
import ReactDOM from 'react-dom'
import Avatar from '@components/avatar'
import {useDispatch, useSelector} from 'react-redux'
import {sendMsg} from './store/actions'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {MessageSquare, Menu} from 'react-feather'
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import BlankUser from "@src/assets/images/avatars/avatar-blank.png"
import {Link} from "react-router-dom"
import {PropTypes} from "prop-types"

const ChatLog = props => {
    const {handleUser, handleUserSidebarRight, handleSidebar, store, userSidebarLeft, currentUser} = props

    const adminStore = useSelector(store => store.chatAdmin)

    const chatArea = useRef(null)
    const dispatch = useDispatch()

    const [msg, setMsg] = useState('')

    const scrollToBottom = () => {
        const chatContainer = ReactDOM.findDOMNode(chatArea.current)
        chatContainer.scrollTop = Number.MAX_SAFE_INTEGER
    }

    useEffect(() => {
        const selectedUserLen = Object.keys(adminStore?.singleUserChatDetail).length
        if (selectedUserLen) {
            scrollToBottom()
        }

    }, [adminStore])

    const renderChats = () => {
        const seletedRDFromSelect = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_SELECTED_RD_USER_FOR_ADMIN_CHAT}`))

        return adminStore?.singleUserChatDetail?.map((item, index) => {
            return (
                <div
                    key={index}
                    className={classnames('chat', {
                        'chat-left': item.from_user_id === seletedRDFromSelect?.value
                    })}
                >
                    <div className='chat-avatar'>
                        {item.from_user_id === seletedRDFromSelect?.value && (
                            <Link to={`/admin/manage-rd/${item.from_user_id}`}>
                                <Avatar className='box-shadow-1 cursor-pointer' img={item.profile_pic || BlankUser}/>
                            </Link>
                        )}
                        {item.from_user_id !== seletedRDFromSelect?.value && (
                            <Link to={`/admin/manage-brands/${item.from_user_id}`}>
                                <Avatar className='box-shadow-1 cursor-pointer' img={item.profile_pic || BlankLogo}/>
                            </Link>
                        )}
                    </div>

                    {item.media ? (
                        <div className="chat-body">
                            <div className="chat-content">
                                <p><a className="font-weight-bolder text-dark" href={item.media} target='_blank'>{item.file_name}</a></p>
                            </div>
                        </div>

                    ) : (
                        <div className="chat-body">
                            <div className="chat-content text-break" style={{whiteSpace: "break-spaces"}}>
                                <div dangerouslySetInnerHTML={{__html: item.message}}></div>
                                <p className="child" style={{marginTop: "10px", textAlign: "right"}}>
                                    {item.timezone}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )
        })
    }

    const handleAvatarClick = obj => {
        handleUserSidebarRight()
        handleUser(obj)
    }

    const handleStartConversation = () => {
        if (!Object.keys(adminStore?.singleUserChatDetail)?.length && !userSidebarLeft && window.innerWidth <= 1200) {
            handleSidebar()
        }
    }

    const handleSendMsg = e => {
        e.preventDefault()
        if (msg.length) {
            dispatch(sendMsg({...adminStore?.singleUserChatDetail, message: msg}))
            setMsg('')
        }
    }

    const ChatWrapper = Object.keys(adminStore?.singleUserChatDetail).length && adminStore?.singleUserChatDetail ? PerfectScrollbar : 'div'

    return (
        <div className='chat-app-window w-100'>
            <div className={classnames('start-chat-area', {'d-none': Object.keys(adminStore?.singleUserChatDetail).length})}>
                <div className='start-chat-icon mb-1'>
                    <MessageSquare/>
                </div>
                <h4 className='sidebar-toggle start-chat-text'
                    onClick={handleStartConversation}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault()
                            handleStartConversation()
                        }
                    }}
                    role="button"
                    tabIndex="0"
                    aria-label="Start Conversation"
                >
                    Start Conversation
                </h4>
            </div>
            {Object.keys(adminStore?.singleUserChatDetail).length ? (
                <div className={classnames('active-chat', {'d-none': adminStore?.singleUserChatDetail === null})}>
                    <div className='chat-navbar'>
                        <header className='chat-header'>
                            <div className='d-flex align-items-center'>
                                <div className='sidebar-toggle d-block d-lg-none mr-1'
                                     onClick={handleSidebar}
                                     role="button"
                                     tabIndex="0"
                                     aria-label="Toggle Sidebar"
                                     onKeyDown={(e) => {
                                         if (e.key === 'Enter' || e.key === ' ') {
                                             e.preventDefault()
                                             handleSidebar()
                                         }
                                     }}
                                >
                                    <Menu size={21}/>
                                </div>
                                <h6 className='mb-0'>{adminStore?.singleUserChatDetail.name}</h6>
                            </div>
                        </header>
                    </div>

                    <ChatWrapper ref={chatArea} className='user-chats' options={{wheelPropagation: false}}>
                        {adminStore?.singleUserChatDetail ? <div className='chats'>{renderChats()}</div> : null}
                    </ChatWrapper>
                </div>
            ) : null}
        </div>
    )
}


ChatLog.propTypes = {
    handleUser: PropTypes.func.isRequired,
    handleUserSidebarRight: PropTypes.func.isRequired,
    handleSidebar: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
    userSidebarLeft: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired
}

export default ChatLog
