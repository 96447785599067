import {MoreHorizontal} from 'react-feather'
import {PropTypes} from "prop-types"

const VerticalNavMenuSectionHeader = ({item, index}) => {
    return (
        <li className='navigation-header'>
      <span>
          {item.header}
      </span>
            <MoreHorizontal className='feather-more-horizontal'/>
        </li>
    )
}

VerticalNavMenuSectionHeader.propTypes = {
    item: PropTypes.shape({
        header: PropTypes.string.isRequired
    }).isRequired,
    index: PropTypes.number.isRequired
}

export default VerticalNavMenuSectionHeader
