import {Link} from "react-router-dom"
import Avatar from "@components/avatar"
import {Heart} from "react-feather"
import {
    FaMapMarkerAlt,
    FaUserFriends,
    FaCalendarAlt,
    FaDollarSign,
    FaHandshake
} from "react-icons/fa"
import {Card, CardHeader, CardTitle, CardBody, Row, Col} from "reactstrap"
import {Fragment} from "react"
import BlankProfile from "@src/assets/images/avatars/avatar-blank.png"
import {PropTypes} from "prop-types"

const ProductCards = (props) => {
    const {
        opportunitySlice,
        handleAddToFavorite
    } = props

    const handleFavorite = (profile) => {
        handleAddToFavorite(profile)
    }

    const renderProducts = () => {
        if (opportunitySlice?.opportunitiesData?.data.length) {
            return opportunitySlice?.opportunitiesData?.data.map((item) => {
                return (
                    <Fragment>
                        <Col sm="6" md="4" lg="3">
                            <Card className="ecommerce-card event-card-at-profile" key={item.event_title}>
                                <div className="d-flex justify-content-between pt-2 px-2">
                                    {item.is_new && (
                                        <small className="border border-1 px-75 py-25 border-muted text-muted">
                                            New
                                        </small>
                                    )}
                                    {item.is_favorite && (
                                        <Heart
                                            className="text-muted cursor-pointer ml-auto"
                                            color="red"
                                            size={14}
                                            onClick={() => handleFavorite(item)}
                                        />
                                    )}
                                    {!item.is_favorite && (
                                        <Heart
                                            className="text-muted cursor-pointer ml-auto"
                                            size={14}
                                            onClick={() => handleFavorite(item)}
                                        />
                                    )}
                                </div>
                                <Link to={`/dashboard/brands/nutrition-detail/${item.id}`}>
                                    <CardHeader className="pt-1">
                                        <CardTitle className="text-primary font-small-4">
                                            {item.event_title}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="text-dark" style={{height: '170px'}}>
                                            {(!!item.city || !!item.state) && <p><FaMapMarkerAlt size={17} className="mr-1"/>{item.city}, {" "}{item.state}</p>}
                                            {!!item.type_of_event && <p><FaHandshake size={17} className="mr-1"/>{item.type_of_event}</p>}
                                            {!!item.exp_reach && item.exp_reach !== 0 && item.exp_reach !== '0' && <p>
                                                <FaUserFriends size={17} className="mr-1"/>{item.exp_reach}</p>}
                                            {!!item.cost_of_partnership && item.cost_of_partnership !== 0 && item.cost_of_partnership !== '0' && <p>
                                                <FaDollarSign size={17} className="mr-1"/>{item.cost_of_partnership}</p>}
                                            <p><FaCalendarAlt size={17} className="mr-1"/>{item.start_date}</p>
                                        </div>

                                        <div className="d-flex align-items-center mt-2">
                                            <Avatar
                                                img={item.profile_img || BlankProfile}
                                                className="mr-1"
                                                imgHeight="70"
                                                imgWidth="70"
                                            />
                                            <div className="user-nav">
                        <span className="user-name font-weight-bold text-primary">
                          {item.organizer}
                        </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Link>
                            </Card>
                        </Col>
                    </Fragment>
                )
            })
        }
    }

    return (
        <div
            className="row match-height"
        >
            {renderProducts()}
        </div>
    )
}

ProductCards.propTypes = {
    opportunitySlice: PropTypes.shape({
        opportunitiesData: PropTypes.shape({
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                    event_title: PropTypes.string.isRequired,
                    is_new: PropTypes.bool,
                    is_favorite: PropTypes.bool,
                    city: PropTypes.string,
                    state: PropTypes.string,
                    type_of_event: PropTypes.string,
                    exp_reach: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                    cost_of_partnership: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                    start_date: PropTypes.string.isRequired,
                    profile_img: PropTypes.string,
                    organizer: PropTypes.string.isRequired
                })
            )
        })
    }).isRequired,
    handleAddToFavorite: PropTypes.func.isRequired
}

export default ProductCards
