import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'
import Avatar from '@components/avatar'
import classnames from 'classnames'
import {Heart, Mail, User} from 'react-feather'
import {Card, CardBody, CardText, Button} from 'reactstrap'
import img1 from '@src/assets/images/avatars/avatar-blank.png'
import {PropTypes} from "prop-types"

const ProductCards = props => {
    const {activeView, nutritionExperts, handleAddToFavorite} = props
    const history = useHistory()

    const handleFavorite = (profile) => {
        handleAddToFavorite(profile)
    }

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatRd', JSON.stringify(user))
        history.push(`/brands/apps/chat/chatBrands/${user.id}`)
    }

    const renderProducts = () => {
        if (nutritionExperts?.length) {
            return nutritionExperts.map((item, i) => {
                return (
                    <Card className='ecommerce-card' key={item.name + i}>
                        <div className="row align-items-center justify-content-between p-1">
                            {item.is_new && <div className="col-auto"><small className="border border-1 px-75 py-25 border-muted text-muted">New</small></div>}
                            <div className="col-auto ml-auto">
                                {item.is_favorite && <Heart
                                    className='text-muted cursor-pointer'
                                    color="red"
                                    size={14}
                                    onClick={() => handleFavorite(item)}
                                />}
                                {!item.is_favorite && <Heart
                                    className='text-muted cursor-pointer'
                                    size={14}
                                    onClick={() => handleFavorite(item)}
                                />}
                            </div>
                        </div>
                        <div className='item-img text-center mx-auto'>
                            <Link to={`/dashboard/brands/nutritions/${item.id}`}>
                                <Avatar img={item.profile_img || img1} imgHeight='120' imgWidth='120'/>
                            </Link>
                        </div>
                        <CardBody>
                            <h4 className='item-name'>
                                <Link className='text-body' to={`/dashboard/brands/nutritions/${item.id}`}>
                                    {item.name}
                                </Link>
                            </h4>
                            <CardText className='item-location'>
                                {item?.city[0]?.label}
                                {(item?.city[0]?.label && item?.state[0]?.label) ? " | " : ""}
                                {item?.state[0]?.label}
                            </CardText>
                            <CardText className='item-description'>{item?.industry[0]?.value || 'N/A'} | {item?.speciality[0]?.value || 'N/A'}</CardText>
                        </CardBody>
                        <div className='item-options text-center'>
                            <Button tag={Link} to={`/dashboard/brands/nutritions/${item.id}`} color='light' className="btn-wishlist">
                                <User className="mr-50" size={14}/>
                                <span>Profile</span>
                            </Button>
                            <Button onClick={() => handleRedirectMessage(item)} color='primary' className="btn-cart move-cart">
                                <Mail className="mr-50" size={14}/>
                                <span>Message</span>
                            </Button>
                        </div>
                    </Card>
                )
            })
        }
    }

    return (
        <div
            className={classnames({
                'grid-view brands-nutritions': activeView === 'grid',
                'list-view': activeView === 'list'
            })}
        >
            {renderProducts()}
        </div>
    )
}

ProductCards.propTypes = {
    activeView: PropTypes.oneOf(['grid', 'list']).isRequired,
    nutritionExperts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            profile_img: PropTypes.string,
            city: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string
                })
            ),
            state: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string
                })
            ),
            industry: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string
                })
            ),
            speciality: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string
                })
            ),
            is_new: PropTypes.bool,
            is_favorite: PropTypes.bool
        })
    ).isRequired,
    handleAddToFavorite: PropTypes.func.isRequired
}

export default ProductCards
