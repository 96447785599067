import axios from 'axios'
import { PENDING_RD, NULL } from '../actionType'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getManagePendingRDsData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${process.env.REACT_APP_BASEURL}admin/load-rd-list?profile_status=pending`
  return dispatch => {
    // await axios.get(`${process.env.REACT_APP_BASEURL}admin/load-rd-list?profile_status=pending`, {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'MANAGE_PENDING_RD_DATA',
        pendingRds: response.data
      })
    })
  }
}

export const changeRdProfileStatus = (id) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("id", id)
    axios.post(`${process.env.REACT_APP_BASEURL}admin/approve-rd-profile`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: PENDING_RD.PROFILE_STATUS,
        pendingRDUpdatedStatus: response.data
      })
    })
  }
}

// ** Search pending Rds data
export const getManagePendingRDsSearchData = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = `${pageUrl}&profile_status=pending` : endpoint = `${process.env.REACT_APP_BASEURL}admin/load-rd-list?profile_status=pending`
  return dispatch => {
    axios.post(endpoint, {
      name: body.name,
      email: body.email,
      city: body.city,
      state_id: body.state,
      country_id: body.country,
      region: body.region,
      industry_id: body.industry,
      speciality_id: body.speciality_id
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'MANAGE_PENDING_RD_DATA',
        pendingRds: response.data
      })
    })
  }
}

// ** Search table Data
export const getCountries = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}get-countries`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'COUNTRIES_DATA',
        countries: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'SEARCH_MANAGE_RD_GET_DATA',
        allData: "response.data.allData",
        manageRDsList: [1, 2, 3, 4],
        totalPages: "response.data.total"
      })
    })
  }
}

// ** Search table Data
export const getStatesById = (country_id) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("country_id", country_id)
    axios.post(`${process.env.REACT_APP_BASEURL}get-states`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'STATES_DATA',
        states: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'SEARCH_MANAGE_RD_GET_DATA',
        allData: "response.data.allData",
        manageRDsList: [1, 2, 3, 4],
        totalPages: "response.data.total"
      })
    })
  }
}
export const getStates = () => {
  return dispatch => {
    axios.post(`${process.env.REACT_APP_BASEURL}get-states`, {
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch({
        type: 'STATES_DATA',
        states: response.data
      })
    }).catch(error => {
      dispatch({
        type: 'SEARCH_MANAGE_RD_GET_DATA',
        allData: "response.data.allData",
        manageRDsList: [1, 2, 3, 4],
        totalPages: "response.data.total"
      })
    })
  }

}

// ** Search table Data
export const getIndustries = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'INDUSTRIES_DATA',
        industries: response.data.industry
      })
    }).catch((error) => {
      dispatch({
        type: 'SEARCH_MANAGE_RD_GET_DATA',
        allData: "response.data.allData",
        manageRDsList: [1, 2, 3, 4],
        totalPages: "response.data.total"
      })
    })
  }
}

export const getSpecialities = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'SPECIALITIES_DATA',
        specialities: response.data.speciality
      })
    }).catch((error) => {
      dispatch({
        type: 'SEARCH_MANAGE_RD_GET_DATA',
        allData: "response.data.allData",
        manageRDsList: [1, 2, 3, 4],
        totalPages: "response.data.total"
      })
    })
  }
}

// ** Delete RD Data
export const deletePendingManageRDsData = (id) => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}admin/delete-rd?id=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: 'DELETE_MANAGE_RD_GET_DATA',
        deleteRDsData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'SEARCH_MANAGE_RD_GET_DATA',
        allData: "response.data.allData",
        manageRDsList: [1, 2, 3, 4],
        totalPages: "response.data.total"
      })
    })
  }
}

// ** Pending RD Profile
export const getRDProfileInfo = (id) => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}admin/view-rd-details/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: PENDING_RD.PROFILE_INFO,
        profileInfo: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: PENDING_RD.ERROR,
        error
      })
    })
  }
}

// ** Approve Pending RD Profile
export const approveRDProfile = (id) => {
  return dispatch => {
    axios.post(`${process.env.REACT_APP_BASEURL}admin/approve-rd-profile`, {
      id
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: PENDING_RD.PROFILE_APPROVE,
        profileApproval: response.data
      })
    }).catch((error) => {
      dispatch({
        type: PENDING_RD.ERROR,
        error
      })
    })
  }
}

export const exportPendingRdsData = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BASEURL}admin/load-rd-list?profile_status=pending&data-type=export`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: PENDING_RD.PENDING_RD_EXPORT,
        exportPendingRds: response.data.data
      })
    }).catch(error => {
      dispatch({
        type: PENDING_RD.ERROR,
        error
      })
    })
  }
}

export const nullSearchData = (listName) => {
  return dispatch => {
    switch (listName) {
      case 'pendingRds':
        dispatch({
          type: 'MANAGE_PENDING_RD_SEARCH_DATA',
          searchPendingRds: null
        })
        break
      case NULL.PROFILE_STATUS:
        dispatch({
          type: PENDING_RD.PROFILE_STATUS,
          pendingRDUpdatedStatus: null
        })
        break
    }
  }
}
