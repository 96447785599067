import {useState, Fragment, useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"
import {useParams, useHistory} from "react-router-dom"
import {Alert, Card, CardBody, Spinner} from "reactstrap"
import {
    onAccountVerificationError,
    onAccountVerificationErrorReset
} from "@src/views/apps/opportunitiesBrands/store/actions/index"

const VerificationError = () => {
    const {token} = useParams()
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        rdAccountFailLoading,
        rdAccountFailFullFill,
        rdAccountFailError
    } = useSelector((store) => store.brandsOppoprunities)

    useEffect(() => {
        const data = {
            token
        }
        dispatch(onAccountVerificationError(data))
    }, [])

    useEffect(() => {
        if (rdAccountFailFullFill?.status === true) {
            dispatch(onAccountVerificationErrorReset())
            history.push('/dashboard/rd/payment-settings?type=fail')
        }
    }, [rdAccountFailFullFill])

    return (
        <Fragment>
            <Card>
                <CardBody>
                    {rdAccountFailError?.response?.data && (
                        <p className="bg-danger p-1 text-white">{rdAccountFailError?.response?.data?.msg}</p>
                    )}
                    {rdAccountFailLoading && (
                        <div className="mt-5 mb-5">
                            <p className="text-center">
                                <Spinner color="primary"/>
                            </p>
                            <p className="text-center font-medium-3 font-weight-bold">
                                Please wait! We are updating your bank information. You will be redirected soon.
                            </p>
                        </div>
                    )}
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default VerificationError
