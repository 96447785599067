import {useEffect, useState, Fragment} from "react"
import DataTable from "react-data-table-component"
import {ChevronDown, MoreHorizontal, Trash, CheckCircle} from "react-feather"
import {Formik} from "formik"
import * as yup from "yup"
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Row,
    Col,
    Input,
    Form,
    Button,
    Label,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge
} from "reactstrap"
import {useSelector, useDispatch} from "react-redux"
import {useHistory} from 'react-router-dom'
import {
    getCountries,
    createManageAchBankCard,
    getBankAccountDetails,
    verifyAccount,
    deleteAccount,
    nullResponse
} from "./store/actions"
import {NULL} from './store/actionType'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const ManageAchForm = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [accountVerifyAccount, setAccountVerifyModal] = useState(false)
    const [deleteBankAccountModal, setDeleteBankAccountModal] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [bankAccountId, setBankAccountId] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [successMsg, setSuccessMsg] = useState('')
    const [visible, setVisible] = useState(false)
    const [error, setError] = useState('')
    const [isBankAccountSubmitting, setIsBankAccountSubmitting] = useState(false)
    const [submitBankCardRes, setSubmitBankCardRes] = useState(null)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const achSlice = useSelector((store) => store.manageAch)
    const dispatch = useDispatch()
    const history = useHistory()

    const sweetAlert = withReactContent(Swal)

    useEffect(() => {
        if (!!achSlice?.bankAccountDetail) {
            setIsTableLoading(false)
        }

        if (!!achSlice?.submitBankCard) {
            setIsBankAccountSubmitting(false)
            setCaptchaError(false)
            setCaptchaValue(null)
            const {status, msg} = achSlice.submitBankCard
            setSubmitBankCardRes(achSlice.submitBankCard)
            setVisible(true)
            if (!status) {
                setError(msg)
            } else {
                setSuccessMsg(msg)
            }
            dispatch(nullResponse(NULL.SUBMIT_BANK_CARD))
        }

        if (!!achSlice?.accountVerification) {
            const {status, msg} = achSlice.accountVerification
            setVisible(true)
            if (!status) {
                setError(msg)
                const timer = setTimeout(() => {
                    setError("")
                    setVisible(false)
                    dispatch(nullResponse(NULL.VERIFY_ACCOUNT))
                    clearTimeout(timer)
                }, 4000)
                dispatch(getBankAccountDetails())
                setIsTableLoading(false)
            } else {
                setSuccessMsg(msg)
                const timer = setTimeout(() => {
                    setSuccessMsg("")
                    setVisible(false)
                    dispatch(nullResponse(NULL.VERIFY_ACCOUNT))
                    clearTimeout(timer)
                }, 4000)
                dispatch(getBankAccountDetails())
                setIsTableLoading(false)
            }
        }

        if (!!achSlice?.accountDelete) {
            const {status, msg} = achSlice.accountDelete
            setVisible(true)
            if (status === false) {
                setError(msg)
                setTimeout(() => {
                    setVisible(false)
                    setError("")
                    dispatch(nullResponse(NULL.DELETE_ACCOUNT))
                }, 4000)
                dispatch(getBankAccountDetails())
                setIsTableLoading(false)
            } else {
                setSuccessMsg(msg)
                setTimeout(() => {
                    setSuccessMsg("")
                    setVisible(false)
                    dispatch(nullResponse(NULL.DELETE_ACCOUNT))
                }, 4000)
                dispatch(getBankAccountDetails())
                setIsTableLoading(false)
            }
        }
    }, [achSlice])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')

        setIsTableLoading(true)
        dispatch(getCountries())
        dispatch(getBankAccountDetails())
    }, [])

    const verifyBankAccount = (values) => {
        setIsTableLoading(true)
        const params = {
            bank_account_id: bankAccountId,
            amount_1: values.firstDeposit,
            amount_2: values.secondDeposit
        }
        dispatch(verifyAccount(params))
        setAccountVerifyModal(!accountVerifyAccount)
        dispatch(getBankAccountDetails())
        setIsTableLoading(true)
        values.firstDeposit = ""
        values.secondDeposit = ""
        setIsTableLoading(true)
    }

    const deleteBankdAccount = () => {
        // setIsTableLoading(true)
        dispatch(deleteAccount(bankAccountId))
        setDeleteBankAccountModal(!deleteBankAccountModal)
        setIsTableLoading(true)
        dispatch(getBankAccountDetails())
    }

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: "Account Holder Name",
            selector: "account_holder_name",
            sortable: true,
            minWidth: "180px"
        },
        {
            name: "Bank Name",
            selector: "bank_name",
            sortable: true,
            minWidth: "120px",
            cell: (row) => {
                return (
                    <Fragment>
                        {row.bank_name}
                        {row.is_default === true ? <Badge color="success" className="ml-1">Default</Badge> : ''}
                    </Fragment>
                )
            }
        },
        {
            name: "Account Number ",
            selector: "last4",
            sortable: true,
            minWidth: "120px"
        },
        {
            name: "Routing Number",
            selector: "routing_number",
            sortable: true,
            minWidth: "70px"
        },
        {
            name: "Status",
            selector: "status",
            sortable: true,
            minWidth: "50px",
            cell: (row) => {
                return (
                    <Fragment>
                        {row.status === "verified" ? <Badge color="success" className="ml-1">Verified</Badge> : <Badge color="warning" className="ml-1">New</Badge>}
                    </Fragment>
                )
            }
        },
        {
            name: "Actions",
            allowOverflow: true,
            cell: (row) => {
                return (
                    <div className="d-flex pl-2">
                        <UncontrolledDropdown>
                            <DropdownToggle className="pr-1" tag="span">
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {row.status === "new" ? <DropdownItem onClick={() => {
                                    setAccountVerifyModal(true)
                                    setBankAccountId(row.account_id)
                                }}>
                                    <CheckCircle size={15}/>
                                    <span className='align-middle ml-50'>Verify</span>
                                </DropdownItem> : ""}
                                <DropdownItem onClick={() => {
                                    setDeleteBankAccountModal(true)
                                    setBankAccountId(row.account_id)
                                }}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50' onClick={() => setDeleteId(row.id)}>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    const dataToRender = () => {
        if (achSlice?.bankAccountDetail?.data?.length) {
            return achSlice?.bankAccountDetail?.data
        }
    }

    const addBankAccount = async (values) => {
        setVisible(false)
        setIsBankAccountSubmitting(true)
        const token = await executeRecaptcha('brand_manage_ach')
        if (token) {
            const params = {
                country_id: values.country_id,
                account_holder_name: values.account_holder_name,
                account_number: values.account_number,
                routing_number: values.routing_number,
                re_captcha_token: token
            }
            dispatch(createManageAchBankCard(params))
            values.country_id = ""
            values.account_holder_name = ""
            values.account_number = ""
            values.routing_number = ""
            dispatch(getBankAccountDetails())
            setIsTableLoading(true)
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    const onCaptchaChange = (value) => {
        setCaptchaError(false)
        setCaptchaValue(value)
    }
    return (
        <Fragment>
            <h3 className="mb-2">Manage ACH</h3>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Add Bank Account</CardTitle>
                </CardHeader>

                <CardBody>
                    <Alert color={!submitBankCardRes?.status ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                        <div className='alert-body'>
                            {!submitBankCardRes?.status ? error : successMsg}
                        </div>
                    </Alert>
                    <Formik
                        initialValues={{
                            country_id: "",
                            account_holder_name: "",
                            account_number: "",
                            routing_number: ""
                        }}
                        validationSchema={yup.object().shape({
                            country_id: yup.string().required("This field is required"),
                            account_holder_name: yup
                                .string()
                                .min(8)
                                .max(255)
                                .required("This field is required"),
                            account_number: yup.string().required("This field is required"),
                            routing_number: yup.string().required("This field is required")
                        })}
                        onSubmit={(values) => {
                            addBankAccount(values)
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values
                          }) => (
                            <Form className="mt-2" onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="nameMulti">Country</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                type="select"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.country_id}
                                                name={`country_id`}
                                                id="country_id"
                                            >
                                                <option value="">Select Country</option>
                                                {achSlice?.countries &&
                                                    achSlice?.countries.map((country) => (
                                                        <option value={country.id}>{country.value}</option>
                                                    ))}
                                            </Input>

                                            <div className="validation-err">
                                                {touched.country_id && errors.country_id}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="lastNameMulti">Account Holder's Name</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.account_holder_name}
                                                name={`account_holder_name`}
                                                type="text"
                                                id="account_holder_name"
                                                placeholder="Account Holder Name"
                                            />
                                            <div className="validation-err">
                                                {touched.account_holder_name &&
                                                    errors.account_holder_name}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="cityMulti">Account Number</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.account_number}
                                                name={`account_number`}
                                                type="text"
                                                id="account_number"
                                                placeholder="Account Number"
                                            />
                                            <div className="validation-err">
                                                {touched.account_number && errors.account_number}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="CountryMulti">Routing Number</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.routing_number}
                                                name={`routing_number`}
                                                type="text"
                                                id="routing_number"
                                                placeholder="Routing Number"
                                            />
                                            <div className="validation-err">
                                                {touched.routing_number && errors.routing_number}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <FormGroup className="d-flex mb-0">
                                            <Button.Ripple
                                                type="submit"
                                                className="mr-1"
                                                color="primary"
                                                // onClick={(e) => e.preventDefault()}
                                                disabled={isBankAccountSubmitting}
                                            >
                                                {isBankAccountSubmitting ? 'Submitting...' : 'Submit'}
                                            </Button.Ripple>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Bank Account List</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md="12" sm="12">

                            {isTableLoading && (
                                <div className="w-100 my-4 text-center">
                                    <Spinner color="primary"/>
                                </div>
                            )}
                            {!isTableLoading && (
                                <Fragment>
                                    <DataTable
                                        noHeader
                                        columns={advSearchColumns}
                                        className="react-dataTable"
                                        sortIcon={<ChevronDown size={10}/>}
                                        data={dataToRender()}
                                    />
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Modal size="lg" isOpen={accountVerifyAccount} toggle={() => setAccountVerifyModal(!accountVerifyAccount)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setAccountVerifyModal(!accountVerifyAccount)}>Verify Your Account</ModalHeader>
                <ModalBody>
                    <Row className="mb-3">
                        <Col md="12" sm="12">
                            Stripe verifies your account by depositing two small amounts into your bank account. Enter these two deposits into the text boxes below, and click
                            on the <b>Verify</b> button.
                        </Col>
                        {/* <Col md="12" sm="12">
            <Alert color={!!error ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                <div className='alert-body'>
                    {error || successMsg}
                </div>
            </Alert>
          </Col> */}
                    </Row>

                    <Formik
                        initialValues={{
                            firstDeposit: "",
                            secondDeposit: ""
                        }}
                        validationSchema={yup.object().shape({
                            firstDeposit: yup.number().required("This field is required."),
                            secondDeposit: yup.number().required("This field is required.")
                        })}
                        onSubmit={(values) => {
                            verifyBankAccount(values)
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6" sm="6">
                                        <FormGroup>
                                            <div className="form-group form-password-toggle">
                                                <Label for="firstDeposit">
                                                    <b><h5>First Deposit <span className="text-danger">*</span></h5></b>
                                                </Label>
                                                <Input
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.firstDeposit}
                                                    name={`firstDeposit`}
                                                    id={`firstDeposit`}
                                                    type="text"
                                                    placeholder="First Deposit"
                                                />
                                                <div className="validation-err">{touched.firstDeposit && errors.firstDeposit}</div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="6">
                                        <FormGroup>
                                            <div className="form-group form-password-toggle">
                                                <Label for="secondDeposit">
                                                    <b><h5>Second Deposit <span className="text-danger">*</span></h5></b>
                                                </Label>
                                                <Input
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.secondDeposit}
                                                    name={`secondDeposit`}
                                                    id={`secondDeposit`}
                                                    type="text"
                                                    placeholder="Second Deposit"
                                                />
                                                <div className="validation-err">{touched.secondDeposit && errors.secondDeposit}</div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="6">
                                        <FormGroup className="d-flex">
                                            <Button.Ripple
                                                className="mr-1"
                                                color="primary"
                                                type="submit"
                                            >
                                                Verify
                                            </Button.Ripple>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>

            <Modal isOpen={deleteBankAccountModal} toggle={() => setDeleteBankAccountModal(!deleteBankAccountModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setDeleteBankAccountModal(!deleteBankAccountModal)}>Delete Bank Account</ModalHeader>
                <ModalBody>
                    Are you sure want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' outline onClick={() => setDeleteBankAccountModal(!deleteBankAccountModal)}>
                        No
                    </Button>
                    <Button color='danger' onClick={deleteBankdAccount}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}
export default ManageAchForm
