import axios from 'axios'
import {API_URL, FAQ} from '../actionsTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getFaqData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-faq-list`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: FAQ.FAQ_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: FAQ.ERROR,
        error
      })
    })
  }
}

export const getFaqSearchData = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-faq-list`
  return async dispatch => {
    await axios.post(endpoint, {
      question: body.question,
      answer: body.answer
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: FAQ.FAQ_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: FAQ.ERROR,
        error
      })
    })
  }
}

export const createNewFaq = (params) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("question", params.question)
    formData.append("answer", params.answer)
    await axios.post(`${API_URL}admin/faq-submit`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: FAQ.ADD_NEW_FAQ_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: FAQ.ERROR,
        error
      })
    })
  }
}

export const updateFaqData = (params) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("id", params.id)
    formData.append("question", params.question)
    formData.append("answer", params.answer)
    await axios.post(`${API_URL}admin/faq-submit`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: FAQ.ADD_NEW_FAQ_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: FAQ.ERROR,
        error
      })
    })
  }
}

export const deleteFaqData = (params) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("id", params.id)
    await axios.post(`${API_URL}admin/delete-faq`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: FAQ.ADD_NEW_FAQ_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: FAQ.ERROR,
        error
      })
    })
  }
}

export const updateFaqStatusData = (params) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("id", params.id)
    await axios.post(`${API_URL}admin/update-faq-status`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: FAQ.ADD_NEW_FAQ_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: FAQ.ERROR,
        error
      })
    })
  }
}

export const nullSearchData = (listName) => {
  return async dispatch => {
    switch (listName) {
      case 'searchFAQ':
        dispatch({
          type: FAQ.SEARCH_FAQ_DATA,
          searchData: null
        })
        break
      default:
        break
    }
  }
}