import PagesRoutes from './Pages'
import RDRoutes from './RD'
import AdminRoutes from './Admin'
import BrandRoutes from './Brands'

const TemplateTitle = '%s - RD Link'
const DefaultRoute = ''

const Routes = [
    ...PagesRoutes,
    ...RDRoutes,
    ...AdminRoutes,
    ...BrandRoutes
]

export {DefaultRoute, TemplateTitle, Routes}
