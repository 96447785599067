import { useEffect, Fragment, useState } from "react"
import {Link, useHistory} from "react-router-dom"
import {Folder, CheckCircle} from "react-feather"
import {
  Button,
  Row,
  Col,
  Spinner,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Input,
  Card,
  ModalHeader,
  ModalBody,
  Modal
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {resourcesRDAction, resourcesRDResetAction} from "@src/views/apps/opportunitiesBrands/store/actions"
import queryString from "query-string"

const Resources = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [search, setSearch] = useState('')
  const [showDescModal, setShowDescModal] = useState(false)
  const [resourceTitle, setResourceTitle] = useState('')
  const [resourceDesc, setResourceDesc] = useState('')
  const [showPricingModal, setShowPricingModal] = useState(false)

  const {resourcesRDLoading, resourcesRDData, resourcesRDError} = useSelector((store) => store.resourcesRReducer)

  useEffect(() => {
    if (resourcesRDData?.status === false && resourcesRDData?.is_plan === false) {
      setShowPricingModal(true)
      dispatch(resourcesRDResetAction())
    }
  }, [resourcesRDData])

  useEffect(() => {
    const parsed = queryString.parseUrl(window.location.href)
    const pageNo = parsed?.query?.page
    dispatch(resourcesRDAction({
      page: pageNo
    }))
  }, [])

  const handleCustomPagination = (pageUrl) => {
    const parsed = queryString.parseUrl(pageUrl)
    const pageNo = parsed?.query?.page
    dispatch(resourcesRDAction({
      title:  search,
      page: pageNo
    }))
    history.push({
      pathname: window.location.pathname,
      search: `?page=${pageNo}`
    })
  }

  const resetResources = () => {
    setSearch('')
    dispatch(resourcesRDAction({}))
  }

  const searchResources = () => {
    dispatch(resourcesRDAction({
      title:  search
    }))
  }

  const showResourcesDetails = (title, desc) => {
    setShowDescModal(true)
    setResourceTitle(title)
    setResourceDesc(desc)
  }

  const closeResourceModal = () => {
    setShowDescModal(false)
    setResourceTitle('')
    setResourceDesc('')
  }

  const closePricingModal = () => {
    setShowPricingModal(false)
    history.push('/dashboard/rd')
  }

  const onEnterSearch = (e) => {
    if (e.key === 'Enter') {
      searchResources()
    }
  }

  return (
    <Fragment>
      <Card className="mt-2">
        <CardBody className="">
          <Row>
            <Col md="12">
              <InputGroup size='lg' className="mt-2 mt-md-0">
                <Input placeholder='Search for resources' onChange={(e) => setSearch(e.target.value)} value={search} disabled={resourcesRDLoading} onKeyDown={onEnterSearch}/>
                <InputGroupAddon addonType='append'>
                  <Button color='primary' outline onClick={searchResources} disabled={resourcesRDLoading}>
                    Search
                  </Button>
                </InputGroupAddon>
                <InputGroupAddon addonType='append'>
                  <Button color='primary' outline onClick={resetResources} disabled={resourcesRDLoading}>
                    Reset
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {!resourcesRDLoading && (
          <h4 className="brandSlidesHeading font-weight-bolder mb-3">
            Resources
          </h4>
      )}
      <div className="brandSlides mb-3">
        <Row>
          {resourcesRDLoading && (<div className="w-100 my-4 text-center"><Spinner color="primary"/></div>)}
          {!resourcesRDLoading && resourcesRDData?.data?.length === 0 && (<div className="w-100 my-4 text-center"><h5>No resource found.</h5></div>)}
          {!resourcesRDLoading && resourcesRDData?.data?.length > 0 && resourcesRDData?.data?.map((val) => (
              <Fragment>
                <Col md="3" className="mb-4">
                  <div className="brandContent">
                    <div className="row justify-content-between">
                      <div className="col-auto ml-auto">
                        {val.items && (
                            <small className="border border-1 px-75 py-25 border-muted text-muted cursor-default">
                              {val.items}
                            </small>
                        )}
                      </div>
                    </div>
                    {val.cover ? (<img src={val.cover} alt={val.cover} className="resource-cover-img" />) : (<Folder size={100} style={{color: '#56B6FF'}}/>)}
                    <h2 className="brandSlidesRd mt-2 cursor-default" style={{height: "50px"}}
                        id={`folder-title-${val.id}`}>
                      {val.title}
                      {/*<UncontrolledTooltip placement='top' target={`folder-title-${val.id}`}>*/}
                      {/*  {val.full_title}*/}
                      {/*</UncontrolledTooltip>*/}
                    </h2>
                    {/*<div className="healthcare" style={{ backgroundColor: "#f8f8f8" }} onClick={() => showResourcesDetails(val.full_title, val.description)}>*/}
                    {/*  <p className="general-wellness cursor-pointer" style={{ color: "#161d31" }}>Learn More</p>*/}
                    {/*</div>*/}
                    <Button.Ripple color="secondary" className="m-25 healthcare" id={`UncontrolledTooltip_${val?.id}`} tag={Link} to={`/dashboard/rd/resources-content/${val?.id}`}>
                      <CheckCircle size={14}/> Explore
                    </Button.Ripple>
                  </div>
                </Col>
              </Fragment>
            ))}
        </Row>

        <Row className="pt-4 justify-content-centered">
          <Col md="12">
            <CustomPagination pageLinks={resourcesRDData?.pages} onPaginationClick={handleCustomPagination} />
          </Col>
        </Row>
      </div>
      <Modal isOpen={showDescModal} className='modal-dialog-centered modal-lg'>
        <ModalHeader toggle={closeResourceModal}>Resource Details</ModalHeader>
        <ModalBody>
          <div className="p-1 bg-light font-weight-bold mb-1">
            {resourceTitle}
          </div>
          <div className="p-1 bg-light text-break" style={{whiteSpace: 'break-spaces', textAlign: 'justify'}}>
            {resourceDesc}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={showPricingModal} className='modal-dialog-centered modal-lg'>
        <ModalHeader toggle={closePricingModal}>Upgrade Plan</ModalHeader>
        <ModalBody>
          {/*<Pricing />*/}
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default Resources
