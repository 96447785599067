import { useState, Fragment, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Flatpickr from 'react-flatpickr'
import {
  getBrandsWhoRequestedForParticipation,
  searchBrandsWhoRequestedForParticipation,
  nullSearchData
} from '../../store/actions/index'
import {
  ChevronDown,
  RefreshCcw,
  Search,
  MoreHorizontal
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Button,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown, Spinner
} from 'reactstrap'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/react/libs/flatpickr/flatpickr.scss'

const DataTableAdvSearch = () => {
  const [searchBrandName, setSearchBrandName] = useState('')
  const [searchOwnerName, setSearchOwnerName] = useState('')
  const [searchDatePicker, setSearchDatePicker] = useState('')

  const [isTableLoading, setIsTableLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [paginationInfo, setPaginationInfo] = useState(null)

  const manageRdsStoreSlice = useSelector(store => store.dataTablesAdmin)
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (!!manageRdsStoreSlice?.brandsWhoRequestedParticipation) {
      const { links, meta, pages } = manageRdsStoreSlice.brandsWhoRequestedParticipation
      setPaginationInfo({ ...links, ...meta, pages })
      setIsTableLoading(false)
    }
    if (!!manageRdsStoreSlice?.searchedBrandsWhoRequestedParticipation) {
      const { links, meta, pages } = manageRdsStoreSlice.searchedBrandsWhoRequestedParticipation
      setPaginationInfo({ ...links, ...meta, pages })
      setIsTableLoading(false)
    }
  }, [manageRdsStoreSlice])

  useEffect(() => {
    dispatch(getBrandsWhoRequestedForParticipation(id))
  }, [])

  const [advSearchColumns, setAdvSearchColumns] = useState([
    {
      name: 'Company Name',
      selector: 'company_name',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Brand Owner Name',
      selector: 'brand_owner_name',
      sortable: true,
      minWidth: '160px'
    },
    {
      name: 'Event Name',
      selector: 'event_name',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Request Date',
      selector: 'request_date',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      minWidth: '70px',
      cell: row => {
        return (
            <span>
                <Badge color={row?.status === "paid" ? "success" : row?.status === "accepted" ? "primary" : "warning"}>{row?.status}</Badge>
            </span>
        )
      }
    }
  ])

  const searchData = () => {
    setIsTableLoading(true)
    const paramsData = {
      brand_name: searchBrandName,
      owner_name: searchOwnerName,
      request_from: searchDatePicker[0] || '',
      request_to: searchDatePicker[1] || ''
    }
    dispatch(searchBrandsWhoRequestedForParticipation(id, paramsData, false))
  }

  const handleCustomPagination = pageUrl => {
    setIsTableLoading(true)
    // dispatch(getBrandsWhoRequestedForParticipation(id, true, pageUrl))
    const paramsData = {
      brand_name: searchBrandName,
      owner_name: searchOwnerName,
      request_from: searchDatePicker[0] || '',
      request_to: searchDatePicker[1] || ''
    }
    dispatch(searchBrandsWhoRequestedForParticipation(id, paramsData, true, pageUrl))
  }

  const dataToRender = () => {
    return manageRdsStoreSlice?.brandsWhoRequestedParticipation?.data
  }

  const makeDate = (range) => {
    const arr = []
    range.map(i => {
      const date = new Date(i)
      const year = date.getFullYear()
      let month = (1 + date.getMonth()).toString()
      month = month.length > 1 ? month : `0${month}`
      let day = date.getDate().toString()
      day = day.length > 1 ? day : `0${day}`
      arr.push(`${month}-${day}-${year}`)
      return true
    })
    setSearchDatePicker(arr)
  }

  const refreshSearchData = () => {
    setIsTableLoading(true)
    setSearchBrandName('')
    setSearchOwnerName('')
    setSearchDatePicker('')
    dispatch(nullSearchData('SearchedBrandsWithParticipationRequest'))
    dispatch(getBrandsWhoRequestedForParticipation(id))
  }

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="pt-0">
          <Row form className='mt-1 mb-50'>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='name'>Brand Name</Label>
                <Input id='name' placeholder='Brand Name' name="brandName"
                  value={searchBrandName} onChange={(e) => setSearchBrandName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='name'>Owner Name</Label>
                <Input id='name' placeholder='Owner Name' name="ownerName"
                  value={searchOwnerName} onChange={(e) => setSearchOwnerName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='4'>
              <FormGroup>
                <Label for='date'>Date</Label>
                <Flatpickr
                  className='form-control'
                  id='date'
                  placeholder='Date'
                  value={searchDatePicker}
                  options={{ mode: 'range', dateFormat: 'm/d/Y' }}
                  onChange={date => makeDate(date)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="admin-filter-actions ml-auto text-right">
                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchData}>
                  <Search size={16} />
                </Button.Ripple>
                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchData}>
                  <RefreshCcw size={16} />
                </Button.Ripple>
              </div>
            </Col>
          </Row>
        </CardBody>
        {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary' /></div>)}
        {!isTableLoading && (
          <Fragment>
            <DataTable
              noHeader
              columns={advSearchColumns}
              paginationPerPage={10}
              className='react-dataTable'
              sortIcon={<ChevronDown size={10} />}
              paginationDefaultPage={currentPage + 1}
              data={dataToRender()}
            />
            <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination} />
          </Fragment>
        )}
      </Card>
    </Fragment>
  )
}

export default DataTableAdvSearch
