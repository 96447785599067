import Axios from "axios"
import {NOTIFICATION_SETTING, NOTIFICATION_SETTING_UPDATE} from '../actionTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getNotificationSettingAction = () => async (dispatch) => {
  dispatch({type: NOTIFICATION_SETTING.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await Axios.get(`${process.env.REACT_APP_BASEURL}brand/email-notification-setting`, {headers: header})
    dispatch({type: NOTIFICATION_SETTING.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: NOTIFICATION_SETTING.ERROR, payload: error.message})
  }
}

export const notificationSettingUpdateAction = (dataObj) => async (dispatch) => {
  dispatch({type: NOTIFICATION_SETTING_UPDATE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await Axios.post(`${process.env.REACT_APP_BASEURL}brand/email-notification-setting-update`, dataObj, {headers: header})
    dispatch({type: NOTIFICATION_SETTING_UPDATE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: NOTIFICATION_SETTING_UPDATE.ERROR, payload: error.message})
  }
}

export const notificationSettingUpdateResetAction = () => async (dispatch) => {
  dispatch({type: NOTIFICATION_SETTING_UPDATE.RESET})
}