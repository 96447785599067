import {Fragment, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import classnames from 'classnames'
import {Filter, Folder, X} from 'react-feather'
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CustomInput,
    FormGroup,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input
} from 'reactstrap'
import Select from 'react-select'
import {
    getGoalsAttributes
} from '@src/views/brands/nutritionExperts/store/actions'
import {
    getCountries, getRegionsByCountryId,
    getStatesByRegionId, getCitiesByStateId,
    getMyRDsAtBrands, searchMyRDsAtBrands,
    getFavoriteFolders,
    createFavoriteFolder,
    addToFavorite, nullResponse
} from '@src/redux/actions/brandsDashboard'
import {NULL} from '@src/redux/actionTypes'
import ProductCards from './ProductCards'
import ProductsSearchbar from './ProductsSearchbar'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const MyRDs = props => {

    const [isSelectFoldersModalOpen, setIsSelectFoldersModalOpen] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [selectedProfile, setSelectedProfile] = useState(null)
    const [folderNameError, setFolderNameError] = useState(false)
    const [isFolderCreating, setIsFolderCreating] = useState(false)

    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [isFoldersLoading, setIsFoldersLoading] = useState(false)
    const [isAddingToFavorite, setIsAddingToFavorite] = useState(false)

    const [showFilters, setShowFilters] = useState(false)
    const [sortCategory, setSortCategory] = useState('favorite')
    const [searchValue, setSearchValue] = useState('')
    const [industryValue, setIndustryValue] = useState('')
    const [specialityValues, setSpecialityValues] = useState([])
    const [personalAttrValues, setPersonalAttrValues] = useState([])

    const [paginationInfo, setPaginationInfo] = useState(null)
    const [goalsAttributes, setGoalsAttrbutes] = useState(null)

    const [countryOptions, setCountryOptions] = useState(null)
    const [regionOptions, setRegionOptions] = useState(null)
    const [stateOptions, setStateOptions] = useState(null)
    const [cityOptions, setCityOptions] = useState(null)

    const [isRegionsLoading, setIsRegionsLoading] = useState(false)
    const [isStatesLoading, setIsStatesLoading] = useState(false)
    const [isCitiesLoading, setIsCitiesLoading] = useState(false)

    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)

    const [rdList, setRdList] = useState(null)
    const [activeView, setActiveView] = useState('grid')
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const rdDashboardStoreSlice = useSelector(store => store.rd)
    const brandsDashboardStoreSlice = useSelector(store => store.brandsDashboardSlice)
    const dispatch = useDispatch()

    useEffect(() => {

        if (!!brandsDashboardStoreSlice?.addToFavroiteRes) {
            dispatch(nullResponse(NULL.BRANDS_MY_RDS))
            dispatch(getMyRDsAtBrands())
            dispatch(nullResponse(NULL.BRANDS_ADD_TO_FAVORITE))
            setIsAddingToFavorite(false)
            setIsSelectFoldersModalOpen(false)
            setIsListLoading(true)
        }
        if (!!brandsDashboardStoreSlice?.favoriteFoldersRes) {
            setIsFoldersLoading(false)
        }
        if (!!brandsDashboardStoreSlice?.createfavoriteFolderRes) {
            setFolderName('')
            setIsFolderCreating(false)
            dispatch(nullResponse(NULL.BRANDS_GET_FOLDERS))
            dispatch(nullResponse(NULL.BRANDS_CREATE_FOLDER))
            dispatch(getFavoriteFolders(false))
            setIsFoldersLoading(true)
        }

        if (!!brandsDashboardStoreSlice?.countries) {
            setCountryOptions(brandsDashboardStoreSlice.countries)
        }

        if (!!brandsDashboardStoreSlice?.regionsByCountryID) {
            setRegionOptions(brandsDashboardStoreSlice?.regionsByCountryID)
            setIsRegionsLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.statesByRegionID) {
            setStateOptions(brandsDashboardStoreSlice?.statesByRegionID)
            setIsStatesLoading(false)
        }

        if (!!brandsDashboardStoreSlice?.citiesByStateID) {
            setCityOptions(brandsDashboardStoreSlice?.citiesByStateID)
            setIsCitiesLoading(false)
        }
        if (!!brandsDashboardStoreSlice?.goalsAttributes) {
            setGoalsAttrbutes(brandsDashboardStoreSlice.goalsAttributes)
        }

        if (!!brandsDashboardStoreSlice?.myRDsAtBrands) {
            const {links, meta, pages} = brandsDashboardStoreSlice?.myRDsAtBrands
            setPaginationInfo({...links, ...meta, pages})
            setRdList(brandsDashboardStoreSlice?.myRDsAtBrands?.data)
            setIsListLoading(false)
        }

    }, [brandsDashboardStoreSlice])

    useEffect(() => {
        setIsListLoading(true)
        dispatch(getGoalsAttributes())
        dispatch(getCountries())
        dispatch(getMyRDsAtBrands())
        dispatch(getFavoriteFolders(false))
        dispatch(nullResponse(NULL.BRANDS_REGIONS_BY_COUNTRY_ID))
        dispatch(nullResponse(NULL.BRANDS_STATES_BY_REGIONS_ID))
        dispatch(nullResponse(NULL.BRANDS_CITIES_BY_STATES_ID))
    }, [])

    const handleChange = (filterName, name, value) => {
        switch (filterName) {
            case 'IndustryChange':
                setIndustryValue(parseInt(value))
                break
            case 'SpecialityChange':
                let values = [...specialityValues]
                if (value) values.push(parseInt(name))
                if (!value) values = values.filter(x => x !== parseInt(name))
                setSpecialityValues(values)
                break
            case 'PersonalAttrChange':
                let values1 = [...personalAttrValues]
                if (value) values1.push(parseInt(name))
                if (!value) values1 = values1.filter(x => x !== parseInt(name))
                setPersonalAttrValues(values1)
                break
        }
    }

    const updateLocation = (location, locationValue) => {
        switch (location) {
            case 'Regions':
                dispatch(getRegionsByCountryId(locationValue))
                break
            case 'States':
                dispatch(getStatesByRegionId(locationValue))
                break
            case 'Cities':
                dispatch(getCitiesByStateId(locationValue))
                break
        }
    }

    const handleSelect = (select, value) => {
        switch (select) {
            case 'CountrySelect':
                setIsRegionsLoading(true)
                updateLocation('Regions', value)
                setSelectedCountry(value)
                break
            case 'RegionSelect':
                setIsStatesLoading(true)
                updateLocation('States', value)
                setSelectedRegion(value)
                break
            case 'StateSelect':
                setIsCitiesLoading(true)
                updateLocation('Cities', value)
                setSelectedState(value)
                break
            case 'CitySelect':
                setSelectedCity(value)
                break
        }
    }

    const handleSearchByName = (searchValue) => {
        setIsListLoading(true)
        setSearchValue(searchValue)
        dispatch(searchMyRDsAtBrands(false, {
            speciality: [],
            industry: [],
            personal_attr: [],
            gender: '',
            country: null,
            region: null,
            state: null,
            city: null,
            name: searchValue
        }))
    }

    const handleFiltersSearch = () => {
        setIsListLoading(true)
        dispatch(searchMyRDsAtBrands(false, {
            speciality: specialityValues,
            industry: industryValue ? [industryValue] : [],
            personal_attr: personalAttrValues,
            gender: '',
            country: selectedCountry?.id || null,
            region: selectedRegion?.id || null,
            state: selectedState?.id || null,
            city: selectedCity?.id || null,
            name: ''
        }))
    }

    const handleResetSearch = () => {
        setIsListLoading(true)
        setSearchValue('')
        setSelectedCountry(null)
        setSelectedRegion(null)
        setSelectedState(null)
        setSelectedCity(null)
        document.querySelectorAll("input[type=radio]").forEach(radio => {
            radio.checked = false
        })
        document.querySelectorAll("input[type=checkbox]").forEach(checkbox => {
            checkbox.checked = false
        })
        dispatch(getMyRDsAtBrands())
    }

    const handleCustomPagination = pageUrl => {
        setIsListLoading(true)
        dispatch(searchMyRDsAtBrands(true, {
            speciality: specialityValues,
            industry: industryValue ? [industryValue] : [],
            personal_attr: personalAttrValues,
            gender: '',
            country: selectedCountry?.id || null,
            region: selectedRegion?.id || null,
            state: selectedState?.id || null,
            city: selectedCity?.id || null,
            name: searchValue
        }, pageUrl))
    }

    const handleCreateFolder = () => {
        if (!folderName) {
            setFolderNameError(true)
            return
        }
        setFolderNameError(false)
        setIsFolderCreating(true)
        dispatch(createFavoriteFolder({folderName}))
    }

    const handleSelectFolder = (folder) => {
        setSelectedFolder(folder)
    }

    const getAddToFavoriteProfile = (profile) => {
        setSelectedProfile(profile)
        if (!profile.is_favorite) setIsSelectFoldersModalOpen(true)
        if (profile.is_favorite) {
            setIsListLoading(true)
            dispatch(addToFavorite({id: profile.id, folder_id: profile.folder_id, type: 'profile'}))
        }
    }
    return (
        // <div className='content-detached content-right'>
        <Fragment>
            <div className='content-body'>
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className='filter-heading'>My RDs</h3>
                    <div className="d-flex align-items-center justify-content-between">
                        {/* <ProductsHeader
              activeView={activeView}
              setActiveView={setActiveView}
              setSidebarOpen={setSidebarOpen}
              handleChangeTypeFilter={handleChangeTypeFilter}
            /> */}
                        <Button.Ripple className='btn-icon ml-1' color='primary' onClick={() => setShowFilters(!showFilters)}>
                            <Filter size={16}/>
                        </Button.Ripple>
                    </div>
                </div>
                {showFilters && <Card className='my-0 filters-right-sidebar'>
                    <CardHeader className="card-header-c">
                        <h3 className='filter-heading my-0'>Filters</h3>
                        <Button.Ripple className='btn-icon' color='danger' onClick={() => setShowFilters(false)}>
                            <X size={16}/>
                        </Button.Ripple>
                    </CardHeader>
                    <CardBody className="pt-2 overflow-y-auto">
                        <h5 className='filter-title mt-0 mb-2'>Speciality</h5>
                        <div className='d-flex flex-wrap' onChange={e => handleChange('SpecialityChange', e.target.name, e.target.checked)}>
                            {goalsAttributes?.speciality?.map((brand, i) => {
                                return (
                                    <CustomInput
                                        key={brand.label}
                                        type='checkbox'
                                        className="mx-2 mb-1"
                                        name={brand.id}
                                        id={brand.label + i}
                                        label={brand.label}
                                    />
                                )
                            })}
                        </div>
                        <h5 className='filter-title mt-2 mb-2'>Industry</h5>
                        <div className='d-flex flex-wrap' onChange={(e) => handleChange('IndustryChange', e.target.name, e.target.value)}>
                            {goalsAttributes?.industry?.map(brand => {
                                return (
                                    <CustomInput
                                        key={brand.label}
                                        type='radio'
                                        className="mx-2 mb-1"
                                        id={brand.label}
                                        value={brand.id}
                                        name="industry"
                                        label={brand.label}
                                        defaultChecked={brand.checked}
                                    />
                                )
                            })}
                        </div>
                        <h5 className='filter-title mt-2 mb-2'>Personal Characteristics</h5>
                        <div className='d-flex flex-wrap' onChange={e => handleChange('PersonalAttrChange', e.target.name, e.target.checked)}>
                            {goalsAttributes?.personal_attributes?.map(brand => {
                                return (
                                    <CustomInput
                                        key={brand.label}
                                        className="mx-2 mb-1"
                                        type='checkbox'
                                        id={brand.label}
                                        name={brand.id}
                                        label={brand.label}
                                    />
                                )
                            })}
                        </div>
                        <FormGroup>
                            <h6 className='filter-title mt-1'>Country</h6>
                            <Select
                                options={countryOptions}
                                className='react-select'
                                classNamePrefix='select'
                                value={selectedCountry}
                                onChange={(value) => handleSelect("CountrySelect", value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <h6 className='filter-title mt-1'>Region</h6>
                            <div className="overlay-select-parent">
                                {isRegionsLoading && <div className="overlay-select"></div>}
                                <Select
                                    options={regionOptions}
                                    className='react-select'
                                    classNamePrefix='select'
                                    value={selectedRegion}
                                    onChange={(value) => handleSelect("RegionSelect", value)}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <h6 className='filter-title mt-1'>State</h6>
                            <div className="overlay-select-parent">
                                {isStatesLoading && <div className="overlay-select"></div>}
                                <Select
                                    options={stateOptions}
                                    className='react-select'
                                    classNamePrefix='select'
                                    value={selectedState}
                                    onChange={(value) => handleSelect("StateSelect", value)}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <h6 className='filter-title mt-1'>City</h6>
                            <div className="overlay-select-parent">
                                {isCitiesLoading && <div className="overlay-select"></div>}
                                <Select
                                    options={cityOptions}
                                    className='react-select'
                                    classNamePrefix='select'
                                    value={selectedCity}
                                    onChange={(value) => handleSelect("CitySelect", value)}
                                />
                            </div>
                        </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button.Ripple color='primary' className="mb-1" onClick={handleFiltersSearch} block>
                            Apply Filters
                        </Button.Ripple>
                        <Button.Ripple onClick={handleResetSearch} color='danger' outline block>
                            Clear All Filters
                        </Button.Ripple>
                    </CardFooter>
                </Card>}
                <div
                    className={classnames('body-content-overlay', {
                        show: sidebarOpen
                    })}
                    onClick={() => setSidebarOpen(false)}
                ></div>
                <ProductsSearchbar handleSearch={handleSearchByName} handleReset={handleResetSearch} searchValue={searchValue}/>
                {!isListLoading && brandsDashboardStoreSlice?.myRDsAtBrands?.data?.length !== 0 && (
                    <Fragment>
                        <ProductCards
                            activeView={activeView}
                            myRDs={brandsDashboardStoreSlice?.myRDsAtBrands?.data}
                            handleAddToFavorite={getAddToFavoriteProfile}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </Fragment>)}
                {isListLoading && (
                    <div className="w-100 my-4 text-center">
                        <Spinner color='primary'/>
                    </div>)}
                {!isListLoading && rdList?.length === 0 && (
                    <div className='d-flex justify-content-center mt-4'>
                        <p>Record doesn't exists</p>
                    </div>)}
            </div>

            <Modal scrollable isOpen={isSelectFoldersModalOpen} className='modal-dialog-centered'>
                <ModalHeader>Select Folder</ModalHeader>
                <ModalBody className="py-2" style={{maxHeight: '50vh', overflowY: 'auto'}}>
                    {isFoldersLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                    {!isFoldersLoading && (
                        <Fragment>
                            {brandsDashboardStoreSlice?.favoriteFoldersRes?.data.map(item => (
                                <Card className={classnames({
                                    'ecommerce-card': true,
                                    'mb-1': true,
                                    'border-1px-white': selectedFolder?.id !== item.id,
                                    'cursor-pointer': true,
                                    'border-1px-purple': selectedFolder?.id === item.id
                                })} key={item.folder_name} onClick={() => handleSelectFolder(item)}>
                                    <CardBody className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Folder
                                                className='mr-1 text-secondary'
                                                size={24}
                                            />
                                            <h5 className='mb-0'>{item.folder_name}</h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))}
                        </Fragment>
                    )}
                    {brandsDashboardStoreSlice?.favoriteFoldersRes?.data?.length !== 0 && <p className="text-center">or</p>}
                    <div className='form-group'>
                        <Label className={classnames({
                            'form-label': true
                        })} htmlFor={`folderName`}>
                            Folder Name<span className="is-invalid">*</span>
                        </Label>
                        <Input
                            type='text'
                            onChange={(e) => {
                                setFolderName(e.target.value)
                                setFolderNameError(false)
                            }}
                            value={folderName}
                            name={`folderName`}
                            id={`folderName`}
                            className={classnames({'is-invalid': folderNameError})}
                        />
                        {folderNameError && <div className="validation-err">This field is required</div>}
                    </div>
                    <div className="text-center">
                        <Button color='primary' outline disabled={isFolderCreating} onClick={handleCreateFolder}>
                            {isFolderCreating ? 'Creating...' : 'Create Folder'}
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' hidden={isAddingToFavorite} onClick={() => setIsSelectFoldersModalOpen(!isSelectFoldersModalOpen)}>
                        Cancel
                    </Button>
                    <Button color='primary' disabled={isAddingToFavorite} onClick={() => handleAddToFavorite()}>
                        {isAddingToFavorite ? 'Adding...' : 'Add to Favorite'}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        // </div>
    )
}

export default MyRDs
