import {combineReducers} from 'redux'
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import outerPages from './outerPages'
import rd from './rdDashboard'
import brandsDashboardSlice from './brandsDashboard'
import adminDashboardSlice from './adminDashboard'
import chatAdmin from '@src/views/admin/viewConversation/chatAdmin/store/reducer'
import chatBrands from '@src/views/brands/messages/store/reducer'
import chatRd from '@src/views/rds/messages/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import dataTablesAdmin from '@src/views/tables/data-tables-admin/store/reducer'
import datatablesAdminManageEvents from '@src/views/tables/data-tables-admin-manage-events/store/reducer'
import datatablesAdminManageCoupons from '@src/views/tables/data-tables-admin-manage-coupons/store/reducer'
import datatablesAdminSubscribedBrands from '@src/views/tables/data-tables-admin-subscribed-brands/store/reducer'
import dataTablesAdminPending from '@src/views/tables/data-tables-admin-pending/store/reducer'
import dataTablesAdminBrands from '@src/views/tables/data-tables-admin-brands/store/reducer'
import dataTablesAdminBrandsPending from '@src/views/tables/data-tables-admin-brands-pending/store/reducer'
import adminAccountSettings from '@src/views/tables/reactstrap/admin/admin-account-settings/store/reducer'
import adminFaqsData from '@src/views/tables/data-tables-admin-faqs/store/reducer'
import adminSubscriptionReport from '@src/views/tables/data-tables-admin-subscription-reports/store/reducer'
import adminCommissionReport from '@src/views/tables/data-tables-admin-comission-reports/store/reducer'
import adminCancellSubscriptionsReport from '@src/views/tables/data-tables-admin-cancelled-subscription-reports/store/reducer'
import adminCmsSettings from '@src/views/tables/reactstrap/admin/admin-manage-cms/store/reducer'
import brandsOppoprunities, {
    locationFromZipCodeReducer,
    campaignMsgReducer,
    campaignAddToFavReducer,
    saveCardInfoRDReducer,
    creditCardInfoRDReducer,
    creditCardSetDefaultRDReducer,
    creditCardDeleteRDReducer,
    masterFeaturesRDReducer,
    getPlanRDReducer,
    getActivePlanRReducer,
    getCancelPlanRReducer,
    resourcesRReducer,
    resourceContentRReducer,
    checkCreditCardRDReducer,
    saveSelectedPlanRDReducer,
    loadUpcomingEventsRDReducer,
    loadPastEventsRDReducer,
    verifyEmailReducer,
    brandInvoicesReducer,
    payNewInvoiceReducer
} from '@src/views/apps/opportunitiesBrands/store/reducer'
import brandDashboard from '@src/redux/reducers/brandProfile/index'
import brandManageUser from '@src/redux/reducers/brandManageUsers/index'
import rdBrands from '@src/views/rds/brands/store/reducer'
import manageAch from '@src/views/brands/manageACH/store/reducer'
import manageCards from '@src/views/brands/manageCards/store/reducer'
import rdCampaigns from '@src/views/rds/brandCampaigns/store/reducer'
import {getNotificationSettingReducer, notificationSettingUpdateReducer} from "@src/views/brands/notificationSetting/store/reducer"
import {
    addResourcesReducer,
    listResourcesReducer,
    statusResourcesReducer,
    deleteResourcesReducer
} from '@src/views/tables/data-tables-admin-resources/store/reducer'
import {
    uploadLinkResourceReducer,
    resourcesContentReducer,
    resourceContentStatusReducer,
    resourceContentDeleteReducer,
    uploadDocsReducer,
    uploadMediaReducer
} from "@src/views/tables/data-tables-admin-resources-content/store/reducer"
import {
    getRDNotificationSettingReducer,
    rdNotificationSettingUpdateReducer
} from "@src/views/rds/others/store/reducer"
import {emailVerificationReducer} from "@src/views/pages/emailVerification/store/reducer"
import {invoiceReducer} from "@src/views/rds/invoices/store/reducer"
import unsubscribeReducer from "./unsubscribe"
import brandReducer from "@store/reducers/BrandReducer"
import rdReducer from "@store/reducers/RDReducer"
import adminReducer from "@store/reducers/AdminReducer"

const rootReducer = combineReducers({
    auth,
    outerPages,
    rd,
    brandsDashboardSlice,
    chatAdmin,
    chatBrands,
    chatRd,
    navbar,
    layout,
    invoice,
    adminDashboardSlice,
    dataTablesAdmin,
    datatablesAdminManageEvents,
    datatablesAdminSubscribedBrands,
    datatablesAdminManageCoupons,
    dataTablesAdminPending,
    dataTablesAdminBrands,
    dataTablesAdminBrandsPending,
    adminAccountSettings,
    adminFaqsData,
    adminSubscriptionReport,
    adminCommissionReport,
    adminCancellSubscriptionsReport,
    adminCmsSettings,
    brandsOppoprunities,
    brandDashboard,
    brandManageUser,
    rdBrands,
    manageAch,
    manageCards,
    rdCampaigns,
    getNotificationSettingReducer,
    notificationSettingUpdateReducer,
    locationFromZipCodeReducer,
    campaignMsgReducer,
    campaignAddToFavReducer,
    saveCardInfoRDReducer,
    creditCardInfoRDReducer,
    creditCardSetDefaultRDReducer,
    creditCardDeleteRDReducer,
    masterFeaturesRDReducer,
    getPlanRDReducer,
    getActivePlanRReducer,
    getCancelPlanRReducer,
    addResourcesReducer,
    listResourcesReducer,
    statusResourcesReducer,
    deleteResourcesReducer,
    uploadLinkResourceReducer,
    resourcesContentReducer,
    resourceContentStatusReducer,
    resourceContentDeleteReducer,
    uploadDocsReducer,
    uploadMediaReducer,
    resourcesRReducer,
    resourceContentRReducer,
    getRDNotificationSettingReducer,
    rdNotificationSettingUpdateReducer,
    checkCreditCardRDReducer,
    saveSelectedPlanRDReducer,
    loadUpcomingEventsRDReducer,
    loadPastEventsRDReducer,
    verifyEmailReducer,
    emailVerificationReducer,
    invoiceReducer,
    brandInvoicesReducer,
    payNewInvoiceReducer,
    unsubscribeReducer,
    brandReducer,
    rdReducer,
    adminReducer
})

export default rootReducer
