import {useState, useEffect, useRef} from 'react'
import {Link} from "react-router-dom"
import Avatar from '@components/avatar'
import {useDispatch, useSelector} from 'react-redux'
import {selectChatFromServer, selectedContactDetail, changeReduxState} from './store/actions'
import {NULL} from './store/actionTypes'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {X, Search, Users} from 'react-feather'
import {
    InputGroup, InputGroupAddon, Input, InputGroupText, Badge, Spinner
} from 'reactstrap'
import BlankProfile from "@src/assets/images/avatars/avatar-blank.png"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {PropTypes} from "prop-types"

const SidebarLeft = props => {
    const {store, sidebar, handleSidebar, userSidebarLeft, handleUserSidebarLeft, chatContacts, selectedUserID} = props

    const sweetAlert = withReactContent(Swal)

    const dispatch = useDispatch()

    const chatBrandsStoreSlice = useSelector(state => state.chatBrands)

    const [about, setAbout] = useState('')
    const [query, setQuery] = useState('')
    const [active, setActive] = useState({})
    const [status, setStatus] = useState('online')
    const [filteredChat, setFilteredChat] = useState([])
    const [filteredContacts, setFilteredContacts] = useState([])
    const [activeContacts, setActiveContacts] = useState([])
    const [contactsOfBrands, setContactsOfBrands] = useState([])
    const [contactsOfBrandsForSearching, setContactsOfBrandsForSearching] = useState([])
    const [loader, setLoader] = useState(true)

    const chatOnLoad = useRef(true)

    const handleUserClick = (type, contact) => {
        dispatch(changeReduxState(NULL.LOAD_SELECTED_USER_CHAT, true))
        dispatch(selectChatFromServer(contact.chat_user))
        dispatch(selectedContactDetail(contact))
        setActive({type, id: contact.chat_user})
        if (sidebar === true) {
            handleSidebar()
        }
        dispatch({type: 'CLEAR_BRAND_CHAT_COUNT_ON_CLICK', payload: contact})
    }

    useEffect(() => {
        const error = chatBrandsStoreSlice?.contactLoadError?.response?.status
        if (error && error !== 401) {
            sweetAlert.fire({
                title: 'Connection Error!',
                icon: 'error',
                html: '<p>Please reload the page.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false,
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
            dispatch({type: "BRAND_CONTACT_LOAD_ERROR_RESET"})
            setLoader(false)
        }
    }, [chatBrandsStoreSlice])

    useEffect(() => {
        if (!!chatContacts && !!selectedUserID && chatOnLoad.current) {
            chatOnLoad.current = false
            const contact = chatContacts.data.filter(contact => contact.chat_user === parseInt(selectedUserID))
            setActive(null)
            handleUserClick('contact', contact[0])
        }

        if (!!chatContacts) {
            setContactsOfBrands(chatContacts.data)
            setContactsOfBrandsForSearching(chatContacts.data)
        }

        if (chatContacts?.data?.length > 0) {
            setLoader(false)
        }
    }, [chatContacts])

    const renderContacts = () => {
        return contactsOfBrands?.map(item => {
            return (
                <li className={classnames({active: active.type === 'contact' && active.id === item?.chat_user})} key={item?.chat_user}>
                    <button className='btn d-flex p-0 w-100 text-left align-items-center' onClick={() => handleUserClick('contact', item)}>
                        <Link to={`/dashboard/brands/nutritions/${item?.chat_user}`} title="Go to profile"
                              onClick={(e) => e.stopPropagation()}>
                            <Avatar img={item?.profile_image || item.profile_img || BlankProfile} imgHeight='42' imgWidth='42'
                                    status="online" className="chat-img-animation"/>
                        </Link>
                        <div className='chat-info flex-grow-1'>
                            <h5 className='mb-0'>{item?.name || item?.rd_name}</h5>
                        </div>
                        <div className='chat-meta text-nowrap'>
                            {item?.unread_messages > 0 && (
                                <Badge className='float-right' color='danger' pill>
                                    {item?.unread_messages}
                                </Badge>
                            )}
                        </div>
                    </button>
                </li>
            )
        })
    }

    const handleFilter = e => {
        setQuery(e.target.value)
        setContactsOfBrands(contactsOfBrandsForSearching.filter(contact => contact.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    return store ? (
        <div className='sidebar-left'>
            <div className='sidebar'>
                <div className={classnames('sidebar-content', {show: sidebar === true})}>
                    <div className='sidebar-close-icon p-0'>
                        <button className='btn btn-link p-0 mr-1' onClick={handleSidebar}>
                            <X style={{height: '24px', width: '24px'}}/>
                        </button>
                    </div>
                    <div className='chat-fixed-search'>
                        <div className='d-flex align-items-center w-100'>
                            <InputGroup className='input-group-merge w-90'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText className='round'>
                                        <Search className='text-muted' size={14}/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    value={query}
                                    className='round'
                                    placeholder='Search contacts'
                                    onChange={handleFilter}
                                />
                            </InputGroup>

                        </div>
                    </div>
                    <div className='d-flex border-bottom justify-content-between align-items-center'>
                        <h4 className='chat-list-title m-0 p-1'>
                            <Users size='18'/> Conversations
                        </h4>
                        {loader && (
                            <span className='m-0 p-1'>
                                <Spinner color='primary' style={{height: '16px', width: '16px'}}/>
                            </span>
                        )}
                    </div>
                    <PerfectScrollbar className='chat-user-list-wrapper list-group' options={{wheelPropagation: false}}>
                        <ul className='chat-users-list contact-list media-list'>
                            {renderContacts()}
                        </ul>
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    ) : null
}


SidebarLeft.propTypes = {
    store: PropTypes.object.isRequired,
    sidebar: PropTypes.bool.isRequired,
    handleSidebar: PropTypes.func.isRequired,
    userSidebarLeft: PropTypes.bool.isRequired,
    handleUserSidebarLeft: PropTypes.func.isRequired,
    chatContacts: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.shape({
                chat_user: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                profile_image: PropTypes.string,
                profile_img: PropTypes.string,
                name: PropTypes.string,
                rd_name: PropTypes.string,
                unread_messages: PropTypes.number
            })
        )
    }),
    selectedUserID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SidebarLeft