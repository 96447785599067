import {useState, useEffect, useRef, Fragment, useContext, memo} from 'react'
import ReactDOM from 'react-dom'
import {SocketContext} from '@src/utility/context/Socket'
import Avatar from '@components/avatar'
import {useDispatch, useSelector} from 'react-redux'
import {
    sendMsg,
    getChatContactsFromServer,
    recieveMsgRD,
    storeMessagesInRedux,
    addRdContactsInRedux,
    sendMsgRD,
    uploadMediaToserver,
    selectedContactDetail,
    saveTemplete
} from './store/actions'
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    MessageSquare, Menu, Send, Paperclip,
    Save, FileText, File, List, ChevronLeft
} from 'react-feather'
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    Label,
    InputGroup,
    InputGroupAddon,
    Input,
    InputGroupText,
    Button,
    UncontrolledButtonDropdown,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    UncontrolledTooltip,
    Spinner,
    Row,
    Col
} from 'reactstrap'
import BlankLogo from "@src/assets/images/avatars/Blank-logo.jpg"
import {allowedFiles, allowedFilesErrorMessages} from '@src/utility/Utils'
import Linkify from "react-linkify"
import {Link} from "react-router-dom"
import ShowMoreText from "react-show-more-text"
import {Slide, toast} from "react-toastify"
import draftToHtml from "draftjs-to-html"
import {ContentState, convertToRaw, EditorState} from "draft-js"
import {Editor} from "react-draft-wysiwyg"
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import htmlToDraft from "html-to-draftjs"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ChatLog = props => {
    const ps = useRef([])
    const attachmets = useRef(null)
    // ** Props & Store
    const {
        handleUser,
        handleUserSidebarRight,
        handleSidebar,
        store,
        userSidebarLeft,
        selectedUserID,
        selectedUserRdChat,
        currentUser
    } = props
    const {userProfile, selectedUser, rdContacts, rdChatWithBrands, selectedRdContactDetail} = store
    const rdChatStoreSlice = useSelector(state => state.chatRd)
    // ** Refs & Dispatch
    const chatArea = useRef(null)
    const dispatch = useDispatch()
    const socket = useContext(SocketContext)

    const sweetAlert = withReactContent(Swal)

    const [msg, setMsg] = useState('')
    const [questate, setQueState] = useState({inQueFilePreview: "", inQueFile: ""})
    const [selectedFile, setSelectedFile] = useState(null)
    const [isFilePicked, setIsFilePicked] = useState(false)
    const [isOpenTempleteModal, setIsOpenTempleteModal] = useState(false)
    const [templeteTitle, setTempleteTitle] = useState(null)
    const [fileUploadError, setFileUploadError] = useState(false)
    const [sendMsgLoader, setSendMsgLoader] = useState(false)
    const [msgEditorState, setMsgEditorState] = useState(null)

    const scrollToBottom = () => {
        const chatContainer = ReactDOM.findDOMNode(chatArea.current)
        chatContainer.scrollTop = Number.MAX_SAFE_INTEGER
    }

    useEffect(() => {
        socket.on('connect', (error) => {
            if (error) {
                alert(error)
            }
        })
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        socket.emit('join', user, (error) => {
        })
        // socket.emit('chat message', 'mesge here will send to server')
        if (selectedUserID) {
            const selectedChatBrandContact = JSON.parse(localStorage.getItem('selectedChatBrand'))
            dispatch(addRdContactsInRedux({chat_user: parseInt(selectedUserID), ...selectedChatBrandContact}))
        }
    }, [])

    // ** If user chat is not empty scrollToBottom
    useEffect(() => {
        if (selectedRdContactDetail) {
            const selectedUserLen = Object.keys(selectedRdContactDetail).length
            if (selectedUserLen) {
                scrollToBottom()
            }
        }

        if (rdChatStoreSlice?.rdMessageResponse) {
            setSendMsgLoader(false)
            if (rdChatStoreSlice?.rdMessageResponse?.status) {
                toast.success(
                    <Fragment>
                        <div className='toastify-header'>
                            <div className='title-wrapper'>
                                <Avatar size='sm' color='success' icon={<MessageSquare size={12}/>}/>
                                <h6 className='toast-title font-weight-bold'>Success</h6>
                            </div>
                        </div>
                        <div className='toastify-body'>
                            <span>{rdChatStoreSlice?.rdMessageResponse?.msg}</span>
                        </div>
                    </Fragment>, {transition: Slide, hideProgressBar: true, autoClose: 5000})
            } else {
                toast.error(<Fragment>{rdChatStoreSlice?.rdMessageResponse?.msg}</Fragment>, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 5000
                })
            }
            setMsg("")
            setMsgEditorState(null)
            dispatch({type: "SEND_MSG_SUCCESS_RESPONSE_RD_NULL"})
        }

        const error = rdChatStoreSlice?.rdChatLoadError?.response?.status
        if (error && error !== 401) {
            sweetAlert.fire({
                title: 'Connection Error!',
                icon: 'error',
                html: '<p>Please reload the page.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false,
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
            dispatch({type: "RD_CHAT_LOAD_ERROR_RESET"})
            dispatch({type: "RD_SELECTED_USER_CHAT_LOADER_RESET"})
        }
    }, [rdChatStoreSlice])

    useEffect(() => {
        socket.on('new-user-message', (data) => {

            const receivedMessage = {
                message: data.data.message,
                media: data.data.media,
                from_user_id: data.data.from_user_id,
                to_user_id: data.data.to_user_id
            }
            dispatch(recieveMsgRD(receivedMessage))
        })

    }, [])

    // --- Send message and media ---
    const handleSendMsg = (e) => {
        e.preventDefault()
        setSendMsgLoader(true)
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        const receiver_id = selectedRdContactDetail.chat_user
        const date = new Date()
        const objTest = {
            chat: [],
            selectedRd: selectedUserID
        }
        const msgObj = {
            message: msg,
            from_user_id: user.chat_id,
            to_user_id: receiver_id,
            timezone: date.toString()
        }
        objTest.chat.push(msgObj)
        dispatch(storeMessagesInRedux(objTest))
        dispatch(sendMsgRD(msgObj))
        socket.emit("new-message", msgObj)

        if (selectedFile) {
            const media = {
                to_user_id: receiver_id,
                file: selectedFile,
                from_user_id: user.chat_id,
                timezone: date.toString(),
                socket
            }
            dispatch(uploadMediaToserver(media))
            setQueState(prevState => ({
                ...prevState,
                inQueFilePreview: '',
                inQueFile: ''
            }))
            setSelectedFile(null)
        }
    }

    const getBase64 = (img, callback) => {
        if (img) {
            const reader = new FileReader()
            reader.addEventListener('load', () => callback(reader.result))
            reader.readAsDataURL(img)
        }
    }

    const changeHandler = (event) => {
        const receiver_id = selectedRdContactDetail.chat_user
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        setFileUploadError(false)
        const file = event.target.files[0]
        if (!file) return
        if (allowedFiles().includes(file.name?.split('.').pop().toLowerCase())) {
            setSelectedFile(file)
            getBase64(file, imageUrl => {
                setQueState(prevState => ({
                    ...prevState,
                    inQueFilePreview: imageUrl,
                    inQueFile: file
                }))
            })
            setIsFilePicked(true)
        } else {
            setSelectedFile(null)
            setQueState({
                inQueFilePreview: '',
                inQueFile: ''
            })
            setFileUploadError(true)
        }
    }

    const handleTitle = (event) => {
        setTempleteTitle(event.target.value)
    }

    const handleMessageTemplete = () => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        const receiver_id = selectedRdContactDetail.chat_user
        const objTest = {
            chat: [],
            selectedRd: selectedUserID
        }
        if (msg.length !== 0) {
            if (templeteTitle !== null) {
                const msgTemplete = {
                    message: msg,
                    title: templeteTitle
                }
                dispatch(saveTemplete(msgTemplete))
                // setMsg("")
                setTempleteTitle("")
                setIsOpenTempleteModal(false)

            } else {
                alert('Template title is required.')
            }
        } else {
            alert('Message field is required.')
            setIsOpenTempleteModal(false)

        }
    }

    const appendTemplete = (item) => {
        const content = htmlToDraft(item.template)
        const contentState = ContentState.createFromBlockArray(content?.contentBlocks)
        setMsgEditorState(EditorState.createWithContent(contentState))
        setMsg(item.template)
    }

    const showMoreLess = (index) => {
        const chatContainer = ReactDOM.findDOMNode(chatArea.current)
        chatContainer.scrollTop = ps.current[index].offsetTop - 10
    }

    // ** Renders user chat
    const renderChats = () => {
        return selectedUserRdChat?.data.map((item, index) => {
            return (
                <div
                    ref={(el) => (ps.current[index] = el)}
                    key={index}
                    className={classnames('chat', {
                        'chat-left': item?.to_user_id !== selectedRdContactDetail?.chat_user
                    })}
                >
                    <div className='chat-avatar'>
                        <Link
                            to={item?.to_user_id === selectedRdContactDetail?.chat_user ? `/dashboard/rd/view-profile` : `/dashboard/rd/brands/${item?.brand_id}`}>
                            <Avatar className='box-shadow-1 cursor-pointer'
                                    img={item?.to_user_id === selectedRdContactDetail?.chat_user ? (currentUser?.profile_img || currentUser?.profile_image) : (selectedRdContactDetail?.brand_logo || BlankLogo)}
                            />
                        </Link>
                    </div>

                    {item.media ? (
                        <div className="chat-body">
                            <div className="chat-content media-file">
                                {item.message_type === "img" && (
                                    <div>
                                        <img src={item.media} height="200" title={item.file_name} alt={item.file_name}/>
                                        <p className="mt-2 text-primary font-small-2 text-center cursor-pointer"
                                           style={{textDecoration: "underline"}}>
                                            <a href={item.media} target="_blank">Download</a>
                                        </p>
                                    </div>
                                )}
                                {item.message_type === "pdf" && (
                                    <div>
                                        <File size={100} style={{color: '#56B6FF'}}/>
                                        <p className="mt-2 text-primary font-small-2 text-center cursor-pointer"
                                           style={{textDecoration: "underline"}}>
                                            <a href={item.media} target="_blank">Download</a>
                                        </p>
                                    </div>
                                )}
                                {item.message_type === "doc" && (
                                    <div>
                                        <File size={100} style={{color: '#56B6FF'}}/>
                                        <p className="mt-2 text-primary font-small-2 text-center cursor-pointer"
                                           style={{textDecoration: "underline"}}>
                                            <a href={item.media} target="_blank">Download</a>
                                        </p>
                                    </div>
                                )}
                                {/*<p><a href={item.media} target='_blank'>{item.file_name}</a></p>*/}
                            </div>
                        </div>) : (
                        <div className="chat-body">
                            <div className="chat-content text-break" style={{whiteSpace: "break-spaces"}}>
                                <div dangerouslySetInnerHTML={{__html: item.message}}></div>
                                <p className="child" style={{marginTop: "15px", textAlign: "right"}}>
                                    {item.timezone}
                                </p>
                            </div>
                        </div>
                    )}
                    {item.send_by &&
                        <div className="child">
                            <p> send by {item.send_by}</p>
                        </div>

                    }
                </div>
            )
        })
    }
    // ** Opens right sidebar & handles its data
    const handleAvatarClick = obj => {
        handleUserSidebarRight()
        handleUser(obj)
    }
    // ** On mobile screen open left sidebar on Start Conversation Click
    const handleStartConversation = () => {
        if (!Object.keys(selectedUser)?.length && !userSidebarLeft && window.innerWidth <= 1200) {
            handleSidebar()
        }
    }
    // ** ChatWrapper tag based on chat's length
    const ChatWrapper = selectedRdContactDetail && selectedUserRdChat ? PerfectScrollbar : 'div'

    return (
        <Fragment>
            <div className="chat-app-window w-100 chat-rd">
                <div
                    className={classnames("start-chat-area", {
                        "d-none": !!selectedRdContactDetail
                    })}
                >
                    <div className="start-chat-icon mb-1">
                        <MessageSquare/>
                    </div>
                    <h4
                        className="sidebar-toggle start-chat-text text-center"
                        onClick={handleStartConversation}
                    >
                        <span>Select a Brand to Start Your First Chat. </span>
                        <span style={{textDecoration: 'underline'}} className="text-primary d-sm-none">Tap Here</span>
                    </h4>
                </div>
                <div
                    className={classnames("active-chat", {
                        "d-none": selectedRdContactDetail === null
                    })}
                >
                    <div className="chat-navbar">
                        <header className="chat-header">
                            <div className="d-flex align-items-center">
                                <div
                                    className="sidebar-toggle d-block d-lg-none mr-1"
                                    onClick={handleSidebar}
                                >
                                    <ChevronLeft size={21}/>
                                </div>
                            </div>
                        </header>
                    </div>
                    <ChatWrapper
                        ref={chatArea}
                        className="user-chats"
                        options={{wheelPropagation: false}}
                    >
                        <div className="chats">
                            {rdChatStoreSlice?.selectedUserRDChatLoading &&
                                <div className="text-center mt-4"><Spinner color='primary'/></div>}
                            {!rdChatStoreSlice?.selectedUserRDChatLoading && <Fragment>{renderChats()}</Fragment>}
                        </div>
                    </ChatWrapper>
                    <div className="bg-white d-block">
                        {fileUploadError &&
                            <div className="validation-err ml-1">{allowedFilesErrorMessages().IMAGE_AND_FILES} </div>}
                        <Form className="chat-app-form" onSubmit={(e) => handleSendMsg(e)}>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <Editor
                                        editorState={msgEditorState}
                                        onEditorStateChange={(e) => {
                                            setMsgEditorState(e)
                                            const contentState = e.getCurrentContent()
                                            if (contentState.hasText()) {
                                                setMsg(draftToHtml(convertToRaw(e.getCurrentContent())))
                                            } else {
                                                setMsg('')
                                            }
                                        }}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'remove', 'history'],
                                            inline: {
                                                options: ['bold', 'italic', 'underline']
                                            },
                                            list: {
                                                options: ['unordered', 'ordered']
                                            },
                                            blockType: {
                                                options: ['Normal', 'H1', 'H2', 'H3']
                                            },
                                            link: {
                                                popupClassName: 'msg-editor-link-and-img',
                                                showOpenOptionOnHover: false
                                            },
                                            fontSize: {
                                                options: [12, 14, 16, 18, 24, 36]
                                            }
                                        }}
                                        editorClassName='msg-editor'
                                        stripPastedStyles={true}
                                    />
                                </Col>
                                <Col xs={12} lg={12} className="d-flex justify-content-between">
                                    <div className="mt-1">
                                        <InputGroup>
                                            <InputGroupAddon>
                                                <InputGroupText style={{padding: '0.438rem 0.5rem'}}>
                                                    {questate.inQueFilePreview && (
                                                        <Fragment>
                                                            {selectedFile?.type === "application/pdf" ? (
                                                                <span style={{marginRight: '5px'}}><FileText size="20"/></span>
                                                            ) : (
                                                                <img src={questate.inQueFilePreview}
                                                                     alt={questate.inQueFilePreview}
                                                                     height="20" width="20" className="img-thumbnail" style={{marginRight: '5px'}}/>
                                                            )}
                                                        </Fragment>
                                                    )}
                                                    <Label className="attachment-icon mb-0" for="attach-doc">
                                                        <Paperclip className="cursor-pointer text-secondary" size={14}/>
                                                        <input type="file"
                                                               id="attach-doc"
                                                               accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls"
                                                               onChange={changeHandler}
                                                               hidden={true}
                                                               disabled={sendMsgLoader}
                                                        />
                                                    </Label>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                    <div className="mt-1">
                                        <Button className="mr-1"
                                                color="success"
                                                id="send-message"
                                                disabled={sendMsgLoader || msg.length === 0}
                                                size="sm"
                                        >
                                            {sendMsgLoader ? (
                                                <span><Spinner style={{height: '12px', width: '12px'}}/></span>
                                            ) : (
                                                <span>Send</span>
                                            )}
                                        </Button>
                                        <Button.Ripple className="mr-1"
                                                       id="save-templete"
                                                       color="primary"
                                                       onClick={() => setIsOpenTempleteModal(true)}
                                                       disabled={sendMsgLoader || msg.length === 0}
                                                       size="sm">
                                            <span>Save</span>
                                        </Button.Ripple>
                                        <UncontrolledButtonDropdown direction="up" id="templates">
                                            <DropdownToggle color="primary" style={{padding: '0.486rem 1rem', borderRadius: '0.358rem', fontSize: '0.9rem'}}>
                                                Templates
                                            </DropdownToggle>
                                            {rdChatStoreSlice?.rdMessageTempletes?.data && (
                                                <DropdownMenu className='msg-template-dropdown'>
                                                    {rdChatStoreSlice?.rdMessageTempletes?.data?.map((item, index) => {
                                                        return (<DropdownItem onClick={() => appendTemplete(item)} key={index}>
                                                            {++index} - {item?.title?.length > 30 ? `${item?.title.substring(0, 30)}...` : item?.title}
                                                        </DropdownItem>)
                                                    })}
                                                </DropdownMenu>
                                            )}
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpenTempleteModal} className='modal-dialog-centered'>
                <ModalHeader>Message Template</ModalHeader>
                <ModalBody>
                    <FormGroup className='mb-2'>
                        <Label htmlFor='coupon-code'>Title</Label>
                        <Input type='text' placeholder='Templete Title' id='title'
                               name="title"
                               value={templeteTitle}
                               onChange={e => handleTitle(e)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' outline onClick={() => setIsOpenTempleteModal(!isOpenTempleteModal)}>
                        Cancel
                    </Button>
                    <Button color='primary' onClick={() => handleMessageTemplete()}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default memo(ChatLog)