import {Fragment, useState, useEffect} from 'react'
import * as yup from "yup"
import {Formik} from "formik"
import {Link} from 'react-router-dom'
import Avatar from '@components/avatar'
import {
    Eye,
    MoreVertical,
    Download,
    Upload, RefreshCcw, GitPullRequest, CheckSquare, Delete, CheckCircle, Send, DollarSign
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Button,
    Label,
    Input,
    CustomInput,
    Card,
    CardTitle,
    Form,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    Alert,
    Table,
    Badge, UncontrolledTooltip, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux'
import {
    getMyPartnerships
} from '@store/actions/brandsDashboard'
import {
    getPaymentDetailData,
    submitAmount,
    uploadAgrement,
    uploadAgrementReset,
    brandCreditCards,
    brandBankAccounts,
    submitAmountReset
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import '@styles/react/apps/app-invoice.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import queryString from "query-string"

const MyPartnerships = () => {

    const AGREEMENT_MAX_SIZE = 1 * 1024 * 1024
    const AGREEMENT_FORMATS = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document means .docx\n",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ]

    const [row, setRow] = useState(null)
    const [successRes, setSuccessRes] = useState(null)
    const [active, setActive] = useState('1')
    const [errorRes, setErrorRes] = useState(null)
    const [searchValue, setSearchValue] = useState("")
    const [statusValue, setStatusValue] = useState("")
    const [payInvoiceModal, setPayInvoiceModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [tokenId, setTokenId] = useState("")
    const [uploadAgreementModal, setUploadAgreementModal] = useState(false)

    const dispatch = useDispatch()

    const {
        uploadBrandAgreementLoading,
        uploadBrandAgreementFullFill,
        uploadBrandAgreementError,

        brandCreditCardsLoading,
        brandCreditCardsFullFill,
        brandCreditCardsError,

        brandBankAccountsLoading,
        brandBankAccountsFullFill,
        brandBankAccountsError,

        brandInvoiceDetailsLoading,
        brandInvoiceDetailsFullFill,
        brandInvoiceDetailsError,

        brandInvoiceSubmitLoading,
        brandInvoiceSubmitFullFill,
        brandInvoiceSubmitError
    } = useSelector((store) => store.brandsOppoprunities)

    const {
        brandMyPartnershipLoading,
        brandMyPartnershipFullFill,
        brandMyPartnershipError
    } = useSelector(state => state.brandsDashboardSlice)

    useEffect(() => {
        if (uploadBrandAgreementFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: uploadBrandAgreementFullFill?.msg
            })
            dispatch(uploadAgrementReset())
            dispatch(getMyPartnerships(""))
            setRow(null)
            setUploadAgreementModal(false)
            setTimeout(() => setSuccessRes(null), 5000)
        }

        if (brandInvoiceSubmitFullFill?.status) {
            setSuccessRes({
                show: true,
                msg: brandInvoiceSubmitFullFill?.msg
            })
            dispatch(submitAmountReset())
            dispatch(getMyPartnerships(""))
            setRow(null)
            setConfirmationModal(false)
            setPayInvoiceModal(false)
            setTimeout(() => setSuccessRes(null), 5000)
        }

        if (brandInvoiceSubmitError?.response?.status === 400) {
            setErrorRes({
                show: true,
                msg: brandInvoiceSubmitError?.response?.data?.msg
            })
            dispatch(submitAmountReset())
            setRow(null)
            setConfirmationModal(false)
            setPayInvoiceModal(false)
            setTokenId("")
            setTimeout(() => setErrorRes(null), 20000)
        }

    }, [uploadBrandAgreementFullFill, brandInvoiceSubmitFullFill, brandInvoiceSubmitError])

    useEffect(() => {
        dispatch(getMyPartnerships(""))
    }, [])

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(getMyPartnerships(`?page=${pageNo}`))
    }

    const submitPayment = () => {
        const params = {
            request_id: row?.id,
            token_id: tokenId
        }
        dispatch(submitAmount(params))
        setSuccessRes(null)
        setErrorRes(null)
    }

    const uploadAgreementData = (values) => {
        const formData = new FormData()
        formData.append("request_id", row?.id)
        formData.append("file", values.agreement)
        dispatch(uploadAgrement(formData))
        setSuccessRes(null)
        setErrorRes(null)
    }

    const handleAgreementModal = (row) => {
        setRow(row)
        setUploadAgreementModal(true)
        setSuccessRes(null)
        setErrorRes(null)
    }

    const handlePayInvoice = (row) => {
        setRow(row)
        setPayInvoiceModal(true)
        dispatch(getPaymentDetailData({request_id: row?.id}))
        dispatch(brandCreditCards())
        dispatch(brandBankAccounts())
        setSuccessRes(null)
        setErrorRes(null)
    }

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            dispatch(getMyPartnerships(`?search=${searchValue}`))
        }
    }

    const onStatusSearch = (e) => {
        setStatusValue(e.target.value)
        dispatch(getMyPartnerships(`?status=${e.target.value}`))
    }

    const handleReload = () => {
        setSearchValue("")
        dispatch(getMyPartnerships(""))
    }

    const columnsMyPartnerships = [
        {
            name: 'Rd name',
            cell: row => {
                return (
                    <div className='d-flex justify-content-left align-items-center'>
                        <Link to={`/dashboard/brands/nutritions/${row.rd_id}`}>
                            <Avatar className='mr-50' img={row.profile_pic} width='32' height='32'/>
                        </Link>
                        <div className='d-flex flex-column'>
                            <h6 className='user-name text-truncate mb-0'>{row.rd_name}</h6>
                        </div>
                    </div>
                )
            }
        },
        {
            name: 'Event Title',
            cell: row => (
                <div className="text-truncate">
                    {row.partnership_details}
                </div>
            )
        },
        {
            name: 'Event Date',
            selector: 'event_date'
        },
        {
            name: 'reach',
            selector: 'reach'
        },
        {
            name: 'Investment',
            cell: row => <span>${row.investment || 0}</span>
        },
        {
            name: 'Cost/Impression',
            cell: row => <span>${row.cost_impression || 0}</span>
        },
        {
            name: 'Agreement',
            cell: row => (
                <div className='column-action d-flex align-items-center'>
                    <UncontrolledDropdown>
                        <DropdownToggle tag='span'>
                            <MoreVertical size={17} className='cursor-pointer'/>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem tag='a'
                                          href={row?.brand_agreement}
                                          className='w-100' target="_blank"
                                          disabled={!row?.brand_agreement}
                            >
                                <Eye size={17} className='mr-50'/>
                                <span className='align-middle'>View</span>
                            </DropdownItem>
                            <DropdownItem className='w-100' onClick={() => handleAgreementModal(row)}>
                                <Upload size={17} className='mr-50'/>
                                <span className='align-middle'>Upload</span>
                            </DropdownItem>
                            <DropdownItem tag='a' href={row?.rd_agreement} className='w-100' target="_blank"
                                          disabled={!row?.rd_agreement}>
                                <Download size={14} className='mr-50'/>
                                <span className='align-middle'>Download RD Agreement</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        },
        {
            name: 'Status',
            cell: row => (
                <div className="column-action d-flex align-items-center">
                    {row?.status === 'Requested' && (
                        <Badge color='warning'>
                            <GitPullRequest style={{stroke: '#FFF'}}/>
                            <span> {row?.status}</span>
                        </Badge>
                    )}

                    {row?.status === 'Accepted' && (
                        <Badge color='info'>
                            <CheckSquare style={{stroke: '#FFF'}}/>
                            <span> {row?.status}</span>
                        </Badge>
                    )}

                    {row?.status === 'Rejected' && (
                        <Badge color='danger'>
                            <Delete style={{stroke: '#FFF'}}/>
                            <span> {row?.status}</span>
                        </Badge>
                    )}

                    {row?.status === 'Paid' && (
                        <Badge color='success'>
                            <CheckCircle style={{stroke: '#FFF'}}/>
                            <span> Invoice Paid</span>
                        </Badge>
                    )}

                    {row?.status === 'Invoice Sent' && (
                        <Badge color='primary'>
                            <Send style={{stroke: '#FFF'}}/>
                            <span> {row?.status}</span>
                        </Badge>
                    )}
                </div>
            )
        },
        {
            name: 'Invoice',
            width: '300px',
            cell: row => (
                <div className='column-action d-flex align-items-center'>
                    {row.can_pay_invoice === true ? (
                        <Button className="btn-sm" color="primary" onClick={() => handlePayInvoice(row)}>
                            <DollarSign style={{stroke: '#FFF'}} size={12}/> Pay Invoice
                        </Button>
                    ) : row?.can_pay_invoice === false && row?.status === 'Paid' ? (
                        <Button className="btn-sm" color="success" href={row.invoice_url} target="_blank">
                            <Eye style={{stroke: '#FFF'}} size={12}/> View Invoice
                        </Button>
                    ) : (
                        <Button className="btn-sm" color="default">
                            No Invoice
                        </Button>
                    )}
                </div>
            )
        }
    ]

    return (
        <Fragment>
            <Card>
                <CardHeader className="d-flex">
                    <CardTitle>My Partnerships</CardTitle>
                    <div style={{width: '500px'}} className="d-flex">
                        <Input
                            id='search-invoice'
                            className="rounded-0"
                            type='text'
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            onKeyDown={onEnterSearch}
                            disabled={brandMyPartnershipLoading}
                            placeholder='Type and press enter to search...'
                        />
                        <Button color='primary' className="rounded-0" onClick={handleReload}>
                            <RefreshCcw size={12} />
                        </Button>
                        <Input
                            type="select"
                            className="ml-1" style={{width: '300px'}}
                            disabled={brandMyPartnershipLoading}
                            onChange={onStatusSearch}
                        >
                            <option value="">Payment Status</option>
                            <option value="all">All</option>
                            <option value="paid">Paid</option>
                            <option value="unpaid">Unpaid</option>
                            <option value="past_due">Past Due</option>
                        </Input>
                    </div>
                </CardHeader>
                <CardBody>

                    {successRes?.show && (
                        <Alert color='success'>
                            <div className='alert-body'>
                                <span>{successRes?.msg}</span>
                            </div>
                        </Alert>
                    )}

                    {errorRes?.show && (
                        <Alert color='danger'>
                            <div className='alert-body'>
                                <span>{errorRes?.msg}</span>
                            </div>
                        </Alert>
                    )}

                    {brandMyPartnershipLoading ? (
                        <div className="w-100 my-5 text-center">
                            <Spinner color='primary'/>
                        </div>
                    ) : (
                        <>
                            <DataTable
                                noHeader={true}
                                columns={columnsMyPartnerships}
                                responsive={true}
                                className='react-dataTable'
                                data={brandMyPartnershipFullFill?.data}
                            />
                            <CustomPagination pageLinks={brandMyPartnershipFullFill?.pages}
                                              onPaginationClick={handleCustomPagination}/>
                        </>
                    )}
                </CardBody>
            </Card>

            <Modal size="lg" isOpen={payInvoiceModal} className='modal-dialog-centered'>
                <ModalHeader>Pay Invoice</ModalHeader>
                <ModalBody>
                    <Table className="mt-2">
                        <thead>
                        <tr>
                            <th width="300">Invoice</th>
                            <th width="100">Price</th>
                            <th>RD Link Partnership Rate</th>
                            <th width="100">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {brandInvoiceDetailsLoading ? (<tr>
                            <td colSpan={4} className="text-center"><Spinner color='primary'/></td>
                        </tr>) : (
                            <>
                                <tr>
                                    <th scope="row" className="text-break cursor-pointer" id="invoice-desc">
                                        {brandInvoiceDetailsFullFill?.data?.name}
                                        <UncontrolledTooltip placement='top' target='invoice-desc'>
                                            <strong>Opportunity : </strong>
                                            <span>{brandInvoiceDetailsFullFill?.data?.description}</span>
                                        </UncontrolledTooltip>
                                    </th>
                                    <td>${brandInvoiceDetailsFullFill?.data?.price}</td>
                                    <td>
                                        ${brandInvoiceDetailsFullFill?.data?.admin_comm}
                                        <Badge color='primary' className="ml-1">
                                            {brandInvoiceDetailsFullFill?.data?.admin_percent}%
                                        </Badge>
                                    </td>
                                    <td><strong>${brandInvoiceDetailsFullFill?.data?.total}</strong></td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td><b>Due Amount</b></td>
                                    <td><strong>${brandInvoiceDetailsFullFill?.data?.amount_due}</strong></td>
                                </tr>
                            </>
                        )}
                        </tbody>
                    </Table>
                    <hr/>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                active={active === '1'}
                                onClick={() => {
                                    toggle('1')
                                }}
                            >
                                Pay through credit card
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={active === '2'}
                                onClick={() => {
                                    toggle('2')
                                }}
                            >
                                Pay through bank account
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className='py-50' activeTab={active}>
                        <TabPane tabId='1'>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Select Card</th>
                                    <th>Type</th>
                                    <th>Card Number</th>
                                    <th>Exp Month</th>
                                    <th>Exp Year</th>
                                </tr>
                                </thead>
                                <tbody>
                                {brandCreditCardsLoading ? (
                                    <tr>
                                        <td colSpan={5} className="text-center"><Spinner color='primary'/></td>
                                    </tr>
                                ) : brandCreditCardsFullFill?.data?.length === 0 ? (
                                    <tr>
                                        <td colSpan={5} className="text-center font-weight-bold">
                                            Sorry! No credit card found.
                                        </td>
                                    </tr>
                                ) : (
                                    brandCreditCardsFullFill?.data?.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <CustomInput
                                                    type='radio'
                                                    id={`credit-card-${index}`}
                                                    name='payment'
                                                    inline
                                                    onChange={() => setTokenId(item?.id)}
                                                />
                                            </td>
                                            <td>{item?.brand}</td>
                                            <td>{item?.last4}</td>
                                            <td>{item?.exp_month}</td>
                                            <td>{item?.exp_year}</td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </Table>
                        </TabPane>
                        <TabPane tabId='2'>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Select Account</th>
                                    <th>Account Holder Name</th>
                                    <th>Bank Name</th>
                                    <th>Account Number</th>
                                </tr>
                                </thead>
                                <tbody>
                                {brandBankAccountsLoading ? (
                                    <tr>
                                        <td colSpan={5} className="text-center"><Spinner color='primary'/></td>
                                    </tr>
                                ) : brandBankAccountsFullFill?.data?.length === 0 ? (
                                    <tr>
                                        <td colSpan={4} className="text-center font-weight-bold">Sorry! No record found.
                                        </td>
                                    </tr>
                                ) : (
                                    brandBankAccountsFullFill?.data?.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <CustomInput
                                                    type='radio'
                                                    id={`bank-account-${index}`}
                                                    name='payment'
                                                    inline
                                                    onChange={() => setTokenId(item?.account_id)}
                                                />
                                            </td>
                                            <td>{item?.account_holder_name}</td>
                                            <td>{item?.bank_name}</td>
                                            <td>{item?.last4}</td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </Table>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => {
                        setPayInvoiceModal(false)
                        setTokenId("")
                    }}>
                        Cancel
                    </Button>
                    {tokenId && (
                        <Button color='success' onClick={() => setConfirmationModal(true)}>
                            Pay Now
                        </Button>)}
                </ModalFooter>
            </Modal>

            <Modal isOpen={confirmationModal} className='modal-dialog-centered'>
                <ModalHeader>Confirmation Alert!</ModalHeader>
                <ModalBody>
                    Are you sure you want to pay the invoice?
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => setConfirmationModal(false)} disabled={brandInvoiceSubmitLoading}>
                        No
                    </Button>
                    <Button color='success' onClick={submitPayment} disabled={brandInvoiceSubmitLoading}>
                        {brandInvoiceSubmitLoading ? (<Spinner style={{width: '14px', height: '14px'}}/>) : "Yes"}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={uploadAgreementModal} className='modal-dialog-centered'>
                <ModalHeader>Upload Agreement</ModalHeader>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        agreement: ""
                    }}
                    validationSchema={yup.object().shape({
                        agreement: yup.mixed()
                            .test(
                                "fileFormat",
                                "File is empty or invalid.",
                                value => (value && AGREEMENT_FORMATS.includes(value.type))
                            )
                            .test(
                                "fileSize",
                                "File too large",
                                value => (value && value.size <= AGREEMENT_MAX_SIZE)
                            )
                    })}
                    onSubmit={(values) => {
                        uploadAgreementData(values)
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched,
                          values,
                          setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <ModalBody>
                                <FormGroup className="my-1">
                                    <Label className="d-flex justify-content-between">
                                        <span>Upload Agreement</span>
                                        <span className="text-danger font-small-1">
                          <strong>Allowed Only (.pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls)</strong>
                        </span>
                                    </Label>
                                    <CustomInput
                                        accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .xls"
                                        type="file"
                                        className="form-control-file"
                                        name="agreement"
                                        onBlur={handleBlur}
                                        onChange={(e) => setFieldValue('agreement', e.target.files[0])}
                                        disabled={uploadBrandAgreementLoading}
                                    />
                                    <div className="validation-err">{touched.agreement && errors.agreement}</div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type="submit" disabled={uploadBrandAgreementLoading}>
                                    {uploadBrandAgreementLoading ? (
                                        <Spinner style={{width: '12px', height: '12px'}}/>) : 'Upload'}
                                </Button>
                                <Button color='danger' onClick={() => {
                                    setRow(null)
                                    setUploadAgreementModal(false)
                                }} disabled={uploadBrandAgreementLoading}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>

        </Fragment>
    )
}

export default MyPartnerships
