import UserDropdown from './UserDropdown'
import NotificationDropdown from './NotificationDropdown'

const NavbarUser = () => {
    return (
        <ul className='nav navbar-nav align-items-center ml-auto'>
            <NotificationDropdown/>
            <UserDropdown/>
        </ul>
    )
}
export default NavbarUser
