import axios from 'axios'
import { API_URL, BRAND, NULL } from '../actionTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getBrandsData = () => {
  return dispatch => {
    axios.get(`${API_URL}admin/load-brand-list`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.MANAGE_BRANDS_DATA,
        brandsData: response.data
      })
    }).catch(error => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const brandsSearchData = (params, isPaginate, pageUrl = '') => {
  let endpoint = ''
  pageUrl ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-brand-list`
  return dispatch => {
    const formData = new FormData()
    formData.append("name", params.name)
    formData.append("email", params.email)
    formData.append("status", params.status)
    formData.append("company", params.company)
    // formData.append("city", params.city)
    // formData.append("country_id", params.country)
    // formData.append("state_id", params.state)
    // formData.append("industry_id[]", params.industry)
    axios.post(endpoint, formData, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.MANAGE_BRANDS_DATA,
        brandsData: response.data
      })
    }).catch(error => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const getCountries = () => {
  return dispatch => {
    axios.get(`${API_URL}get-countries`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.COUNTRIES_DATA,
        countries: response.data
      })
    }).catch((error) => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const getStatesById = (country_id) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("country_id", country_id)
    axios.post(`${API_URL}get-states`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.STATES_DATA,
        states: response.data
      })
    }).catch((error) => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const getIndustries = () => {
  return dispatch => {
    axios.get(`${API_URL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.INDUSTRIES_DATA,
        industries: response.data.industry
      })
    }).catch((error) => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const getSpecialities = () => {
  return dispatch => {
    axios.get(`${API_URL}get-goals-attr`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.SPECIALITIES_DATA,
        specialities: response.data.speciality
      })
    }).catch((error) => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const deleteBrandsRDsData = (id) => {
  return dispatch => {
    axios.get(`${API_URL}admin/delete-brand?id=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.DELETE_MANAGE_BRAND_GET_DATA,
        deleteBrandData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const updateManageBrandStatus = (params) => {
  return dispatch => {
    axios.post(`${API_URL}admin/update-brand-status`, params, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.UPDATE_BRAND_STATUS,
        changeBrandStatus: null
      })
    }).catch(error => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })

  }
}

export const getParticipationRequestsData = (id) => {
  return dispatch => {
    axios.get(`${API_URL}admin/load-purchased-membership-list?requested_user_id=${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.PARTICIPATION_REQUESTS,
        participationRequests: response.data
      })
    }).catch(error => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const searchParticipationRequestsData = (id, params, isPagination, pageUrl = '') => {
  let endpoint = ''
  isPagination ? endpoint = `${pageUrl}&requested_user_id=${id}` : endpoint = `${API_URL}admin/load-purchased-membership-list?requested_user_id=${id}`
  return dispatch => {
    const formData = new FormData()
    formData.append("rd_name", params.rd_name)
    formData.append("event_name", params.event_name)
    formData.append("from_date", params.from_date)
    formData.append("to_date", params.to_date)
    formData.append("request_from", params.request_from)
    formData.append("request_to", params.request_to)
    formData.append("amount", params.amount)
    axios.post(endpoint, formData, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.SEARCHED_PARTICIPATION_REQUESTS,
        participationRequests: response.data
        // searchedParticipationRequests: response.data.data
      })
    }).catch(error => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const getBrandProfileDetail = (id) => {
  return dispatch => {
    axios.get(`${API_URL}admin/view-brand-details?id=${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.BRAND_PROFILE_DETAIL,
        brandProfileData: response.data.data
      })
    }).catch(error => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const exportBrandsData = () => {
  return dispatch => {
    axios.get(`${API_URL}admin/load-brand-list?data-type=export`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.EXPORT_BRANDS,
        exportBrandsData: response.data.data
      })
    }).catch(error => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const nullSearchData = (listName) => {
  return dispatch => {
    switch (listName) {
      case 'manageBrandsList':
        dispatch({
          type: BRAND.MANAGE_BRANDS_DATA_NULL
        })
        break
      case 'searchParticipationRequest':
        dispatch({
          type: BRAND.SEARCHED_PARTICIPATION_REQUESTS,
          searchedParticipationRequests: null
        })
        break
      case NULL.ADMIN_ASSIGN_PLAN:
        dispatch({
          type: BRAND.ASSIGN_PLAN,
          assigningPlan: null
        })
        break
      case NULL.ADMIN_DELETE_MANAGE_BRAND_GET_DATA:
        dispatch({
          type: BRAND.DELETE_MANAGE_BRAND_GET_DATA,
          deleteBrandData: null
        })
        break
    }
  }
}

export const assignPlanToBrand = (params) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("user_id", params.userId)
    formData.append("duration", params.duration)

    axios.post(`${API_URL}admin/assign-free`, formData, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: BRAND.ASSIGN_PLAN,
        assigningPlan: response.data
      })
    }).catch(error => {
      dispatch({
        type: BRAND.ERROR,
        error
      })
    })
  }
}

export const getSecondaryUsers = (dataObj) => async (dispatch) => {
  dispatch({type: BRAND.SECONDARY_USER_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.post(`${API_URL}admin/view-secondary-users`, dataObj, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.SECONDARY_USER_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.SECONDARY_USER_FAIL, payload: error})
  }
}

export const makePrimaryUser = (dataObj) => async (dispatch) => {
  dispatch({type: BRAND.MAKE_PRIMARY_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.post(`${API_URL}admin/make-primary-user`, dataObj, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.MAKE_PRIMARY_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.MAKE_PRIMARY_FAIL, payload: error})
  }
}

export const makePrimaryUserReset = () => async (dispatch) => {
  dispatch({type: BRAND.MAKE_PRIMARY_RESET})
}

export const profileViews = (query) => async (dispatch) => {
  dispatch({type: BRAND.PROFILE_VIEWS_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.get(`${API_URL}admin/profile-views${query}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.PROFILE_VIEWS_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.PROFILE_VIEWS_FAIL, payload: error})
  }
}

export const campaignData = (dataObj) => async (dispatch) => {
  dispatch({type: BRAND.CAMPAIGN_DATA_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.post(`${API_URL}admin/campaign-views`, dataObj, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.CAMPAIGN_DATA_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.CAMPAIGN_DATA_FAIL, payload: error})
  }
}

export const profileLinks = (query) => async (dispatch) => {
  dispatch({type: BRAND.PROFILE_LINKS_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.get(`${API_URL}admin/profile-links${query}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.PROFILE_LINKS_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.PROFILE_LINKS_FAIL, payload: error})
  }
}

export const recommendationViews = (query) => async (dispatch) => {
  dispatch({type: BRAND.RECO_VIEWS_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.get(`${API_URL}admin/recommendation-views${query}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.RECO_VIEWS_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.RECO_VIEWS_FAIL, payload: error})
  }
}

export const onlyActiveBrands = (query) => async (dispatch) => {
  dispatch({type: BRAND.ACTIVE_BRANDS_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.get(`${API_URL}admin/only-active-brands${query}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.ACTIVE_BRANDS_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.ACTIVE_BRANDS_FAIL, payload: error})
  }
}

export const brandsTotalImpression = () => async (dispatch) => {
  dispatch({type: BRAND.BRAND_TOTAL_IMP_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.get(`${API_URL}admin/brands-total-impression`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.BRAND_TOTAL_IMP_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.BRAND_TOTAL_IMP_FAIL, payload: error})
  }
}

export const brandsMassMessages = (query) => async (dispatch) => {
  dispatch({type: BRAND.BRAND_MASS_MSG_REQUEST})
  try {
    const {
      data,
      status
    } = await axios.get(`${API_URL}admin/brands-mass-message${query}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })
    dispatch({type: BRAND.BRAND_MASS_MSG_SUCCESS, payload: {data, status}})
  } catch (error) {
    dispatch({type: BRAND.BRAND_MASS_MSG_FAIL, payload: error})
  }
}