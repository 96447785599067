import { Fragment, useState, useEffect } from 'react'
import { getRDashboardNotifications } from '../../../redux/actions/rdDashboard'
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, TabContent, Card, Spinner } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import CardBody from 'reactstrap/lib/CardBody'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import '@styles/base/pages/page-faq.scss'

const RdNotifications = () => {
  const [isPageLoading, setIsPageLoading] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const rdStoreSliceN = useSelector(store => store.rd)

  useEffect(() => {
    if (!!rdStoreSliceN?.rdNotifications) {
      setIsPageLoading(false)
    }
  }, [rdStoreSliceN])

  useEffect(() => {
    setIsPageLoading(true)
    dispatch(getRDashboardNotifications(false))
  }, [])

  const handleCustomPagination = pageUrl => {
    setIsPageLoading(true)
    dispatch(getRDashboardNotifications(true, pageUrl))
  }

  const handleRedirectMessage = (user) => {
    localStorage.setItem('selectedChatBrand', JSON.stringify({
      user_id: user.user_id,
      brand_logo: user.brand_logo,
      brand_name: user.brand_name
    }))
    history.push(`/rd/apps/chat/chatRd/${user.user_id}`)
  }

  const renderTabContent = () => {
    return rdStoreSliceN?.rdNotifications?.data.map((item, index) => {
      return (
        <Fragment>
          {item.type === 'message' &&
            <Card>
              <a onClick={(e) => {
                e.preventDefault()
                handleRedirectMessage(item)
              }}>
                <CardBody>
                  <small className='notification-text'>
                    {item.message}
                  </small>
                </CardBody>
              </a>
            </Card>
          }
          {item.type === 'request' &&
            <Card>
              <Link to="/dashboard/rd/partnerships/manage">
                <CardBody>

                  <small className='notification-text'>
                    {item.message}
                  </small>
                </CardBody>
              </Link>
            </Card>
          }
          {item.type === 'invoice_paid' &&
            <Card>
              <Link to="/dashboard/rd/partnerships/payment">
                <CardBody>

                  <small className='notification-text'>
                    {item.message}
                  </small>
                </CardBody>
              </Link>
            </Card>
          }
          {item.type === 'subscription' &&
            <Card>
              <Link to='/' onClick={e => e.preventDefault()}>
                <CardBody>
                  <small className='notification-text'>
                    {item.message}
                  </small>
                </CardBody>
              </Link>
            </Card>
          }
          {item.type === 'new_campaign' &&
              <Card>
                <Link to='/dashboard/rd/campaigns'>
                  <CardBody>
                    <small className='notification-text'>
                      {item.message}
                    </small>
                  </CardBody>
                </Link>
              </Card>
          }
        </Fragment>
      )
    })
  }

  return (
    <Fragment>
      {isPageLoading && (<div className="w-100 my-4 text-center">
        <Spinner color='primary' />
      </div>)}
      {!isPageLoading && (<Fragment>
        <Row>
          <Col sm='12'>
            <h3 className="mb-2">All Notifications</h3>
            <TabContent activeTab={1}>{renderTabContent()}</TabContent>
          </Col>
        </Row>
        <CustomPagination pageLinks={rdStoreSliceN?.rdNotifications?.pages} onPaginationClick={handleCustomPagination} />
      </Fragment>)}

    </Fragment>
  )
}

export default RdNotifications
