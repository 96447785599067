import {useState, useEffect, Fragment, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Formik} from "formik"
import * as yup from "yup"
import DataTable from "react-data-table-component"
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Alert,
    Row,
    Col,
    Input,
    Badge,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, UncontrolledTooltip
} from "reactstrap"
import {
    CheckCircle,
    ChevronDown, Delete
} from "react-feather"
import "@styles/base/pages/page-auth.scss"
import {
    saveCardInfoRDAction,
    saveCardInfoRDResetAction,
    creditCardInfoRDAction,
    creditCardSetDefaultRDAction,
    creditCardSetDefaultRDResetAction,
    creditCardDeleteRDAction,
    creditCardDeleteRDResetAction
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import Cleave from "cleave.js/react"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const RDManageCards = () => {

    const {executeRecaptcha} = useGoogleReCaptcha()

    const formikRef = useRef()

    const [visible, setVisible] = useState(false)
    const [resMsg, setResMsg] = useState('')
    const [resStatus, setStatus] = useState('')
    const [cardID, setCardID] = useState('')
    const [deleteCardID, setDeleteCardID] = useState('')
    const [cardDeleteModal, setCardDeleteModal] = useState(false)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const dispatch = useDispatch()

    const sweetAlert = withReactContent(Swal)

    const {saveCardInfoRDLoading, saveCardInfoRDData, saveCardInfoRDError} = useSelector((store) => store.saveCardInfoRDReducer)
    const {creditCardInfoRDLoading, creditCardInfoRDData, creditCardInfoRDError} = useSelector((store) => store.creditCardInfoRDReducer)
    const {creditCardSetDefaultRDLoading, creditCardSetDefaultRDData, creditCardSetDefaultRDError} = useSelector((store) => store.creditCardSetDefaultRDReducer)
    const {creditCardDeleteRDLoading, creditCardDeleteRDData, creditCardDeleteRDError} = useSelector((store) => store.creditCardDeleteRDReducer)

    useEffect(() => {
        dispatch(creditCardInfoRDAction())
    }, [])

    useEffect(() => {
        if (saveCardInfoRDData?.status === true) {
            setStatus(saveCardInfoRDData?.status)
            setResMsg(saveCardInfoRDData?.msg)
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
            }, 3000)
            dispatch(saveCardInfoRDResetAction())
            formikRef.current?.resetForm()
            dispatch(creditCardInfoRDAction())
        }
        if (saveCardInfoRDData?.status === false) {
            setStatus(saveCardInfoRDData?.status)
            setResMsg(saveCardInfoRDData?.msg)
            setVisible(true)
            dispatch(saveCardInfoRDResetAction())
        }
    }, [saveCardInfoRDData])

    useEffect(() => {
        if (creditCardSetDefaultRDData?.status === true || creditCardSetDefaultRDData?.status === false) {
            setStatus(creditCardSetDefaultRDData?.status)
            setResMsg(creditCardSetDefaultRDData?.msg)
            setVisible(true)
            dispatch(creditCardSetDefaultRDResetAction())
            setTimeout(() => {
                setVisible(false)
            }, 3000)
            window.scroll(0, 0)
            dispatch(creditCardInfoRDAction())
            setCardID('')
        }
    }, [creditCardSetDefaultRDData])

    useEffect(() => {
        if (creditCardDeleteRDData?.status === true || creditCardDeleteRDData?.status === false) {
            setStatus(creditCardDeleteRDData?.status)
            setResMsg(creditCardDeleteRDData?.msg)
            setVisible(true)
            dispatch(creditCardDeleteRDResetAction())
            setTimeout(() => {
                setVisible(false)
            }, 3000)
            window.scroll(0, 0)
            dispatch(creditCardInfoRDAction())
            setDeleteCardID('')
        }
    }, [creditCardDeleteRDData])

    const addCreditCard = async (values) => {
        const token = await executeRecaptcha('rd_manage_cards')
        if (token) {
            const params = {
                card_number: values.card_number,
                expiry_month: values.expiry?.split("/")[0] ?? "",
                expiry_year: values.expiry?.split("/")[1] ?? "",
                cvc: values.cvv,
                owner_name: values.owner_name,
                re_captcha_token: token
            }
            dispatch(saveCardInfoRDAction(params))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    const onCaptchaChange = (value) => {
        setCaptchaError(false)
        setCaptchaValue(value)
    }

    const setDefaultCard = (cardId) => {
        setCardID(cardId)
        dispatch(creditCardSetDefaultRDAction({
            card_id: cardId
        }))
    }

    const advSearchColumns = [
        {
            name: "Type",
            selector: "brand"
        },
        {
            name: "Card Number",
            selector: "last4"
        },
        {
            name: "Expiry Month",
            selector: "exp_month"
        },
        {
            name: "Expiry Year",
            selector: "exp_year"
        },
        {
            name: "Default Card",
            cell: (row) => {
                return (
                    <div className="">
                        {row.is_default && <Badge color='light-success'>Yes</Badge>}
                        {!row.is_default && <Badge color='light-danger'>No</Badge>}
                    </div>
                )
            }
        },
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="">
                        <Button.Ripple color='primary' outline id={`set-as-default-${row.id}`} disabled={row.is_default} onClick={() => {
                            setDefaultCard(row.id)
                        }}>
                            {(row.id === cardID) ? (<Spinner color="primary" style={{height: "18px", width: "18px"}}/>) : (<CheckCircle size={16}/>)}
                        </Button.Ripple>
                        <UncontrolledTooltip placement='top' target={`set-as-default-${row.id}`}>
                            Set as default
                        </UncontrolledTooltip>
                        <Button.Ripple className="ml-1" color='danger' onClick={() => {
                            setCardDeleteModal(true)
                            setDeleteCardID(row.id)
                        }}>
                            {(row.id === deleteCardID && creditCardDeleteRDLoading) ? (<Spinner color="primary" style={{height: "18px", width: "18px"}}/>) : (<Delete size={16}/>)}
                        </Button.Ripple>
                    </div>
                )
            }
        }
    ]

    const dataToRender = () => {
        if (creditCardInfoRDData?.data?.length) {
            return creditCardInfoRDData?.data
        }
    }

    const deleteCreditCard = () => {
        setCardDeleteModal(false)
        dispatch(creditCardDeleteRDAction({
            card_id: deleteCardID
        }))
    }

    return (
        <Fragment>
            <h3 className="mb-2 text-uppercase">Manage Cards</h3>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Add Credit Cards</CardTitle>
                </CardHeader>
                <CardBody>
                    <Alert color={resStatus === true ? "success" : "danger"} isOpen={visible} toggle={() => setVisible(false)}>
                        <div className='alert-body'>
                            {resMsg}
                        </div>
                    </Alert>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            owner_name: "",
                            card_number: "",
                            expiry: "",
                            cvv: ""
                        }}
                        validationSchema={yup.object().shape({
                            owner_name: yup.string().required("This field is required").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
                            card_number: yup.number().required("This field is required"),
                            expiry: yup.string().required("This field is required"),
                            cvv: yup.number().required("This field is required")
                        })}
                        onSubmit={(values) => {
                            addCreditCard(values)
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values
                          }) => (
                            <Form className="mt-2" onSubmit={handleSubmit} autoComplete="off">
                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="owner-name">Owner Name</Label>
                                            <span className="text-danger">*</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.owner_name}
                                                name="owner_name"
                                                type="text"
                                                id="owner-name"
                                                placeholder="Owner Name"
                                                disabled={saveCardInfoRDLoading}
                                                onKeyPress={(event) => {
                                                    if (!/^[a-zA-Z_ ]*$/.test(event.key)) {
                                                        event.preventDefault()
                                                    }
                                                }}
                                            />
                                            <div className="validation-err">{touched.owner_name && errors.owner_name}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="card-number">Card Number</Label>
                                            <span className="text-danger">*</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.card_number}
                                                name="card_number"
                                                type="text"
                                                id="card-number"
                                                placeholder="Card Number"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault()
                                                    }
                                                }}
                                                disabled={saveCardInfoRDLoading}
                                            />
                                            <div className="validation-err">
                                                {touched.card_number && errors.card_number}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="expiry">Expiry</Label>
                                            <span className="text-danger"> *</span>
                                            <Cleave
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder='MM/YY'
                                                value={values.expiry}
                                                name="expiry"
                                                options={{date: true, delimiter: '/', datePattern: ['m', 'y']}}
                                                id='expiry'
                                                className="form-control"
                                                disabled={saveCardInfoRDLoading}
                                            />
                                            <div className="validation-err">
                                                {touched.expiry && errors.expiry}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="cvv">CVV / CVC</Label>
                                            <span className="text-danger"> *</span>
                                            <Input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.cvv}
                                                name="cvv"
                                                type="text"
                                                id="cvv"
                                                placeholder="CVV / CVC"
                                                disabled={saveCardInfoRDLoading}
                                            />
                                            <div className="validation-err">
                                                {touched.cvv && errors.cvv}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <FormGroup className="d-flex mb-0 justify-content-end">
                                            <Button.Ripple
                                                type="submit"
                                                className="mr-1"
                                                color="primary"
                                                disabled={saveCardInfoRDLoading}
                                            >
                                                {saveCardInfoRDLoading ? 'Submitting...' : 'Submit'}
                                            </Button.Ripple>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle tag="h4"></CardTitle>
                </CardHeader>

                <CardBody>
                    {creditCardInfoRDLoading && (
                        <div className="w-100 my-4 text-center">
                            <Spinner color="primary"/>
                        </div>
                    )}
                    {!creditCardInfoRDLoading && (
                        <Fragment>
                            <Row>
                                <Col md="12" sm="12">
                                    <CardTitle tag="h4">Credit Card List</CardTitle>
                                    <DataTable
                                        noHeader
                                        columns={advSearchColumns}
                                        className="react-dataTable"
                                        sortIcon={<ChevronDown size={10}/>}
                                        data={dataToRender()}
                                    />
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </CardBody>
            </Card>
            <Modal isOpen={cardDeleteModal} toggle={() => setCardDeleteModal(!cardDeleteModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setCardDeleteModal(!cardDeleteModal)}>Delete Credit Card</ModalHeader>
                <ModalBody>
                    Are you sure want to delete this?
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' outline onClick={() => setCardDeleteModal(!cardDeleteModal)}>
                        No
                    </Button>
                    <Button color='danger' onClick={deleteCreditCard}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default RDManageCards
