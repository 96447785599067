import {useState, Fragment, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {
    getManageRDsData,
    searchManageRDsData,
    deleteManageRDsData,
    deleteResponseManageRDsData,
    updateManageRDsStatus,
    getIndustries,
    getSpecialities,
    getExportCsvData,
    nullSearchData
} from '@src/views/tables/data-tables-admin/store/actions'
import {
    getCountries,
    getCitiesByStateId,
    getStatesByRegionId,
    getRegionsByCountryId
} from '@src/redux/actions/brandsDashboard'
import {
    ChevronDown,
    RefreshCcw,
    Search,
    MoreHorizontal,
    FileText,
    Eye,
    Trash, Mail, RefreshCw
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    CustomInput,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner,
    Alert,
    Tooltip, Table, Form
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {
    rdEmailNotificationStatsAction,
    rdSiteViewStatsAction
} from "@src/redux/actions/AdminActions"
import {useFormik} from "formik"
import * as Yup from "yup"
import moment from "moment/moment"
import Flatpickr from "react-flatpickr"

const ManageRDs = () => {
    const [isPageLoader, setIsPageLoader] = useState(true)
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [manageRds, setManageRds] = useState([])
    const [show, setShow] = useState(false)
    const [deleteMsg, setDeleteMsg] = useState('')
    const [toolTip, setToolTip] = useState(false)
    const [openEmailStatsModal, setOpenEmailStatsModal] = useState(false)
    const [openSiteStatsModal, setOpenSiteStatsModal] = useState(false)

    const manageRdsStoreSlice = useSelector(store => store.dataTablesAdmin)
    const brandsDashboardStoreSlice = useSelector(store => store.brandsDashboardSlice)
    const {
        rdEmailNotificationsStatsLoading,
        rdEmailNotificationsStatsData,
        rdEmailNotificationsStatsError,

        rdSiteViewStatsLoading,
        rdSiteViewStatsData,
        rdSiteViewStatsError
    } = useSelector(store => store.adminReducer)

    const dispatch = useDispatch()

    const siteViewStatsFromDateForm = useFormik({
        initialValues: {
            startDate: [new Date()],
            endDate: [new Date()]
        },
        validationSchema: Yup.object().shape({
            startDate: Yup.array().required('Start date is required.'),
            endDate: Yup.array().required('End date is required.')
        }),
        onSubmit: (values) => {
            const startDate = moment(values.startDate[0]).format('yyyy-MM-DD')
            const endDate = moment(values.endDate[0]).format('yyyy-MM-DD')
            dispatch(rdSiteViewStatsAction({
                start_date: startDate,
                end_date: endDate
            }))
        }
    })

    useEffect(() => {
        if (!!manageRdsStoreSlice?.manageRDsList) {
            const {links, meta, pages} = manageRdsStoreSlice.manageRDsList
            setPaginationInfo({...links, ...meta, pages})
            setManageRds(manageRdsStoreSlice.manageRDsList?.data)
            setIsPageLoader(false)
        }

        if (manageRdsStoreSlice?.deleteRDsData?.success === true) {
            dispatch(getManageRDsData())
            dispatch(deleteResponseManageRDsData())
            setDeleteMsg(manageRdsStoreSlice?.deleteRDsData?.message)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000)
        }

        if (manageRdsStoreSlice?.exportCsv.length > 0) {
            setToolTip(true)
            setTimeout(() => {
                setToolTip(false)
            }, 10000)
        }
    }, [manageRdsStoreSlice, brandsDashboardStoreSlice])

    useEffect(() => {
        setIsPageLoader(true)
        dispatch(getManageRDsData())
        dispatch(getCountries())
        dispatch(getIndustries())
        dispatch(getSpecialities())
        dispatch(getExportCsvData())
    }, [])

    // ** States
    const [searchName, setSearchName] = useState('')
    const [searchSpeciality, setSearchSpeciality] = useState('')
    const [searchCity, setSearchCity] = useState('')
    const [searchStatus, setSearchStatus] = useState('')
    const [searchCountry, setSearchCountry] = useState('')
    const [searchState, setSearchState] = useState('')
    const [searchRegion, setSearchRegion] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [searchEmail, setSearchEmail] = useState('')
    const [searchIndustry, setSearchIndustry] = useState('')
    const [membership, setMembership] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deleteFAQModal, setDeleteFAQModal] = useState(false)

    const updateRdStatus = (id) => {
        const params = {
            id,
            type: "manage"
        }
        dispatch(updateManageRDsStatus(params))
    }

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            minWidth: '100px'
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Cdr',
            selector: 'cdr_number',
            sortable: true,
            minWidth: '50px',
            wrap: true
        },
        {
            name: 'Industry',
            selector: 'industry',
            sortable: true,
            minWidth: '150px',
            cell: row => {
                return (
                    <div>
                        {row.industry?.map(indust => (
                            <span>{indust.label}</span>
                        ))}
                    </div>
                )
            }
        },
        {
            name: 'Speciality',
            selector: 'speciality',
            sortable: true,
            minWidth: '150px',
            cell: row => {
                return (
                    <div>
                        {row.speciality?.map(special => (
                            <span>{special.label}</span>
                        ))}
                    </div>
                )
            }
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '30px',
            cell: row => {
                return (
                    <CustomInput
                        className='custom-control-success'
                        type='switch'
                        id={`success_${row.id}`}
                        name='success'
                        inline
                        defaultChecked={row.status === 'enabled'}
                        onClick={() => updateRdStatus(row.id)}/>
                )
            }
        },
        {
            name: 'Reg. Date',
            selector: 'reg_date',
            sortable: true,
            minWidth: '80px',
            wrap: true
        },
        {
            name: 'Location',
            selector: 'location',
            sortable: true,
            minWidth: '70px',
            cell: row => {
                return (
                    <div>
                        {row.location}
                    </div>
                )
            }
        },
        {
            name: 'TimeZone',
            selector: 'timezone',
            sortable: true,
            minWidth: '70px',
            cell: row => {
                return (
                    <div>
                        {row.timezone}
                    </div>
                )
            }
        },
        {
            name: 'Membership',
            selector: 'membership',
            sortable: true,
            minWidth: '70px',
            cell: row => {
                return (
                    <div>
                        {row.membership}
                    </div>
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2' style={{cursor: 'pointer'}}>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={Link} to={`/admin/manage-rd/${row.id}`}>
                                    <Eye size={15}/>
                                    <span className='align-middle ml-50'>View</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    setDeleteFAQModal(true)
                                    setDeleteId(row.id)
                                }}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    const searchData = () => {
        setIsPageLoader(true)
        dispatch(searchManageRDsData(false, {
            name: searchName,
            email: searchEmail,
            city: searchCity || null,
            state: searchState || null,
            country: searchCountry || null,
            region: searchRegion || null,
            status: searchStatus,
            industry: searchIndustry ? [searchIndustry] : [],
            speciality_id: searchSpeciality,
            rd_membership: membership
        }))
    }

    const deleteRd = () => {
        setDeleteFAQModal(!deleteFAQModal)
        dispatch(deleteManageRDsData(deleteId))
        setIsPageLoader(true)
    }

    const refreshSearchData = () => {
        // setIsPageLoader(true)
        setSearchName('')
        setSearchEmail('')
        setSearchCity('')
        setSearchState('')
        setSearchCountry('')
        setSearchRegion('')
        setSearchStatus('')
        setSearchIndustry('')
        setSearchSpeciality('')
        dispatch(nullSearchData('manageRds'))
        setIsPageLoader(true)
        dispatch(getManageRDsData())
        brandsDashboardStoreSlice.regionsByCountryID = []
        brandsDashboardStoreSlice.statesByRegionID = []
        brandsDashboardStoreSlice.citiesByStateID = []
    }

    // ** Function to handle Pagination
    const handlePagination = page => setCurrentPage(page.selected)

    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
        let result = ''
        result += "Name, Email, CDR Number, Country, Region, States, Cities, Industry, Speciality , Status, Reg Date, Profile Status, Profile Image, Location, Timezone \n"
        array.forEach(item => {
            result += `${item?.name?.replaceAll(',', '')}, ${item?.email}, ${item?.cdr_number.replaceAll('#', 'No.')}, ${item?.country}, ${item?.region}, ${item?.state}, ${item?.city},`
            result += `${item?.industry.map(e => e.label).join(" | ").replaceAll(',', ' ')},`
            result += `${item?.speciality.map(e => e.label).join(" | ").replaceAll(',', ' ')},`
            result += `${item?.status}, ${item?.reg_date.replaceAll(',', ' ')}, ${item?.profile_status}, ${item?.profile_image}, ${item?.location}, ${item?.timezone}`
            result += "\n"
        })
        return result
    }

    // ** Downloads CSV
    function downloadCSV(array) {
        if (array.length > 0) {
            const link = document.createElement('a')
            let csv = convertArrayOfObjectsToCSV(array)
            if (csv === null) return
            const filename = 'Manage-RDS.csv'
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`
            }
            link.setAttribute('href', encodeURI(csv))
            link.setAttribute('download', filename)
            link.click()
        } else {
            alert('Data is being loaded, Please wait...')
        }
    }

    const getRegions = (id) => {
        dispatch(getRegionsByCountryId({id}))
    }

    const getStates = (id) => {
        dispatch(getStatesByRegionId({id}))
    }

    const getCities = (id) => {
        dispatch(getCitiesByStateId({id}))
    }

    // ** Table data to render
    const dataToRender = () => {
        // if (
        //   manageRdsStoreSlice?.searchedManageRDsList?.length
        // ) {
        // return manageRdsStoreSlice.searchedManageRDsList
        if (manageRds) {
            return manageRds
        }
    }

    const handleCustomPagination = pageUrl => {
        setIsPageLoader(true)
        dispatch(searchManageRDsData(true, {
            name: searchName,
            email: searchEmail,
            city: searchCity || null,
            state: searchState || null,
            country: searchCountry || null,
            region: searchRegion || null,
            status: searchStatus,
            industry: searchIndustry ? [searchIndustry] : [],
            speciality_id: searchSpeciality,
            rd_membership: membership
        }, pageUrl))
    }

    return (
        <Fragment>
            <Card className="brands-my-campaign-table-parent">
                <CardHeader className='d-flex align-items-center justify-content-between flex-wrap border-bottom p-1 px-2'>
                    <CardTitle tag='h4'>Manage RDs</CardTitle>
                    <div>
                        <Button.Ripple className="mr-1" color='primary' size='sm' onClick={() => {
                            setOpenSiteStatsModal(true)
                            dispatch(rdSiteViewStatsAction(''))
                        }}>
                            <Eye size={15}/> Site View Stats
                        </Button.Ripple>
                        <Button.Ripple className="mr-1" size='sm' color='primary' onClick={() => {
                            setOpenEmailStatsModal(true)
                            dispatch(rdEmailNotificationStatsAction())
                        }}>
                            <Mail size={15}/> Email Notifications Stats
                        </Button.Ripple>
                        <Button.Ripple id="BtnTooltip" color='primary' size='sm' onClick={() => downloadCSV(manageRdsStoreSlice?.exportCsv)}>
                            <FileText size={15}/> Export (CSV)
                        </Button.Ripple>
                        <Tooltip placement="bottom" isOpen={toolTip} target="BtnTooltip">
                            Data is ready for download.
                        </Tooltip>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Name:</Label>
                                <Input id='name' placeholder='Name' name="name" value={searchName} onChange={(e) => setSearchName(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='email'>Email:</Label>
                                <Input
                                    type='email'
                                    id='email'
                                    placeholder='Email'
                                    value={searchEmail}
                                    name="email" onChange={(e) => setSearchEmail(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='country'>Country:</Label>
                                <Input id='country' type="select" placeholder='Country' name="country" onChange={(e) => {
                                    setSearchCountry(e.target.value)
                                    getRegions(e.target.value)
                                    setSearchRegion('')
                                    setSearchState('')
                                    setSearchCity('')
                                }}
                                       value={searchCountry}>
                                    <option value=''>Country</option>
                                    {brandsDashboardStoreSlice?.countries && brandsDashboardStoreSlice?.countries.map(country => (
                                        <option value={country.id}>{country.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='region'>Region:</Label>
                                <Input id='region' type="select" placeholder='Region' name="region" onChange={(e) => {
                                    setSearchRegion(e.target.value)
                                    getStates(e.target.value)
                                    setSearchState('')
                                    setSearchCity('')
                                }}
                                       value={searchRegion}>
                                    <option value=''>Region</option>
                                    {brandsDashboardStoreSlice?.regionsByCountryID && brandsDashboardStoreSlice?.regionsByCountryID.map(region => (
                                        <option value={region.id}>{region.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='state'>State:</Label>
                                <Input id='state' type="select" placeholder='State' name="state" onChange={(e) => {
                                    setSearchState(e.target.value)
                                    getCities(e.target.value)
                                    setSearchCity('')
                                }}
                                       value={searchState}>
                                    <option value=''>State</option>
                                    {brandsDashboardStoreSlice?.statesByRegionID && brandsDashboardStoreSlice?.statesByRegionID.map(state => (
                                        <option value={state.id}>{state.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='city'>City:</Label>
                                <Input type="select" id='city' placeholder='City'
                                       name="city" onChange={(e) => setSearchCity(e.target.value)}
                                       value={searchCity}>
                                    <option value=''>City</option>
                                    {brandsDashboardStoreSlice?.citiesByStateID && brandsDashboardStoreSlice?.citiesByStateID.map(city => (
                                        <option value={city.id}>{city.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='industry'>Industry:</Label>
                                <Input type="select" id='industry' placeholder='Industry' name="industry" onChange={(e) => setSearchIndustry(e.target.value)}
                                       value={searchIndustry}>
                                    <option value=''>Industry</option>
                                    {manageRdsStoreSlice?.industries && manageRdsStoreSlice?.industries.map(industry => (
                                        <option value={industry.id}>{industry.value}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='speciality'>Speciality:</Label>
                                <Input type="select" id='speciality' placeholder='Speciality' name="speciality" onChange={(e) => setSearchSpeciality(e.target.value)}
                                       value={searchSpeciality}>
                                    <option>Speciality</option>
                                    {manageRdsStoreSlice?.specialities && manageRdsStoreSlice?.specialities.map(speciality => (
                                            <option value={speciality.id}>{speciality.value}</option>
                                        )
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='status'>Status:</Label>
                                <Input type="select" id='status' placeholder='Status' name="status" onChange={(e) => setSearchStatus(e.target.value)}
                                       value={searchStatus}>
                                    <option value=''>Status</option>
                                    <option value="enabled">enabled</option>
                                    <option value="disabled">disabled</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='status'>Membership:</Label>
                                <Input type="select" id='status' placeholder='Status' name="status" onChange={(e) => setMembership(e.target.value)}
                                       value={membership}>
                                    <option value=''>Membership</option>
                                    <option value="free">Free</option>
                                    <option value="paid">Paid</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="admin-filter-actions ml-auto text-right">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchData}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchData}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>

                    <Alert color={'success'} isOpen={show} className="mt-2">
                        <div className='alert-body'>
                            {deleteMsg}
                        </div>
                    </Alert>

                </CardBody>
                {isPageLoader && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isPageLoader && (
                    <DataTable
                        noHeader
                        paginationServer
                        columns={advSearchColumns}
                        paginationPerPage={7}
                        className='react-dataTable'
                        sortIcon={<ChevronDown size={10}/>}
                        paginationDefaultPage={currentPage + 1}
                        // paginationComponent={CustomPagination}
                        data={dataToRender()}
                    />
                )}
                <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                <Modal isOpen={deleteFAQModal} toggle={() => setDeleteFAQModal(!deleteFAQModal)} className='modal-dialog-centered'>
                    <ModalHeader toggle={() => setDeleteFAQModal(!deleteFAQModal)}>Delete RD</ModalHeader>
                    <ModalBody>
                        Are you sure want to delete this RD?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={() => setDeleteFAQModal(!deleteFAQModal)}>
                            No
                        </Button>
                        <Button color='danger' onClick={deleteRd}>
                            Yes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Card>
            {/* --- Email Notifications Stats Modal --- */}
            <Modal isOpen={openEmailStatsModal} className='modal-dialog-centered' size="lg">
                <ModalHeader toggle={() => {
                    setOpenEmailStatsModal(false)
                }}>
                    Email Notifications Stats
                </ModalHeader>
                <ModalBody>
                    <Table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Notification Type</th>
                            <th>Toggle ON RD's</th>
                            <th>Toggle OFF RD's</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rdEmailNotificationsStatsLoading ? (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    <Spinner color='primary'/>
                                </td>
                            </tr>
                        ) : (
                            rdEmailNotificationsStatsData?.data?.length > 0 ? (
                                rdEmailNotificationsStatsData?.data?.map((item, i) => (
                                    <tr key={i}>
                                        <td><span className='align-middle font-weight-bold'>{++i}</span></td>
                                        <td>{item.title}</td>
                                        <td><span className='badge badge-success'>{item?.toggle_on}</span></td>
                                        <td><span className='badge badge-danger'>{item?.toggle_off}</span></td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="text-center" colSpan="4">
                                        Sorry! No record found.
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
            {/* --- Site View Stats Modal --- */}
            <Modal isOpen={openSiteStatsModal} className='modal-dialog-centered' size="lg">
                <ModalHeader toggle={() => {
                    setOpenSiteStatsModal(false)
                }}>
                    Site View Stats
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={siteViewStatsFromDateForm.handleSubmit} autoComplete="off">
                        <Row>
                            <Col md='3'>
                                <FormGroup>
                                    <Label for='start-date'><strong>Start Date:</strong></Label>
                                    <Flatpickr
                                        className='form-control'
                                        id='start-date'
                                        placeholder='Start Date'
                                        value={siteViewStatsFromDateForm.values.startDate}
                                        options={{dateFormat: 'd M, Y'}}
                                        onChange={(e) => {
                                            siteViewStatsFromDateForm.setFieldValue('startDate', e)
                                        }}
                                        disabled={rdSiteViewStatsLoading}
                                    />
                                    {siteViewStatsFromDateForm.errors.startDate && siteViewStatsFromDateForm.touched.startDate && (
                                        <div className='text-danger'>{siteViewStatsFromDateForm.errors.startDate}</div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md='3'>
                                <FormGroup>
                                    <Label for='end-date'><strong>End Date:</strong></Label>
                                    <Flatpickr
                                        className='form-control'
                                        id='end-date'
                                        placeholder='End Date'
                                        value={siteViewStatsFromDateForm.values.endDate}
                                        options={{dateFormat: 'd M, Y'}}
                                        onChange={(e) => {
                                            siteViewStatsFromDateForm.setFieldValue('endDate', e)
                                        }}
                                        disabled={rdSiteViewStatsLoading}
                                    />
                                    {siteViewStatsFromDateForm.errors.endDate && siteViewStatsFromDateForm.touched.endDate && (
                                        <div className='text-danger'>{siteViewStatsFromDateForm.errors.endDate}</div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md='6'>
                                <Button color='primary' type='submit' disabled={rdSiteViewStatsLoading} className="mt-2 mr-1">
                                    <Search size={14}/> Search
                                </Button>
                                <Button color='danger' type='button' disabled={rdSiteViewStatsLoading} className="mt-2"
                                        onClick={() => {
                                            dispatch(rdSiteViewStatsAction(``))
                                            siteViewStatsFromDateForm.resetForm()
                                        }}>
                                    <RefreshCw size={15}/> Clear All
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Target</th>
                            <th>Number of RD's</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rdSiteViewStatsLoading ? (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    <Spinner color='primary'/>
                                </td>
                            </tr>
                        ) : (
                            rdSiteViewStatsData?.data ? (
                                <>
                                    <tr>
                                        <td><span className='align-middle font-weight-bold'>1</span></td>
                                        <td><strong>Direct from Login</strong></td>
                                        <td><span className='badge badge-primary'>{rdSiteViewStatsData?.data?.web}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span className='align-middle font-weight-bold'>2</span></td>
                                        <td><strong>Email or Other Sources</strong></td>
                                        <td><span className='badge badge-primary'>{rdSiteViewStatsData?.data?.other}</span></td>
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <td className="text-center" colSpan="3">
                                        Sorry! No record found.
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
            {/*  <Toast isOpen={show} style={{*/}
            {/*  position: 'absolute',*/}
            {/*  top: 0,*/}
            {/*  right: 0*/}
            {/*}} className="bg-success">*/}
            {/*    <ToastHeader>Deleted Success</ToastHeader>*/}
            {/*    <ToastBody>*/}
            {/*      Record deleted successfully  */}
            {/*    </ToastBody>*/}
            {/*  </Toast>*/}
        </Fragment>
    )
}

export default ManageRDs
