import {React, Fragment, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Eye, MoreVertical, List} from "react-feather"
import {
    Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Modal, ModalHeader, ModalBody,
    ModalFooter, Button, CardText, Table, CustomInput, Alert, Card, CardBody
} from "reactstrap"
import "@styles/base/pages/app-ecommerce.scss"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {
    brandAllInvoicesAction,
    payNewInvoiceAction,
    payNewInvoiceResetAction
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import {getBankAccountDetails} from "@src/views/brands/manageACH/store/actions"
import {getCardsListing} from "@src/views/brands/manageCards/store/actions"
import DataTable from 'react-data-table-component'
import queryString from "query-string"

const Invoices = () => {
    const dispatch = useDispatch()

    const [isAccountsTableLoading, setIsAccountsTableLoading] = useState(false)
    const [isCardsTableLoading, setIsCardsTableLoading] = useState(false)
    const [isPayNow, setIsPayNow] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [isBankCard, setIsBankCard] = useState(false)
    const [isBankAccount, setIsBankAccount] = useState(false)
    const [tokenId, setTokenId] = useState("")
    const [payNowDisable, setPayNowDisable] = useState(false)
    const [invoiceData, setInvoiceData] = useState(null)
    const [payInvoiceModal, setPayInvoiceModal] = useState(false)

    const achSlice = useSelector((store) => store.manageAch)
    const cardsSelector = useSelector(store => store.manageCards)
    const {brandInvoicesLoading, brandInvoicesData} = useSelector((store) => store.brandInvoicesReducer)
    const {payNewInvoiceLoading, payNewInvoiceData} = useSelector((store) => store.payNewInvoiceReducer)

    useEffect(() => {
        if (!!achSlice?.bankAccountDetail) {
            setIsAccountsTableLoading(false)
        }

        if (!!cardsSelector?.cardsListingData) {
            setIsCardsTableLoading(false)
        }
    }, [achSlice, cardsSelector])

    useEffect(() => {
        if (payNewInvoiceData?.status === true) {
            setConfirmationModal(false)
            setPayInvoiceModal(false)
            setInvoiceData(null)
            dispatch(brandAllInvoicesAction())
            setTimeout(() => dispatch(payNewInvoiceResetAction()), 5000)
        }
    }, [payNewInvoiceData])

    useEffect(() => {
        dispatch(brandAllInvoicesAction())
    }, [])

    const invoiceModalView = (row) => {
      if (row.invoice_url) {
        window.open(row.invoice_url, "_blank")
      }
    }

    const invoiceModalPay = (row) => {
        setInvoiceData(row)
        setPayInvoiceModal(true)
    }

    const advSearchColumns = [
        {
            name: 'RD Name',
            width: '180px',
            cell: row => {
                return (
                    <p className="text-primary">{row.rd_name}</p>
                )
            }
        },
        {
            name: 'Invoice Title',
            selector: 'title',
            width: '250px',
            wrap: true
        },
        {
            name: 'Description',
            selector: 'description',
            width: '300px',
            wrap: true,
            cell: row => {
                return (
                    row?.description?.length > 30 ? (
                        <span title={row?.description} className="cursor-pointer">
                          {row?.description?.substring(0, 30)}...
                        </span>
                    ) : row?.description
                )
            }
        },
        {
            name: 'Amount',
            cell: row => {
                return (
                    <p>${row.amount}</p>
                )
            }
        },
        {
            name: 'Admin Commission',
            width: '180px',
            cell: row => {
                return (
                    <p>
                        <span>{row.admin_com_percent}%</span>
                        <span> - (${row.admin_commission})</span>
                    </p>
                )
            }
        },
        {
            name: 'To Pay',
            width: '100px',
            cell: row => {
                return (
                    <Badge color="primary">${row.total}</Badge>
                )
            }
        },
        {
            name: 'Is Paid',
            selector: 'is_paid',
            cell: row => {
                return (
                    row.is_paid === 'No' ? (<Badge color="warning">No</Badge>) : (<Badge color="success">Yes</Badge>)
                )
            }
        },
        {
            name: 'Actions',
            justifyContent: 'center',
            cell: row => (
                <div className='column-action d-flex align-items-center'>
                    <UncontrolledDropdown>
                        <DropdownToggle tag='span'>
                            <MoreVertical size={17} className='cursor-pointer'/>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => invoiceModalPay(row)} disabled={row.is_paid === 'Yes'}>
                                <List size={17} className='mr-50'/>
                                <span className='align-middle'>Pay Invoice</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => invoiceModalView(row)} disabled={row.is_paid === 'No'}>
                                <Eye size={17} className='mr-50'/>
                                <span className='align-middle'>View Invoice</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        }
    ]

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(brandAllInvoicesAction({
            page: pageNo
        }))
    }

    const fetchBankCards = () => {
        setIsBankCard(true)
        setIsBankAccount(false)
        setIsCardsTableLoading(true)
        dispatch(getCardsListing())
    }

    const fetchBankAccounts = () => {
        setIsBankAccount(true)
        setIsBankCard(false)
        setIsAccountsTableLoading(true)
        dispatch(getBankAccountDetails())
    }

    const submitPayment = () => {
        if (invoiceData?.id) {
            dispatch(payNewInvoiceAction({
                id: invoiceData.id,
                token_id: tokenId
            }))
        }
    }

    return (
        <Card>
            <CardBody>
                {payNewInvoiceData?.status === true && (
                    <Alert color="success" isOpen={true}>
                        <div className='alert-body'>
                            {payNewInvoiceData?.msg}
                        </div>
                    </Alert>
                )}
                {/* --- Invoice Table --- */}
                {brandInvoicesLoading ? (
                    <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
                ) : (
                    <>
                        <DataTable
                            noHeader={true}
                            columns={advSearchColumns}
                            className='react-dataTable'
                            data={brandInvoicesData?.data}
                        />
                        <CustomPagination pageLinks={brandInvoicesData?.pages}
                                          onPaginationClick={handleCustomPagination}/>
                    </>
                )}
                {/* --- Pay Invoice Modal --- */}
                <Modal size="lg" isOpen={payInvoiceModal} className='modal-dialog-centered'>
                    <ModalHeader>Invoice</ModalHeader>
                    <ModalBody>
                        <CardText>
                            {payNewInvoiceData?.status === false && (
                                <Alert color="danger" isOpen={true}>
                                    <div className='alert-body'>
                                        {payNewInvoiceData?.msg}
                                    </div>
                                </Alert>
                            )}
                        </CardText>
                        <Table className="mt-2">
                            <thead>
                            <tr>
                                <th width={300}>Title</th>
                                <th>Amount</th>
                                <th>Admin Commission</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{invoiceData?.title}</td>
                                <td align="center">${invoiceData?.amount}</td>
                                <td align="center">${invoiceData?.admin_commission}</td>
                                <td align="center"><strong>${invoiceData?.total}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="3" align="right"><strong>Due Amount</strong></td>
                                <td align="center"><strong>${invoiceData?.total}</strong></td>
                            </tr>
                            </tbody>
                        </Table>
                        <div className="mb-2">
                            <p className="mb-1 bg-light p-1 font-weight-bold">Payment Method</p>
                            <div className="text-center">
                                <CustomInput type='radio'
                                             className="font-weight-bold"
                                             id='pay-with-card'
                                             name="payment_methods"
                                             inline label='Card'
                                             onChange={() => {
                                                 fetchBankCards()
                                                 setIsPayNow(false)
                                             }}
                                />
                                <CustomInput type='radio'
                                             className="font-weight-bold"
                                             id='pay-with-bank'
                                             inline label="Bank Account"
                                             name="payment_methods"
                                             onChange={() => {
                                                 fetchBankAccounts()
                                                 setIsPayNow(false)
                                             }}
                                />
                            </div>
                        </div>
                        {isBankAccount && (
                            <Table>
                                <thead>
                                <tr>
                                    <th>Select Account</th>
                                    <th>Account Holder Name</th>
                                    <th>Bank Name</th>
                                    <th>Account Number</th>
                                </tr>
                                </thead>
                                <tbody>
                                {isAccountsTableLoading && (<tr>
                                    <td colSpan={5} className="text-center"><Spinner color='primary'/></td>
                                </tr>)}
                                {!isAccountsTableLoading && (
                                    achSlice?.bankAccountDetail?.data && achSlice?.bankAccountDetail?.data?.map((bandAccount, index) => (
                                        <tr key={index}>
                                            <td>
                                                <CustomInput type='radio'
                                                             id={`exampleCustomRadio_${bandAccount?.account_id}`}
                                                             name='payment'
                                                             inline onChange={() => {
                                                    setIsPayNow(true)
                                                    setTokenId(bandAccount?.account_id)
                                                }}
                                                />
                                            </td>
                                            <td>{bandAccount?.account_holder_name}</td>
                                            <td>{bandAccount?.bank_name}</td>
                                            <td>{bandAccount?.last4}</td>
                                        </tr>
                                    ))
                                )}
                                {!isAccountsTableLoading && achSlice?.bankAccountDetail?.data?.length === 0 && (<tr>
                                    <td colSpan={4} className="text-center font-weight-bold">Sorry! No record found.
                                    </td>
                                </tr>)}
                                </tbody>
                            </Table>
                        )}
                        {isBankCard && (
                            <Table>
                                <thead>
                                <tr>
                                    <th>Select Card</th>
                                    <th>Type</th>
                                    <th>Card Number</th>
                                    <th>Month</th>
                                    <th>Year</th>
                                </tr>
                                </thead>
                                <tbody>
                                {isCardsTableLoading && (<tr>
                                    <td colSpan={5} className="text-center"><Spinner color='primary'/></td>
                                </tr>)}
                                {!isCardsTableLoading && (
                                    cardsSelector?.cardsListingData?.data && cardsSelector?.cardsListingData?.data?.map((bandCard, index) => (
                                        <tr key={index}>
                                            <td>
                                                <CustomInput type='radio' id={`exampleCustomRadio_${bandCard?.id}`}
                                                             name='payment'
                                                             inline onChange={() => {
                                                    setIsPayNow(true)
                                                    setTokenId(bandCard?.id)
                                                }}
                                                />
                                            </td>
                                            <td>{bandCard?.brand}</td>
                                            <td>{bandCard?.last4}</td>
                                            <td>{bandCard?.exp_month}</td>
                                            <td>{bandCard?.exp_year}</td>
                                        </tr>
                                    ))
                                )}
                                {!isCardsTableLoading && cardsSelector?.cardsListingData?.data?.length === 0 && (<tr>
                                    <td colSpan={5} className="text-center font-weight-bold">Sorry! No record found.
                                    </td>
                                </tr>)}
                                </tbody>
                            </Table>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='danger' outline onClick={() => {
                            setIsPayNow(false)
                            setIsBankCard(false)
                            setIsBankAccount(false)
                            setPayNowDisable(false)
                            setPayInvoiceModal(false)
                            setInvoiceData(null)
                        }}>
                            Cancel
                        </Button>
                        {isPayNow && (<Button color='success' onClick={() => {
                                setConfirmationModal(true)
                                setPayNowDisable(true)
                            }} disabled={payNowDisable}>
                                Pay Now
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
                {/* --- Confirm Invoice Modal --- */}
                <Modal isOpen={confirmationModal} className='modal-dialog-centered'>
                    <ModalHeader>Confirmation Alert !</ModalHeader>
                    <ModalBody>
                        Are you sure you want to pay ?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='danger' onClick={() => {
                            setConfirmationModal(false)
                            setPayNowDisable(false)
                        }} disabled={payNewInvoiceLoading}>
                            No
                        </Button>
                        <Button color='success' onClick={submitPayment} disabled={payNewInvoiceLoading}>
                            {payNewInvoiceLoading ? (<Spinner size="sm"/>) : "Yes"}
                        </Button>
                    </ModalFooter>
                </Modal>
            </CardBody>
        </Card>
    )
}
export default Invoices
