import axios from 'axios'
import {ACTIVE_BRANDS, ALL_INVOICES, CREATE_INVOICE, DELETE_INVOICE} from '../actionsTypes/index'

const API_URL = process.env.REACT_APP_BASEURL

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const index = () => async (dispatch) => {}

export const activeBrandsAction = () => async (dispatch) => {
    dispatch({type: ACTIVE_BRANDS.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.get(`${API_URL}rd/active-brands`, {headers: headersObj})
        dispatch({type: ACTIVE_BRANDS.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ACTIVE_BRANDS.ERROR, payload: error.message})
    }
}

export const createInvoiceAction = (dataObj) => async (dispatch) => {
    dispatch({type: CREATE_INVOICE.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}rd/save-invoice`, dataObj, {headers: headersObj})
        dispatch({type: CREATE_INVOICE.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: CREATE_INVOICE.ERROR, payload: error.message})
    }
}

export const createInvoiceResetAction = () => async (dispatch) => {
    dispatch({type: CREATE_INVOICE.RESET})
}

export const allInvoicesAction = (dataObj) => async (dispatch) => {
    dispatch({type: ALL_INVOICES.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}rd/invoices`, dataObj, {headers: headersObj})
        dispatch({type: ALL_INVOICES.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ALL_INVOICES.ERROR, payload: error.message})
    }
}

export const deleteInvoiceAction = (dataObj) => async (dispatch) => {
    dispatch({type: DELETE_INVOICE.REQUEST})
    const headersObj = {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
    }
    try {
        const {data} = await axios.post(`${API_URL}rd/delete-invoice`, dataObj, {headers: headersObj})
        dispatch({type: DELETE_INVOICE.SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: DELETE_INVOICE.ERROR, payload: error.message})
    }
}

export const deleteInvoiceResetAction = () => async (dispatch) => {
    dispatch({type: DELETE_INVOICE.RESET})
}