import {useState, useEffect} from 'react'
import {
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
    Card,
    CardBody
} from 'reactstrap'
import {PropTypes} from "prop-types"

const ProductsSearchbar = props => {
    const {searchValue, handleSearch, handleReset} = props
    const [searchInputValue, setSearchInputValue] = useState('')

    useEffect(() => {
        setSearchInputValue(searchValue)
    }, [searchValue])

    const handeSearchOpportunities = () => {
        handleSearch(searchInputValue)
    }

    const handeResetSearch = () => {
        setSearchInputValue('')
        handleReset()
    }

    const onEnterSearch = (e) => {
        if (e.key === 'Enter') {
            handeSearchOpportunities()
        }
    }

    return (
        <Card className="mt-2">
            <CardBody className="">
                <Row>
                    <Col md="12">
                        <InputGroup size='lg' className="">
                            <Input placeholder='Search By Name' value={searchInputValue} onChange={e => setSearchInputValue(e.target.value)} onKeyDown={onEnterSearch}/>
                            <InputGroupAddon addonType='append'>
                                <Button color='primary' onClick={handeSearchOpportunities} outline>
                                    Search
                                </Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType='append'>
                                <Button color='primary' onClick={handeResetSearch} outline>
                                    Reset
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

ProductsSearchbar.propTypes = {
    searchValue: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired
}

export default ProductsSearchbar
