import { useState, useEffect } from 'react'
import { Row, Col, InputGroup, InputGroupAddon, Input, InputGroupText, Button, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

const ProductsSearchbar = props => {
  const { searchValue, handleSearch, handleReset } = props
  const [searchInputValue, setSearchInputValue] = useState('')
  const [activePage, setActivePage] = useState('My Brands')

  useEffect(() => {
    setSearchInputValue(searchValue)
  }, [searchValue])

  const handeSearchProfiles = () => {
    handleSearch(searchInputValue)
  }

  const handeResetSearch = () => {
    setSearchInputValue('')
    handleReset()
  }

  const onEnterSearch = (e) => {
    if (e.key === 'Enter') {
      handeSearchProfiles()
    }
  }

  return (
    <Card className="mt-2">
      <CardBody className="">
        <Row>
        <Col md="6">
          <InputGroup size='lg' className="input-group-with-tabs-c">
              <InputGroupAddon addonType='append'>
                <Button
                  className={classnames({
                    'active-tab': activePage === 'All'
                  })}
                  onClick={() => setActivePage('All')}
                  tag={Link}
                  to={'/dashboard/rd/brands'}
                  color='primary'
                  outline>
                  All
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType='append'>
                <Button
                  className={classnames({
                    'active-tab': activePage === 'My Brands'
                  })}
                  onClick={() => setActivePage('My Brands')}
                  tag={Link}
                  to={'/dashboard/rd/my-brands'}
                  color='primary' outline>
                  My Brands
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType='append'>
                <Button
                  className={classnames({
                    'active-tab': activePage === 'Favorites'
                  })}
                  onClick={() => setActivePage('Favorites')}
                  tag={Link}
                  to={'/dashboard/rd/favorite-folders'}
                  color='primary'
                  outline>
                  Favorites
                </Button>
              </InputGroupAddon>
            </InputGroup>
        </Col>
          <Col md="6">
            <InputGroup size='lg' className="mt-2 mt-md-0">
              <Input placeholder='Search By Brand Name' value={searchInputValue} onChange={e => setSearchInputValue(e.target.value)} onKeyDown={onEnterSearch} />
              <InputGroupAddon addonType='append'>
                <Button color='primary' onClick={handeSearchProfiles} outline>
                  Search
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType='append'>
                <Button color='primary' onClick={handeResetSearch} outline>
                  Reset
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ProductsSearchbar
