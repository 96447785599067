import {useEffect, useState, createContext} from 'react'
import {PropTypes} from "prop-types"

const ThemeColors = createContext()

const ThemeContext = ({children}) => {
    const [colors, setColors] = useState({})

    useEffect(() => {
        if (window) {
            const getHex = color => window.getComputedStyle(document.body).getPropertyValue(color).trim()
            const obj = {
                primary: {
                    light: getHex('--primary').concat('1a'),
                    main: getHex('--primary')
                },
                secondary: {
                    light: getHex('--secondary').concat('1a'),
                    main: getHex('--secondary')
                },
                success: {
                    light: getHex('--success').concat('1a'),
                    main: getHex('--success')
                },
                danger: {
                    light: getHex('--danger').concat('1a'),
                    main: getHex('--danger')
                },
                warning: {
                    light: getHex('--warning').concat('1a'),
                    main: getHex('--warning')
                },
                info: {
                    light: getHex('--info').concat('1a'),
                    main: getHex('--info')
                },
                dark: {
                    light: getHex('--dark').concat('1a'),
                    main: getHex('--dark')
                }
            }

            setColors({...obj})
        }
    }, [])

    return <ThemeColors.Provider value={{colors}}>{children}</ThemeColors.Provider>
}


ThemeContext.propTypes = {
    children: PropTypes.node.isRequired
}

export {ThemeColors, ThemeContext}
