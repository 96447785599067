import {useEffect, useRef} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormGroup,
    CustomInput,
    Button,
    CardHeader, Spinner
} from 'reactstrap'
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/base/pages/page-blog.scss'
import {Editor} from '@tinymce/tinymce-react'
import {useFormik} from "formik"
import * as Yup from "yup"
import {
    adminBlogAddUpdateAction,
    adminBlogAddUpdateResetAction,
    adminBlogDetailsAction,
    adminBlogDetailsResetAction
} from "@store/actions/AdminActions"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import {List} from "react-feather"

const AddUpdateBlog = () => {
    const {id} = useParams()

    const editorRef = useRef(null)

    const dispatch = useDispatch()

    const history = useHistory()

    const sweetAlert = withReactContent(Swal)

    const PIC_FORMATS = ["image/png", "image/jpeg", "image/jpg"]

    const PIC_MAX_SIZE = 2 * 1024 * 1024

    const {
        adminBlogAddUpdateLoading,
        adminBlogAddUpdateData,
        adminBlogAddUpdateError,

        adminBlogDetailsLoading,
        adminBlogDetailsData,
        adminBlogDetailsError
    } = useSelector(store => store.adminReducer)

    const blogForm = useFormik({
        initialValues: {
            title: '',
            desc: '',
            image: ''
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().required('Title is required'),
            image: Yup.mixed().test(
                "fileFormat",
                "Image is empty or invalid.",
                value => !value || (value && PIC_FORMATS.includes(value.type))
            ).test(
                "fileSize",
                "Image size too large. Max 2MB.",
                value => !value || (value && value.size <= PIC_MAX_SIZE)
            )
        }),
        onSubmit: (values) => {
            const blogData = new FormData()
            if (id) {
                blogData.append("id", id)
            }
            blogData.append("title", values.title)
            if (values.image) {
                blogData.append("blog_image", values.image)
            }
            blogData.append("description", editorRef.current ? editorRef.current.getContent() : "")
            dispatch(adminBlogAddUpdateAction(blogData))
        }
    })

    useEffect(() => {
        if (adminBlogAddUpdateData?.success) {
            sweetAlert.fire('Congratulations!', adminBlogAddUpdateData?.msg, 'success')
            sweetAlert.fire({
                title: 'Congratulations!',
                icon: 'success',
                html: `<p>${adminBlogAddUpdateData?.msg}</p>`,
                confirmButtonText: 'OK',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    history.push('/dashboard/admin/blogs')
                }
            })
            dispatch(adminBlogAddUpdateResetAction())
        }
        if (adminBlogAddUpdateError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (adminBlogAddUpdateError?.response?.data?.id) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminBlogAddUpdateError?.response?.data?.id[0]}</li>`)
            }
            if (adminBlogAddUpdateError?.response?.data?.title) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminBlogAddUpdateError?.response?.data?.title[0]}</li>`)
            }
            if (adminBlogAddUpdateError?.response?.data?.blog_image) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${adminBlogAddUpdateError?.response?.data?.blog_image[0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(adminBlogAddUpdateResetAction())
        }
        if (adminBlogDetailsData?.data) {
            blogForm.setFieldValue('title', adminBlogDetailsData?.data?.title)
            blogForm.setFieldValue('desc', adminBlogDetailsData?.data?.description)
        }
    }, [
        adminBlogAddUpdateData,
        adminBlogAddUpdateError,
        adminBlogDetailsData
    ])

    useEffect(() => {
        dispatch(adminBlogDetailsResetAction())
        blogForm.resetForm()
        if (id) {
            dispatch(adminBlogDetailsAction({id}))
        }
    }, [])

    return (
        <Card>
            <CardHeader>
                <h3>
                    {id ? 'Update Blog' : 'Add New Blog'}
                </h3>
                <Button.Ripple tag={Link} to="/dashboard/admin/blogs" color='primary'>
                    <List size={15}/> All Blogs
                </Button.Ripple>
            </CardHeader>
            <CardBody>
                {(adminBlogDetailsLoading) && (
                    <div className="text-center bg-light p-1 mb-1">
                        <Spinner color='primary' style={{height: '16px', width: '16px'}}/> Please wait...
                    </div>
                )}
                <Form onSubmit={blogForm.handleSubmit}>
                    <Row>
                        <Col sm='12'>
                            <hr/>
                        </Col>
                        <Col md='12'>
                            <FormGroup>
                                <Label for='title'><strong>Title</strong><span className="is-invalid">*</span></Label>
                                <Input id='title'
                                       name='title'
                                       onBlur={blogForm.handleBlur}
                                       onChange={blogForm.handleChange}
                                       value={blogForm.values.title}
                                       placeholder='Title'
                                       disabled={adminBlogAddUpdateLoading || adminBlogDetailsLoading}
                                />
                                <div className="validation-err">{blogForm.touched.title && blogForm.errors.title}</div>
                            </FormGroup>
                        </Col>
                        <Col sm='12'>
                            <FormGroup>
                                <Label><strong>Content</strong></Label>
                                <Editor apiKey="vde6zvlguv1c18x1b4vclfy3hmc669hcyg44kvnhmb1tg9uj"
                                        onInit={(_evt, editor) => {
                                            editorRef.current = editor
                                        }}
                                        initialValue={blogForm.values.desc}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help | link',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            elementpath: false,
                                            branding: false
                                        }}
                                />
                            </FormGroup>
                        </Col>
                        {adminBlogDetailsData?.data?.banner_image && (
                            <Col sm='12'>
                                <Label for='image' className='d-flex justify-content-between'>
                                    <strong>Current Image</strong>
                                </Label>
                                <FormGroup>
                                    <img src={adminBlogDetailsData?.data?.banner_image} alt={adminBlogDetailsData?.data?.title} height='200'/>
                                </FormGroup>
                            </Col>
                        )}
                        <Col sm='12'>
                            <Label for='image' className='d-flex justify-content-between'>
                                <strong>{id ? 'Change Blog Image' : 'Image'} <span className="is-invalid">*</span></strong>
                                <strong className='text-danger'>(PNG, JPG, JPEG - Max 2MB)</strong>
                            </Label>
                            <FormGroup>
                                <CustomInput
                                    type='file'
                                    id='image'
                                    name='image'
                                    onChange={(e) => blogForm.setFieldValue('image', e.target.files[0])}
                                    label='Choose file (PNG, JPG, JPEG - Max 2MB)'
                                    disabled={adminBlogAddUpdateLoading || adminBlogDetailsLoading}
                                />
                                <div className="validation-err">{blogForm.touched.image && blogForm.errors.image}</div>
                            </FormGroup>
                        </Col>
                        <Col sm='12'>
                            <hr/>
                        </Col>
                        <Col sm='12'>
                            <div className="d-flex justify-content-between">
                                <Button.Ripple tag={Link} to="/dashboard/admin/blogs" color='danger' disabled={adminBlogAddUpdateLoading || adminBlogDetailsLoading}>
                                    Cancel
                                </Button.Ripple>
                                <Button.Ripple color='primary' type='submit' disabled={adminBlogAddUpdateLoading || adminBlogDetailsLoading}>
                                    {(adminBlogAddUpdateLoading || adminBlogDetailsLoading) ? 'Please wait...' : 'Save'}
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )
}

export default AddUpdateBlog
