import axios from 'axios'
import {API_URL, EVENT} from '../actionTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getManageEventsData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-event-list`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.MANAGE_EVENTS_DATA,
        manageEventsData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getEventTypes = () => {
  return async dispatch => {
    await axios.get(`${API_URL}get-event-type`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.EVENT_TYPES,
        eventTypesData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getSearchEventsData = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-event-list`
  return async dispatch => {
    await axios.post(endpoint, {
      rd_name: body.rd_name,
      event_name: body.event_name,
      type: body.type,
      country_id: body.country_id,
      region_id: body.region_id,
      state_id: body.state_id,
      city_id: body.city_id,
      from_date: body.from_date,
      to_date: body.to_date
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.MANAGE_EVENTS_DATA,
        manageEventsData: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const changeEventsStatus = (params) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append('id', params.id)
    formData.append('type', params.type)
    await axios.post(`${API_URL}admin/update-event-status`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.CHANGE_EVENTS_DATA,
        changeEventsStatus: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getCountries = (params) => {
  return async dispatch => {
      await axios.get(`${API_URL}get-countries`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`
        }        
      }).then(response => {
        dispatch({
        type: EVENT.COUNTRIES_DATA,
        countries: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getStatesById = (country_id) => {
  return async dispatch => {
    const formData = new FormData()
    formData.append("country_id", country_id)
      await axios.post(`${API_URL}get-states`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`
        }
      }).then(response => {
        dispatch({
        type: EVENT.STATES_DATA,
        states: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getEventDetailData = (id) => {
  return async dispatch => {
    await axios.get(`${API_URL}admin/event-details/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.EVENTS_DETAIL_DATA,
        eventDetails: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getBrandPurchasedMembershiplData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-partnership-purchased`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.BRAND_PURCHASE_MEMBERSHIP,
        purchaseMemberships: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getSearchBrandPurchasedMembershiplData = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-partnership-purchased`
  return async dispatch => {
    await axios.post(endpoint,  {
      brand_name: body.brand_name,
      owner_name: body.owner_name,
      from_date: body.from_date,
      to_date: body.to_date
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.BRAND_PURCHASE_MEMBERSHIP,
        purchaseMemberships: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getBrandsParticipationRequestData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-participate-request`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.BRAND_PARTICIPATION_REQUEST,
        participationRequests: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const getSearchBrandsParticipationRequestData = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-participate-request`
  return async dispatch => {
    await axios.post(endpoint, {
      brand_name: body.brand_name,
      owner_name: body.owner_name,
      from_date: body.from_date,
      to_date: body.to_date
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.BRAND_PARTICIPATION_REQUEST,
        participationRequests: response.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const exportManageBrandsData = () => {
  return async dispatch => {
    await axios.get(`${API_URL}admin/load-event-list?data-type=export`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: EVENT.EXPORT_EVENTS,
        exportManageEventsData: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: EVENT.ERROR,
        error     
      })
    })
  }
}

export const nullSearchData = (listName) => {
  return async dispatch => {
    switch (listName) {
      case 'eventsSearhData':
        dispatch({
          type: EVENT.EVENTS_SEARCH_DATA,
          eventsSearhData: null
        })
        break
      case 'purchaseMemberships':
        dispatch({
          type: EVENT.BRAND_PURCHASE_MEMBERSHIP_SEARCH,
          searchedPurchaseMemberships: null
        })
        break
      // case 'eventsSearhData':
      //   dispatch({
      //     type: EVENT.EVENTS_SEARCH_DATA,
      //     eventsSearhData: null
      //   })
      //   break
      default:
        break
    }

  }
}