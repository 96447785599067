import React, {useEffect, useState, Fragment} from 'react'
import {Formik} from "formik"
import * as yup from "yup"
import {useHistory} from 'react-router'
import {
    Row,
    Col,
    Spinner,
    Input,
    FormGroup,
    Alert,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody, CustomInput
} from 'reactstrap'
import CurrentPlan from './CurrentPlan'
import SubscriptionHistory from './SubscriptionHistory'
import {useDispatch, useSelector} from 'react-redux'
import {
    getBrandsSubscriptionPlans,
    cancelPlan,
    cancelPlanReset
} from "@src/redux/actions/brandProfile/index"
import {Check, Delete} from "react-feather"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const SubscriptionPlan = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const history = useHistory()
    const dispatch = useDispatch()

    const sweetAlert = withReactContent(Swal)

    const [isLoading, setIsLoading] = useState(false)
    const [reasonModal, setReasonModal] = useState(false)

    const {
        currentPlanData,

        cancelPlanRequest,
        cancelPlanData,
        cancelPlanError
    } = useSelector(store => store.brandDashboard)

    useEffect(() => {
        if (cancelPlanData?.status === true) {
            setTimeout(() => {
                setReasonModal(false)
                dispatch(cancelPlanReset())
                dispatch(getBrandsSubscriptionPlans())
                //history.push('/dashboard/brands/pricing-plan')
            }, 3000)
        }

        if (cancelPlanData?.status === false) {
            setTimeout(() => {
                dispatch(cancelPlanReset())
            }, 5000)
        }

        if (currentPlanData?.data) {
            setIsLoading(false)
        }

        if (cancelPlanError?.response?.status === 400) {
            setReasonModal(false)
            setTimeout(() => {
                dispatch(cancelPlanReset())
            }, 5000)
        }
    }, [cancelPlanData, currentPlanData, cancelPlanError])

    useEffect(() => {
        setIsLoading(true)
        dispatch(getBrandsSubscriptionPlans())
    }, [])

    const cancelSubscriptionPlan = async (values) => {
        const token = await executeRecaptcha('sub_plan_brands')
        if (token) {
            const obj = {
                re_captcha_token: token,
                reason: [
                    {
                        que: values.q1,
                        ans: values.a1
                    },
                    {
                        que: values.q2,
                        ans: values.a2
                    },
                    {
                        que: "Other",
                        ans: values.other
                    }
                ]
            }
            dispatch(cancelPlan(obj))
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    const handleOpenModal = () => {
        setReasonModal(true)
    }

    return (
        <Row>
            {isLoading ? (
                <div className="w-100 my-4 text-center"><Spinner color="primary"/></div>
            ) : (
                <Fragment>
                    <Col>
                        {cancelPlanError?.response?.status === 400 && (
                            <Alert color="danger">
                                <div className='alert-body'>
                                    {cancelPlanError?.response?.data?.msg}
                                </div>
                            </Alert>
                        )}
                        <CurrentPlan planData={currentPlanData} openingModal={handleOpenModal}/>
                    </Col>
                    <Col>
                        <SubscriptionHistory subscriptionHistory={currentPlanData?.subscription_history}/>
                    </Col>
                </Fragment>
            )}

            {/*--- Plan Cancel Modal ---*/}
            <Modal isOpen={reasonModal} className='modal-dialog-centered' size="lg">
                <ModalHeader>Cancel Plan</ModalHeader>
                <ModalBody>
                    {cancelPlanData?.status === true && (
                        <Alert color="success">
                            <div className='alert-body'>
                                {cancelPlanData?.msg}
                            </div>
                        </Alert>
                    )}
                    {cancelPlanData?.status === false && (
                        <Alert color="danger">
                            <div className='alert-body'>
                                {cancelPlanData?.msg}
                            </div>
                        </Alert>
                    )}
                    <div className="text-center mb-2">
                        <h1 className="mb-1">We're sorry to see you go!</h1>
                        <p>Thanks for giving The RD Link a try and for being part of the community.</p>
                        <p>Please let us know how we can improve your experience. Thank you!</p>
                    </div>
                    <FormGroup>
                        <hr/>
                    </FormGroup>
                    <Formik
                        initialValues={{
                            q1: "What made you decide to cancel?",
                            a1: "",
                            q2: "How can we improve?",
                            a2: "",
                            other: ""
                        }}
                        validationSchema={yup.object().shape({
                            a1: yup.string().required("This field is required"),
                            a2: yup.string().required("This field is required"),
                            other: yup.string().when("a1", {
                                is: "Other",
                                then: yup.string().required("This field is required")
                            })
                        })}
                        onSubmit={(values) => {
                            cancelSubscriptionPlan(values)
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              touched,
                              values,
                              setFieldValue
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label className="font-weight-bold font-small-4">{values.q1}<span className="is-invalid">*</span></Label>
                                    <CustomInput type="radio"
                                                 label="No longer needed / Completed project or campaign."
                                                 value="No longer needed / Completed project or campaign."
                                                 className="mt-1 mb-1"
                                                 id="cancelOption1"
                                                 name="cancelOption"
                                                 onChange={(e) => setFieldValue('a1', e.target.value)}
                                                 disabled={cancelPlanRequest}
                                    />
                                    <CustomInput type='radio'
                                                 label="Didn't work as expected"
                                                 value="Didn't work as expected"
                                                 className="mt-1 mb-1"
                                                 id="cancelOption2"
                                                 name="cancelOption"
                                                 onChange={(e) => setFieldValue('a1', e.target.value)}
                                                 disabled={cancelPlanRequest}
                                    />
                                    <CustomInput type='radio'
                                                 label="Didn't know how to use it / not enough instrutions."
                                                 value="Didn't know how to use it / not enough instrutions."
                                                 className="mt-1 mb-1"
                                                 id="cancelOption3"
                                                 name="cancelOption"
                                                 onChange={(e) => setFieldValue('a1', e.target.value)}
                                                 disabled={cancelPlanRequest}
                                    />
                                    <CustomInput type='radio'
                                                 label="Too expensive"
                                                 value="Too expensive"
                                                 className="mt-1 mb-1"
                                                 id="cancelOption4"
                                                 name="cancelOption"
                                                 onChange={(e) => setFieldValue('a1', e.target.value)}
                                                 disabled={cancelPlanRequest}
                                    />
                                    <CustomInput type='radio'
                                                 label="Other"
                                                 value="Other"
                                                 className="mt-1"
                                                 id="cancelOption5"
                                                 name="cancelOption"
                                                 onChange={(e) => setFieldValue('a1', e.target.value)}
                                                 disabled={cancelPlanRequest}
                                    />
                                    {touched.a1 && errors.a1 && (
                                        <div className="validation-err mt-1">
                                            {touched.a1 && errors.a1}
                                        </div>
                                    )}
                                </FormGroup>
                                {values.a1 === "Other" && (
                                    <FormGroup>
                                        <Input onBlur={handleBlur}
                                               onChange={handleChange}
                                               value={values.other}
                                               name="other"
                                               id="other"
                                               type="textarea"
                                               disabled={cancelPlanRequest}
                                               placeholder="Other..."
                                               rows="3"
                                        />
                                        <div className="validation-err">
                                            {touched.other && errors.other}
                                        </div>
                                    </FormGroup>
                                )}
                                <FormGroup>
                                    <Label className="font-weight-bold font-small-4">{values.q2}<span className="is-invalid">*</span></Label>
                                    <Input onBlur={handleBlur}
                                           onChange={handleChange}
                                           value={values.a2}
                                           name="a2"
                                           id="a2"
                                           type="textarea"
                                           disabled={cancelPlanRequest}
                                           rows="3"
                                           placeholder="How can we improve?"
                                    />
                                    <div className="validation-err">
                                        {touched.a2 && errors.a2}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <hr/>
                                </FormGroup>
                                <FormGroup className="d-flex justify-content-between">
                                    <Button.Ripple type='button' color='danger'
                                                   disabled={cancelPlanRequest}
                                                   onClick={() => {
                                                       setReasonModal(false)
                                                       dispatch(cancelPlanReset())
                                                   }}
                                    >
                                        <Delete size={14}/> Close
                                    </Button.Ripple>
                                    <Button.Ripple type='submit' color='primary' disabled={cancelPlanRequest}>
                                        {cancelPlanRequest ? (
                                            <>
                                                <Spinner style={{height: '14px', width: '14px'}}/> Cancel Plan
                                            </>
                                        ) : (
                                            <>
                                                <Check size={14}/> Cancel Plan
                                            </>
                                        )}
                                    </Button.Ripple>
                                </FormGroup>
                            </Form>)}
                    </Formik>
                </ModalBody>
            </Modal>
        </Row>
    )
}

export default SubscriptionPlan
