import {useEffect} from 'react'
import Avatar from "@components/avatar"
import {Link} from 'react-router-dom'
import BlankProfile from "@src/assets/images/avatars/avatar-blank.png"
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Spinner
} from "reactstrap"
import {FaMapMarkerAlt, FaGift, FaUserFriends, FaCalendarAlt} from "react-icons/fa"
import {useDispatch, useSelector} from 'react-redux'
import {getMyCurrentOpportunities} from '@store/actions/brandsDashboard'
import '@styles/react/apps/app-invoice.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import queryString from "query-string"

const RecommendedOpportunities = () => {
    const dispatch = useDispatch()

    const {
        brandRecOppLoading,
        brandRecOppFullFill,
        brandRecOppError
    } = useSelector(state => state.brandsDashboardSlice)

    useEffect(() => {
        dispatch(getMyCurrentOpportunities(""))
    }, [])

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(getMyCurrentOpportunities(`?page=${pageNo}`))
    }

    return (
        <>
            {brandRecOppLoading ? (
                <div className="d-flex justify-content-center" style={{height: '200px'}}>
                    <div className="align-self-center">
                        <Spinner color='primary'/>
                    </div>
                </div>
            ) : (
                brandRecOppFullFill?.data?.length > 0 && (
                    <>
                        <h3 className="mb-2">
                            Recommended Opportunities
                        </h3>
                        <Row className="match-height">
                            {brandRecOppFullFill?.data?.map(currentData => (
                                <Col sm="6" md="4" lg="3">
                                    <Card className="ecommerce-card event-card-at-profile">
                                        <Link to={`/dashboard/brands/nutrition-detail/${currentData.id}`}>
                                            <CardHeader>
                                                <CardTitle>
                                                    {currentData?.event_title || 'N/A'}
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="d-flex flex-wrap">
                                                    <p className="item">
                                                        <FaMapMarkerAlt size={17} className="mr-1"/>
                                                        {currentData?.country || 'N/A'}
                                                    </p>
                                                    <p className="item">
                                                        <FaGift size={17} className="mr-1"/>
                                                        {currentData?.type_of_event || 'N/A'}
                                                    </p>
                                                    <p className="item">
                                                        <FaUserFriends size={17} className="mr-1"/>
                                                        {currentData?.exp_reach || 'N/A'}
                                                    </p>
                                                    <p className="item">
                                                        <FaCalendarAlt size={17} className="mr-1"/>
                                                        {currentData?.start_date || 'N/A'}
                                                    </p>
                                                </div>
                                                <div className="d-flex align-items-center mt-2">
                                                    <Avatar
                                                        img={currentData?.profile_img || BlankProfile}
                                                        className="mr-1"
                                                        imgHeight="40"
                                                        imgWidth="40"
                                                    />
                                                    <div className="user-nav">
                                                            <span className="user-name font-weight-bold">
                                                              {currentData?.organizer || 'N/A'}
                                                            </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Link>
                                    </Card>
                                </Col>
                            ))}
                            <CustomPagination pageLinks={brandRecOppFullFill?.pages}
                                              onPaginationClick={handleCustomPagination}/>
                        </Row>
                    </>
                )
            )}
        </>
    )
}

export default RecommendedOpportunities
