import axios from 'axios'
import { API_URL, MANAGE_ACH, NULL } from '../actionType'

// ** Get Token
const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getManageRDsData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-rd-list`

  return dispatch => {
    axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_ACH.MANAGE_ACH_LISTING,
        manageRDsList: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_ACH.ERROR,
        error
      })
    })
  }
}

export const getCountries = () => {
  return dispatch => {
    axios.get(`${API_URL}get-countries`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_ACH.COUNTRIES,
        countries: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_ACH.ERROR,
        error
      })
    })
  }
}

export const createManageAchBankCard = (params) => {
  return dispatch => {
    const formData = new FormData()
    formData.append("country_id", params.country_id)
    formData.append("account_holder_name", params.account_holder_name)
    formData.append("account_number", params.account_number)
    formData.append("routing_number", params.routing_number)
    formData.append("re_captcha_token", params.re_captcha_token)
    axios.post(`${API_URL}brand/add-bank-account`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_ACH.SUBMIT_BANK_CARD,
        submitBankCard: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_ACH.ERROR,
        error
      })
    })
  }
}

export const getBankAccountDetails = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}brand/bank-account-details`

  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_ACH.BANK_ACCOUNT_DETAILS,
        bankAccountDetail: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_ACH.ERROR,
        error
      })
    })
  }
}

export const verifyAccount = (params) => {

  return async dispatch => {
    await axios.post(`${API_URL}brand/verify-bank-account`, params, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_ACH.VERIFY_ACCOUNT,
        accountVerification: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_ACH.ERROR,
        error
      })
    })
  }
}

export const deleteAccount = (bank_account_id) => {

  return dispatch => {
    const formData = new FormData()
    formData.append("bank_account_id", bank_account_id)
    axios.post(`${API_URL}brand/delete-bank-account`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: MANAGE_ACH.DELETE_ACCOUNT,
        accountDelete: response.data
      })
    }).catch((error) => {
      dispatch({
        type: MANAGE_ACH.ERROR,
        error
      })
    })
  }
}

export const nullResponse = (property) => {
  return dispatch => {
    switch (property) {
      case NULL.SUBMIT_BANK_CARD:
        dispatch({ type: MANAGE_ACH.SUBMIT_BANK_CARD, submitBankCard: null })
        break
      case NULL.VERIFY_ACCOUNT:
        dispatch({ type: MANAGE_ACH.VERIFY_ACCOUNT, accountVerification: null })
        break
      case NULL.DELETE_ACCOUNT:
        dispatch({ type: MANAGE_ACH.DELETE_ACCOUNT, accountDelete: null })
        break
      case NULL.BANK_ACCOUNT_DETAILS:
        dispatch({ type: MANAGE_ACH.BANK_ACCOUNT_DETAILS, bankAccountDetail: null })
        break
    }
  }
}