import React, {useState, useEffect, Fragment} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardText,
    CardBody,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner
} from 'reactstrap'
import img1 from '@src/assets/images/avatars/1.png'
import Avatar from '@components/avatar'
import {getRDProfileInfo, approveRDProfile} from '@src/views/tables/data-tables-admin-pending/store/actions'

const ViewPendingRdProfile = () => {
    const [activeTab, setActiveTab] = useState('1')
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isRequestSubmitting, setIsRequestSubmitting] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [profileInfo, setProfileInfo] = useState(null)
    const [profileApprovalInfo, setProfileApprovalInfo] = useState(null)
    const [isPageLoader, setIsPageLoader] = useState(true)

    const {id} = useParams()
    const dispatch = useDispatch()
    const pendingRdsSlice = useSelector(store => store.dataTablesAdminPending)

    useEffect(() => {
        if (!!pendingRdsSlice?.profileInfo) {
            setProfileInfo(pendingRdsSlice.profileInfo)
            setIsPageLoader(false)
        }
        if (!!pendingRdsSlice?.profileApproval) {
            setProfileApprovalInfo(pendingRdsSlice.profileApproval)
            setIsRequestSubmitting(false)
            setApproveModal(false)
        }
    }, [pendingRdsSlice])


    useEffect(() => {
        dispatch(getRDProfileInfo(id))
    }, [profileApprovalInfo])

    const getProfileApproved = () => {
        setIsRequestSubmitting(true)
        dispatch(approveRDProfile(id))
    }

    const onProfileApprove = () => {
        if (profileInfo?.profile_status === 'pending') setApproveModal(true)
    }

    return (
        <div className="view-page">
            <Link to="/admin/manage-rd-pending" className='btn btn-primary mb-1'>Back</Link>
            <Row className="match-height">
                {isPageLoader && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isPageLoader && (
                    <>
                        <Col lg="4">
                            <Card className='ecommerce-card view-card'>
                                <div className='item-img text-center mx-auto mt-3'>
                                    <Avatar img={`${profileInfo?.profile_img || img1}`} imgHeight='120' imgWidth='120'/>
                                </div>
                                <CardBody className="text-center">
                                    <h4 className='item-name font-weight-bolder'>{profileInfo?.name}</h4>
                                    <h5 className='item-name font-weight-bolder'>{profileInfo?.email}</h5>
                                    <div className="healthcare blue-bg-500">
                                        <p className="general-wellness font-weight-bolder blue-700">
                                            {profileInfo?.attributes?.industry ? profileInfo?.attributes?.industry[0].value : 'N/A'}
                                            {" "}|{" "}
                                            {profileInfo?.attributes?.speciality ? profileInfo?.attributes?.speciality[0].value : 'N/A'}
                                        </p>
                                    </div>
                                    <div className="text-left location-info mb-1">
                                        <CardText className='item-description d-flex font-weight-bolder'>
                                            <div className="w-65">City</div>
                                            {/*{profileInfo?.city || 'N/A'}*/}
                                            <span className="ml-2 text-primary">
                                                {profileInfo?.location?.city.length > 0 ? profileInfo?.location?.city?.map((cityMap, index) => (
                                                    <span>{cityMap?.label}</span>
                                                )) : "N/A"}
                                            </span>
                                        </CardText>
                                        <CardText className='item-description d-flex font-weight-bolder'>
                                            <div className="w-65">State</div>
                                            <span className="ml-2 text-primary">
                                                {profileInfo?.location?.state.length > 0 ? profileInfo?.location?.state?.map((stateMap, index) => (
                                                    <span>{stateMap?.label}</span>
                                                )) : "N/A"}
                                            </span>
                                        </CardText>
                                        <CardText className='item-description d-flex font-weight-bolder'>
                                            <div className="w-65">Website</div>
                                            <span className="ml-2 text-truncate">
                                                {profileInfo?.website ? (
                                                    <a className="text-primary" href={profileInfo?.website} target="_blank">
                                                        {profileInfo?.website}
                                                    </a>
                                                ) : (
                                                    <span>N/A</span>
                                                )}
                                            </span>
                                        </CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <Card className='ecommerce-card'>
                                <CardHeader>
                                    <h2 className='font-weight-bolder'>About</h2>
                                    <Button color='primary'
                                            onClick={() => onProfileApprove()}
                                            disabled={profileInfo?.profile_status !== 'pending'}>
                                        {profileInfo?.profile_status !== 'pending' ? 'Approved' : 'Approve'}
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>Industry</h5>
                                                <h6 className=''>
                                                    {profileInfo?.attributes?.industry?.length === 0 ? 'N/A' : (
                                                        <Fragment>
                                                            {profileInfo?.attributes?.industry?.map(name => (<span key={name.id}>{name.value}, </span>))}
                                                        </Fragment>
                                                    )}
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>Speciality</h5>
                                                <h6 className=''>
                                                    {profileInfo?.attributes?.speciality?.length === 0 ? 'N/A' : (
                                                        <Fragment>
                                                            {profileInfo?.attributes?.speciality?.map(name => (<span key={name.id}>{name.value}, </span>))}
                                                        </Fragment>
                                                    )}
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>Are you a RD2Be or Dietitian?</h5>
                                                <h6 className=''>
                                                    <span>
                                                        {profileInfo?.is_rd === 1 && ("RD")}
                                                        {profileInfo?.is_dietetic_intern === 1 && ("RD2Be")}
                                                        {profileInfo?.other_rd ?? ""}
                                                    </span>
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>How do you primarily want to connect with brands?</h5>
                                                <h6 className=''>
                                                    <span>{profileInfo?.primarily_brands?.label || 'N/A'}</span>
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText className='oppo-detail'>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>How did you here about LINK?</h5>
                                                <h6 className=''>
                                                    <span>{profileInfo?.hear_about || 'N/A'}</span>
                                                </h6>
                                            </div>
                                        </CardText>
                                        <CardText className='oppo-detail'>
                                            <div className="p-25">
                                                <h5 className='font-weight-bolder'>CDR</h5>
                                                <h6 className=''>
                                                    <span>{profileInfo?.cdr_number || 'N/A'}</span>
                                                </h6>
                                            </div>
                                        </CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                )}
            </Row>
            <Modal isOpen={approveModal} toggle={() => setApproveModal(!approveModal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setApproveModal(!approveModal)}>Approve Profile</ModalHeader>
                <ModalBody>
                    Are you sure want to approve this profile?
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' outline
                            onClick={() => setApproveModal(!approveModal)}
                            hidden={isRequestSubmitting}>
                        No
                    </Button>
                    <Button color='primary' onClick={getProfileApproved} disabled={isRequestSubmitting}>
                        {isRequestSubmitting ? 'Loading...' : 'Yes'}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ViewPendingRdProfile