import {useEffect} from "react"
import {Link, useHistory} from "react-router-dom"
import Avatar from "@components/avatar"
import {User, MessageSquare} from "react-feather"
import {
    Button,
    Row,
    Col,
    Spinner,
    UncontrolledTooltip
} from "reactstrap"
import {getRecommendedRdsAtBrandsDashboard} from "@src/redux/actions/brandsDashboard/index"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import BlankProfile from "@src/assets/images/avatars/avatar-blank.png"
import queryString from "query-string"

const RecommendedRDs = () => {
    const history = useHistory()

    const dispatch = useDispatch()

    const {
        brandRecRDsLoading,
        brandRecRDsFullFill,
        brandRecRDsError
    } = useSelector(store => store.brandsDashboardSlice)

    useEffect(() => {
        dispatch(getRecommendedRdsAtBrandsDashboard(""))
    }, [])

    const handleCustomPagination = pageUrl => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        dispatch(getRecommendedRdsAtBrandsDashboard(`?page=${pageNo}`))
    }

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatRd', JSON.stringify(user))
        history.push(`/brands/apps/chat/chatBrands/${user.id}`)
    }

    return (
        <>
            {brandRecRDsLoading ? (
                <div className="d-flex justify-content-center" style={{height: '200px'}}>
                    <div className="align-self-center">
                        <Spinner color='primary'/>
                    </div>
                </div>
            ) : (
                brandRecRDsFullFill?.data?.length > 0 && (
                    <>
                        <h3 className="mb-2">
                            Recommended RDs
                        </h3>
                        <div className="brandSlides mb-1">
                            <Row className="match-height">
                                {brandRecRDsFullFill?.data?.map((rd) => (
                                    <Col sm="6" md="4" lg="3" key={rd.id}>
                                        <div className="full-height brandContent mb-2">
                                            <Avatar
                                                img={rd?.profile_img || BlankProfile}
                                                imgHeight="91"
                                                imgWidth="91"
                                            />
                                            <h2 className="brandSlidesRd mt-2 mb-1">
                                                {rd?.name || "N/A"}
                                            </h2>
                                            <div
                                                className="healthcare"
                                                style={{backgroundColor: "#c9f7f5"}}
                                            >
                                                <p
                                                    className="general-wellness"
                                                    style={{color: "#17c2ba"}}
                                                >
                                                    {rd?.industry[0]?.value || "N/A"}
                                                    {" "}|{" "}
                                                    {rd?.speciality[0]?.value || "N/A"}
                                                </p>
                                            </div>
                                            <Button.Ripple
                                                tag={Link} to={`/dashboard/brands/nutritions/${rd.id}`}
                                                id={`ProfileUncontrolledTooltip_${rd?.id}`}>
                                                <User size={14}/>
                                                <UncontrolledTooltip placement='top'
                                                                     target={`ProfileUncontrolledTooltip_${rd?.id}`}>
                                                    Profile
                                                </UncontrolledTooltip>
                                            </Button.Ripple>
                                            <Button.Ripple
                                                color="secondary"
                                                className="m-25"
                                                onClick={() => handleRedirectMessage(rd)}
                                                id={`MsgUncontrolledTooltip_${rd?.id}`}>
                                                <MessageSquare size={14}/>
                                                <UncontrolledTooltip placement='top'
                                                                     target={`MsgUncontrolledTooltip_${rd?.id}`}>
                                                    Message
                                                </UncontrolledTooltip>
                                            </Button.Ripple>

                                        </div>
                                    </Col>
                                ))
                                }
                            </Row>
                            <Row className="pt-1 justify-content-centered">
                                <Col md="12">
                                    <CustomPagination pageLinks={brandRecRDsFullFill?.pages}
                                                      onPaginationClick={handleCustomPagination}/>
                                </Col>
                            </Row>
                        </div>
                    </>
                )
            )}
        </>
    )
}

export default RecommendedRDs
