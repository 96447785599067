import {
    GENERAL,
    BRANDS,
    BRAND_MY_PARTNERSHIP,
    BRAND_STATS,
    REC_RDS,
    REC_OPPORTUNITIES
} from '../../actionTypes'

const initialState = {
    favoriteFoldersRes: null,
    createfavoriteFolderRes: null,
    updatefavoriteFolderRes: null,
    deletefavoriteFolderRes: null,
    countries: null,
    regionsByCountryID: null,
    statesByRegionID: null,
    citiesByStateID: null,
    goalsAttributes: null,
    nutritionExperts: null,
    myRDsAtBrands: null,
    addToFavroiteRes: null,
    favoriteRDProfilesRes: null,
    favoriteRDOpportunitiesRes: null,
    audienceTypes: null,
    eventTypes: null,
    brandNotifications: null,
    updateBrandProfile: null,
    removeMediaAtProfile: null,
    error: null,
    brandUnreadMessages: null,
    brandUnreadNotifications: null,
    recommendedRd: null,
    addedNote: null,

    brandMyPartnershipLoading: false,
    brandMyPartnershipFullFill: null,
    brandMyPartnershipError: null,

    brandStatsLoading: false,
    brandStatsFullFill: null,
    brandStatsError: null,

    brandRecRDsLoading: false,
    brandRecRDsFullFill: null,
    brandRecRDsError: null,

    brandRecOppLoading: false,
    brandRecOppFullFill: null,
    brandRecOppError: null
}

const brandsReducer = (state = initialState, action) => {
    switch (action.type) {
        case BRANDS.EVENT_TYPES:
            return {
                ...state,
                eventTypes: action.eventTypes
            }
        case BRANDS.AUDIENCE_TYPES:
            return {
                ...state,
                audienceTypes: action.audienceTypes
            }
        case BRANDS.COUNTRIES:
            return {
                ...state,
                countries: action.countries
            }
        case BRANDS.REGIONS_BY_COUNTRY_ID:
            return {
                ...state,
                regionsByCountryID: action.regionsByCountryID
            }
        case BRANDS.STATES_BY_REGIONS_ID:
            return {
                ...state,
                statesByRegionID: action.statesByRegionID
            }
        case BRANDS.CITIES_BY_STATES_ID:
            return {
                ...state,
                citiesByStateID: action.citiesByStateID
            }
        case BRANDS.GOALS_ATTRIBUTES:
            return {
                ...state,
                goalsAttributes: action.goalsAttributes
            }
        case BRANDS.NUTRITION_EXPERTS:
            return {
                ...state,
                nutritionExperts: action.nutritionExperts
            }
        case "CLEAR_ALL_RDS":
            return {
                ...state,
                nutritionExperts: null
            }
        case GENERAL.BRANDS_DASHBOARD_ERROR:
            return {
                ...state,
                error: action.error
            }
        case BRANDS.ADD_TO_FAVORITE:
            return {
                ...state,
                addToFavroiteRes: action.addToFavroiteRes
            }
        case BRANDS.FAVORITE_RD_PROFILES:
            return {
                ...state, favoriteRDProfilesRes: action.favoriteRDProfilesRes
            }
        case BRANDS.FAVORITE_RD_OPPORTUNITIES:
            return {
                ...state, favoriteRDOpportunitiesRes: action.favoriteRDOpportunitiesRes
            }
        case BRANDS.FAVORITE_FOLDERS:
            return {
                ...state,
                favoriteFoldersRes: action.favoriteFoldersRes
            }
        case BRANDS.CREATE_FAVORITE_FOLDER:
            return {
                ...state,
                createfavoriteFolderRes: action.createfavoriteFolderRes
            }
        case BRANDS.UPDATE_FAVORITE_FOLDER:
            return {
                ...state,
                updatefavoriteFolderRes: action.updatefavoriteFolderRes
            }
        case BRANDS.DELETE_FAVORITE_FOLDER:
            return {
                ...state,
                deletefavoriteFolderRes: action.deletefavoriteFolderRes
            }
        case BRANDS.BRAND_NOTIFICATIONS:
            return {
                ...state,
                brandNotifications: action.brandNotifications
            }
        case BRANDS.MY_RDS:
            return {
                ...state,
                myRDsAtBrands: action.myRDsAtBrands
            }
        case BRANDS.RD_PROFILE:
            return {
                ...state,
                rdDetailsAtBrand: action.rdDetailsAtBrand
            }
        case BRANDS.UPDATE_PROFILE:
            return {
                ...state,
                updateBrandProfile: action.updateBrandProfile
            }
        case BRANDS.REMOVE_MEDIA_ON_PROFILE:
            return {
                ...state,
                removeMediaAtProfile: action.removeMediaAtProfile
            }
        case BRANDS.BRAND_UNREAD_MESSAGES:
            return {
                ...state,
                brandUnreadMessages: action.brandUnreadMessages
            }
        case BRANDS.BRAND_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                brandUnreadNotifications: action.brandUnreadNotifications
            }
        case "REMOVE_NOTIFICATION_COUNT_ON_CLICK_BRAND":
            return {
                ...state,
                brandUnreadNotifications: null
            }
        case BRANDS.RECOMMENDED_RD:
            return {
                ...state,
                recommendedRD: action.recommendedRd
            }
        case BRANDS.ADD_NOTE:
            return {
                ...state,
                addedNote: action.addedNote
            }
        case "CLEAR_FAV_FOLDERS_LIST":
            return {
                ...state,
                favoriteFoldersRes: null
            }
        case "CLEAR_FAV_PROFILES":
            return {
                ...state,
                favoriteRDProfilesRes: null
            }
        case BRAND_MY_PARTNERSHIP.REQUEST:
            return {
                ...state,
                brandMyPartnershipLoading: true,
                brandMyPartnershipFullFill: null,
                brandMyPartnershipError: null
            }
        case BRAND_MY_PARTNERSHIP.SUCCESS:
            return {
                ...state,
                brandMyPartnershipLoading: false,
                brandMyPartnershipFullFill: action.payload,
                brandMyPartnershipError: null
            }
        case BRAND_MY_PARTNERSHIP.ERROR:
            return {
                ...state,
                brandMyPartnershipLoading: false,
                brandMyPartnershipFullFill: null,
                brandMyPartnershipError: action.payload
            }
        case BRAND_STATS.REQUEST:
            return {
                ...state,
                brandStatsLoading: true,
                brandStatsFullFill: null,
                brandStatsError: null
            }
        case BRAND_STATS.SUCCESS:
            return {
                ...state,
                brandStatsLoading: false,
                brandStatsFullFill: action.payload,
                brandStatsError: null
            }
        case BRAND_STATS.ERROR:
            return {
                ...state,
                brandStatsLoading: false,
                brandStatsFullFill: null,
                brandStatsError: action.payload
            }
        case REC_RDS.REQUEST:
            return {
                ...state,
                brandRecRDsLoading: true,
                brandRecRDsFullFill: null,
                brandRecRDsError: null
            }
        case REC_RDS.SUCCESS:
            return {
                ...state,
                brandRecRDsLoading: false,
                brandRecRDsFullFill: action.payload,
                brandRecRDsError: null
            }
        case REC_RDS.ERROR:
            return {
                ...state,
                brandRecRDsLoading: false,
                brandRecRDsFullFill: null,
                brandRecRDsError: action.payload
            }
        case REC_OPPORTUNITIES.REQUEST:
            return {
                ...state,
                brandRecOppLoading: true,
                brandRecOppFullFill: null,
                brandRecOppError: null
            }
        case REC_OPPORTUNITIES.SUCCESS:
            return {
                ...state,
                brandRecOppLoading: false,
                brandRecOppFullFill: action.payload,
                brandRecOppError: null
            }
        case REC_OPPORTUNITIES.ERROR:
            return {
                ...state,
                brandRecOppLoading: false,
                brandRecOppFullFill: null,
                brandRecOppError: action.payload
            }
        default:
            return state
    }
}

export default brandsReducer