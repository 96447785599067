import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {nullResponse} from '@store/actions/auth'
import {Button} from 'reactstrap'

const PendingRDAccountMessage = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const HandleRedirect = () => {
        dispatch(nullResponse('LOGIN'))
        localStorage.removeItem(`${process.env.REACT_APP_USERDATA}`)
        localStorage.removeItem(`${process.env.REACT_APP_USERTOKEN}`)
        history.push('/')
    }

    return (
        <div className='misc-wrapper'>
            <div className='misc-inner p-2 p-sm-3'>
                <div className='w-100 text-center'>
                    <p className='mb-2 font-medium-1'>We are verifying your RD credential. You will be notified via email when your account has been approved.</p>
                    <Button onClick={HandleRedirect} color='primary' className='btn-sm-block mb-2'>
                        Back to Home
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default PendingRDAccountMessage
