import {useState, useEffect} from 'react'
import {
    CardTitle,
    CardBody,
    CardFooter,
    Card,
    CardHeader,
    FormGroup,
    Label,
    Input,
    Button,
    Toast,
    ToastHeader,
    ToastBody
} from 'reactstrap'
import classnames from 'classnames'
import {useHistory} from 'react-router-dom'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, ContentState, convertToRaw} from 'draft-js'
import {useDispatch} from 'react-redux'
import {updateCmsData} from '@src/views/tables/reactstrap/admin/admin-manage-cms/store/actions'
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import {PropTypes} from "prop-types"

const UpdateCms = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [editId, setEditId] = useState(props?.history?.location?.query?.id)
    const [pageTitle, setPageTitle] = useState(props?.history?.location?.query?.title)
    const [pageContent, setContent] = useState('')
    const [show, setShow] = useState(false)
    const [toastHeaderTitle, setToastHeaderTitle] = useState('')
    const [toastMessage, setToastMessage] = useState('')

    useEffect(() => {
        if (!props.history.location.query?.content) {
            history.push('/admin/cms')
        } else {
            const contentBlock = htmlToDraft(props?.history?.location?.query?.content)
            const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks)
            const editorState = EditorState.createWithContent(contentState)
            setEditorState(editorState)
        }
    }, [])

    const updateCmsContent = () => {
        const params = {
            id: editId,
            title: pageTitle,
            content: pageContent.length === 0 ? props?.history?.location?.query?.content : pageContent,
            slug: props?.history?.location?.query?.slug
        }
        dispatch(updateCmsData(params))
        history.push('/admin/cms')
        setToastHeaderTitle("Updation Success")
        setToastMessage("CMS content update successfully.")
        setShow(true)
    }


    const uploadImageCallBack = (file) => {
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                xhr.open('POST', `${process.env.REACT_APP_BASEURL}editor-upload-image`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    return (
        <>
            <Card>
                <CardHeader className='border-bottom'>
                    <CardTitle tag='h4'>Edit {props?.history?.location?.query?.title}</CardTitle>
                </CardHeader>
                <CardBody>
                    <br></br>
                    <FormGroup>
                        <Label className={classnames({
                            'is-invalid': false,
                            'form-label': true
                        })} htmlFor={`pageTitle`}>
                            Title<span className="is-invalid">*</span>
                        </Label>
                        <Input
                            type='text'
                            name={`pageTitle`}
                            value={pageTitle}
                            id={`pageTitle`}
                            placeholder=''
                            className={classnames({'is-invalid': false})}
                            onChange={(e) => setPageTitle(e.target.value)}
                        />
                        <div className="validation-err"></div>
                    </FormGroup>
                    <FormGroup className='mb-2'>
                        <Label>Content</Label>
                        <Editor editorState={editorState}

                                onEditorStateChange={newState => {
                                    setEditorState(newState)
                                    setContent(draftToHtml(convertToRaw(newState.getCurrentContent())))
                                }}
                                toolbar={{
                                    inline: {inDropdown: true},
                                    list: {inDropdown: true},
                                    textAlign: {inDropdown: true},
                                    link: {inDropdown: true},
                                    history: {inDropdown: false},
                                    image: {
                                        uploadCallback: uploadImageCallBack,
                                        alt: {present: false, mandatory: false},
                                        previewImage: true,
                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                        alignmentEnabled: false
                                    }
                                }}/>

                    </FormGroup>
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                    <Button.Ripple className='mr-1' color='danger' outline onClick={() => history.push('/admin/cms')}>
                        Cancel
                    </Button.Ripple>
                    <Button.Ripple color='primary' onClick={updateCmsContent}>
                        Save Changes
                    </Button.Ripple>
                </CardFooter>
            </Card>
            <Toast isOpen={show} style={{
                position: 'absolute',
                top: 0,
                right: 0
            }} className="bg-success">
                <ToastHeader>{toastHeaderTitle}</ToastHeader>
                <ToastBody> {toastMessage} </ToastBody>
            </Toast>
        </>
    )
}

UpdateCms.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            query: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                title: PropTypes.string,
                content: PropTypes.string,
                slug: PropTypes.string
            })
        }),
        push: PropTypes.func.isRequired
    }).isRequired
}

export default UpdateCms
