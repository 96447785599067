import {useState, Fragment, useEffect, useRef} from 'react'
import Flatpickr from 'react-flatpickr'
import {
    ChevronDown,
    Search,
    RefreshCcw
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Label,
    FormGroup,
    Row,
    Col,
    Button,
    Spinner
} from 'reactstrap'
import {useSelector, useDispatch} from 'react-redux'
import {
    getSubscribedBrandsData,
    getSearchSubscribedBrands,
    getPlansData
} from '@src/views/tables/data-tables-admin-subscribed-brands/store/actions'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const SubscribedBrands = () => {
    const [Picker, setPicker] = useState('')
    const [searchName, setSearchName] = useState('')
    const [searchState, setSearchState] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [searchEmail, setSearchEmail] = useState('')
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState(null)
    const refComp = useRef(null)


    const subscriptionsSelector = useSelector(store => store.datatablesAdminSubscribedBrands)
    const dispatch = useDispatch()

    useEffect(() => {
        const subscrioptionList = subscriptionsSelector?.subscrioptionList ? subscriptionsSelector?.subscrioptionList : null
        if (subscrioptionList) {
            const {links, meta, pages} = subscrioptionList
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }
    }, [subscriptionsSelector])

    useEffect(() => {
        setIsTableLoading(true)
        dispatch(getSubscribedBrandsData())
        dispatch(getPlansData())
    }, [])

    const [advSearchColumns, setAdvSearchColumns] = useState([
        {
            name: 'company',
            selector: 'company',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'contact name',
            selector: 'contact_name',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'purchased date',
            selector: 'purchased_date',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'expire date',
            selector: 'expiry_date',
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'subscription plan',
            selector: 'subscription_plan',
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '70px'
        },
        {
            name: 'Total paid',
            selector: 'total_paid',
            sortable: true,
            minWidth: '100px',
            cell: row => <span>${row.total_paid}</span>
        }
    ])

    const dataToRender = () => {
        if (subscriptionsSelector.subscrioptionList) {
            return subscriptionsSelector?.subscrioptionList?.data
        }
    }

    const searchSubscribedData = () => {
        setIsTableLoading(true)
        dispatch(getSearchSubscribedBrands(false, {
            brand_name: searchName,
            owner_name: searchEmail,
            plan_id: searchState,
            purchase_from: Picker ? Picker[0] : '',
            purchase_to: Picker ? Picker[1] : ''
        }))
    }

    const makeDate = (range) => {
        const arr = []
        range.map(i => {
            const date = new Date(i)
            const year = date.getFullYear()
            let month = (1 + date.getMonth()).toString()
            month = month.length > 1 ? month : `${month}`
            let day = date.getDate().toString()
            day = day.length > 1 ? day : `${day}`
            arr.push(`${month}-${day}-${year}`)
            return true
        })
        setPicker(arr)
    }

    const refreshSearchForm = () => {
        refComp.current.flatpickr.clear()
        setPicker('')
        setSearchName('')
        setSearchState('')
        setSearchEmail('')
        setIsTableLoading(true)
        dispatch(getSubscribedBrandsData())
    }

    const handleCustomPagination = pageUrl => {
        setIsTableLoading(true)
        dispatch(getSearchSubscribedBrands(true, {
            brand_name: searchName,
            owner_name: searchEmail,
            plan_id: searchState,
            purchase_from: Picker ? Picker[0] : '',
            purchase_to: Picker ? Picker[1] : ''
        }, pageUrl))
    }
    return (
        <Fragment>
            <Card>
                <CardHeader className='border-bottom'>
                    <CardTitle tag='h4'>Subscribed Brands</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row form className='mt-1 mb-50'>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='name'>Company Name</Label>
                                <Input id='name' placeholder='Company Name' value={searchName} onChange={(e) => setSearchName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='email'>Brand Owner Name</Label>
                                <Input
                                    type='email'
                                    id='email'
                                    placeholder='Brand Owner Name'
                                    value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='state'>Subscription Plan</Label>
                                <Input type="select" id='state' placeholder='Subscription Plan' value={searchState} onChange={(e) => setSearchState(e.target.value)}
                                >
                                    <option value=''>Subscription Plan</option>
                                    {subscriptionsSelector.subscriptionPlans && subscriptionsSelector.subscriptionPlans.map(plan => (
                                        <option value={plan.id}>{plan.value}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg='3' md='4'>
                            <FormGroup>
                                <Label for='date'>Date:</Label>
                                <Flatpickr
                                    className='form-control'
                                    id='date'
                                    placeholder='Date'
                                    options={{mode: 'range', dateFormat: 'M d, Y'}}
                                    onChange={date => makeDate(date)}
                                    ref={refComp}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="admin-filter-actions ml-auto text-right">
                                <Button.Ripple className='btn-icon mr-10px' color='primary' onClick={searchSubscribedData}>
                                    <Search size={16}/>
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' onClick={refreshSearchForm}>
                                    <RefreshCcw size={16}/>
                                </Button.Ripple>
                            </div>
                        </Col>
                    </Row>
                </CardBody>

                {isTableLoading && (<div className="w-100 my-4 text-center"><Spinner color='primary'/></div>)}
                {!isTableLoading && (
                    <>
                        <DataTable
                            noHeader
                            paginationServer
                            columns={advSearchColumns}
                            paginationPerPage={7}
                            className='react-dataTable'
                            sortIcon={<ChevronDown size={10}/>}
                            paginationDefaultPage={currentPage + 1}
                            data={dataToRender()}
                        />
                        <CustomPagination pageLinks={paginationInfo?.pages} onPaginationClick={handleCustomPagination}/>
                    </>
                )}
            </Card>
        </Fragment>
    )
}

export default SubscribedBrands
