import DashboardStats from '@src/views/brands/dashboard/DashboardStats'
import RecommendedRDs from "@src/views/brands/dashboard/RecommendedRDs"
import RecommendedOpportunities from '@src/views/brands/dashboard/RecommendedOpportunities'

import MyPartnerships from '@src/views/brands/dashboard/MyPartnerships'

import '@styles/react/libs/charts/apex-charts.scss'
import '@styles/react/libs/charts/recharts.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import '@styles/react/libs/swiper/swiper.scss'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {Alert, Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, Spinner} from 'reactstrap'
import SwiperCore, {
    Autoplay,
    EffectCoverflow,
    EffectCube,
    EffectFade,
    Lazy,
    Navigation,
    Pagination,
    Virtual
} from 'swiper'
import {useDispatch, useSelector} from "react-redux"
import {
    sendVerifyEmailLinkAction,
    sendVerifyEmailLinkResetAction
} from "@src/views/apps/opportunitiesBrands/store/actions"
import {verifyEmailAction, verifyEmailResetAction} from '@src/views/pages/emailVerification/store/actions'
import {AlertCircle, CheckCircle} from "react-feather"
import {Formik} from "formik"
import * as yup from "yup"

SwiperCore.use([Navigation, Pagination, EffectFade, EffectCube, EffectCoverflow, Autoplay, Lazy, Virtual])

const BrandDashboard = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState(false)

    const data = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))

    const {
        verifyEmailLoading,
        verifyEmailData,
        verifyEmailError
    } = useSelector((store) => store.verifyEmailReducer)

    const {
        emailVerificationLoading,
        emailVerificationData,
        emailVerificationError
    } = useSelector(store => store.emailVerificationReducer)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')
    }, [])

    const handleSendEmail = () => {
        setOpenModal(true)
        dispatch(sendVerifyEmailLinkAction())
    }

    const handleCloseModal = () => {
        dispatch(sendVerifyEmailLinkResetAction())
        dispatch(verifyEmailResetAction())
        setOpenModal(false)
    }

    useEffect(() => {
        if (emailVerificationData?.status) {
            const data = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
            data.email_verified = true
            localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(data))
        }
    }, [emailVerificationData])

    return (
        <div id='dashboard-brands'>
            {data && data.email_verified === false && (
                <div>
                    <Alert color='danger'>
                        <div className='alert-body'>
                            <span>Your email is not verified. </span>
                            <span className="cursor-pointer"
                                  onClick={handleSendEmail}>
                            <u>Click to verify your email.</u>
                        </span>
                        </div>
                    </Alert>
                </div>
            )}
            <Row className='match-height mb-2'>
                <Col xl='12' md='12' xs='12'>
                    <DashboardStats/>
                </Col>
            </Row>
            <Row className='match-height mb-2'>
                <Col xs='12'>
                    {/* <AdminRdSlides /> */}
                    <RecommendedRDs/>
                </Col>
            </Row>
            <Row className='match-height mb-2'>
                <Col lg='12' sm='12'>
                    <RecommendedOpportunities/>
                </Col>
            </Row>
            <Row className='match-height mb-2'>
                <Col xs='12'>
                    <MyPartnerships/>
                </Col>
            </Row>
            <Modal isOpen={openModal} className='modal-dialog-centered'>
                <ModalHeader>Verify Email</ModalHeader>
                <ModalBody>
                    {verifyEmailLoading ? (
                        <div className="text-center"><Spinner color="primary"/></div>) : verifyEmailData?.status ? (
                        <div className="text-break">
                            <p><CheckCircle size={14} style={{color: '#56B6FF'}}/> Email verification code has been sent
                                to your email address.</p>
                            <p><CheckCircle size={14} style={{color: '#56B6FF'}}/> Copy and paste the code below to
                                verify your email.</p>
                            {emailVerificationData?.status === true && (
                                <Alert color='success'>
                                    <div className='alert-body'>
                                        <span>{emailVerificationData?.msg}</span>
                                    </div>
                                </Alert>
                            )}
                            {emailVerificationData?.status === false && (
                                <Alert color='danger'>
                                    <div className='alert-body'>
                                        <span>{emailVerificationData?.msg}</span>
                                    </div>
                                </Alert>
                            )}
                            <Formik
                                initialValues={{
                                    code: ''
                                }}
                                validationSchema={yup.object().shape({
                                    code: yup.number().required("This field is required.").typeError('Should be a number')
                                })}
                                onSubmit={(values) => {
                                    dispatch(verifyEmailAction({token: values.code}))
                                }}
                            >
                                {({
                                      errors,
                                      handleBlur,
                                      handleChange,
                                      handleSubmit,
                                      touched,
                                      values,
                                      setFieldValue
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Input
                                                type='number'
                                                name="code"
                                                id="code"
                                                placeholder='Paste code here...'
                                                value={values.code}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={emailVerificationLoading || emailVerificationData?.status}
                                            />
                                            <div className="validation-err">{touched.code && errors.code}</div>
                                        </FormGroup>
                                        <div className="text-right">
                                            <Button color='danger' onClick={handleCloseModal} className="mr-1"
                                                    disabled={emailVerificationLoading}>
                                                Close
                                            </Button>
                                            <Button color='primary' type="submit"
                                                    disabled={emailVerificationLoading || emailVerificationData?.status}>
                                                {emailVerificationLoading ? (
                                                    <Spinner style={{width: '16px', height: '16px'}}/>) : "Verify"}
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <div>
                            <p className="text-center"><AlertCircle size={50}/></p>
                            <p>{verifyEmailData?.msg}</p>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default BrandDashboard
