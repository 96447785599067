import {useState, useEffect, createRef, useRef} from "react"
import {useHistory, Link} from 'react-router-dom'
import {useSelector, useDispatch} from "react-redux"
import {useFormik} from "formik"
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    FormGroup,
    Label,
    Input,
    Form,
    Alert, Col, Row, CustomInput, Badge
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import CardTitle from "reactstrap/lib/CardTitle"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import {
    getCountries,
    getRegions,
    getStates,
    getCities,
    getEventTypes,
    getAudienceType,
    createNewOpportunity,
    locationFromZipCodeAction,
    createNewOpportunityReset,
    locationFromZipCodeActionReset
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import {Spinner} from "reactstrap/es"
import * as yup from "yup"
import draftToHtml from "draftjs-to-html"
import {convertToRaw} from "draft-js"
import {Editor} from 'react-draft-wysiwyg'
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import Select from "react-select"
import * as ICON from "react-feather"
import moment from "moment"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"

const AddOpportunity = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()

    const history = useHistory()

    const dispatch = useDispatch()

    const sweetAlert = withReactContent(Swal)

    const AGREEMENT_MAX_SIZE = 5 * 1024 * 1024

    const AGREEMENT_FORMATS = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document means .docx\n",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ]

    const SEEKING_OPTIONS = [
        {id: "free", value: "free", label: "Free Product Samples"},
        {id: "paid", value: "paid", label: "Paid Partnership"}
    ]

    const {
        locationFromZipCodeLoading,
        locationFromZipCodeData,
        locationFromZipCodeError
    } = useSelector((store) => store.locationFromZipCodeReducer)

    const {
        rdCreateOpportunityLoading,
        rdCreateOpportunityFullFill,
        rdCreateOpportunityError
    } = useSelector((store) => store.brandsOppoprunities)

    const opportunitySlice = useSelector((store) => store.brandsOppoprunities)

    const form = useFormik({
        initialValues: {
            title: "",
            description: "",
            startDate: "",
            endDate: "",
            venue: "",
            zipCode: "",
            country: "",
            region: "",
            state: "",
            city: "",
            numberOfAttendees: "",
            numberOfFollowers: "",
            expectedReach: "",
            eventType: "",
            audienceType: "",
            partnership: "",
            costOfPartnership: "",
            file: ""
        },
        validationSchema: yup.object().shape({
            title: yup.string().required("This field is required.").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
            description: yup.string().required("This field is required.").matches(/[^\s*].*[^\s*]/g, 'This field cannot contain only white space.'),
            startDate: yup.array().min(1).required("This field is required."),
            endDate: yup.array().min(1).required("This field is required."),
            partnership: yup.object().required("This field is required."),
            costOfPartnership: yup.string().when("partnership.value", {
                is: "paid",
                then: yup.string().required("This field is required.").matches(/^[0-9]+(-[0-9]+)?$/g, 'Invalid format.')
            }),
            file: yup.mixed()
                .test(
                    "fileFormat",
                    "File is empty or invalid.",
                    value => !value || (value && AGREEMENT_FORMATS.includes(value.type))
                )
                .test(
                    "fileSize",
                    "File too large",
                    value => !value || (value && value.size <= AGREEMENT_MAX_SIZE)
                )
        }),
        onSubmit: async (values) => {
            const token = await executeRecaptcha('rd_add_event')
            if (token) {
                const formData = new FormData()
                formData.append("title", values?.title ?? "")
                formData.append("description", values?.description.trim() ?? "")
                formData.append("start_datetime", values.startDate[0] ? moment(values.startDate[0]).format('YYYY-MM-DD') : "")
                formData.append("end_datetime", values.endDate[0] ? moment(values.endDate[0]).format('YYYY-MM-DD') : "")
                formData.append("venue", values?.venue ?? "")
                formData.append("postal_code", values?.zipCode ?? "")
                formData.append("country_id", values?.country?.id ?? "")
                formData.append("region_id", values?.region?.id ?? "")
                formData.append("state_id", values?.state?.id ?? "")
                formData.append("city", values?.city?.id ?? "")
                formData.append("expected_attendees", values?.numberOfAttendees ?? "")
                formData.append("no_of_follower", values?.numberOfFollowers ?? "")
                formData.append("expected_reach", values?.expectedReach ?? "")
                formData.append("event_type", values?.eventType?.id ?? "")
                formData.append("audience_type", values?.audienceType?.id ?? "")
                formData.append("partnership", values?.partnership?.id ?? "")
                formData.append("cost_of_partnership", values?.costOfPartnership ?? "")
                formData.append("invoice_amount", 0)
                formData.append("re_captcha_token", token)
                if (values?.file) {
                    formData.append("documents[]", values?.file)
                }
                dispatch(createNewOpportunity(formData))
            } else {
                sweetAlert.fire({
                    title: 'Captcha Error',
                    icon: 'error',
                    html: '<p>Execute recaptcha not yet available.</p>',
                    confirmButtonText: 'Reload',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            }
        }
    })

    const START_DATE_OPTIONS = {
        allowInput: false,
        enableTime: true,
        minDate: "today",
        dateFormat: "M d, Y H:i K"
    }

    const END_DATE_OPTIONS = {
        allowInput: false,
        enableTime: true,
        minDate: form.values.startDate[0] ?? new Date(),
        maxDate: moment(form.values.startDate[0]).add(3, 'months').toDate(),
        dateFormat: "M d, Y H:i K"
    }

    useEffect(() => {
        if (locationFromZipCodeData?.data?.city_id) {
            const loc = locationFromZipCodeData?.data
            dispatch(getRegions(loc?.country_id))
            dispatch(getStates(loc?.region_id))
            dispatch(getCities(loc.state_id))
            form.setFieldValue('country', {id: loc.country_id, value: loc.country_name, label: loc.country_name})
            form.setFieldValue('region', {id: loc.region_id, value: loc.region_name, label: loc.region_name})
            form.setFieldValue('state', {id: loc.state_id, value: loc.state_name, label: loc.state_name})
            form.setFieldValue('city', {id: loc.city_id, value: loc.city_name, label: loc.city_name})
            dispatch(locationFromZipCodeActionReset())
        }

        if (rdCreateOpportunityFullFill?.success) {
            sweetAlert.fire('Congratulations!', rdCreateOpportunityFullFill?.msg, 'success')
            dispatch(createNewOpportunityReset())
            history.push('/dashboard/rd/opportunities?event=success')
        }

        if (rdCreateOpportunityError?.response?.status) {
            const errorHTML = ['<ul class="list-group">']
            if (rdCreateOpportunityError?.response?.data?.title) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdCreateOpportunityError?.response?.data?.title[0]}</li>`)
            }
            if (rdCreateOpportunityError?.response?.data?.description) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdCreateOpportunityError?.response?.data?.description[0]}</li>`)
            }
            if (rdCreateOpportunityError?.response?.data?.start_datetime) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdCreateOpportunityError?.response?.data?.start_datetime[0]}</li>`)
            }
            if (rdCreateOpportunityError?.response?.data?.end_datetime) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdCreateOpportunityError?.response?.data?.end_datetime[0]}</li>`)
            }
            if (rdCreateOpportunityError?.response?.data?.partnership) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdCreateOpportunityError?.response?.data?.partnership[0]}</li>`)
            }
            if (rdCreateOpportunityError?.response?.data?.cost_of_partnership) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdCreateOpportunityError?.response?.data?.cost_of_partnership[0]}</li>`)
            }
            if (rdCreateOpportunityError?.response?.data['documents.0']) {
                errorHTML.push(`<li class="list-group-item font-small-3 text-danger">${rdCreateOpportunityError?.response?.data['documents.0'][0]}</li>`)
            }
            errorHTML.push('</ul>')
            sweetAlert.fire({
                title: 'Please Fix!',
                icon: 'error',
                html: errorHTML.join(''),
                confirmButtonText: 'OK'
            })
            dispatch(createNewOpportunityReset())
        }
    }, [
        locationFromZipCodeData,
        rdCreateOpportunityFullFill,
        rdCreateOpportunityError
    ])

    useEffect(() => {
        dispatch(getCountries())
        dispatch(getEventTypes())
        dispatch(getAudienceType())
    }, [])

    const getRegionsById = (country_id) => {
        form.setFieldValue('region', "")
        form.setFieldValue('state', "")
        form.setFieldValue('city', "")
        dispatch(getRegions(country_id))
    }

    const getStatesById = (region_id) => {
        form.setFieldValue('state', "")
        form.setFieldValue('city', "")
        dispatch(getStates(region_id))
    }

    const getCitiesById = (state_id) => {
        form.setFieldValue('city', "")
        dispatch(getCities(state_id))
    }

    const getLocation = (e) => {
        const zipCode = e.target.value
        dispatch(locationFromZipCodeAction({
            zip_code: zipCode
        }))
    }

    return (
        <Card>
            <CardHeader className='d-flex'>
                <CardTitle>Create Opportunity</CardTitle>
                <CardTitle>
                    <Button.Ripple color="danger" type="button" tag={Link} to="/dashboard/rd/opportunities"
                                   disabled={rdCreateOpportunityLoading} size='sm'>
                        <ICON.XCircle size={14}/> Close
                    </Button.Ripple>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Form onSubmit={form.handleSubmit} autoComplete="off">
                    <Row>
                        <FormGroup tag={Col} md="12">
                            <Label htmlFor="title" className='font-weight-bold'>
                                <span>Title</span>
                                <span className="text-danger">*</span>
                            </Label>
                            <Input id="title"
                                   name="title"
                                   value={form.values.title}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   placeholder="Opportunity Title"
                                   disabled={rdCreateOpportunityLoading}
                            />
                            <div className="validation-err">{form.touched.title && form.errors.title}</div>
                        </FormGroup>

                        <FormGroup tag={Col} md="12">
                            <Label htmlFor="description" className='font-weight-bold'>
                                <span>Description</span>
                                <span className="text-danger">*</span>
                            </Label>
                            <Editor onEditorStateChange={(e) => {
                                const contentState = e.getCurrentContent()
                                if (contentState.hasText()) {
                                    form.setFieldValue('description', draftToHtml(convertToRaw(contentState)))
                                } else {
                                    form.setFieldValue('description', "")
                                }
                            }}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'remove', 'history'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline']
                                        },
                                        list: {
                                            options: ['unordered', 'ordered']
                                        },
                                        blockType: {
                                            options: ['Normal', 'H1', 'H2', 'H3']
                                        },
                                        link: {
                                            showOpenOptionOnHover: false
                                        },
                                        fontSize: {
                                            options: [12, 14, 16, 18, 24, 36]
                                        }
                                    }}
                                    stripPastedStyles={true}
                                    editorStyle={{minHeight: '250px'}}
                            />
                            <div className="validation-err">{form.touched.description && form.errors.description}</div>
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label className="d-flex justify-content-between font-weight-bold">
                                <span>Start Date <span className="text-danger">*</span></span>
                                <span>
                                    <Badge color='danger'
                                           onClick={() => {
                                               form.setFieldValue('startDate', "")
                                               form.setFieldValue('endDate', "")
                                           }}
                                           className="cursor-pointer">
                                        Clear
                                    </Badge>
                                </span>
                            </Label>
                            <Flatpickr id="startDate"
                                       value={form.values.startDate}
                                       className="form-control"
                                       options={START_DATE_OPTIONS}
                                       onChange={(e) => {
                                           form.setFieldValue('startDate', e)
                                           form.setFieldValue('endDate', "")
                                       }}
                                       placeholder="Start Date"
                                       disabled={rdCreateOpportunityLoading}
                            />
                            <div className="validation-err">{form.touched.startDate && form.errors.startDate}</div>
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label className="d-flex justify-content-between font-weight-bold">
                                <span>End Date <span className="text-danger">*</span></span>
                                <span>
                                    <Badge color='danger'
                                           onClick={() => form.setFieldValue('endDate', "")}
                                           className="cursor-pointer">
                                        Clear
                                    </Badge>
                                </span>
                            </Label>
                            <Flatpickr
                                id="endDate"
                                value={form.values.endDate}
                                className="form-control"
                                options={END_DATE_OPTIONS}
                                onChange={(e) => form.setFieldValue('endDate', e)}
                                placeholder="End Date"
                                disabled={rdCreateOpportunityLoading || !form.values.startDate}
                            />
                            <div className="validation-err">{form.touched.endDate && form.errors.endDate}</div>
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="venue" className='font-weight-bold'>Venue</Label>
                            <Input id="venue"
                                   name="venue"
                                   value={form.values.venue}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   placeholder="Venue"
                                   disabled={rdCreateOpportunityLoading}
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="zipCode" className='font-weight-bold d-flex justify-content-between'>
                                <span>Postal Code</span>
                                {locationFromZipCodeLoading && (
                                    <span className="text-success font-weight-bold">
                                        <Spinner style={{width: '14px', height: '14px'}}/> Finding Location...
                                    </span>
                                )}
                            </Label>
                            <Input id="zipCode"
                                   name="zipCode"
                                   value={form.values.zipCode}
                                   onBlur={(e) => {
                                       form.handleBlur(e)
                                       getLocation(e)
                                   }}
                                   onChange={form.handleChange}
                                   disabled={locationFromZipCodeLoading || rdCreateOpportunityLoading}
                                   placeholder="Postal Code"
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="country" className='font-weight-bold'>
                                Country
                            </Label>
                            <Select options={opportunitySlice?.countries}
                                    className='react-select'
                                    classNamePrefix='select'
                                    placeholder="Please Select..."
                                    value={form.values.country}
                                    onChange={(e) => {
                                        form.setFieldValue('country', e)
                                        getRegionsById(e?.id)
                                    }}
                                    isDisabled={locationFromZipCodeLoading || rdCreateOpportunityLoading}
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="region" className='font-weight-bold'>
                                Regions
                            </Label>
                            <Select options={opportunitySlice?.regions ?? []}
                                    className='react-select'
                                    classNamePrefix='select'
                                    placeholder="Please Select..."
                                    value={form.values.region}
                                    onChange={(e) => {
                                        form.setFieldValue('region', e)
                                        getStatesById(e.id)
                                    }}
                                    isDisabled={locationFromZipCodeLoading || rdCreateOpportunityLoading}
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="state" className='font-weight-bold'>
                                State
                            </Label>
                            <Select options={opportunitySlice?.states ?? []}
                                    className='react-select'
                                    classNamePrefix='select'
                                    placeholder="Please Select..."
                                    value={form.values.state}
                                    onChange={(e) => {
                                        form.setFieldValue('state', e)
                                        getCitiesById(e.id)
                                    }}
                                    isDisabled={locationFromZipCodeLoading || rdCreateOpportunityLoading}
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="city" className='font-weight-bold'>
                                City
                            </Label>
                            <Select options={opportunitySlice?.cities ?? []}
                                    className='react-select'
                                    classNamePrefix='select'
                                    placeholder="Please Select..."
                                    value={form.values.city}
                                    onChange={(e) => form.setFieldValue('city', e)}
                                    isDisabled={locationFromZipCodeLoading || rdCreateOpportunityLoading}
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4} className='font-weight-bold'>
                            <Label htmlFor="expectedAttend" className="d-flex justify-content-between">
                                <span>Expected number of Attendees</span>
                                <span className="font-weight-bold">(For in-person events)</span>
                            </Label>
                            <Input type="number"
                                   id="numberOfAttendees"
                                   name="numberOfAttendees"
                                   value={form.values.numberOfAttendees}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   placeholder="0"
                                   disabled={rdCreateOpportunityLoading}
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4} className='font-weight-bold'>
                            <Label htmlFor="numberFollowers" className="d-flex justify-content-between">
                                <span>Number of Followers</span>
                                <span className="font-weight-bold">(For social media partnerships)</span>
                            </Label>
                            <Input type="number"
                                   id="numberOfFollowers"
                                   name="numberOfFollowers"
                                   value={form.values.numberOfFollowers}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   placeholder="0"
                                   disabled={rdCreateOpportunityLoading}
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4} className='font-weight-bold'>
                            <Label htmlFor="expectedReach">Expected Reach</Label>
                            <Input type="number"
                                   id="expectedReach"
                                   name="expectedReach"
                                   value={form.values.expectedReach}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   placeholder="0"
                                   disabled={rdCreateOpportunityLoading}
                            />
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="typeOfEvent" className='font-weight-bold'>
                                Type of Event
                            </Label>
                            <Select options={opportunitySlice?.eventTypes ?? []}
                                    className='react-select'
                                    classNamePrefix='select'
                                    placeholder="Please Select..."
                                    value={form.values.eventType}
                                    onChange={(e) => form.setFieldValue('eventType', e)}
                                    isDisabled={rdCreateOpportunityLoading}
                            />
                            <div className="validation-err">{form.touched.eventType && form.errors.eventType}</div>
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="typeOfAudience" className='font-weight-bold'>
                                Type of Audience
                            </Label>
                            <Select options={opportunitySlice?.audienceType ?? []}
                                    className='react-select'
                                    classNamePrefix='select'
                                    placeholder="Please Select..."
                                    value={form.values.audienceType}
                                    onChange={(e) => form.setFieldValue('audienceType', e)}
                                    isDisabled={rdCreateOpportunityLoading}
                            />
                            <div className="validation-err">{form.touched.audienceType && form.errors.audienceType}</div>
                        </FormGroup>

                        <FormGroup tag={Col} lg={4}>
                            <Label htmlFor="seeking" className='font-weight-bold'>
                                <span>I am Seeking</span>
                                <span className="text-danger">*</span>
                            </Label>
                            <Select options={SEEKING_OPTIONS}
                                    className='react-select'
                                    classNamePrefix='select'
                                    placeholder="Please Select..."
                                    value={form.values.partnership}
                                    onChange={(e) => form.setFieldValue('partnership', e)}
                                    isDisabled={rdCreateOpportunityLoading}
                            />
                            <div className="validation-err">{form.touched.partnership && form.errors.partnership}</div>
                        </FormGroup>

                        <FormGroup tag={Col} lg={4} className='font-weight-bold'>
                            <Label htmlFor="costOfPartnership" className="d-flex justify-content-between">
                                <span>Cost of Partnership</span>
                                <span className="font-weight-bold">(Fixed or Range, Example: 10, 30-50)</span>
                            </Label>
                            <Input type="text"
                                   id="costOfPartnership"
                                   name="costOfPartnership"
                                   value={form.values.costOfPartnership}
                                   onBlur={form.handleBlur}
                                   onChange={form.handleChange}
                                   placeholder="0"
                                   disabled={rdCreateOpportunityLoading}
                            />
                            <div className="validation-err">{form.touched.costOfPartnership && form.errors.costOfPartnership}</div>
                        </FormGroup>

                        <FormGroup tag={Col} lg={12} className='font-weight-bold'>
                            <Label className="d-flex justify-content-between">
                                <span>Upload (Additional details or sponsorship prospectus)</span>
                                <span className="font-weight-bold">(Allowed File Types: .png, .jpeg, .jpg, .pdf, .doc, .docx, .xls, .xlsx) (Max: 5MB)</span>
                            </Label>
                            <CustomInput type="file"
                                         accept=".png, .jpeg, .jpg, .pdf, .doc, .docx, .xls, .xlsx"
                                         name="file"
                                         className="form-control-file"
                                         onBlur={form.handleBlur}
                                         onChange={(e) => {
                                             form.setFieldValue('file', e.target.files[0])
                                         }}
                                         disabled={rdCreateOpportunityLoading}
                                         id='file'
                            />
                            <div className="validation-err">{form.touched.file && form.errors.file}</div>
                        </FormGroup>

                        <FormGroup tag={Col} lg={12} className="d-flex align-items-center justify-content-end">
                            <Button.Ripple type="submit" color="primary" disabled={rdCreateOpportunityLoading}>
                                {rdCreateOpportunityLoading ? (
                                    <Spinner style={{height: '12px', width: '12px'}}/>
                                ) : (
                                    <span><ICON.Save size={14}/> Submit</span>
                                )}
                            </Button.Ripple>
                        </FormGroup>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )
}

export default AddOpportunity
