import axios from 'axios'
import {API_URL, CANLCELLED_REPORT} from '../actionTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getCancellSubscriptionReportingData = (isPaginate, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-cancel-subscription-list`
  return async dispatch => {
    await axios.get(endpoint, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: CANLCELLED_REPORT.LISTING,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: CANLCELLED_REPORT.ERROR,
        error
      })
    })
  }
}

export const getPlansData = () => {
  return async dispatch => {
    await axios.get(`${API_URL}admin/load-plans`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: CANLCELLED_REPORT.PLANS_DATA,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: CANLCELLED_REPORT.ERROR,
        error
      })
    })
  }
}

export const getSearchCancellSubscriptionReports = (isPaginate, body, pageUrl = '') => {
  let endpoint = ''
  isPaginate ? endpoint = pageUrl : endpoint = `${API_URL}admin/load-cancel-subscription-list`
  return async dispatch => {
    await axios.post(endpoint, {
      brand_name: body.brand_name,
      owner_name: body.owner_name,
      plan_id: body.plan_id,
      purchase_from: body.purchase_from,
      purchase_to: body.purchase_to      
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(response => {
      dispatch({
        type: CANLCELLED_REPORT.LISTING,
        allData: response.data
      })
    }).catch(error => {
      dispatch({
        type: CANLCELLED_REPORT.ERROR,
        error
      })
    })
  }
}

export const nullSearchData = (listName) => {
  return async dispatch => {
    switch (listName) {
      case 'searchCancelledSubscriptionReport':
        dispatch({
          type: CANLCELLED_REPORT.SEARCH,
          searchCancelSubscriptionReports: null
        })
        break
      default:
        break
    }
  }
}