import {useEffect, Fragment, useState} from "react"
import {Link, useHistory} from "react-router-dom"
import Avatar from "@components/avatar"
import {
    Mail,
    List, CheckSquare, CheckCircle, XCircle
} from "react-feather"
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    CardText,
    ModalFooter,
    Spinner,
    Tooltip,
    UncontrolledTooltip
} from "reactstrap"
import img4 from "@src/assets/images/avatars/avatar-blank.png"
import {getAllRdCampaigns, getAppliedCampaignDetail} from "@src/views/rds/brandCampaigns/store/actions"
import {useSelector, useDispatch} from "react-redux"
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import Linkify from "react-linkify"
import queryString from "query-string"

const TabsBasic = () => {
    const history = useHistory()
    const [paginationInfo, setPaginationInfo] = useState(null)
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [isCampaignDetail, setIsCampaignDetail] = useState(false)
    const [appliedDetailModal, setAppliedDetailModal] = useState(false)
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const dispatch = useDispatch()
    const {
        rdRecommendedCampaignsDetailLoading,
        rdRecommendedCampaignsDetailFullFill,
        rdRecommendedCampaignsDetailError
    } = useSelector((store) => store.rdCampaigns)

    const rdCampaignSelector = useSelector((store) => store.rdCampaigns)

    const [campInfo, setCampInfo] = useState({
        brandName: "",
        campName: "",
        brandImage: "",
        campDetail: "",
        formTitle: "",
        formAttributes: "",
        opportunityFor: "",
        campId: "",
        campFormId: ""
    })

    useEffect(() => {
        if (!!rdCampaignSelector?.allCampaignsListing) {
            const {links, meta, pages} = rdCampaignSelector?.allCampaignsListing
            setPaginationInfo({...links, ...meta, pages})
            setIsTableLoading(false)
        }

        if (!!rdCampaignSelector?.appliedCampDetail) {
            setIsCampaignDetail(false)
        }
    }, [rdCampaignSelector])

    useEffect(() => {
        setIsTableLoading(true)
        const parsed = queryString.parseUrl(window.location.href)
        const pageNo = parsed?.query?.page
        const API_URL = process.env.REACT_APP_BASEURL
        const path = `${API_URL}rd/all-campaigns?page=${pageNo}`
        if (pageNo) {
            dispatch(getAllRdCampaigns(true, path))
        } else {
            dispatch(getAllRdCampaigns())
        }
    }, [])

    const handleCustomPagination = (pageUrl) => {
        setIsTableLoading(true)
        dispatch(getAllRdCampaigns(true, pageUrl))
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        history.push({
            pathname: window.location.pathname,
            search: `?page=${pageNo}`
        })
    }

    const getAppliedCampaignData = (id) => {
        dispatch(getAppliedCampaignDetail(id))
        setAppliedDetailModal(true)
    }
    return (
        <Fragment>
            <h4 className="brandSlidesHeading font-weight-bolder mb-3">
                Brand Campaigns
            </h4>
            <div className="brandSlides mb-3">
                <Row>
                    {isTableLoading && (
                        <div className="w-100 my-4 text-center">
                            <Spinner color="primary"/>
                        </div>
                    )}
                    {!isTableLoading && rdCampaignSelector?.allCampaignsListing?.data?.length === 0 && (
                        <div className="w-100 my-4 text-center">
                            <h5>No campaigns found</h5>
                        </div>
                    )}
                    {!isTableLoading &&
                        rdCampaignSelector?.allCampaignsListing?.data?.length > 0 &&
                        rdCampaignSelector?.allCampaignsListing?.data?.map((rdCamps, index) => (
                            <Fragment key={index}>
                                <Col md="4" className="mb-4">
                                    <div className="brandContent">
                                        {/*<div className="row justify-content-between">*/}
                                        {/*    <div className="col-auto ml-auto">*/}
                                        {/*        <small className="border border-1 px-75 py-25 border-muted text-muted">*/}
                                        {/*            {rdCamps?.opportunity_for}*/}
                                        {/*        </small>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <Avatar img={rdCamps?.brand_img || img4} imgHeight="80" imgWidth="80"/>
                                        <h4 className="mt-1 text-break font-weight-bold" title={rdCamps?.campaign_title || "N/A"} style={{height: "50px"}}>
                                            {rdCamps?.campaign_title.length > 40 ? `${rdCamps?.campaign_title.substring(0, 40)}...` : rdCamps?.campaign_title}
                                        </h4>
                                        {/* "#f63f5d" "#ffe2e6" */}
                                        <div
                                            className="healthcare"
                                            style={{backgroundColor: "#f8f8f8"}}
                                        >
                                            <p
                                                className="general-wellness cursor-pointer"
                                                style={{color: "#161d31"}}
                                            >
                                                {rdCamps?.brand_name || "N/A"}
                                            </p>
                                        </div>
                                        {/*<Button.Ripple*/}
                                        {/*  tag={Link}*/}
                                        {/*  to="#"*/}
                                        {/*  color="secondary"*/}
                                        {/*  className="m-25"*/}
                                        {/*  onClick={() => {*/}
                                        {/*    setFormModal(!formModal)*/}
                                        {/*    setCampInfo({*/}
                                        {/*      brandName: rdCamps?.brand_name,*/}
                                        {/*      campName: rdCamps?.campaign_title,*/}
                                        {/*      brandImage: rdCamps?.brand_img,*/}
                                        {/*      campDetail: rdCamps?.campaign_details,*/}
                                        {/*      formTitle: rdCamps?.form_title,*/}
                                        {/*      formAttributes: rdCamps?.form_attributes,*/}
                                        {/*      opportunityFor: rdCamps?.opportunity_for,*/}
                                        {/*      campId: rdCamps?.id,*/}
                                        {/*      campFormId: rdCamps?.campaign_form_id*/}
                                        {/*    })*/}
                                        {/*  }}*/}
                                        {/*  id={`NewUncontrolledTooltip_${rdCamps?.id}`}*/}
                                        {/*>*/}
                                        {/*  <List size={14} />*/}
                                        {/*  <UncontrolledTooltip placement='top' target={`NewUncontrolledTooltip_${rdCamps?.id}`}>*/}
                                        {/*    Details*/}
                                        {/*  </UncontrolledTooltip>*/}
                                        {/*</Button.Ripple>*/}
                                        {!rdCamps?.is_applied && rdCamps?.can_apply && (
                                            <Button.Ripple
                                                tag={Link}
                                                to={{
                                                    pathname: `/dashboard/rd/campaign-application/${rdCamps?.id}`,
                                                    state: {
                                                        brandName: rdCamps?.brand_name,
                                                        campName: rdCamps?.campaign_title,
                                                        brandImage: rdCamps?.brand_img,
                                                        campDetail: rdCamps?.campaign_details,
                                                        formTitle: rdCamps?.form_title,
                                                        formAttributes: rdCamps?.form_attributes,
                                                        opportunityFor: rdCamps?.opportunity_for,
                                                        campId: rdCamps?.id,
                                                        campFormId: rdCamps?.campaign_form_id,
                                                        rdDetails: rdCampaignSelector?.allCampaignsListing?.rd_details
                                                    }
                                                }}
                                                color="secondary"
                                                className="m-25 healthcare"
                                                id={`UncontrolledTooltip_${rdCamps?.id}`}
                                            >
                                                <CheckCircle size={14}/> Learn More &amp; Apply
                                                {/*<UncontrolledTooltip placement='top' target={`UncontrolledTooltip_${rdCamps?.id}`}>*/}
                                                {/*Learn More &amp; Apply.*/}
                                                {/*</UncontrolledTooltip>*/}
                                            </Button.Ripple>
                                        )}
                                        {rdCamps?.is_applied ? (
                                            <Button.Ripple
                                                onClick={() => getAppliedCampaignData(rdCamps?.id)}
                                                color="secondary"
                                                className="m-25 healthcare"
                                                id={`UncontrolledTooltip_${rdCamps?.id}`}
                                            >
                                                <List size={14}/> Applied
                                                <UncontrolledTooltip placement='top' target={`UncontrolledTooltip_${rdCamps?.id}`}>
                                                    Applied
                                                </UncontrolledTooltip>
                                            </Button.Ripple>
                                        ) : rdCamps?.can_apply === false ? (
                                            <Button.Ripple color="danger"
                                                           className="m-25 healthcare"
                                                           disabled={true}
                                            >
                                                <XCircle size={14}/> Limit exceeded.
                                            </Button.Ripple>
                                        ) : null}
                                    </div>
                                </Col>
                            </Fragment>
                        ))}
                </Row>

                <Row className="pt-4 justify-content-centered">
                    <Col md="12">
                        <CustomPagination
                            pageLinks={paginationInfo?.pages}
                            onPaginationClick={handleCustomPagination}
                        />
                    </Col>
                </Row>
            </div>
            <Modal
                size="lg"
                isOpen={appliedDetailModal}
                toggle={() => setAppliedDetailModal(!appliedDetailModal)}
                className="modal-dialog-centered"
            >
                <ModalHeader toggle={() => setAppliedDetailModal(!appliedDetailModal)}>
                    Applied Campaign Detail
                </ModalHeader>
                {rdRecommendedCampaignsDetailLoading ? (
                    <div className="w-100 my-4 text-center">
                        <Spinner color="primary"/>
                    </div>
                ) : (
                    <ModalBody>
                        <CardText className="bg-light p-1 text-break">
                            <b>{rdRecommendedCampaignsDetailFullFill?.data?.campaign_title}</b>
                        </CardText>

                        <CardText className='text-break pl-1' style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                            <div dangerouslySetInnerHTML={{__html: rdRecommendedCampaignsDetailFullFill?.data?.campaign_details}}></div>
                        </CardText>
                        <CardText className="text-capitalize bg-light p-1">
                        <b>{rdRecommendedCampaignsDetailFullFill?.data?.opportunity_for}</b>
                        </CardText>
                        <CardText className="bg-light p-1">
                            <b>{rdRecommendedCampaignsDetailFullFill?.data?.form_title}</b>
                        </CardText>
                        {rdRecommendedCampaignsDetailFullFill?.data?.form_attributes?.length &&
                            rdRecommendedCampaignsDetailFullFill?.data?.form_attributes.map((dat, index) => (
                                <Fragment key={index}>
                                    <CardText className="p-1 text-break" style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                                        <b>Question #{index + 1}</b>
                                        <br/>
                                        {dat?.question}
                                    </CardText>
                                    <CardText className="p-1 text-break" style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                                        <b>Answer</b><br/>
                                        {dat?.answer}
                                    </CardText>
                                </Fragment>
                            ))}
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button.Ripple
                        type="button"
                        color="primary"
                        onClick={() => setAppliedDetailModal(!appliedDetailModal)}
                    >
                        Close
                    </Button.Ripple>
                </ModalFooter>
            </Modal>
            <Modal
                size="lg"
                isOpen={formModal}
                toggle={() => setFormModal(!formModal)}
                className="modal-dialog-centered"
            >
                <ModalHeader toggle={() => setFormModal(!formModal)}>
                    Campaign Detail
                </ModalHeader>
                <ModalBody>
                    <CardText className="bg-light p-1 text-break">
                        <b>{campInfo?.campName}</b>
                    </CardText>

                    <CardText className='text-break pl-1' style={{whiteSpace: "break-spaces", textAlign: "justify"}}>
                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>)}>
                            {campInfo?.campDetail}
                        </Linkify>

                    </CardText>
                    <CardText className="text-capitalize bg-light p-1">
                        <b>{campInfo?.opportunityFor}</b>
                    </CardText>
                    <CardText className="bg-light p-1">
                        <b>{campInfo?.formTitle}</b>
                    </CardText>
                    {campInfo?.formAttributes?.length > 0 &&
                        campInfo?.formAttributes.map((dat, i) => (
                            <Fragment key={i}>
                                <CardText className="pl-1 text-break">
                                    {++i}. {dat?.question}
                                </CardText>
                            </Fragment>
                        ))}
                </ModalBody>
                <ModalFooter>
                    <Button.Ripple
                        type="button"
                        color="primary"
                        onClick={() => setFormModal(!formModal)}
                    >
                        Close
                    </Button.Ripple>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}
export default TabsBasic
