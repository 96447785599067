import DashboardStats from "@src/views/rds/dashboard/DashboardStats"
import RecommendedBrands from "@src/views/rds/dashboard/RecommendedBrands"
import RecommendedBrandCampaigns from "@src/views/rds/dashboard/RecommendedBrandCampaigns"
import MyPartnerships from "@src/views/rds/dashboard/MyPartnerships"
import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap"
import {useDispatch, useSelector} from "react-redux"
import {
    verifyEmailAction,
    verifyEmailResetAction
} from '@src/views/pages/emailVerification/store/actions'
import {
    sendVerifyEmailLinkAction,
    sendVerifyEmailLinkResetAction
} from "@src/views/apps/opportunitiesBrands/store/actions"
import {
    getVerificationLink,
    getVerificationLinkReset
} from "@src/views/apps/opportunitiesBrands/store/actions/index"
import {useEffect, useState} from "react"
import {AlertCircle, CheckCircle} from "react-feather"
import {Formik} from "formik"
import * as yup from "yup"
import {Link} from "react-router-dom"

const RdDashboard = () => {
    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState(false)

    const data = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))

    const {
        verifyEmailLoading,
        verifyEmailData,
        verifyEmailError
    } = useSelector((store) => store.verifyEmailReducer)

    const {
        emailVerificationLoading,
        emailVerificationData,
        emailVerificationError
    } = useSelector(store => store.emailVerificationReducer)

    const {
        rdAccountVerifyLoading,
        rdAccountVerifyFullFill,
        rdAccountVerifyError
    } = useSelector((store) => store.brandsOppoprunities)

    const handleSendEmail = () => {
        setOpenModal(true)
        dispatch(sendVerifyEmailLinkAction())
    }

    const handleCloseModal = () => {
        dispatch(sendVerifyEmailLinkResetAction())
        dispatch(verifyEmailResetAction())
        setOpenModal(false)
    }

    const handleVerify = () => {
        dispatch(getVerificationLink())
    }

    useEffect(() => {
        if (emailVerificationData?.status) {
            const data = JSON.parse(localStorage.getItem(process.env.REACT_APP_USERDATA))
            data.email_verified = true
            localStorage.setItem(process.env.REACT_APP_USERDATA, JSON.stringify(data))
        }
    }, [emailVerificationData])

    useEffect(() => {
        if (rdAccountVerifyFullFill?.data?.url) {
            dispatch(getVerificationLinkReset())
            window.open(rdAccountVerifyFullFill?.data?.url, "_self")
        }
    }, [rdAccountVerifyFullFill])

    return (
        <div id="dashboard-brands">
            {data && data.email_verified === false && (
                <div>
                    <Alert color='danger'>
                        <div className='alert-body'>
                            <span>Your email is not verified. </span>
                            <span className="cursor-pointer"
                                  onClick={handleSendEmail}>
                            <u>Click to verify your email.</u>
                        </span>
                        </div>
                    </Alert>
                </div>
            )}
            {(data && data.stripe_account === false) ? (
                <div>
                    <Alert color='danger'>
                        <div className='alert-body'>
                            <span>Your bank account is not connected. </span>
                            <Link to="/dashboard/rd/payment-settings" className="text-danger">
                                <u>Click to add you bank account.</u>
                            </Link>
                        </div>
                    </Alert>
                </div>
            ) : (data && data.stripe_account && data.stripe_account_status === false) && (
                <div>
                    <Alert color='danger'>
                        <div className='alert-body'>
                            {rdAccountVerifyLoading ? (
                                <span><Spinner style={{width: '16px', height: '16px'}}/> Please wait we are redirecting you to the stripe dashboard...</span>
                            ) : (
                                <>
                                    <span>Your stripe account is not verified . </span>
                                    <span className="cursor-pointer" onClick={handleVerify}><u>Click to verify your account.</u></span>
                                </>
                            )}
                        </div>
                    </Alert>
                </div>
            )}
            <Row className="mb-1">
                <Col>
                    <DashboardStats cols={{xl: "", sm: "4"}}/>
                </Col>
            </Row>
            <Row>
                <Col xl="12" md="12" xs="12">
                    <RecommendedBrands/>
                </Col>
            </Row>
            <Row>
                <Col xl="12" md="12" xs="12">
                    <RecommendedBrandCampaigns/>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col xs="12">
                    <MyPartnerships/>
                </Col>
            </Row>
            <Modal isOpen={openModal} className='modal-dialog-centered'>
                <ModalHeader>Verify Email</ModalHeader>
                <ModalBody>
                    {verifyEmailLoading ? (
                        <div className="text-center"><Spinner color="primary"/></div>) : verifyEmailData?.status ? (
                        <div className="text-break">
                            <p><CheckCircle size={14} style={{color: '#56B6FF'}}/> Email verification code has been sent
                                to your email address.</p>
                            <p><CheckCircle size={14} style={{color: '#56B6FF'}}/> Copy and paste the code below to
                                verify your email.</p>
                            {emailVerificationData?.status === true && (
                                <Alert color='success'>
                                    <div className='alert-body'>
                                        <span>{emailVerificationData?.msg}</span>
                                    </div>
                                </Alert>
                            )}
                            {emailVerificationData?.status === false && (
                                <Alert color='danger'>
                                    <div className='alert-body'>
                                        <span>{emailVerificationData?.msg}</span>
                                    </div>
                                </Alert>
                            )}
                            <Formik
                                initialValues={{
                                    code: ''
                                }}
                                validationSchema={yup.object().shape({
                                    code: yup.number().required("This field is required.").typeError('Should be a number')
                                })}
                                onSubmit={(values) => {
                                    console.log(values)
                                    dispatch(verifyEmailAction({token: values.code}))
                                }}
                            >
                                {({
                                      errors,
                                      handleBlur,
                                      handleChange,
                                      handleSubmit,
                                      touched,
                                      values,
                                      setFieldValue
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Input
                                                type='number'
                                                name="code"
                                                id="code"
                                                placeholder='Paste code here...'
                                                value={values.code}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={emailVerificationLoading || emailVerificationData?.status}
                                            />
                                            <div className="validation-err">{touched.code && errors.code}</div>
                                        </FormGroup>
                                        <div className="text-right">
                                            <Button color='danger' onClick={handleCloseModal} className="mr-1"
                                                    disabled={emailVerificationLoading}>
                                                Close
                                            </Button>
                                            <Button color='primary' type="submit"
                                                    disabled={emailVerificationLoading || emailVerificationData?.status}>
                                                {emailVerificationLoading ? (
                                                    <Spinner style={{width: '16px', height: '16px'}}/>) : "Verify"}
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <div>
                            <p className="text-center"><AlertCircle size={50}/></p>
                            <p>{verifyEmailData?.msg}</p>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default RdDashboard
