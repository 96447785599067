import {useState, Fragment, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {
    ChevronDown,
    RefreshCcw,
    Search,
    MoreHorizontal,
    Trash,
    Edit,
    PlusSquare
} from 'react-feather'
import DataTable from 'react-data-table-component'
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Row,
    Col,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    CustomInput,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner,
    Form
} from 'reactstrap'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import CustomPagination from '@src/views/components/pagination/CustomPagination'
import {
    adminBlogListAction,
    adminBlogDeleteAction,
    adminBlogDeleteResetAction,
    adminBlogStatusAction
} from "@store/actions/AdminActions"
import queryString from "query-string"
import {useFormik} from "formik"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ManageBlogs = () => {
    const sweetAlert = withReactContent(Swal)
    const dispatch = useDispatch()

    const [readMoreModal, setReadMoreModal] = useState(false)
    const [description, setDescription] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [deleteBlogModal, setDeleteBlogModal] = useState(false)

    const {
        adminBlogListLoading,
        adminBlogListData,
        adminBlogListError,

        adminBlogDeleteLoading,
        adminBlogDeleteData,
        adminBlogDeleteError
    } = useSelector(store => store.adminReducer)

    const searchForm = useFormik({
        initialValues: {
            title: '',
            desc: ''
        },
        onSubmit: (values) => {
            dispatch(adminBlogListAction(`?title=${values.title}&description=${values.desc}`))
        }
    })

    useEffect(() => {
        if (adminBlogDeleteData?.success) {
            sweetAlert.fire('Congratulations!', adminBlogDeleteData?.msg, 'success')
            dispatch(adminBlogDeleteResetAction())
            dispatch(adminBlogListAction(``))
        }
    }, [adminBlogDeleteData])

    useEffect(() => {
        dispatch(adminBlogListAction(``))
    }, [])

    const handleReadMoreClick = (e, colName, cellValue) => {
        setDescription(cellValue)
        setModalTitle(colName)
        setReadMoreModal(true)
    }

    const deleteBlog = (id) => {
        sweetAlert.fire({
            title: 'Confirm!',
            icon: 'warning',
            html: `Do you want to delete this blog?`,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminBlogDeleteAction({
                    id
                }))
            }
        })
    }

    const handleCustomPagination = (pageUrl) => {
        const parsed = queryString.parseUrl(pageUrl)
        const pageNo = parsed?.query?.page
        const title = searchForm.values.title
        const desc = searchForm.values.desc
        dispatch(adminBlogListAction(`?title=${title}&description=${desc}&page=${pageNo}`))
    }

    const [advSearchColumns] = useState([
        {
            name: 'Title',
            selector: 'title',
            sortable: false,
            minWidth: '100px'
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: false,
            minWidth: '320px',
            wrap: false,
            allowOverflow: false,
            omit: false,
            cell: row => {
                return (
                    <div>
                        <div className="text-truncate" style={{maxWidth: '300px'}} dangerouslySetInnerHTML={{__html: row.description}}></div>
                        <button onClick={e => handleReadMoreClick(e, row.title, row.full_description)} className='btn btn-link p-0'>
                            Read More
                        </button>
                    </div>
                )
            }
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            minWidth: '40px',
            cell: row => {
                return (
                    <CustomInput className='custom-control-success'
                                 type='switch'
                                 id={`success_${row.id}`}
                                 defaultChecked={row.status === 'enabled'}
                                 onClick={() => dispatch(adminBlogStatusAction({id: row.id}))}
                    />
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: row => {
                return (
                    <div className='d-flex pl-2'>
                        <UncontrolledDropdown className='cursor-pointer'>
                            <DropdownToggle className='pr-1' tag='span'>
                                <MoreHorizontal size={15}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={Link} to={`/dashboard/admin/blogs/${row.id}`}>
                                    <Edit size={15}/>
                                    <span className='align-middle ml-50'>Edit</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => deleteBlog(row.id)}>
                                    <Trash size={15}/>
                                    <span className='align-middle ml-50'>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ])

    return (
        <Fragment>
            <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-bottom mb-3'>
                    <CardTitle tag='h4'>Blogs</CardTitle>
                    <Button.Ripple tag={Link} to="/dashboard/admin/blogs/add" color='primary'>
                        <PlusSquare size={15}/> Add Blog
                    </Button.Ripple>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={searchForm.handleSubmit}>
                        <Row>
                            <Col lg='3' md='4'>
                                <FormGroup>
                                    <Input id='title'
                                           name='title'
                                           placeholder='Blog Title'
                                           onBlur={searchForm.handleBlur}
                                           onChange={searchForm.handleChange}
                                           value={searchForm.values.title}
                                           disabled={adminBlogListLoading || adminBlogDeleteLoading}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg='3' md='4'>
                                <FormGroup>
                                    <Input id='desc'
                                           name='desc'
                                           placeholder='Blog Description'
                                           onBlur={searchForm.handleBlur}
                                           onChange={searchForm.handleChange}
                                           value={searchForm.values.desc}
                                           disabled={adminBlogListLoading || adminBlogDeleteLoading}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg='3' md='4'>
                                <Button.Ripple className='btn-icon mr-10px' color='primary' type='submit' disabled={adminBlogListLoading || adminBlogDeleteLoading}>
                                    <Search size={16}/> Search
                                </Button.Ripple>
                                <Button.Ripple className='btn-icon' outline color='primary' type='button' onClick={() => {
                                    searchForm.resetForm()
                                    dispatch(adminBlogListAction(``))
                                }} disabled={adminBlogListLoading || adminBlogDeleteLoading}>
                                    <RefreshCcw size={16}/> Reset
                                </Button.Ripple>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>

                {(adminBlogDeleteLoading) && (
                    <div className="text-center bg-light p-1 mb-1">
                        <Spinner color='primary' style={{height: '16px', width: '16px'}}/> Please wait...
                    </div>
                )}

                {adminBlogListLoading ? (
                    <div className="w-100 my-4 text-center"><Spinner color='primary'/></div>
                ) : (
                    <DataTable noHeader
                               paginationServer
                               columns={advSearchColumns}
                               className='react-dataTable'
                               sortIcon={<ChevronDown size={10}/>}
                               data={adminBlogListData?.data}
                    />
                )}

                <CustomPagination pageLinks={adminBlogListData?.pages} onPaginationClick={handleCustomPagination}/>

                <Modal isOpen={readMoreModal} className='modal-dialog-centered' size='lg'>
                    <ModalHeader toggle={() => setReadMoreModal(false)}>
                        {modalTitle}
                    </ModalHeader>
                    <ModalBody>
                        <div dangerouslySetInnerHTML={{__html: description}}/>
                    </ModalBody>
                </Modal>

                <Modal isOpen={deleteBlogModal} toggle={() => setDeleteBlogModal(false)} className='modal-dialog-centered'>
                    <ModalHeader toggle={() => setDeleteBlogModal(false)}>Delete Blog</ModalHeader>
                    <ModalBody>
                        Are you sure want to delete this blog?
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={() => setDeleteBlogModal(false)}>
                            No
                        </Button>
                        <Button color='danger' onClick={deleteBlog}>
                            Yes
                        </Button>
                    </ModalFooter>
                </Modal>
            </Card>
        </Fragment>
    )
}

export default ManageBlogs
