import axios from 'axios'
import {API_URL, RD, NULL, CHANGE_PARTNERSHIP_STATUS, SEND_INVOICE, RD_MANAGE_PARTNERSHIPS} from '../actionTypes/index'

const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_USERTOKEN)
}

export const getAllAttrs = () => {
  return dispatch => {
    return axios.get(`${API_URL}brand-product-attr`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      dispatch({ type: RD.FILTERS, filters: res.data })
    }).catch(error => {
      dispatch({ type: RD.ERROR, error })
    })
  }
}

export const getBrandDetail = (id) => {
  return dispatch => {
    return axios.get(`${API_URL}rd/view-brand-profile?id=${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }).then(res => {
      dispatch({ type: RD.DETAIL, brandDetail: res.data })
    }).catch(error => {
      dispatch({ type: RD.ERROR, error })
    })
  }
}

export const getManagePartnerships = (qryString) => async (dispatch) => {
  dispatch({type: RD_MANAGE_PARTNERSHIPS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.get(`${API_URL}rd/manage-partnerships${qryString}`, {headers: header})
    dispatch({type: RD_MANAGE_PARTNERSHIPS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: RD_MANAGE_PARTNERSHIPS.ERROR, payload: error})
  }
}

export const partnershipChangeStatus = (dataObj) => async (dispatch) => {
  dispatch({type: CHANGE_PARTNERSHIP_STATUS.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}rd/update-participation-status`, dataObj, {headers: header})
    dispatch({type: CHANGE_PARTNERSHIP_STATUS.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: CHANGE_PARTNERSHIP_STATUS.ERROR, payload: error})
  }
}

export const partnershipChangeStatusReset = () => async (dispatch) => {
  dispatch({type: CHANGE_PARTNERSHIP_STATUS.RESET})
}

export const partnershipSendInvoice = (dataObj) => async (dispatch) => {
  dispatch({type: SEND_INVOICE.REQUEST})
  const header = {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`
  }
  try {
    const {data} = await axios.post(`${API_URL}rd/send-invoice`, dataObj, {headers: header})
    dispatch({type: SEND_INVOICE.SUCCESS, payload: data})
  } catch (error) {
    dispatch({type: SEND_INVOICE.ERROR, payload: error})
  }
}

export const partnershipSendInvoiceReset = (dataObj) => async (dispatch) => {
  dispatch({type: SEND_INVOICE.RESET})
}

// ** GET Products
// export const getProducts = params => {
//   return dispatch => {
//     return dispatch({ type: 'GET_PRODUCTS', data: [], params: null })
//     // return axios.get('/apps/brandsRd/products', { params }).then(res => {
//     //   dispatch({ type: 'GET_PRODUCTS', data: res.data, params })
//     // })
//   }
// }

// ** Add Item to Cart
// export const addToCart = id => {
//   return (dispatch, getState) => {
//     return axios.post('/apps/brandsRd/cart', { productId: id }).then(res => {
//       dispatch({ type: 'ADD_TO_CART', data: res.data })
//       dispatch(getProducts(getState().ecommerce.params))
//     })
//   }
// }

// ** GET Wishlist Items
// export const getWishlistItems = () => {
//   return dispatch => {
//     return axios.get('/apps/brandsRd/wishlist').then(res => {
//       dispatch({ type: 'GET_WISHLIST', data: res.data })
//     })
//   }
// }

// ** DELETE Wishlist Item
// export const deleteWishlistItem = id => {
//   return dispatch => {
//     return axios.delete(`/apps/brandsRd/wishlist/${id}`).then(res => {
//       dispatch({ type: 'DELETE_WISHLIST_ITEM', data: res.data })
//       dispatch(getWishlistItems())
//     })
//   }
// }

// ** GET Cart Items
// export const getCartItems = () => {
//   return dispatch => {
//     return axios.get('/apps/brandsRd/cart').then(res => {
//       dispatch({ type: 'GET_CART', data: res.data })
//     })
//   }
// }

// ** GET Single Product
// export const getProduct = slug => {
//   return dispatch => {
//     return axios.get(`/apps/brandsRd/products/${slug}`).then(res => {
//       dispatch({ type: 'GET_PRODUCT', data: res.data })
//     })
//   }
// }

// ** Add Item to Wishlist
// export const addToWishlist = id => {
//   return dispatch => {
//     return axios.post('/apps/brandsRd/wishlist', { productId: id }).then(() => {
//       dispatch({ type: 'ADD_TO_WISHLIST' })
//     })
//   }
// }

// ** DELETE Cart Items
// export const deleteCartItem = id => {
//   return dispatch => {
//     return axios.delete(`/apps/brandsRd/cart/${id}`).then(res => {
//       dispatch({ type: 'DELETE_CART_ITEM', data: res.data })
//       dispatch(getCartItems())
//     })
//   }
// }

export const nullResponse = (property) => {
  return dispatch => {
      switch (property) {
          case NULL.SEND_INVOICE:
              dispatch({ type: RD.SEND_INVOICE, sendInvoice: null })
              break
          case NULL.STATUS:
              dispatch({ type: RD.STATUS, changStatus: null })
              break
      }
  }
}